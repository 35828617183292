import { useAttachAdditionalData } from "@sk/hooks";
import { DealService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  KeyVal,
  Spinner,
  Toaster,
} from "@sk/uis";
import React, { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import ClubDealPurchaseLimitAuditLog from "../components/ClubDealPurchaseLimitAuditLog";

const canvasStyle = {
  width: "50%",
};

const additionDataConfig = [
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "createdByLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const ClubDealPurchaseLimitViewModal = ({ show, callback, configId }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({});

  useEffect(() => {
    if (show) {
      if (configId) {
        loadDetails(configId);
      } else {
        setLoading(false);
      }
    }
  }, [configId, loadDetails, show]);

  const loadDetails = useCallback(
    async (id) => {
      setLoading(true);
      const r = await DealService.getClubDealPurchaseLimit({
        filter: { _id: id },
      });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp : [];
      if (d.length > 0) {
        let tmp = [];

        setAdditionalData(d, additionDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == additionDataConfig.length) {
            setDetails((v) => ({ ...v, ...[...attachAllData(d, tmp)][0] }));
          }
        });

        setDetails(d[0]);
        setLoading(false);
      } else {
        setLoading(false);
        Toaster.error("Failed to load details");
        callback({ status: "closed" });
      }
    },
    [attachAllData, callback, setAdditionalData]
  );

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} placement="end" style={canvasStyle} onHide={onHide}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">
            View Purchase Limit {details?._id ? " - #" + details?._id : ""}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        {loading ? (
          <div className="text-center p-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="p-4">
              <div className="row">
                <div className="col-12">
                  <KeyVal label="Deal" template="col" className="mb-4">
                    {details?.dealName} - {details?.dealId}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal
                    label="Per Order Limit"
                    template="col"
                    className="mb-4"
                  >
                    {details._perOrderLimit} {details._unitType}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal label="Per Day Limit" template="col" className="mb-4">
                    {details._perDayLimit} {details._unitType}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal
                    label="Per Month Limit"
                    template="col"
                    className="mb-4"
                  >
                    {details._perMonthLimit} {details._unitType}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal label="Status" template="col" className="mb-4">
                    <HighlightText status={details.status}>
                      {details.status}
                    </HighlightText>
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal label="Created By" template="col" className="mb-4">
                    {details._createdBy?.name}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal label="Created On" template="col" className="mb-4">
                    <DateFormatter date={details.createdAt} />
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal
                    label="Last Updated By"
                    template="col"
                    className="mb-4"
                  >
                    {details._modifiedBy?.name || "--"}
                  </KeyVal>
                </div>
                <div className="col-4">
                  <KeyVal
                    label="Last Updated On"
                    template="col"
                    className="mb-4"
                  >
                    <DateFormatter date={details.lastUpdated} />
                  </KeyVal>
                </div>
              </div>
            </div>

            <ClubDealPurchaseLimitAuditLog auditLogs={details.auditLog} />
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ClubDealPurchaseLimitViewModal;
