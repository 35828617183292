/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, DealService, UtilityService } from "@sk/services";
import {
  Alert,
  AppTitle,
  BooleanCheckboxInput,
  EntitySearchInput,
  PageLoader,
  RadioInput,
  Spinner,
  Toaster,
  InputErrorMsg,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import AccessLevelConfig from "./components/AccessLevelConfig";

// Validation Schema
const validationSchema = yup.object({
  type: yup.string().trim().required("Type is Required"),

  isAllowed: yup.boolean(),

  brand: yup.array().when("type", {
    is: (type) => {
      return ["brand", "brand-category"].indexOf(type) != -1;
    },
    then: (schema) => schema.label("Brand").min(1, "Brand is Required"),
  }),

  category: yup.array().when("type", {
    is: (type) => {
      return ["brand-category"].indexOf(type) != -1;
    },
    then: (schema) => schema.label("Category").min(1, "Category is Required"),
  }),

  deal: yup.array().when("type", {
    is: (type) => {
      return type == "deal";
    },
    then: (schema) => schema.label("Deal").min(1, "Deal is Required"),
  }),

  location: yup.array().label("Level").min(1, "Level is Required").required(),
});

const typeOptions = [
  { label: "Brand", value: "brand" },
  { label: "Brand/Category", value: "brand-category" },
  { label: "Deal", value: "deal" },
];

// default From Values
const defaultFromData = {
  brand: [],
  category: [],
  deal: [],
  isAllowed: false,
  type: "",
};

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "45%",
  },
};

const ManageDealRestrictionModal = ({
  show,
  callback,
  stateListOptions = [],
  rmfSubtypeOptions = [],
  rfSubtypeOptions = [],
  sfSubtypeOptions = [],
}) => {
  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  // React Hook Form
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultFromData },
  });

  const [type] = useWatch({ control: methods.control, name: ["type"] });

  useEffect(() => {
    // methods.reset();
  }, []);

  // Preparing Payload While Hitting API
  const preparePayload = (d) => {
    let p = {
      isAllowed: d.isAllowed,
    };
    if (["brand", "brand-category"].indexOf(d.type) != -1) {
      p.brand_id = d.brand[0].value._id;
    }
    if (["brand-category"].indexOf(d.type) != -1) {
      p.cat_id = d.category[0].value._id;
    }
    if (["deal"].indexOf(d.type) != -1) {
      p.deal = d.deal[0].value._id;
    }

    return [p];
  };

  // for resetting the form data and its dependent state
  const resetFormData = () => {
    // reset();
  };

  const onSubmit = async (data) => {
    console.log("d", data);
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);

    let r = await DealService.createRestrictedConfig(p);

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0] || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success("Added Successfully");

    triggerCloseModal("submit");
  };

  // To Close Modal
  const triggerCloseModal = (status = "cancel") => {
    callback({ status });
  };

  const onTypeChange = (chngfn) => (e) => {
    methods.setValue("brand", []);
    methods.setValue("category", []);
    methods.setValue("deal", []);
    chngfn(e);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title="Add Deal Restriction"
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div>
                <FormProvider
                  {...methods}
                  // onSubmit={methods.handleSubmit(onSubmit)}
                >
                  <form autoComplete="off">
                    <div className="row">
                      {/* Type */}
                      <div className="col-12 mb-3">
                        <Controller
                          name="type"
                          render={({ field: { value, onChange } }) => (
                            <RadioInput
                              label="Type"
                              register={methods.register}
                              name="type"
                              cols="4"
                              value={value}
                              onChange={onTypeChange(onChange)}
                              error={methods?.formState?.errors?.type?.message}
                              isMandatory={true}
                              options={typeOptions}
                            />
                          )}
                        />
                      </div>

                      {/* Brand Name  */}
                      {["brand", "brand-category"].indexOf(type) != -1 ? (
                        <div className="col-12 mb-3">
                          {" "}
                          <Controller
                            control={methods.control}
                            name="brand"
                            key="brand"
                            render={({ field: { onChange, value } }) => (
                              <EntitySearchInput
                                type="brand"
                                label="Search for Brand"
                                name="brand"
                                placeholder="Search by  Id/Name"
                                value={value}
                                error={
                                  methods?.formState?.errors?.brand?.message
                                }
                                isMandatory={true}
                                callback={onChange}
                                uid="brand"
                                emptyLabel="No Brand Found"
                              />
                            )}
                          />
                        </div>
                      ) : null}

                      {/* Category Name  */}
                      {["brand-category"].indexOf(type) != -1 ? (
                        <div className="col-12 mb-3">
                          <Controller
                            control={methods.control}
                            name="category"
                            key="category"
                            render={({ field: { onChange, value } }) => (
                              <EntitySearchInput
                                type="category"
                                label="Search for Category"
                                name="category"
                                placeholder="Search by  Id/Name"
                                value={value}
                                error={
                                  methods?.formState?.errors?.category?.message
                                }
                                isMandatory={true}
                                callback={onChange}
                                uid="category"
                                emptyLabel="No Category Found"
                              />
                            )}
                          />
                        </div>
                      ) : null}

                      {/* Deal Name  */}
                      {["deal"].indexOf(type) != -1 ? (
                        <div className="col-12 mb-3">
                          <Controller
                            control={methods.control}
                            name="deal"
                            key="deal"
                            render={({ field: { onChange, value } }) => (
                              <EntitySearchInput
                                type="deal"
                                label="Search for Deal"
                                name="deal"
                                placeholder="Search by  Id/Name"
                                value={value}
                                error={
                                  methods?.formState?.errors?.deal?.message
                                }
                                isMandatory={true}
                                callback={onChange}
                                uid="deal"
                                emptyLabel="No Deal Found"
                              />
                            )}
                          />
                        </div>
                      ) : null}

                      {/*  Is Allowed */}
                      <div className="col-12 mb-3">
                        <Controller
                          control={methods.control}
                          name="isAllowed"
                          render={({ field: { onChange, value } }) => (
                            <BooleanCheckboxInput
                              label="Is  Allowed?"
                              name="isAllowed"
                              error={
                                methods?.formState?.errors?.isAllowed?.message
                              }
                              isMandatory={false}
                              callback={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2 mb-3 fw-semibold fs-valmd">
                        Levels
                      </div>
                      <InputErrorMsg
                        msg={methods?.formState?.errors?.location?.message}
                      />
                      <AccessLevelConfig
                        stateListOptions={stateListOptions}
                        rmfSubtypeOptions={rmfSubtypeOptions}
                        rfSubtypeOptions={rfSubtypeOptions}
                        sfSubtypeOptions={sfSubtypeOptions}
                      />
                    </div>

                    <div className="my-3 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-dark me-1"
                        onClick={triggerCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        // type="submit"
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={submitting}
                      >
                        Submit {submitting ? <Spinner isSmall={true} /> : null}
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ManageDealRestrictionModal);
