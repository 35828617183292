import { FranchiseService } from "@sk/services";
import { Amount, KeyVal, Spinner, Rbac } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import classNames from "classnames";

const rbac = {
  viewSlc: ["ViewSkLandingCost"],
};

const StockDisplay = ({
  fid,
  dealId,
  displayMrp = false,
  displayRsp = false,
  type = 1,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((fid, dealId)) {
      loadPosStockDetails();
    }
  }, [loadPosStockDetails, fid, dealId]);

  const loadPosStockDetails = useCallback(async () => {
    let p = {
      groupbycond: "deal",
      groupbycondName: "name",
      dealFilter: { _id: { $in: [dealId] } },
      filter: { _id: fid },
    };
    setLoading(true);
    setData({});
    let m = await FranchiseService.getFranchiseInventorySummary(p);
    setData(Array.isArray(m.resp) && m.resp.length > 0 ? m.resp[0] : {});
    setLoading(false);
  }, [setData, setLoading]);

  return (
    <div>
      {type == 1 ? (
        <div className="row mt-2">
          <div className="col-6 text-start ">
            <KeyVal label="Stock Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?._qty || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
          <div className="col-5 text-start">
            <KeyVal label="Reserve Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?.reserveConfig?._available || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
        </div>
      ) : (
        <div className="row mt-2">
          <div className="col-auto ">
            <KeyVal label="MRP" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  <Amount value={data?.mrp || 0} decimalPlace={2} />
                </span>
              )}
            </KeyVal>
          </div>

          <div className="col-auto ">
            <KeyVal label="RSP" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  <Amount value={data?.retailerSellingPrice} decimalPlace={2} />
                </span>
              )}
            </KeyVal>
          </div>
          <div className="col-auto ">
            <KeyVal label="Stock Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?._qty || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
          <div className="col-auto ">
            <KeyVal label="Reserve Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?.reserveConfig?._available || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
          <Rbac roles={rbac.viewSlc}>
            <div className="col-auto">
              <KeyVal label="Store PNL" template="col">
                <span
                  className={classNames({
                    "fs-val-md": true,
                    "text-success":
                      (data?.retailerSellingPrice || 0) -
                        (data?.b2bprice || 0) >
                      0,
                    "text-danger":
                      (data?.retailerSellingPrice || 0) -
                        (data?.b2bprice || 0) <
                      0,
                    "text-primary":
                      (data?.retailerSellingPrice || 0) -
                        (data?.b2bprice || 0) ==
                      0,
                  })}
                >
                  <Amount
                    value={
                      (data?.retailerSellingPrice || 0) - (data?.b2bprice || 0)
                    }
                    decimalPlace={2}
                  />
                </span>
              </KeyVal>
            </div>
            <div className="col-auto">
              <KeyVal label="SK PNL" template="col">
                <span
                  className={classNames({
                    "fs-val-md": true,
                    "text-success":
                      (data?.retailerSellingPrice || 0) - (data?.slc || 0) > 0,
                    "text-danger":
                      (data?.retailerSellingPrice || 0) - (data?.slc || 0) < 0,
                    "text-primary":
                      (data?.retailerSellingPrice || 0) - (data?.slc || 0) == 0,
                  })}
                >
                  <Amount
                    value={(data?.retailerSellingPrice || 0) - (data?.slc || 0)}
                    decimalPlace={2}
                  />
                </span>
              </KeyVal>
            </div>
          </Rbac>
        </div>
      )}
    </div>
  );
};

export default memo(StockDisplay);
