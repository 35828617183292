import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CommonService, FranchiseService } from "@sk/services";
import {
  Amount,
  AutoCompleteInput,
  KeyVal,
  Rbac,
  SelectInput,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import tooltips from "../../../tooltipservice";
import classNames from "classnames";
const rbac = {
  viewSlc: ["ViewSkLandingCost"],
};

const defaultFromData = {
  deal: [],
  isFree: false,
  discountType: "Percentage",
  qty: "",
  mrp: "",
  sellInLooseQty: false,
  price: "",
  discountValue: "",
  category: [],
  brand: [],
  isFixedMrp: false,
  mrpValue: null,
};

const offerProductTypeOptions = [
  { label: "Deal", value: "deal" },
  {
    label: "Category",
    value: "category",
  },
  { label: "Brand", value: "brand" },
  { label: "Category-Brand", value: "category-brand" },
];

const info = tooltips.info;

const discountTypeDropdownOptions = [
  {
    label: " % ",
    value: "Percentage",
    inputLabel: "Discount in %",
  },
  { label: "₹", value: "Fixed", inputLabel: "  Discount in ₹" },
];

const rewardProductValidationSchema = yup.object({
  deal: yup.array().when("offerProductsType", {
    is: (offerProductsType) => offerProductsType == "deal",
    then: (schema) => schema.min(1, "Please fill deal first").required(),
  }),
  category: yup.array().when("offerProductsType", {
    is: (offerProductsType) =>
      ["category", "category-brand"].indexOf(offerProductsType) != -1,
    then: (schema) =>
      schema.min(1, "Category is Required").required("Category is Required"),
  }),
  brand: yup.array().when("offerProductsType", {
    is: (offerProductsType) =>
      ["brand", "category-brand"].indexOf(offerProductsType) != -1,
    then: (schema) =>
      schema.min(1, "Brand is Required").required("Brand is Required"),
  }),
  isFree: yup.boolean().default(false),
  qty: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("offerProductsType", {
      is: (offerProductsType) => offerProductsType == "deal",
      then: (schema) =>
        schema
          .moreThan(0, "Should be greater than 0")
          .required("Qty is Required"),
    }),

  discountValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["isFree", "discountType", "offerProductsType"], {
      is: (isFree, discountType, offerProductsType) =>
        !isFree && discountType == "Percentage" && offerProductsType == "deal",
      then: (schema) =>
        schema
          .max(99.99, "cannot be greater that 100")
          .required("Discount Value is Required"),
    })
    .moreThan(0, "Should be greater than 0")
    .when(["isFree", "offerProductsType"], {
      is: (isFree, offerProductsType) => !isFree && offerProductsType == "deal",
      then: (schema) => schema.label("Discount Value").required(),
    }),

  isFixedMrp: yup.boolean().default(false),
  mrpValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["isFixedMrp", "offerProductsType"], {
      is: (isFixedMrp, offerProductsType) =>
        !isFixedMrp &&
        ["category", "brand", "category-brand"].indexOf(offerProductsType) !=
          -1,
      then: (schema) =>
        schema
          .moreThan(0, "Should be greater than 0")
          .label("MRP Value")
          .required("MRP  Value is Required"),
    }),
});

const ManageRewardProducts = ({
  data = [],
  callback,
  offerType,
  mainDeal,
  franchise,
  rewardTypeOffer,
  offerProductTypeCallback,
  updatedOfferProductsType,
  mode = "add",
}) => {
  const [discountType, setDiscountType] = useState(
    discountTypeDropdownOptions[0]
  );

  const [disableDealInput, setDisableDealInput] = useState(false);

  const {
    register,
    control,
    reset,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultFromData, offerProductsType: "deal" },
    resolver: yupResolver(rewardProductValidationSchema),
  });

  const dealData = useWatch({ control, name: "deal" })?.[0]?.value;

  const offerProductsType = useWatch({ control, name: "offerProductsType" });

  useEffect(() => {
    if (mode && mode == "edit" && updatedOfferProductsType) {
      setValue("offerProductsType", updatedOfferProductsType);
      trigger("offerProductsType");
    }
  }, [mode, setValue, trigger, updatedOfferProductsType]);

  useEffect(() => {
    if (offerType == "Buy-A Get-A" && mainDeal?._id) {
      setValue("deal", [{ value: mainDeal, label: mainDeal.name }]);
      setDisableDealInput(true);
    } else {
      setDisableDealInput(false);
    }

    if (offerType == "Buy-A Get-A" && !mainDeal?._id) {
      setValue("deal", []);
    }
    trigger("deal");
  }, [offerType, mainDeal]);

  const isFree = useWatch({
    control,
    name: "isFree",
  });

  const isFixedMrp = useWatch({
    control,
    name: "isFixedMrp",
  });

  const getSearchType = (x) => {
    setValue("discountValue", "");
    setDiscountType(x);
    setValue("discountType", x.value);
  };

  const getDiscountTypeByGroupContent = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="none">
          {discountType.label} <i className="bi bi-chevron-down"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {discountTypeDropdownOptions.map((x) => (
            <Dropdown.Item key={x.value} onClick={() => getSearchType(x)}>
              <span className="">{x.label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const validateProduct = (dealData) => {
    const isProductExist = data.find((x) => x.dealId == dealData._id);

    const discountType = getValues("discountType");

    const discountValue = getValues("discountValue");

    let msg = "";

    if (!franchise) {
      msg = "Please add franchise first";
      // If Franchise is not there we will refresh
      reset(defaultFromData);
    } else if (isProductExist) {
      msg = "Item already in list  ";
    } else if (discountType == "Fixed" && discountValue >= dealData.mrp) {
      msg = "Value cannot be greater than MRP";
    } else if (offerType == "Buy-A Get-A" && !mainDeal) {
      msg = "Please add Main Deal first";
    } else if (offerType == "Buy-A Get-A" && mainDeal?._id != dealData._id) {
      msg = "Please select same deal or choose different offer type";
    }

    return {
      msg,
      isValid: !msg,
    };
  };

  const validateBrandCategory = (formData) => {
    let msg = "";

    if (["brand", "category-brand"].indexOf(offerProductsType) != -1) {
      const d = formData.brand?.[0]?.value;
      const isProductExist = data.find((x) => x.brandId == d._id);
      if (isProductExist) {
        msg = "Selected Brand Already in List, Please Choose New One";
      }
    }
    if (["category", "category-brand"].indexOf(offerProductsType) != -1) {
      const d = formData.category?.[0]?.value;
      const isProductExist = data.find((x) => x.categoryId == d._id);
      if (isProductExist) {
        msg = "Selected Category Already in List, Please Choose New One";
      }
    }

    return {
      msg,
      isValid: !msg,
    };
  };

  const addProduct = (formData) => {
    if (
      offerType == "Buy-N Get-N" &&
      rewardTypeOffer == "MANYPRODUCT" &&
      ["category", "category-brand", "brand"].indexOf(offerProductsType) != -1
    ) {
      const v = validateBrandCategory(formData);

      if (!v.isValid) {
        Toaster.error(v.msg);
        return;
      }
    } else {
      const dealData = formData.deal?.[0]?.value;
      const v = validateProduct(dealData);

      if (!v.isValid) {
        Toaster.error(v.msg);
        return;
      }
      if (formData.isFree) {
        delete formData.discountType;
        delete formData.discountValue;
      }
    }
    callback({ formData });
    setValue("mrp", "");
    setValue("price", "");
    reset({
      ...defaultFromData,
    });
    setValue("offerProductsType", formData.offerProductsType);
  };

  const onIsFreeChange = (chngFn) => (val) => {
    chngFn(val);
    const v = getValues("isFree");
    if (v) {
      setValue("discountValue", "");
    }
  };

  const onDiscountValueChange = useCallback(
    debounce((e) => {
      const v = CommonService.roundedByDecimalPlace(
        1 * e.target.value,
        getValues("discountType") == "Percentage" ? 3 : 2
      );
      e.target.value = v;
    }, 700),
    [getValues]
  );

  const onQtyChange = useCallback(
    debounce((e) => {
      if (e.target.value) {
        const v = CommonService.roundedByDecimalPlace(
          1 * e.target.value,
          dealData?.sellInLooseQty ? 3 : 0
        );
        e.target.value = v;
      }
    }, 1500),
    [dealData]
  );

  const showAddButton = useMemo(() => {
    return !(data.length > 0 && offerType == "Buy-A Get-A");
  }, [data.length, offerType]);

  const onProductListChange = async (val, callback) => {
    let params = {
      franchiseId: franchise._id,
      groupbycondName: "deal",
      excludeOutOfStock: true,
      groupbycond: "deal",
      filter: {
        _id: franchise._id,
      },
      dealFilter: {},
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.dealFilter = {
        $or: [{ _id: searchRegex }, { name: searchRegex }],
      };
    }
    const r = await FranchiseService.getFranchiseInventorySummary(params);

    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const onCategoryListChange = async (val, callback) => {
    let params = {
      franchiseId: franchise._id,
      groupbycondName: "name",
      excludeOutOfStock: true,
      groupbycond: "category",
      filter: {
        _id: franchise._id,
      },

      categoryFilter: {},
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.categoryFilter = {
        $or: [{ _id: searchRegex }, { name: searchRegex }],
      };
    }
    const r = await FranchiseService.getFranchiseInventorySummary(params);

    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const onBrandListChange = async (val, callback) => {
    let params = {
      franchiseId: franchise._id,
      groupbycondName: "name",
      excludeOutOfStock: true,
      groupbycond: "brand",
      filter: {
        _id: franchise._id,
      },

      brandFilter: {},
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.brandFilter = {
        $or: [{ _id: searchRegex }, { name: searchRegex }],
      };
    }
    const r = await FranchiseService.getFranchiseInventorySummary(params);

    callback(
      (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const onOfferProductTypeChange = useCallback(
    (d) => {
      setValue("productList", []);
      reset({ ...defaultFromData });
      setValue("offerProductsType", d);
      offerProductTypeCallback({ type: d });
      trigger("offerProductsType");
    },
    [offerProductTypeCallback, reset, setValue, trigger]
  );

  return (
    <div className="">
      {/* Form */}
      <div className="row">
        {/* {dealData?.sellInLooseQty && (
          <div className="alert alert-warning col-12  fs-val-md" role="alert">
            Quantity you enter is in KG
          </div>
        )} */}

        {/* Many Products */}

        {rewardTypeOffer == "MANYPRODUCT" && offerType == "Buy-N Get-N" ? (
          <div className="col-2 mb-3">
            <SelectInput
              name="offerProductsType"
              label="Type"
              register={register}
              options={offerProductTypeOptions}
              callback={onOfferProductTypeChange}
              gap={0}
            />
          </div>
        ) : null}

        {/* Deal */}
        {(offerType == "Buy-N Get-N" &&
          rewardTypeOffer == "MANYPRODUCT" &&
          offerProductsType == "deal") ||
        offerType != "Buy-N Get-N" ||
        rewardTypeOffer != "MANYPRODUCT" ? (
          <>
            <div className="col-11">
              <div className="row">
                <div className="col-5">
                  <Controller
                    control={control}
                    name="deal"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search For Deal"
                        placeholder="Search By Name /ID "
                        value={value}
                        onSearch={onProductListChange}
                        callback={onChange}
                        uid="product-list-search"
                        isMultiple={false}
                        info={{ content: info.product }}
                        emptyLabel="No Deal Found"
                        disabled={!franchise?._id || disableDealInput}
                        error={errors?.deal?.message}
                        gap={0}
                      />
                    )}
                  />
                </div>
                <div className="col-2 ">
                  <TextInput
                    type="number"
                    register={register}
                    name="qty"
                    placeholder="eg., 1,2,4.."
                    isMandatory={true}
                    callback={onQtyChange}
                    error={errors?.qty?.message}
                    label={`Quantity ${
                      dealData?.sellInLooseQty
                        ? `in ${dealData?.defaultUnitType}`
                        : ""
                    } `}
                    info={{ content: info.productQty }}
                  />
                </div>

                <div className="col-auto">
                  <Controller
                    control={control}
                    name="isFree"
                    render={({ field: { onChange, value } }) => (
                      <SwitchInput
                        label="is Free?"
                        name="isFree"
                        callback={onIsFreeChange(onChange)}
                        value={value}
                        info={{ content: info.isFree }}
                      />
                    )}
                  />
                </div>

                {!isFree ? (
                  <div className="col-2">
                    <TextInput
                      type="number"
                      register={register}
                      name="discountValue"
                      isMandatory={true}
                      info={{ content: info.discountValue }}
                      error={errors?.discountValue?.message}
                      label={discountType.inputLabel}
                      callback={onDiscountValueChange}
                      placeholder="Value"
                      groupContent={getDiscountTypeByGroupContent()}
                    />
                  </div>
                ) : null}

                {dealData?._id && (
                  <div className="col-12">
                    <div className="row  mb-3">
                      <div className="col-auto p-1">
                        <div className="row border rounded p-2 m-1">
                          <div className="col-auto">
                            <KeyVal label="MRP" template="col">
                              <span className="fs-val-md">
                                <Amount value={dealData.mrp} decimalPlace={2} />
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-auto">
                            <KeyVal label="RSP" template="col">
                              <span className="fs-val-md">
                                <Amount
                                  value={dealData.retailerSellingPrice}
                                  decimalPlace={2}
                                />
                              </span>
                            </KeyVal>
                          </div>
                        </div>
                      </div>

                      <Rbac roles={rbac.viewSlc}>
                        <div className="col-auto p-1">
                          <div className="row border rounded p-2 m-1">
                            <div className="col-auto">
                              <KeyVal label="Store Landing" template="col">
                                <span className="fs-val-md text-primary">
                                  <Amount
                                    value={dealData.b2bprice}
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>

                            <div className="col-auto">
                              <KeyVal label="Store PNL" template="col">
                                <span
                                  className={classNames({
                                    "fs-val-md": true,
                                    "text-success":
                                      dealData?.retailerSellingPrice -
                                        dealData?.b2bprice >
                                      0,
                                    "text-danger":
                                      dealData?.retailerSellingPrice -
                                        dealData?.b2bprice <
                                      0,
                                    "text-primary":
                                      dealData?.retailerSellingPrice -
                                        (dealData?.b2bprice || 0) ==
                                      0,
                                  })}
                                >
                                  <Amount
                                    value={
                                      dealData?.retailerSellingPrice -
                                      dealData?.b2bprice
                                    }
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto p-1">
                          <div className="row border rounded p-2 m-1">
                            <div className="col-auto">
                              <KeyVal label="Sk Landing" template="col">
                                <span className="fs-val-md text-primary">
                                  <Amount
                                    value={dealData.slc}
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>

                            <div className="col-auto">
                              <KeyVal label="SK PNL" template="col">
                                <span
                                  className={classNames({
                                    "fs-val-md": true,
                                    "text-success":
                                      dealData?.retailerSellingPrice -
                                        dealData?.slc >
                                      0,
                                    "text-danger":
                                      dealData?.retailerSellingPrice -
                                        dealData?.slc <
                                      0,
                                    "text-primary":
                                      dealData?.retailerSellingPrice -
                                        (dealData?.slc || 0) ==
                                      0,
                                  })}
                                >
                                  <Amount
                                    value={
                                      dealData?.retailerSellingPrice -
                                      dealData?.slc
                                    }
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>
                          </div>
                        </div>
                      </Rbac>

                      <div className="col-auto p-1">
                        <div className="row border rounded p-2 m-1">
                          <div className="col-auto">
                            <KeyVal label="Stock Qty" template="col">
                              <span className="fs-val-md">
                                {dealData?._qty || 0}{" "}
                                {dealData?._displayUnitKey}
                              </span>
                            </KeyVal>
                          </div>

                          <div className="col-auto">
                            <KeyVal label="Reserve Qty" template="col">
                              <span className="fs-val-md">
                                {dealData.reserveConfig?._available || 0}{" "}
                                {dealData?._displayUnitKey}
                              </span>
                            </KeyVal>
                          </div>

                          {dealData.sellInLooseQty && (
                            <div className="col-auto">
                              <KeyVal label="Pack Size" template="col">
                                <span className="fs-val-md">
                                  {dealData.packSize}
                                </span>
                              </KeyVal>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}

        {offerType == "Buy-N Get-N" &&
        rewardTypeOffer == "MANYPRODUCT" &&
        ["brand", "category-brand", "category"].indexOf(offerProductsType) !=
          -1 ? (
          <>
            {/* Category */}
            {["category", "category-brand"].indexOf(offerProductsType) != -1 ? (
              <>
                <div className="col-4 mb-3">
                  <Controller
                    control={control}
                    name="category"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search For Category"
                        placeholder="Search By Name /ID "
                        value={value}
                        onSearch={onCategoryListChange}
                        callback={onChange}
                        uid="category-list-search"
                        isMultiple={false}
                        info={{ content: info.product }}
                        emptyLabel="No category Found"
                        disabled={!franchise?._id}
                        error={errors?.category?.message}
                        gap={0}
                      />
                    )}
                  />
                </div>
              </>
            ) : null}

            {/* Brand */}
            {["brand", "category-brand"].indexOf(offerProductsType) != -1 ? (
              <>
                <div className="col-4  mb-3">
                  <Controller
                    control={control}
                    name="brand"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search For Brand"
                        placeholder="Search By Name /ID "
                        value={value}
                        onSearch={onBrandListChange}
                        callback={onChange}
                        uid="brand-list-search"
                        isMultiple={false}
                        info={{ content: info.product }}
                        emptyLabel="No Brand Found"
                        disabled={!franchise?._id}
                        error={errors?.brand?.message}
                        gap={0}
                      />
                    )}
                  />
                </div>
              </>
            ) : null}

            {["brand", "category-brand", "category"].indexOf(
              offerProductsType
            ) != -1 ? (
              <>
                <div className="col-auto">
                  <Controller
                    control={control}
                    name="isFixedMrp"
                    render={({ field: { onChange, value } }) => (
                      <SwitchInput
                        label="is Fixed MRP?"
                        name="isFixedMrp"
                        callback={onIsFreeChange(onChange)}
                        value={value}
                      />
                    )}
                  />
                </div>

                {!isFixedMrp ? (
                  <div className="col-2 mb-2">
                    <TextInput
                      name="mrpValue"
                      label="MRP Value"
                      type="number"
                      error={errors?.mrpValue?.message}
                      register={register}
                      placeholder="Enter MRP Value"
                      isMandatory={true}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}

        <div className="ms-auto col-auto mt-4 mb-3">
          {showAddButton && (
            <button
              className="btn btn-outline-primary btn-sm fs-val-md"
              onClick={handleSubmit(addProduct)}
            >
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageRewardProducts;
