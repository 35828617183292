import { NavService } from "@sk/services";
import { HighlightText, TableHeader } from "@sk/uis";

const tableHeaders = [
  { label: "#", width: "5%" },
  { label: "Type", width: "15%", isCentered: false },
  { label: "Template", width: "30%", isCentered: false },
  { label: "Preview", isCentered: false },
  { label: "Action", width: "10%", isCentered: true },
];

const TemplatesTable = ({ templates, callback }) => {
  const handleRemoveTemplate = (channel, index, id) => {
    callback("remove", { channel, index, id });
  };

  const viewTemplateCb = (templateId) => {
    NavService.openInNewTab("/utility/whatsapp-template/view", {
      id: templateId,
    });
  };

  return (
    <>
      <table className="table bg-white table-striped table-sm">
        <TableHeader data={tableHeaders} noBg={true} />
        <tbody className="fs-val-md">
          {templates.map((template, index) => (
            <tr key={template.value._id}>
              <td>{index + 1}</td>
              <td>
                <HighlightText type="warning" template={2}>
                  {template.channelType}
                </HighlightText>
              </td>
              <td>
                <div className="mb-2">{template.label}</div>
                <div className="row mb-1">
                  <div className="col-auto">
                    <span className="align-middle text-muted">ID: </span>
                    <button
                      className="btn btn-sm btn-link text-muted fs-val-xs p-0"
                      onClick={() => viewTemplateCb(template.value._id)}
                    >
                      {template.value._id}
                    </button>
                  </div>
                </div>
              </td>
              <td className="text-muted fs-val-sm">
                {template.value.default?.body}
              </td>
              <td className="text-center">
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() =>
                    handleRemoveTemplate(
                      template.channelType,
                      index,
                      template.value._id
                    )
                  }
                  type="button"
                >
                  <i className="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          ))}
          {templates.length === 0 && (
            <tr>
              <td colSpan={tableHeaders.length} className="text-center py-3">
                No templates added
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TemplatesTable;
