import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { format } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";

import { DealService } from "@sk/services";

import listView from "../../constantService";

import LuckyDrawAuditLog from "../components/LuckyDrawAuditLog";

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const LuckyDrawViewModal = ({ show, closeModal, modalData }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const triggerCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const isValidDate = (dateString) => {
    // testing Both date String and ISO Stirng
    return /\(IST\)$/gi.test(dateString) || /000Z/.test(dateString);
  };

  useEffect(() => {
    if (show) {
      if (modalData._id) {
        const fetchDetails = async () => {
          setLoading(true);
          const r = await DealService.getLuckyDrawById(modalData._id);

          let d = r.resp || {};

          // set value for status

          // for formatting the audit log date values
          if (d.auditLog) {
            (d.auditLog || []).forEach((x) => {
              try {
                if (isValidDate(x.oldData)) {
                  x.oldData = format(
                    new Date(x.oldData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
                if (isValidDate(x.newData)) {
                  x.newData = format(
                    new Date(x.newData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
              } catch (error) {}
            });
          }

          setDetails(d);

          setLoading(false);

          if (d._id) {
            let tmp = [];
            // Attach User Info
            setAdditionalData([d], attachAdditionalDataConfig, (x) => {
              tmp.push(x);
              if (tmp.length == attachAdditionalDataConfig.length) {
                const t = [...attachAllData([d], tmp)][0];
                setDetails((v) => ({ ...v, ...t }));
              }
            });
          } else {
            Toaster.error("Failed to fetch data, please try again");
            triggerCloseModal();
          }
        };

        fetchDetails();
      }
    }
  }, [
    modalData._id,
    show,
    triggerCloseModal,
    setAdditionalData,
    attachAllData,
  ]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          <div className="fs-val-lg text-dark px-2">
            {"Lucky Draw Config" + " - " + modalData?._id}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {/* Store Details */}
                  <div className="p-4 bg-light border-bottom">
                    <div className="row">
                      <div className="col-10">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Smart Store
                        </div>
                        {/* Franchise Details */}
                        <div className="col-12">
                          <div className="fw-semibold fs-val-lg text-primary mb-3">
                            {details.franchiseLoading ? (
                              <Spinner type="dots" />
                            ) : (
                              <>
                                {details?._franchise?.name} -{" "}
                                {details?.franchiseId}
                                <div className="fs-val-sm text-muted">
                                  <span>{details?._franchise?.town}, </span>
                                  <span>{details?._franchise?.district}, </span>
                                  <span>{details?._franchise?.state} </span>
                                  <span>- {details?._franchise?.pincode}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Status */}
                      <div className="col-2 mb-2">
                        <KeyVal label="Status" template="col">
                          <HighlightText
                            status={details.status}
                            isBadge={true}
                          />
                        </KeyVal>
                      </div>
                    </div>
                  </div>

                  {/* Offer Details */}
                  <div className="p-4 bg-white border-bottom">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Lucky Draw Details
                        </div>
                      </div>

                      {/* Title */}
                      <div className="col-9 mb-2">
                        <KeyVal label="Title" template="col">
                          <span>{details.title}</span>
                        </KeyVal>
                      </div>

                      {/* Description
                      <div className="col-12 mb-2">
                        <KeyVal label="Description" template="col">
                          {details.desc}
                        </KeyVal>
                      </div> */}

                      {/* Offer Start Date */}
                      <div className="col-2 mb-2">
                        <KeyVal label="Eligible SmartCoins" template="col">
                          <span className="text-primary">
                            {details?.minValue}
                          </span>
                        </KeyVal>
                      </div>

                      {/* Offer Start Date */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Event Start Date" template="col">
                          <DateFormatter
                            date={details?.offerValidity?.validFrom}
                          />
                        </KeyVal>
                      </div>
                      {/* Offer End Date */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Event End Date" template="col">
                          <DateFormatter
                            date={details?.offerValidity?.validTo}
                          />
                        </KeyVal>
                      </div>
                      {/* Created At */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Created At" template="col">
                          <DateFormatter date={details.createdAt} />
                        </KeyVal>
                      </div>
                      {/* Created By */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Created By" template="col">
                          <span>
                            {details.userLoading ? (
                              <Spinner />
                            ) : (
                              details._createdBy?.name
                            )}
                          </span>
                        </KeyVal>
                      </div>
                      {/* Last Updated */}
                      <div className="col-3 mb-2">
                        <KeyVal label="Last Updated At" template="col">
                          <DateFormatter date={details.lastUpdated} />
                        </KeyVal>
                      </div>
                      {/* Last Updated By */}
                      {details._modifiedBy?.name && (
                        <div className="col-3 mb-2">
                          <KeyVal label="Last Updated By" template="col">
                            {details._modifiedBy?.name ? (
                              <span>{details._modifiedBy.name}</span>
                            ) : null}
                          </KeyVal>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Audit Log */}
                  <LuckyDrawAuditLog auditLogs={details.auditLog || []} />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(LuckyDrawViewModal);
