import { TextareaInput, Toaster } from "@sk/uis";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";

const MultipleFranchiseFormModal = ({ show, callback, data }) => {
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: { ids: "" },
  });

  useEffect(() => {
    if (show && data.length) {
      setValue("ids", data);
    } else {
      setValue("ids", "");
    }
  }, []);

  const onSubmit = () => {
    let f = getValues("ids").trim();
    if (!f?.length) {
      Toaster.error("Please Enter Franchise IDs");
      return;
    }
    callback({ status: "submit", data: f });
  };

  const closeModal = () => {
    callback({ status: "close", data: "" });
  };

  return (
    <Modal show={show} backdrop={"static"} size="md" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-5">Please Enter Multiple Franhcise ID's</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div>
          <TextareaInput
            name="ids"
            placeholder="Please Enter , seperated Franchise IDs"
            error={errors?.ids?.message}
            register={register}
            isMandatory={true}
            maxLength="500"
            rows="10"
          />
        </div>

        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              className="btn btn-primary btn-sm fs-val-md"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MultipleFranchiseFormModal;
