import { BannerService } from "@sk/services";
import {
  AppTitle,
  ImgRender,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const headers = [
  { label: "Sl No", width: "10%", isCentered: true },
  { label: "Image", width: "40%", isCentered: true },
  { label: "Titl/Tags", width: "40%", isCentered: true },
  { label: "Action", isCentered: true },
];

const pagination = {
  totalRecords: 0,
  rowsPerPage: 5,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 5,
};

const BannerImgSelectModal = ({ show, data, callback, selectedId }) => {
  const { register, getValues, control, reset } = useForm({
    defaultValues: { search: "" },
  });

  const [list, setList] = useState({ loading: false, data: [] });

  const [selectedData, setSelectedData] = useState({ index: -1, data: {} });

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // const [selectedLocData, setSelectedLocData] = useState({});
  const selectedLocDataRef = useRef({});

  const selectedImgRef = useRef("");

  const paginationRef = useRef({ ...pagination });

  useEffect(() => {
    setSelectedData({ index: -1, data });
    if (show && data?._id) {
      selectedLocDataRef.current = data;
      selectedImgRef.current = selectedId || "";
      applyFilter();
    }
  }, [show, data, applyFilter, selectedId]);

  const closeModal = () => {
    callback({ status: "close", data: {} });
  };

  const onSelect = (i) => {
    let d = list?.data?.length ? list.data[i] : {};
    selectedImgRef.current = d?._id || "";
    setSelectedData({ index: i, data: d });
  };

  const onDeselect = (i) => {
    selectedImgRef.current = "";
    setSelectedData({ index: -1, data: {} });
  };

  const onSubmit = () => {
    if (selectedData.index == -1) {
      Toaster.error("Please Choose Banner");
      return;
    }
    callback({ status: "submit", data: selectedData });
  };

  const paginationCb = useCallback(
    (d) => {
      paginationRef.current.startSlNo = d.startSlNo;
      paginationRef.current.endSlNo = d.endSlNo;
      paginationRef.current.activePage = d.activePage;
      loadBannerSizeList();
    },
    [loadBannerSizeList]
  );

  const loadBannerSizeList = useCallback(async () => {
    setList({ loading: true, data: [] });
    let params = prepareFilterParams();
    const r = await BannerService.getBannerDesignMasterList(params);
    setList({
      loading: false,
      data: Array.isArray(r.resp) ? r.resp : [],
    });
  }, [setList]);

  const getCount = async (params) => {
    try {
      const r = await BannerService.getBannerDesignMasterListCount(params);
      return { count: r.resp || 0 };
    } catch (error) {
      return { count: 0 };
    }
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;

    loadBannerSizeList();
    // for total records
    setLoadingTotalRecords(true);
    let p = prepareFilterParams();
    const c = await getCount(p);
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadBannerSizeList, prepareFilterParams]);

  const prepareFilterParams = useCallback(() => {
    let r = { ...selectedLocDataRef.current?.assetAttributes };
    let params = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: { status: "Active" },
    };

    if (r?.heightPxl && r?.widthPxl) {
      params.filter["assetAttributes.heightPxl"] = r?.heightPxl;
      params.filter["assetAttributes.widthPxl"] = r?.widthPxl;
    }

    let d = getValues("name");
    let searchKey = d?.length ? { $regex: d.trim(), $options: "i" } : "";

    if (searchKey) {
      params.filter = {
        $or: [{ assetTitle: searchKey }, { tags: searchKey }],
      };
    }
    return params;
  }, []);

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 700),
    [applyFilter]
  );

  return (
    <>
      <Modal show={show} size="lg" backdrop={"static"} onHide={closeModal}>
        <Modal.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Modal.Title>
            <AppTitle title="Choose Banner From Banner Master" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4  border-top">
          <div>
            Resolution : {selectedLocDataRef.current?.assetAttributes?.widthPxl}
            x{selectedLocDataRef.current?.assetAttributes?.heightPxl} (WxH)
          </div>

          <div className="mt-2 mb-4">
            <TextInput
              name="name"
              type="text"
              register={register}
              callback={onSearch}
              placeholder="Search By Title / Tags Name"
            />
          </div>

          <div className="mb-3">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={loadingTotalRecords}
            />
          </div>

          <table className="table table-bordered bg-white">
            <TableHeader data={headers} />
            <tbody className="fs-val-md">
              {/* Table Loader */}
              {list.loading && (
                <TableSkeletonLoader
                  rows={10}
                  cols={headers.length}
                  height={40}
                />
              )}

              {/* No Data */}
              {!list.loading && !list.data?.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {!list.loading &&
                list.data.map((e, index) => (
                  <tr
                    key={index}
                    // className={classNames({
                    //   bgColor: ,
                    // })}
                    style={{
                      backgroundColor:
                        selectedData.index == index ||
                        selectedImgRef.current == e._id
                          ? "#b1fafa"
                          : "#FFFFFF",
                    }}
                  >
                    <td className="text-center">
                      {paginationRef.current.startSlNo + index}
                    </td>
                    <td>
                      <div>
                        <ImgRender assetId={e?.assetId || ""} height="230" />
                      </div>
                    </td>
                    <td>
                      <div className="my-2 fs-val-md">
                        Title : {e?.assetTitle}
                      </div>
                      <div className="fs-val-md">
                        Tags :
                        {(e?.tags || []).map((e) => (
                          <span className="badge bg-primary mx-1">{e}</span>
                        ))}
                        {!e?.tags?.length ? "N/A" : ""}
                      </div>
                      <div className="my-2 fs-val-md">
                        Resolution :
                        {selectedLocDataRef.current?.assetAttributes?.widthPxl}x
                        {selectedLocDataRef.current?.assetAttributes?.heightPxl}{" "}
                        (WxH)
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="mt-5">
                        {selectedData.index != index &&
                        selectedImgRef.current != e._id ? (
                          <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => {
                              onSelect(index);
                            }}
                          >
                            Select
                          </button>
                        ) : (
                          <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => {
                              onDeselect(index);
                            }}
                          >
                            Deselect
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <PaginationBlock
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            loadingTotalRecords={loadingTotalRecords}
            showSummary={false}
          />
        </Modal.Body>

        <Modal.Footer>
          {list.data?.length > 0 ? (
            <div className="mt-3 text-end">
              <button className="btn btn-primary" onClick={onSubmit}>
                Submit
              </button>
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(BannerImgSelectModal);
