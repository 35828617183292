import { OmsService } from "@sk/services";
import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { orderBy } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "50%" };

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Customer Id", isCentered: false },
  { label: "Customer Name", isCentered: false },
  { label: "Latest Order Date", isCentered: true },
  { label: "Total Qty Bought", isCentered: true },
];

const pagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const CustomerDetailsListModal = ({ show, configId, callback }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const paginationConfigRef = useRef({ ...pagination });
  const detailsRef = useRef({});

  useEffect(() => {
    if (show && configId) {
      loadAnalyticsData(configId);
    }
  }, [show, configId, loadAnalyticsData]);

  const loadAnalyticsData = useCallback(
    async (id) => {
      setLoading(true);
      let r = await OmsService.getStoreOfferAnalyticsDetails({
        offerIds: [id],
      });

      let m = Array.isArray(r.resp) && r?.resp?.length ? r.resp[0] : {};

      let k = orderBy(m.orders, ["orderPlacedOn"], ["desc"]);
      let d = [];
      k.forEach((e) => {
        let index = d.findIndex((i) => i.customerId === e.customerId);
        if (index == -1) {
          e._totalQty = e.nonOfferDealQuantity;
          d.push(e);
        } else {
          d[index]._totalQty += e.nonOfferDealQuantity;
        }
      }, {});

      let l = d ? [...d].splice(0, pagination.rowsPerPage) : [];
      paginationConfigRef.current = {
        ...paginationConfigRef.current,
        totalRecords: detailsRef.current?.length,
      };

      detailsRef.current = [...d];
      setList([...l]);
      setLoading(false);
    },
    [setList, setLoading]
  );

  const paginationCb = useCallback((d) => {
    paginationConfigRef.current.startSlNo = d.startSlNo;
    paginationConfigRef.current.endSlNo = d.endSlNo;
    paginationConfigRef.current.activePage = d.activePage;

    let l = (detailsRef.current.orders || []).splice(
      (d.activePage - 1) * pagination.rowsPerPage,
      pagination.rowsPerPage
    );

    setList([...l]);
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg">#{configId} - Customer Details List</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <PaginationSummary
              paginationConfig={paginationConfigRef.current}
              loadingTotalRecords={false}
            />

            <table className="table table-bordered bg-white">
              <TableHeader data={headers} />
              <tbody className="fs-val-md">
                {/*  When Table is Loading  */}
                {loading && (
                  <TableSkeletonLoader
                    rows={10}
                    cols={headers.length}
                    height={40}
                  />
                )}

                {/* When No loading */}
                {!loading && !list?.length ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <NoDataFound>No Data Found </NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {(list || []).map((x, index) => (
                  <tr key={index}>
                    {/* SL No */}
                    <td className="text-center">
                      {paginationConfigRef.current.startSlNo + index}
                    </td>
                    <td>{x?.customerId}</td>
                    <td>{x?.customerName}</td>
                    <td className="text-center">
                      <DateFormatter
                        date={x?.orderPlacedOn}
                        format="dd MMM yyyy"
                      />
                    </td>
                    <td className="text-center">{x?._totalQty || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBlock
              paginationConfig={paginationConfigRef.current}
              paginationCb={paginationCb}
              loadingTotalRecords={false}
            />
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(CustomerDetailsListModal);
