import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";
import totalImg from "../../assets/imgs/total.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Manage Deal Restriction",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      filterParams: {},
      defaultFilterParams: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Publish Count",
      value: 0,
      loading: true,
      filter: { status: "Publish" },
      filterParams: {},
      defaultFilterParams: { status: "Publish" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Unpublish Count",
      value: 0,
      loading: true,
      filter: { status: "Unpublish" },
      filterParams: {},
      defaultFilterParams: { status: "Unpublish" },
      img: inactiveImg,
    },
    {
      label: "Draft",
      value: 0,
      loading: true,
      filter: { status: "Draft" },
      filterParams: {},
      defaultFilterParams: { status: "Draft" },
      color: "warning",
      img: notLinkedImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "brand_id",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brand",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "cat_id",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_category",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "deal_id",
      api: "deal",
      loadingKey: "dealLoading",
      dataKey: "_deal",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "franchise_id",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    isAllowed: {
      label: "Type",
    },
    status: {
      label: "Status",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    deal: {
      label: "Deal",
      valuePath: "[0].value.name",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    deal: [],
    status: "",
    brand: [],
    category: [],
    isAllowed: "",
    franchise: [],
    createdAt: [],
  },
};

export default listView;
