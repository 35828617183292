import { BrandService, CategoryService, DealService } from "@sk/services";
import { Toaster } from "@sk/uis";
import { difference } from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const BulkFilterModal = ({ show = false, callback, type = "", title }) => {
  const [ids, setIds] = useState("");

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setIds("");
    }
  }, [show]);

  const submit = async () => {
    const a = ids
      .split(",")
      .map((x) => x.trim())
      .filter(Boolean);

    if (!a.length) {
      Toaster.error("Please provide comma separated IDs");
      return;
    }

    if (a.length > 200) {
      Toaster.error("IDs cannot be greater than 200");
      return;
    }

    let p = {
      page: 1,
      count: a.length,
      filter: {
        _id: {
          $in: a,
        },
      },
      select: "name",
    };

    let d = [];

    setSubmitting(true);

    if (type == "menu") {
      const r = await CategoryService.getList({
        ...p,
        filter: { ...p.filter, hasParentCategory: false },
      });
      d = Array.isArray(r.resp) ? r.resp : [];
    }

    if (type == "category") {
      const r = await CategoryService.getList({
        ...p,
        filter: { ...p.filter, hasParentCategory: true },
      });
      d = Array.isArray(r.resp) ? r.resp : [];
    }

    if (type == "brand") {
      const r = await BrandService.getList(p);
      d = Array.isArray(r.resp) ? r.resp : [];
    }

    if (type == "deal") {
      const r = await DealService.getDeals(p);
      d = Array.isArray(r.resp) ? r.resp : [];
    }

    setSubmitting(false);

    const finalIds = d.map((x) => x._id);
    const diff = difference(a, finalIds);

    if (!finalIds.length) {
      Toaster.error("Failed to fetch details, please try again");
      return;
    }

    if (diff.length) {
      Toaster.error("These ids are not added " + diff.join(","));
    }

    Toaster.success(finalIds.length + " IDs added successfully");

    callback({
      data: d.map((x) => ({ label: x.name, value: x })),
      action: "added",
      type,
    });
  };

  return (
    <Modal show={show} onHide={callback}>
      <Modal.Header closeButton={true}>
        <Modal.Title className="mb-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="form-control"
          placeholder="Enter comma separated IDs"
          rows={10}
          value={ids}
          onChange={(e) => setIds(e.target.value)}
        ></textarea>
        <div className="text-muted fs-val-sm">Allowed only 30 IDs</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-success"
          disabled={submitting}
          onClick={submit}
        >
          {submitting ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : null}
          <span> Save </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkFilterModal;
