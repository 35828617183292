import { CustomerService } from "@sk/services";
import {
  DateFormatter,
  EntitySearchInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Requested By", isCentered: false },
  { label: "Requested ID", isCentered: true },
  { label: "Requested Qty ", isCentered: true },
  { label: "Requested On", isCentered: true },
  { label: "Status", isCentered: true },
];

const pagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

// To Get The Brand List Data
const getData = async (id, params) => {
  const r = await CustomerService.getStoreOfferRequestedList(id, params);
  return Array.isArray(r.resp) ? r.resp : [];
};

// To Get Count
const getCount = async (id, params) => {
  let p = { ...params, outputType: "count" };
  try {
    const r = await CustomerService.getStoreOfferRequestedList(id, p);
    return {
      count: r.statusCode == 200 && Array.isArray(r.resp) ? r.resp[0].total : 0,
    };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const RequestedList = ({ details }) => {
  const { register, getValues, control } = useForm({
    defaultValues: { id: "", customer: "" },
  });

  const [list, setList] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const paginationConfigRef = useRef({ ...pagination });

  const detailsRef = useRef([]);

  useEffect(() => {
    if (details?._id) {
      detailsRef.current = details;
      applyFilter();
    }
  }, [details, applyFilter]);

  const applyFilter = useCallback(() => {
    loadCount();
    loadList();
  }, [loadCount, loadList]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(detailsRef.current._id, p);

    setList(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(detailsRef.current._id, p);
    paginationConfigRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    let p = {
      page: paginationConfigRef.current.activePage,
      count: paginationConfigRef.current.rowsPerPage,
      filter: {},
      sort: { requestedAt: -1 },
    };
    let f = getValues();
    if (f.id?.length) {
      p.filter._id = { $regex: f.id.trim(), $options: "gi" };
    }

    if (f?.customer?.length) {
      p.filter["customerInfo.id"] = f.customer[0].value._id;
    }

    return p;
  }, [getValues]);

  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      paginationConfigRef.current.activePage = d.activePage;

      loadList();
    },
    [loadList]
  );

  const onSearch = debounce(() => {
    applyFilter();
  }, 700);

  const onSelectCb = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-2">
          <TextInput
            name="id"
            register={register}
            callback={onSearch}
            placeholder="Search by Request  ID"
          />
        </div>

        <div className="col-4">
          <Controller
            control={control}
            name="customer"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="customer"
                placeholder="Search by Customer Name/ID"
                value={value}
                callback={onSelectCb(onChange)}
                uid="customer"
                isMultiple={false}
                isMandatory={true}
                emptyLabel="Customer Not found"
              />
            )}
          />
        </div>
      </div>

      <PaginationSummary
        paginationConfig={paginationConfigRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />

      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {loadingData && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loadingData && !list?.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {(list || []).map((x, index) => (
            <tr key={index}>
              {/* SL No */}
              <td className="text-center">
                {paginationConfigRef.current.startSlNo + index}
              </td>

              {/* Customer Details */}
              <td className="text-cente">
                <div className="mb-1 fw-semibold">{x?.customerInfo?.name} </div>
                <div className="mb-2 fs-val-xs">Id : {x?.customerInfo?.id}</div>
                <div className="mb-2 fs-val-sm text-muted">
                  {x?.customerInfo?.city} {x?.customerInfo?.city ? "," : null}
                  {x?.customerInfo?.district}
                  {x?.customerInfo?.district ? "," : null}{" "}
                  {x?.customerInfo?.state}
                </div>
              </td>

              <td className="text-center text-primary">{x?._id}</td>

              <td className="text-center">{x?.offerQty}</td>

              <td className="text-center">
                <DateFormatter date={x?.requestedAt} />
              </td>
              <td className="text-center text-capitalize">
                <HighlightText status={x?.requestStatus} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </div>
  );
};

export default RequestedList;
