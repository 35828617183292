import { Amount, AppCard, Spinner, TableHeader } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "75%" };

const tableHeaders = [
  {
    label: "",
    isCentered: true,
  },
  {
    label: "Last 24 hrs",
    isCentered: true,
  },
  {
    label: "Last 7 Days",
    isCentered: true,
  },
  {
    label: "Last 30 Days",
    isCentered: true,
  },
  {
    label: "Last 3 Months",
    isCentered: true,
  },
];

const list = [
  { label: "Sent" },
  { label: "Delivered" },
  { label: "Rejected" },
  { label: "Customers Notified" },
  { label: "Customers Visited" },
  { label: "Customers Orders" },
];

const ViewChannelLogsModal = ({ show, data, callback }) => {
  useEffect((show) => {}, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> View Channel Logs </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="p-2">
              {data?.length &&
                data?.map((x, k) => (
                  <div key={k}>
                    {x?.status ? (
                      <div className="row mb-3 bg-white border rounded p-3">
                        <div className="col-1 text-align-center">
                          <div className="text-primary fw-semibold fs-val-lg">
                            <i className="bi bi-whatsapp text-success align-middle me-2 fs-val-xxl"></i>
                            {x?.type}
                          </div>
                        </div>
                        <div className="col-3">
                          <AppCard title="Template Details">
                            {x?.templateId ? (
                              <>
                                <div className="mb-2 pt-2 pb-1 fw-val-md">
                                  ID :
                                  <span className="fw-semibold ps-1">
                                    {x?.templateId}{" "}
                                  </span>
                                </div>
                                <div className="mb-3 fw-val-md">
                                  Name :
                                  {x.loading ? (
                                    <Spinner type="dots" />
                                  ) : (
                                    <span className="text-primary fw-semibold ps-1">
                                      {x?.details?.name}
                                    </span>
                                  )}
                                </div>

                                <div className="mb-3">
                                  Message :
                                  <div className="text-muted fs-val-sm pt-1">
                                    Dear Delivery Person, Your delivery
                                    assignment OTP for order. Please show this
                                    OTP message to accept delivery. Thank
                                  </div>
                                </div>

                                <div className="mb-3 fw-val-md">
                                  Last Updated On : 14 May 2024
                                </div>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </AppCard>
                        </div>
                        <div className="col-8">
                          <AppCard title="Notification Details">
                            {x?.templateId ? (
                              <>
                                <table className="table table-bordered">
                                  <TableHeader data={tableHeaders} />
                                  <tbody className="fs-val-md">
                                    {list.map((x, k) => (
                                      <tr key={k}>
                                        <td className="text-center">
                                          {x?.label || ""}
                                        </td>
                                        <td className="text-center">30</td>
                                        <td className="text-center">40</td>
                                        <td className="text-center">70</td>
                                        <td className="text-center">150</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </AppCard>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewChannelLogsModal);
