import { FranchiseService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import produce from "immer";
import orderBy from "lodash/orderBy";
import { useCallback, useEffect, useRef, useState } from "react";

const prepareParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    sort: { name: 1 },
    filter: {
      _id: {
        $nin: filter.selectedFid,
      },
      "sk_franchise_details.franchise_sub_type": {
        $in: ["SMARTSFCOCO"],
      },
    },
    select: "name,state,district,town,pincode",
  };

  return p;
};

const CloneFranchiseSelect = ({ ids, callback, selectedFid = "" }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [headers, setHeaders] = useState(() => [
    { label: "Sl No", width: "8%" },
    { label: "" },
    // {
    //   label: "",
    //   hasCustomTemplate: true,
    //   isCentered: true,
    //   template: () => (
    //     <input
    //       type="checkbox"
    //       ref={selectAllRef}
    //       onChange={onSelectAllChange}
    //     />
    //   ),
    // },
    { label: "Name" },
    { label: "Location" },
  ]);

  const filterDataRef = useRef({});

  const sortRef = useRef({});

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 25,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 25,
  });

  const selectAllRef = useRef(null);

  const triggerSelectAllRef = useRef(false);

  useEffect(() => {
    if (triggerSelectAllRef.current) {
      triggerSelectAllRef.current = false;
      triggerCb(data);
    }
  }, [data, ids.length, triggerCb]);

  const onSelectAllChange = (e) => {
    const v = e.target.checked;

    triggerSelectAllRef.current = true;

    setData(
      produce((draft) => {
        draft.forEach((e) => {
          e._isChecked = v;
        });
      })
    );
  };

  const applyFilter = useCallback(async () => {
    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    const t = c.statusCode == 200 && c.resp ? c.resp : 100;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    const d = (r.resp || []).map((e) => ({
      ...e,
      _isChecked: ids.indexOf(e._id) != -1,
      // || selectAllRef.current.checked ? true : false,
    }));

    if (selectAllRef.current?.checked) {
      triggerSelectAllRef.current = true;
    }

    setData(orderBy(d, ["name"], ["asc"]));
    setLoadingData(false);
  }, [getFilterParams, ids]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getFilterParams = useCallback(() => {
    filterDataRef.current = { ...filterDataRef.current, selectedFid };
    return prepareParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, [selectedFid]);

  const getData = (params) => {
    return FranchiseService.getList(params);
  };

  const getCount = (params) => {
    return FranchiseService.getCount(params);
  };

  const checkBoxCb = useCallback(
    (v, index) => {
      setData(
        produce((draft) => {
          draft[index]._isChecked = v;
        })
      );

      if (!v) {
        selectAllRef.current.checked = false;
      }

      const f = data[index];
      triggerCb([f]);
    },
    [data, triggerCb]
  );

  const triggerCb = useCallback(
    (frans) => {
      callback({
        data: frans.map((e) => ({
          id: e._id,
          name: e.name,
          state: e.state,
          district: e.district,
          town: e.town,
          pincode: e.pincode,
        })),
      });
    },
    [callback]
  );

  const onRowClick = (index) => {
    setData(
      produce((draft) => {
        draft[index]._isChecked = !draft[index]._isChecked;

        // selectAllRef.current.checked = false;
      })
    );

    triggerCb([data[index]]);
  };

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />
      <table className="table table-bordered bg-white table-sm table-hover">
        <TableHeader data={headers} />
        <tbody>
          {!loadingData && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound />
              </td>
            </tr>
          ) : null}
          {data.map((x, index) => (
            <tr
              key={x._id}
              onClick={() => onRowClick(index)}
              className="cursor-pointer fs-val-md"
            >
              <td className="text-center">
                {paginationRef.current.startSlNo + index}
              </td>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={x._isChecked}
                  defaultValue={x._id}
                  onChange={(e) => {
                    checkBoxCb(e.target.checked, index);
                  }}
                />
              </td>
              <td>
                {x?.name} - {x?._id}
              </td>
              <td>
                {x?.state}, {x?.district}, {x?.town} - {x?.pincode}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default CloneFranchiseSelect;
