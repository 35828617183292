import { CommonService, DealService } from "@sk/services";
import {
  BusyLoader,
  HighlightText,
  NoDataFound,
  PageLoader,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { produce } from "immer";
import { merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CloneFranchiseSelect from "./components/CloneFranchiseSelect";

const headers = [
  { label: "SL No", width: "8%" },
  { label: "Franchise" },
  { label: "Location" },
  { label: "" },
];
const canvasStyle = { width: "50%" };

const CloneOfferModal = ({
  configId,
  show,
  callback,
  title,
  type,
  similarOfferCode,
}) => {
  const { setValue } = useForm();

  const [loading, setLoading] = useState(true);

  const [franchiseList, setFranchiseList] = useState([]);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [status, setStatus] = useState("pending");

  const detailsRef = useRef({});

  const selectedFidsRef = useRef([]);

  useEffect(() => {
    if (show && configId) {
      selectedFidsRef.current = [];

      setStatus("pending");

      setFranchiseList([]);

      const fetchDetails = async () => {
        setLoading(true);

        const r = await DealService.getStoreDiscountById(configId);

        let d = r?.resp || {};

        detailsRef.current = d;

        setLoading(false);
      };

      fetchDetails();
    }
  }, [show, setValue, configId]);

  const removeFranchise = (i) => {
    setFranchiseList(
      produce((draft) => {
        draft.splice(i, 1);
      })
    );
  };

  const onSubmit = () => {
    let result = [];
    let fran = [...franchiseList];

    if (!fran.length) {
      Toaster.error("Please choose Franchise to proceed");
      return;
    }

    const looper = async (index) => {
      let p = merge({}, detailsRef.current, {
        franchiseId: fran[index].id,
        franchiseName: fran[index].name,
        status: "Pending",
        offerCount: 0,
        requestCount: 0,
      });

      if (type != "CARTOFFER") {
        let cd = ["offerType", "rewardType", "rewardTypeOffer", "franchiseId"]
          .map((e) => p[e])
          .join("-");
        p.offerCode +=
          "-" + p.offerDeals.map((e) => e.dealId + "-" + e.qty).join(":");
        p.offerCode = CommonService.createMD5Hash(cd);
      } else if (type == "CARTOFFER") {
        p.offerCode = new Date().getTime().toString();
      }

      delete p._id;
      delete p?.auditLog;
      delete p?.analytics;
      delete p?.__v;
      delete p?.createdBy;
      delete p?.createdAt;
      delete p?.modifiedBy;
      delete p?.lastUpdated;

      setBusyLoader({
        show: true,
        msg: "Cloning Please wait... (" + (index + 1) + "/" + fran.length + ")",
      });
      const r = await DealService.createStoreDiscount(p);
      if (r.statusCode == 200) {
        result.push({ fid: fran[index].id, status: "success" });
      } else {
        result.push({
          fid: fran[index].id,
          status: "error",
          msg: r.resp?.message,
        });
      }
      const next = index + 1;
      if (next < fran.length) {
        looper(next);
      } else {
        setBusyLoader({ show: false, msg: "" });

        setFranchiseList(
          produce((draft) => {
            draft.forEach((e) => {
              const t = result.find((x) => x.fid == e.id);
              e._status = t?.status;
              e._msg = t?.msg;
            });
          })
        );

        let suc = result.filter((e) => e.status == "success");
        let err = result.filter((e) => e.status == "error");

        if (suc?.length > 0 && !err?.length) {
          Toaster.success("Cloned Successfully ->  Success : " + suc?.length);
        } else if (!suc?.length && err?.length > 0) {
          Toaster.error("Clone Failed" + err?.length);
        } else if (err.length > 0 && suc.length > 0) {
          Toaster.success(
            "Cloned Successfully -> Success : " +
              suc?.length +
              " Failed :" +
              err?.length
          );
        }
        setStatus("completed");
      }
    };
    looper(0);
  };

  const closeModal = () => {
    callback({ status });
  };

  const selectedFranchiseCb = useCallback(
    (d) => {
      const frans = d.data;
      const data = produce(franchiseList, (draft) => {
        frans.forEach((e) => {
          const i = draft.findIndex((x) => x.id == e.id);

          if (i != -1) {
            draft.splice(i, 1);
          } else {
            draft.push(e);
          }
        });
      });
      selectedFidsRef.current = data.map((e) => e.id);

      setFranchiseList([...data]);
    },
    [franchiseList]
  );

  return (
    <Offcanvas
      show={show}
      onHide={closeModal}
      placement="end"
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-light">
        {/* Modal Title */}
        <Offcanvas.Title>
          <div className="fs-val-lg">#{configId} - Clone Offer</div>
          <div className="mt-2 fs-val-sm">Title : {title} </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <PageLoader />
        ) : (
          <div>
            {/* FRAN LIST TABLE */}
            {status == "pending" ? (
              <CloneFranchiseSelect
                callback={selectedFranchiseCb}
                ids={selectedFidsRef.current}
                selectedFid={detailsRef.current.franchiseId}
              />
            ) : null}

            {status == "completed" ? (
              <>
                <div>
                  <table className="table table-bordered bg-white">
                    <TableHeader data={headers} />

                    {/* Table Body */}
                    <tbody>
                      {/* When No Data */}
                      {!franchiseList?.length ? (
                        <tr>
                          <td colSpan={headers.length}>
                            <NoDataFound>No Franchise's Selected </NoDataFound>
                          </td>
                        </tr>
                      ) : null}

                      {franchiseList.map((e, index) => (
                        <tr key={index} className="fs-val-md">
                          <td>{index + 1}</td>
                          <td>
                            {e?.name} - {e.id}
                          </td>
                          <td>
                            {e?.town}, {e?.district}, {e?.state} - {e?.pincode}
                          </td>

                          <td>
                            {status == "pending" ? (
                              <div>
                                <button
                                  className="btn btn-sm cursor-pointer text-danger me-1  mb-3 mx-1"
                                  title="Delete"
                                  type="button"
                                  onClick={() => {
                                    removeFranchise(index);
                                  }}
                                >
                                  <i className="bi bi-trash"></i> Remove
                                </button>
                              </div>
                            ) : (
                              <div>
                                <div className="text-capitalize">
                                  <HighlightText status={e?._status} />
                                  {e?._msg ? (
                                    <span className="ps-2 text-danger fs-val-sm">
                                      - {e?._msg}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}

            <BusyLoader show={busyLoader.show} message={busyLoader.msg} />

            {status == "pending" ? (
              <div className="position-absolute bottom-0 left-0 right-0 w-100 text-end p-4">
                <button className="btn btn-primary" onClick={onSubmit}>
                  Proceed
                </button>
              </div>
            ) : null}
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CloneOfferModal;
