import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  Rbac,
} from "@sk/uis";

import { memo, useRef, useState } from "react";
import LuckyDrawViewModal from "../modals/LuckyDrawViewModal";

const rbac = {
  editButton: ["EditLuckyDraw"],
  viewButton: ["ViewLuckyDraw"],
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", isCentered: true, width: "7%" },
  { label: "Title", isCentered: true },
  { label: "Smart Store", width: "18%" },
  { label: "Start Date /End Date", width: "12%" },
  { label: "Eligible SmartCoins", isCentered: true, width: "10%" },
  { label: "Status", isCentered: true, width: "7%" },
  { label: "Created On", width: "12%" },
  { label: "Actions", isCentered: true, width: "9%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  editCb,
  sortCb,
  markAsActive,
  markAsInActive,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const viewModalRef = useRef({ data: {} });

  const openViewModal = (data) => {
    viewModalRef.current.data = data;
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    viewModalRef.current.data = {};
    setShowViewModal(false);
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Lucky Draw Event Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>
                <td className="text-center">{x._id}</td>
                <td className="text-break">{x.title || "N/A"}</td>

                <td className="text-primary">
                  {x.franchiseLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <>
                      <div className="text-primary mb-1">
                        {x._franchise.name ?? "N/A"} -{" "}
                        {x._franchise._id ?? "N/A"}
                      </div>
                      <div className="fs-val-sm text-muted">
                        <span>{x._franchise?.town}, </span>
                        <span>{x._franchise?.district}, </span>
                        <span>{x._franchise?.state} </span>
                        <span>- {x._franchise?.pincode}</span>
                      </div>
                    </>
                  )}
                </td>

                <td>
                  <div className="text-muted fs-val-sm">Start Date </div>
                  <div className="fs-val-md mb-2">
                    <DateFormatter
                      date={x?.offerValidity?.validFrom}
                      format="dd MMM yyyy hh:mm a"
                    />
                  </div>
                  <div className="text-muted fs-val-sm">End Date</div>
                  <div className="fs-val-md">
                    <DateFormatter
                      date={x?.offerValidity?.validTo}
                      format="dd MMM yyyy hh:mm a"
                    />
                  </div>
                </td>

                <td className="text-center">
                  <span className="fw-semibold">{x.minValue}</span>
                </td>

                {/* Status  */}
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={false} />

                  <Rbac roles={rbac.editButton}>
                    {/* FOR MARK AS IN-ACTIVE BUTTON  */}
                    {x.status == "Active" ? (
                      <div>
                        <button
                          className="btn btn-link text-danger fs-val-xs p-0"
                          onClick={() => markAsInActive(x)}
                        >
                          Mark as Inactive
                        </button>
                      </div>
                    ) : null}

                    {/* FOR MARK AS ACTIVE BUTTON  */}
                    {x.status == "Inactive" ? (
                      <button
                        className="btn btn-link text-success fs-val-xs p-0"
                        onClick={() => markAsActive(x)}
                      >
                        Mark as Active
                      </button>
                    ) : null}
                  </Rbac>
                </td>
                <td>
                  <DateFormatter
                    date={x?.createdAt}
                    format="dd MMM yyyy hh:mm a"
                  />
                </td>

                <td className="text-center p-0">
                  <Rbac roles={rbac.editButton}>
                    {x.status != "Ended" && (
                      <button
                        className="btn btn-outline-warning btn-sm me-1"
                        onClick={() => editCb(x._id)}
                      >
                        Edit
                      </button>
                    )}
                  </Rbac>
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => openViewModal(x)}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <LuckyDrawViewModal
        show={showViewModal}
        modalData={viewModalRef.current.data}
        closeModal={closeViewModal}
      />
    </>
  );
};

export default memo(Table);
