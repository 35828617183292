import { CommonService } from "@sk/services";
import { Amount, NoDataFound, TextInput, Toaster } from "@sk/uis";
import produce from "immer";
import { each, orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const defaultFormData = {
  min: null,
  max: null,
  coins: null,
};

function SmartCoinRewardSlab({ callback, slabData = [], mode = "add" }) {
  const { register, getValues, reset } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [slabs, setSlabs] = useState([]);

  useEffect(() => {
    if (Array.isArray(slabData)) {
      setSlabs(
        produce((draft) => {
          draft.length = 0;
          slabData.forEach((e) => draft.push(e));
        })
      );
    }
  }, [slabData]);

  const onAdd = () => {
    const f = getValues();

    const min = f.min;
    const max = f.max;
    const coins = f.coins || 0;

    let msg = "";

    if (!/^[0-9]*$/.test("" + min) || min < 0) {
      msg = "Please provide Min. Order Value";
    } else if (min == max) {
      msg = "Min and Max cannot have same value";
    } else if (!max || max < 0) {
      msg = "Please provide Max. Order Value";
    } else if (min > max) {
      msg = "Min. Order Value cannot be greater than Max.Order Value";
    } else if (!coins) {
      msg = "Please provide Parent Coin Reward";
    } else {
      msg = "";
    }

    // for duplicate check
    if (!msg) {
      let hasDuplicate = false;
      each(slabs, (v) => {
        if (f.min >= v.min && f.min <= v.max) {
          hasDuplicate = true;
        }

        if (!hasDuplicate && f.max >= v.min && f.max <= v.max) {
          hasDuplicate = true;
        }

        if (!hasDuplicate && v.min >= f.min && v.min <= f.max) {
          hasDuplicate = true;
        }

        return hasDuplicate ? false : true;
      });
      if (hasDuplicate) {
        msg = "Given slab already exists";
      }
    }

    if (msg) {
      Toaster.error(msg);
      return;
    }

    const t = produce(slabs, (draft) => {
      draft.push({ ...f });
    });

    setSlabs(t);

    callback({ slabs: t });

    reset();
  };

  const removeSlab = (index) => {
    const t = produce(slabs, (draft) => {
      draft.splice(index, 1);
    });
    setSlabs(t);
    callback({ slabs: t });
  };

  const onNumChange = (e) => {
    const v = e.target.value;
    if (v) {
      e.target.value = CommonService.roundedByDecimalPlace(v, 0);
    }
  };

  return (
    <>
      <table className="table table-sm">
        <thead className="thead-light">
          {mode == "add" ? (
            <tr className="bg-white">
              <th className="bg-white">
                <div className="ps-3">
                  <TextInput
                    name="min"
                    label="Min. Order Value (Rs.)"
                    register={register}
                    type="number"
                    isMandatory={true}
                    maxLength={10}
                    callback={onNumChange}
                  />
                </div>
              </th>
              <th className="bg-white">
                <TextInput
                  name="max"
                  label="Max. Order Value (Rs.)"
                  register={register}
                  type="number"
                  isMandatory={true}
                  maxLength={10}
                  callback={onNumChange}
                />
              </th>
              <th className="bg-white">
                <TextInput
                  name="coins"
                  label="Parent Coin Reward"
                  register={register}
                  type="number"
                  isMandatory={true}
                  maxLength={10}
                  callback={onNumChange}
                />
              </th>
              <th className="bg-white">
                <button onClick={onAdd} className="btn btn-primary mt-2">
                  ADD
                </button>
              </th>
            </tr>
          ) : null}
          <tr>
            <th>
              <div className="ps-3">Min. Order Value</div>
            </th>
            <th>Max. Order Value</th>
            <th>Parent Coins Reward</th>
            {mode == "add" ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {!slabs.length ? (
            <tr>
              <td colSpan={4}>
                <NoDataFound>No slabs added</NoDataFound>
              </td>
            </tr>
          ) : null}
          {slabs.map((e, k) => (
            <tr key={k}>
              <td>
                <div className="ps-3">
                  <Amount value={e.min} />
                </div>
              </td>
              <td>
                <Amount value={e.max} />
              </td>
              <td className="text-primary fw-semibold">{e.coins} coins</td>
              {mode == "add" ? (
                <td>
                  <button
                    onClick={() => removeSlab(k)}
                    className="btn btn-link text-danger text-xs px-0"
                  >
                    Remove
                  </button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default SmartCoinRewardSlab;
