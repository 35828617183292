import { yupResolver } from "@hookform/resolvers/yup";
import { BannerService } from "@sk/services";
import {
  Alert,
  AutoCompleteInput,
  FileUpload,
  ImgPreviewModal,
  ImgRender,
  InputErrorMsg,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import { uniqBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  // page: yup.string().required(),
  // location: yup.string().required(),
  tags: yup.array(),
  assetId: yup.string().label("Banner Image").required(),
  status: yup.string().required(),
  imgSize: yup.string().required(),
});

const statusOptions = [
  { label: "Choose", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const canvasStyle = {
  width: "35%",
};

const defaultFormData = {
  title: "",
  page: "",
  location: "",
  tagInput: "",
  tags: [],
  assetId: "",
  status: "Inactive",
};

const ManageBannerDesignMasterModal = ({ show, callback, editId = "" }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [locationList, setLocationList] = useState({
    data: [],
    loading: false,
    pageOptions: [],
    imageSizeOptions: [],
  });

  const [locationOptions, setLocationOptions] = useState([
    { label: "Select", value: "" },
  ]);

  const [tagsList, setTagsList] = useState([]);

  const [selectedLocDetails, setSelectedLocDetails] = useState({});

  const [tags, page, location, assetId, imgSize] = useWatch({
    control,
    name: ["tags", "page", "location", "assetId", "imgSize"],
  });

  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    if (show) {
      setTagsList([]);
      loadLocationList(editId || "");
      reset({ ...defaultFormData });
    }
  }, [editId, reset, show, loadLocationList]);

  const loadLocationList = useCallback(
    async (id) => {
      setLoading(true);
      setLocationList({ data: [], loading: true, pageOptions: [] });
      const r = await BannerService.getBannerLocationMasterSizeList();
      let d = Array.isArray(r.resp) ? r.resp : [];

      // let page = [{ label: "Select", value: "" }];
      // if (Array.isArray(r.resp) && r.resp.length) {
      //   uniqBy(r.resp, "page").forEach((e) => {
      //     page.push({ label: e.page, value: e.page });
      //   });
      // }

      let s = [{ label: "Choose Image size", value: "" }];
      d.map((e) => {
        s.push({ label: e.resolution, value: e.resolution });
      });

      let p = {
        data: [...d],
        loading: false,
        pageOptions: [],
        imageSizeOptions: [...s],
      };

      setLocationList(p);

      if (id) {
        loadDetails(id, [...s]);
      } else {
        setLoading(false);
      }
    },
    [setLoading, setLocationList]
  );

  const loadDetails = useCallback(
    async (id, data) => {
      setLoading(true);
      const r = await BannerService.getBannerDesignMasterById(id);
      if (r?.resp?._id) {
        let d = r.resp;
        setValue("status", d?.status);
        setValue("page", d?.page);
        setValue("title", d?.assetTitle);
        let l = d?.tags.map((e) => {
          return { label: e, value: e };
        });
        setValue("tags", l);
        setTagsList([...l]);
        setValue("assetId", d?.assetId);
        setValue("status", d?.status);

        let imgSize =
          d?.assetAttributes?.widthPxl + "x" + d?.assetAttributes?.heightPxl;
        setValue("imgSize", imgSize);
        setLocationList((prev) => {
          return { ...prev, imageSizeOptions: [...data] };
        });

        // if (d.page?.length) {
        //   let loc = [{ label: "Select", value: "" }];

        //   let k = uniqBy(data, "location");

        //   k.forEach((e) => {
        //     if (e.page == d.page) {
        //       loc.push({ label: e.location, value: e.location });
        //     }
        //   });

        //   setLocationOptions([...loc]);
        //   setValue("location", d?.location);
        // }
        setLoading(false);
        setData(d);
      } else {
        setLoading(false);
        Toaster.error("Failed to load details");
        callback({ status: "closed" });
      }
    },
    [callback, setValue, setLocationList]
  );

  const onHide = () => {
    callback({ status: "closed" });
  };

  const onSubmit = async (f) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }
    const x = f.imgSize;
    const w = x.slice(0, x.indexOf("x")) * 1;
    const h = x.slice(x.indexOf("x") + 1) * 1;

    const p = {
      page: f.page,
      // location: f.location,
      // locationId: selectedLocDetails._id,
      assetTitle: f.title,
      assetId: f.assetId,
      tags: f.tags.map((e) => {
        return e.value;
      }),
      assetAttributes: { widthPxl: w, heightPxl: h },
      status: f.status,
    };

    setSubmitting(true);

    let r;
    if (editId) {
      r = await BannerService.UpdateBannerDesignMaster(editId, p);
    } else {
      r = await BannerService.createBannerDesignMaster(p);
    }
    setSubmitting(false);
    if (r.statusCode == 200) {
      reset({ ...defaultFormData });
      callback({ status: "submitted" });
      Toaster.success(r.resp.message || "Submitted succesfully");
    } else {
      Toaster.error(r.resp.message || "Failed to submit");
    }
  };

  const onSelectPageChange = useCallback(() => {
    let f = getValues();
    if (f.page?.length) {
      let loc = [{ label: "Select", value: "" }];

      let k = uniqBy(locationList.data, "location");

      k.forEach((e) => {
        if (e.page == f.page) {
          loc.push({ label: e.location, value: e.location });
        }
      });

      setLocationOptions([...loc]);
    } else {
      setLocationOptions([{ label: "Select", value: "" }]);
    }
    setValue("location", "");
  }, [setValue, setLocationOptions, getValues, locationList]);

  const onSelectLocChange = useCallback(() => {
    let f = getValues();

    if (f.page && f.location) {
      let d = [...locationList.data].filter((e) => {
        return e.page == f.page && e.location == f.location;
      });
      setSelectedLocDetails(d.length ? d[0] : {});
    } else {
      setSelectedLocDetails({});
    }
  }, [setSelectedLocDetails, getValues, locationList]);

  const addTags = useCallback(
    (e, callback) => {
      let f = getValues();
      if (!e.length || !e.trim().length) {
        return;
      }
      callback([{ label: e, value: e }]);
      // if (!f.tagInput?.length || !f.tagInput?.trim().length) {
      //   Toaster.error("Please Enter Tag Name");
      //   return;
      // }
      // let list = [...f.tags];
      // let inp = f.tagInput;
      // if (list.indexOf(inp) != -1) {
      //   Toaster.error("Duplicate Tag Name");
      //   return false;
      // }
      // list.push(inp);
      // setValue("tags", [...list]);
      // setTagsList([...list]);
      // trigger("tags");
      // setValue("tagInput", "");
    },
    [setTagsList, setValue]
  );

  const onChipAddCb = useCallback((val) => {
    let v = val;
    const isArr = Array.isArray(val) ? true : false;
  }, []);

  const onChipRemoveCb = useCallback((index) => {
    // setValue(
    //   produce((draft) => {
    //     draft.splice(index, 1);
    //   })
    // );
  }, []);

  const removeImg = useCallback(async () => {
    setValue("assetId", "");
    await trigger(assetId);
  }, [getValues, setValue, trigger]);

  const imagesCb = async (e) => {
    setValue("assetId", e.asset);
  };

  const removeTagCb = useCallback(
    async (index) => {
      let f = getValues("tags");
      f.splice(index, 1);
      setValue("tags", f);
      setTagsList([...f]);
      await trigger("tags");
    },
    [setValue, getValues]
  );

  const viewImage = (x) => {
    if (x?.length > 0) {
      let d = [{ id: x }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">
            {data?._id ? "Edit" : "Add"} Banner
            {data?._id ? ` - # ${data?._id}` : null}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <div className="text-center p-4">
            <Spinner />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <TextInput
                  isMandatory={true}
                  error={errors.title?.message}
                  label="Title"
                  type="text"
                  register={register}
                  name="title"
                  placeholder="Add Title For the Banner"
                />
              </div>

              <div className="mb-3">
                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      label="Tags"
                      error={errors?.tags?.message}
                      placeholder="Add Tags"
                      value={value}
                      callback={onChange}
                      onSearch={addTags}
                      uid="assign-group"
                      isMultiple={true}
                      note="Add Searching Tags for this Banner"
                    />
                  )}
                />
              </div>

              <div className="col-12 mb-3">
                <SelectInput
                  error={errors.imgSize?.message}
                  isMandatory={true}
                  label="Unique Images size from Location Master ( in WxH )"
                  name="imgSize"
                  register={register}
                  options={locationList.imageSizeOptions}
                />
              </div>

              <div className="row mb-3">
                {/* Add Image */}
                <div className="my-3 mb-4">
                  <div className="row">
                    <div className="col-12">
                      <label className="col-form-label fs-val-md">
                        Banner Image <span className="text-danger">*</span>
                      </label>
                    </div>

                    {/* Image Rendering */}
                    <div className="col-auto">
                      {!assetId?.length ? (
                        <div className="upload-placeholder-t1">
                          <i className="bi bi-image"></i>
                        </div>
                      ) : (
                        <div className="uploaded-t1-img-cnt">
                          <i
                            tabIndex={-1}
                            role="button"
                            className="bi bi-x-circle close"
                            onClick={() => {
                              removeImg();
                            }}
                          ></i>
                          <span
                            onClick={() => {
                              viewImage(assetId);
                            }}
                          >
                            <ImgRender
                              assetId={assetId.length ? assetId : ""}
                              width="100"
                              height="100"
                            />
                          </span>
                        </div>
                      )}
                    </div>

                    {/* Action button  */}
                    <div className="col col-ms-auto align-self-end">
                      <div>
                        <FileUpload template={2} callback={imagesCb} />
                      </div>
                      {/* {imgSize?.length > 0 ? (
                        <div className="mt-2 text-danger">
                          <small>Note : Image should be {imgSize} WxH</small>
                        </div>
                      ) : null} */}
                    </div>
                  </div>

                  {errors?.assetId?.message && (
                    <div className="col-12">
                      <InputErrorMsg msg={errors?.assetId?.message} />
                    </div>
                  )}
                </div>

                <div className="col-6 mt-2">
                  <SelectInput
                    name="status"
                    register={register}
                    label="Status"
                    options={statusOptions}
                    error={errors.status?.message}
                    isMandatory={true}
                  />
                </div>
              </div>

              <div className="text-end">
                <button className="btn btn-danger me-2" type="button">
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </form>
            <ImgPreviewModal
              show={imgPreviewData.show}
              handleClose={closeImgPreviewModal}
              assets={imgPreviewData.data}
            />
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ManageBannerDesignMasterModal;
