import { PaginationBlock, TableHeader } from "@sk/uis";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { NavService } from "@sk/services";
import StockDisplay from "../components/StockDisplay";

const tableHeader = [
  { label: "Sl.no", isCentered: true },
  { label: "Name", width: "30%" },
  { label: "ID" },
  // { label: "MRP", isCentered: true },
  // { label: "RSP", isCentered: true },
  { label: "Qty", isCentered: true },
  { label: "Pack Size", isCentered: true },
  { label: "Deal Details", isCentered: true },
  // { label: "Reserve Qty", isCentered: true },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const getDefaultPaginationConfig = (totalRecords) => {
  return {
    ...defaultPagination,
    totalRecords,
  };
};

const RewardProductTable = ({
  offerDeals = [],
  mode = "add",
  deleteCb,
  disableStartDate = true,
  fid,
}) => {
  const header = useMemo(
    () =>
      mode == "add" || !disableStartDate
        ? [...tableHeader, { label: "Actions", isCentered: true }]
        : tableHeader,
    [mode, disableStartDate]
  );
  const [data, setData] = useState([]);

  const fidRef = useRef("");

  const paginationRef = useRef(getDefaultPaginationConfig(data.length));

  useEffect(() => {
    if (fid) {
      fidRef.current = fid;
      paginationRef.current = getDefaultPaginationConfig(offerDeals.length);
      loadList();
    }
  }, [offerDeals, fid]);

  const loadList = useCallback(() => {
    const d = offerDeals.slice(
      paginationRef.current.startSlNo - 1,
      paginationRef.current.endSlNo
    );
    setData(d);
  }, [offerDeals]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      loadList();
    },
    [loadList]
  );
  return (
    <>
      <table className="table table-bordered table-sm px-4 mx-0">
        <TableHeader data={header} />
        <tbody>
          {data.length == 0 && (
            <tr>
              <td colSpan={header.length} className="text-center py-2">
                No data found
              </td>
            </tr>
          )}

          {data.length > 0 &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-md">
                <td className="text-center">
                  {paginationRef.current.startSlNo + i}
                </td>
                <td>
                  <div
                    className="fw-semibold fs-val-sm cursor-pointer text-primary"
                    onClick={() => {
                      NavService.viewDeal({
                        id: x.offerDeals?.[0]?.dealId,
                      });
                    }}
                  >
                    {x?.name}
                  </div>
                </td>
                <td>
                  <div
                    className="fw-semibold fs-val-sm cursor-pointer text-primary"
                    onClick={() => {
                      NavService.viewDeal({
                        id: x.offerDeals?.[0]?.dealId,
                      });
                    }}
                  >
                    {x?.dealId}
                  </div>
                </td>

                {/* <td className="text-center">
                  <Amount value={x.mrp || "0"} />
                </td>

                <td className="text-center">
                  <Amount value={x.price || "0"} />
                </td> */}

                <td className="text-center">
                  {x.qty} {x?.unitType}
                  {x.offerLimit ? (
                    <>
                      <div className="text-center mt-3 text-muted">
                        Offer Limit
                      </div>
                      <div className="text-center ">
                        {x.offerLimit} {x?.unitType}
                      </div>
                    </>
                  ) : null}
                </td>

                <td className="text-center">{x.packSize || "N/A"}</td>

                {/* Stock */}
                <td className="text-center">
                  <StockDisplay
                    fid={fidRef.current}
                    type={2}
                    dealData={x}
                    showOfferRsp={false}
                  />
                </td>

                {(mode == "add" || !disableStartDate) && (
                  <td className="text-center">
                    <button
                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-danger border shadow-sm"
                      type="button"
                      onClick={() => deleteCb(x, i)}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </>
  );
};

export default memo(RewardProductTable);
