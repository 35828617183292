import {
  Amount,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo, useRef, useState } from "react";

import SmartCoinRewardViewModal from "../modals/SmartCoinRewardViewModal";

const rbac = {
  editButton: ["EditLuckyDraw"],
  viewButton: ["ViewLuckyDraw"],
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", isCentered: true, width: "7%" },
  { label: "Rule Type", isCentered: true },
  { label: "Min Order Value", isCentered: true, width: "10%" },
  { label: "StoreKing Coins", isCentered: true, width: "14%" },
  { label: "Start Date /End Date", width: "15%" },
  { label: "Status", isCentered: true, width: "7%" },
  { label: "Created On", width: "12%" },
  { label: "Actions", isCentered: true, width: "9%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  editCb,
  sortCb,
  markAsActive,
  markAsInActive,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const viewModalRef = useRef({ data: {} });

  const openViewModal = (data) => {
    viewModalRef.current.data = data;
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    viewModalRef.current.data = {};
    setShowViewModal(false);
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Config Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>
                <td className="text-center">{x._id}</td>
                <td className="text-break text-center">
                  {x._displayRuleType || "N/A"}
                </td>
                <td className="text-center">
                  <Amount value={x.slab[0].min} />
                </td>
                <td>
                  {x.type == "ParentCoinReward" ? (
                    <>
                      {/* <KeyVal
                        label="Order Limit"
                        labelCol="col-6"
                        contentCol="col-6"
                      >
                        : {x?.orderLimitValidity?.orderLimit || 0} orders
                      </KeyVal> */}

                      <KeyVal
                        label="Monthly Limit"
                        labelCol="col-6"
                        contentCol="col-6"
                      >
                        : {x?.monthlyOrderLimitValidity?.orderLimit || 0} orders
                      </KeyVal>

                      <KeyVal label="Slabs" labelCol="col-6" contentCol="col-6">
                        : {x?.slab?.length || 0}
                      </KeyVal>
                    </>
                  ) : null}

                  {x.type != "NoInviteFirstorder" &&
                    x.type != "ParentCoinReward" && (
                      <>
                        <KeyVal
                          label="Parent Coins"
                          labelCol="col-6"
                          contentCol="col-6"
                        >
                          : {x.slab[0].senderValue || "N/A"}
                        </KeyVal>
                        <KeyVal
                          label="Child Coins"
                          labelCol="col-6"
                          contentCol="col-6"
                        >
                          : {x.slab?.[0]?.reciverValue || "N/A"}
                        </KeyVal>
                      </>
                    )}
                  {x.type == "NoInviteFirstorder" && (
                    <KeyVal label="Coins" labelCol="col-6" contentCol="col-6">
                      : {x.slab?.[0]?.reciverValue || "N/A"}
                    </KeyVal>
                  )}
                </td>
                <td>
                  <div className="text-muted fs-val-sm">Start Date </div>
                  <div className="fs-val-md mb-2">
                    <DateFormatter
                      date={x?.validityDate?.from}
                      format="dd MMM yyyy hh:mm a"
                    />
                  </div>
                  <div className="text-muted fs-val-sm">End Date</div>
                  <div className="fs-val-md">
                    <DateFormatter
                      date={x?.validityDate?.to}
                      format="dd MMM yyyy hh:mm a"
                    />
                  </div>
                </td>
                <td className="text-center">
                  <HighlightText status={x.status} isBadge />
                  <>
                    {x.status == "Active" ? (
                      <div>
                        <button
                          className="btn btn-link text-danger fs-val-xs p-0"
                          onClick={() => markAsInActive(x)}
                        >
                          Mark as Inactive
                        </button>
                      </div>
                    ) : null}

                    {/* FOR MARK AS ACTIVE BUTTON  */}
                    {x.status == "Inactive" ? (
                      <button
                        className="btn btn-link text-success fs-val-xs p-0"
                        onClick={() => markAsActive(x)}
                      >
                        Mark as Active
                      </button>
                    ) : null}
                  </>
                </td>
                <td>
                  <DateFormatter
                    date={x?.createdAt}
                    format="dd MMM yyyy hh:mm a"
                  />
                  <div className="mt-1">
                    By :
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x?._createdBy?.name
                    )}
                  </div>
                </td>
                <td className="text-center p-0">
                  <button
                    className="btn btn-outline-warning btn-sm me-1"
                    onClick={() => editCb(x._id)}
                  >
                    Edit
                  </button>
                  {/* <Rbac roles={rbac.editButton}>
                    {x.status == "Pending" && (
                      <button
                        className="btn btn-outline-warning btn-sm me-1"
                        onClick={() => editCb(x._id)}
                      >
                        Edit
                      </button>
                    )}
                  </Rbac> */}
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => openViewModal(x)}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <SmartCoinRewardViewModal
        show={showViewModal}
        modalData={viewModalRef.current.data}
        closeModal={closeViewModal}
      />
    </>
  );
};

export default memo(Table);
