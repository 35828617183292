import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useState } from "react";

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "ID", isCentered: false },
  { label: "Page Name", isCentered: false },
  { label: "Location", isCentered: true },
  { label: "Image Width", isCentered: true },
  { label: "Image Height", isCentered: true },
  { label: "Image Max Size", isCentered: true },
  { label: "Sliding Time", isCentered: true },
  { label: "Multi Banner Enabled", isCentered: true },
  // { label: "Total Images", isCentered: true },
  { label: "Last Updated", isCentered: true },
  { label: "Status/ Actions", isCentered: true },
];

const rbac = {
  edit: ["EditBannerLocationMaster"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  auditCb,
}) => {
  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };
  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No Data */}
          {!loading && !data?.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* List  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id + index}>
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>
                {/*ID */}
                <td>{x._id}</td>

                {/*Page */}
                <td>
                  <div>{x?.page || "N/A"}</div>
                </td>
                {/* location */}
                <td className="text-center">
                  <div>{x?.location || "N/A"}</div>
                </td>

                {/* width */}
                <td className="text-center">
                  <div>{x?.assetAttributes?.widthPxl || "N/A"}px</div>
                </td>

                {/* height */}
                <td className="text-center">
                  <div>{x?.assetAttributes?.heightPxl || "N/A"}px</div>
                </td>

                {/* size */}
                <td className="text-center">
                  <div>{x?.assetAttributes?.maxSizeBytes || "N/A"} MB</div>
                </td>

                {/* Slider Time */}
                <td className="text-center">
                  {x?.assetAttributes?.slideTimeSecs} sec
                </td>

                <td className="text-center">
                  <HighlightText
                    status={x?.assetAttributes?.allowMultiImage ? "Yes" : "No"}
                    isBadge={true}
                  />
                  {x?.assetAttributes?.allowMultiImage ? (
                    <div className="pt-2">
                      Max Images : {x?.assetAttributes?.totalImages || 1}
                    </div>
                  ) : null}
                </td>

                {/* Last Updated */}
                <td className="text-center">
                  <DateFormatter date={x.lastUpdated} />
                  <div>
                    By :
                    {x.modifiedLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x?._modifiedBy?.name || x.modifiedBy || "N/A"
                    )}
                  </div>
                </td>

                {/* Action block */}
                <td className="text-center">
                  <div className="mb-3">
                    <HighlightText status={x?.status} isBadge={true} />
                  </div>

                  <Rbac roles={rbac.edit}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs mb-3 mx-1"
                      onClick={() => {
                        editCb(x?._id);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                  <button
                    className="btn btn-sm btn-outline-info me-1 fs-val-xs mb-3 mx-1"
                    onClick={() => {
                      auditCb(x);
                    }}
                  >
                    Audit Logs
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        showSummary={false}
      />
    </>
  );
};

export default memo(Table);
