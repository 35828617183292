import classNames from "classnames";
import {
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";

const defaultHeaders = [
  { label: "Sl No", isCentered: true, width: "10%" },
  { label: "Type", width: "15%" },
  { label: "ID", width: "10%" },
  { label: "Name", width: "40%" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const searchTypeDropdownOptions = [
  { label: "Deal", value: "deal", inputLabel: "Search for Deal" },
  {
    label: "Category",
    value: "category",
    inputLabel: "Search for Category",
  },
  { label: "Brand", value: "brand", inputLabel: "Search for Brand" },
];

const getDefaultPaginationConfig = (totalRecords) => {
  return {
    ...defaultPagination,
    totalRecords,
  };
};

const ExcludeForTable = ({
  header,
  excludeForData,
  removeFromExclude,
  mode,
}) => {
  const [data, setData] = useState([]);

  const [searchType, setSearchType] = useState(searchTypeDropdownOptions[0]);

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      searchKey: "",
    },
  });

  const headers = useMemo(() => {
    if (mode == "edit") {
      return [
        ...defaultHeaders,
        { label: "Actions", width: "10%", isCentered: true },
      ];
    } else {
      return [...defaultHeaders];
    }
  }, [mode]);

  const paginationRef = useRef(
    getDefaultPaginationConfig(excludeForData.length)
  );

  useEffect(() => {
    paginationRef.current = getDefaultPaginationConfig(excludeForData.length);
    setData(excludeForData.slice(0, 10));
  }, [excludeForData]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = excludeForData.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setData((prev) => d);
      applyFilter();
    },
    [applyFilter, excludeForData]
  );

  const onSearch = debounce(() => {
    const searchKey = getValues("searchKey").trim();
    applyFilter(searchKey);
  }, 700);

  const applyFilter = useCallback(
    (searchKey = "") => {
      let list = [];
      if (searchKey) {
        list = data.filter((x) => {
          const name = new RegExp(searchKey, "gi").test(x.name);
          const id = new RegExp(searchKey, "gi").test(x.id);
          return x.type == searchType.value && (name || id);
        });
        paginationRef.current = getDefaultPaginationConfig(list.length);
      } else {
        list = excludeForData.slice(
          paginationRef.current.startSlNo - 1,
          paginationRef.current.endSlNo
        );
        paginationRef.current = getDefaultPaginationConfig(
          excludeForData.length
        );
      }
      setData(list);
    },
    [data, excludeForData, searchType.value]
  );

  const getSearchType = (x) => {
    setValue("searchKey", "");
    setSearchType(x);
    applyFilter();
  };

  //   Drop Down
  const getSearchByGroupContent = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="none">
          {searchType.label} <i className="bi bi-chevron-down"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {searchTypeDropdownOptions.map((x) => (
            <Dropdown.Item key={x.value} onClick={() => getSearchType(x)}>
              {x.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      {/* Filter Block */}
      <div className="row">
        <div
          className={classNames("col-6", {
            "px-4": mode == "view",
          })}
        >
          <TextInput
            name="searchKey"
            register={register}
            callback={onSearch}
            placeholder={searchType.inputLabel}
            groupContent={getSearchByGroupContent()}
          />
        </div>
      </div>

      <div className="px-4 mb-1">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={false}
        />
      </div>
      <table className="table table-bordered">
        <TableHeader data={headers} />
        <tbody>
          {data.length == 0 ? (
            <tr>
              <td colSpan={headers.length} className="text-center py-2">
                No data found
              </td>
            </tr>
          ) : null}

          {data.map((x, i) => (
            <tr key={i} className="fs-val-md">
              <td className="text-center">{i + 1}</td>
              <td className="text-uppercase">{x.type}</td>
              <td>{x.id}</td>
              <td>{x.name}</td>
              {mode == "edit" && (
                <td className="text-center">
                  <button
                    className="btn fs-val-md bg-white px-1 fw-bold py-0 text-danger border shadow-sm"
                    type="button"
                    onClick={() => removeFromExclude(x)}
                  >
                    <i className="bi bi-x"></i>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </>
  );
};

export default memo(ExcludeForTable);
