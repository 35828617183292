import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { NotificationService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageInfo,
  Rbac,
  Spinner,
} from "@sk/uis";
import { format, set } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { detailsView, filterView } from "../constantService";
import ManageMarketingPromotionModal from "../modals/manage-modal/ManageMarketingPromotionModal";
import TemplateDetails from "./components/TemplateDetails";

const rbac = {
  editButton: ["EditBanner"],
};

const ViewMarketingPromotion = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([...detailsView.breadcrumbs]);
  // data for View Banner
  const [data, setData] = useState({});

  // Loading State For Data
  const [loading, setLoading] = useState(true);

  const [showManageModal, setShowManageModal] = useState({
    show: false,
    id: "",
    mode: "add",
  });

  // To Get all Search Params
  const [searchParams] = useSearchParams();

  // NAvigation Object
  const router = useNavigate();

  // To get Query Params
  const query = useFetchUrlQueryString(searchParams);

  // Custom Hook To attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Use Effect To Load Details
  useEffect(() => {
    if (query.id) {
      loadDetails(query.id);
    } else {
      setLoading(false);
    }
  }, [attachAllData, query.id, setAdditionalData, loadDetails]);

  // To Load  Data
  const loadDetails = useCallback(
    async (id, type) => {
      setLoading(true);

      let m = await NotificationService.getPromotionTemplateById(id);

      const d = m.resp || {};
      let t = d.executeTime;
      const a = [t.slice(0, 2), t.slice(3)];

      // set array values below
      const dt = set(new Date(), {
        hours: a[0] * 1,
        minutes: a[1] * 1,
      });

      const time = format(dt, "hh:mm aa");
      d._executeTime = time;
      d._notificationTypes = d.notifcationTypes.filter((e) => {
        return e.status;
      });

      // For executeType display text
      if (d.criteriaDetails?.executeType) {
        let s = filterView.criteriaExecuteTypeOptions.find(
          (k) => d.criteriaDetails.executeType === k.value
        );
        d._executeType = s.label;
      }

      // For executeAction display text
      if (d.criteriaDetails?.executeAction) {
        let s = filterView.criteriaExecuteActionOptions.find(
          (k) => d.criteriaDetails.executeAction === k.value
        );
        d._executeAction = s.label;
      }

      // For customerstatus display text
      if (d.criteriaDetails?.customerStatus) {
        let s = filterView.criteriaCustomerStatusOptions.find(
          (k) => d.criteriaDetails.customerStatus === k.value
        );
        d._customerstatus = s.label;
      }

      // Attach additional Info
      if (d._id) {
        let tmp = [];

        setAdditionalData([d], detailsView.additionalTableDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == detailsView.additionalTableDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });

        // Setting Data
        setData(d);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [attachAllData, setAdditionalData]
  );

  // To Open Edit Modal
  const editConfig = useCallback((d) => {
    setShowManageModal({ show: true, id: d?._id, mode: "edit" });
  }, []);

  // To Open Edit Modal
  const closeManageModal = useCallback(() => {
    setShowManageModal({ show: false, id: "", mode: "edit" });
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Message Config Details"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* No Data Found */}

      {!loading && !data?._id ? (
        <AppCard>
          <NoDataFound> No Data Found </NoDataFound>
        </AppCard>
      ) : null}

      {data?._id ? (
        <>
          {/* Main Details starts (left side) */}
          <AppCard>
            <div className="row   border-bottom pb-2 ">
              <div className="col-12">
                <div className="fs-val-lg text-primary mb-3 fw-semibold">
                  {data.title} - ({data?._id})
                </div>
              </div>

              {/* Type */}
              <div className="col-3 mb-2">
                <KeyVal label="Type" labelCol="col-5" contentCol="col-7">
                  <span className=" fw-semibold">: {data?.type}</span>
                </KeyVal>
              </div>

              {/* Criteria Type */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Event Type"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="fw-semibold"
                >
                  <span className=" fw-semibold">
                    : {data?.criteriaDetails?.eventType || "N/A"}
                  </span>
                </KeyVal>
              </div>

              {/* When To Send Notifications */}

              {/* Execute Type */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Execute Type"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="fw-semibold"
                >
                  : {data?._executeType}
                </KeyVal>
              </div>

              {/* Execute Date */}
              {data.criteriaDetails?.executeType == "Date" ? (
                <div className="col-3 mb-2">
                  <KeyVal
                    label="Execut Date"
                    labelCol="col-5"
                    contentCol="col-7"
                    className="text-primary fw-semibold"
                  >
                    :{" "}
                    <DateFormatter
                      date={data?.criteriaDetails?.executeDate}
                      format="dd MMM yyyy"
                    />
                  </KeyVal>
                </div>
              ) : null}

              {/* Execute Type Custom Value */}
              {data.criteriaDetails?.executeType == "Custom" ? (
                <>
                  <div className="col-3 mb-2">
                    <KeyVal
                      label="Execute Custom Value :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="fw-semibold"
                    >
                      {data.criteriaDetails?.executeTypeCustomValue}
                    </KeyVal>
                  </div>
                </>
              ) : null}

              {/* Event Unit */}
              {["Start Of", "End Of", "Custom"].indexOf(
                data.criteriaDetails?.executeType
              ) != -1 ? (
                <div className="col-3 mb-2">
                  <KeyVal
                    label="Event Unit"
                    labelCol="col-5"
                    contentCol="col-7"
                    className="fw-semibold"
                  >
                    : {data.criteriaDetails?.executeUnit}
                  </KeyVal>
                </div>
              ) : null}

              {/* Execute Action */}
              {/* <div className="col-3 mb-2">
                <KeyVal
                  label="Execute Action"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="fw-semibold"
                >
                  : {data._executeAction}
                </KeyVal>
              </div> */}

              {/* Execute Time */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Execute Time"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="fw-semibold"
                >
                  : {data._executeTime}
                </KeyVal>
              </div>

              {/* Customer Status */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Customer Status"
                  labelCol="col-auto"
                  contentCol="col"
                  className="fw-semibold"
                >
                  <span className="ps-4 ms-1 fw-semibold">
                    : {data?._customerstatus}
                  </span>
                </KeyVal>
              </div>

              {/*  Status */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Status"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="fw-semibold"
                >
                  <span className="ms-1 fw-semibold">
                    :{" "}
                    {data.executeOption == "Scheduled" && data.isActive ? (
                      <HighlightText status={data?.status} isBadge={true} />
                    ) : (
                      <HighlightText
                        status={data?.isActive ? "Active" : "Inactive"}
                        isBadge={true}
                      />
                    )}
                  </span>
                </KeyVal>
              </div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-auto me-4">
                <KeyVal
                  label="Created By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._createdBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto pe-5 border-end">
                <KeyVal
                  label="Created At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              <div className="col-auto ps-4 pe-3">
                <KeyVal
                  label="Modified By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._modifiedBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto me-auto">
                <KeyVal
                  label="Modified At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.lastUpdated} />
                </KeyVal>
              </div>

              <div className="col-auto">
                <Rbac roles={rbac.editButton}>
                  <button
                    className="btn btn-primary fs-val-sm"
                    onClick={() => {
                      editConfig(data);
                    }}
                  >
                    Edit Config
                  </button>
                </Rbac>
              </div>
            </div>
          </AppCard>

          <TemplateDetails tempData={data.notifcationTypes} />
        </>
      ) : null}

      <BusyLoader show={loading} />

      {/* Edit Modal */}
      <ManageMarketingPromotionModal
        show={showManageModal.show}
        closeModal={closeManageModal}
        pid={showManageModal.id}
        mode={showManageModal.mode}
      />
    </>
  );
};

export default ViewMarketingPromotion;
