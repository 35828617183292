import { useAttachAdditionalData } from "@sk/hooks";
import { BannerService } from "@sk/services";
import { BusyLoader, PageInfo, Rbac, TextInput } from "@sk/uis";
import produce from "immer";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import Table from "./components/Table";
import ManageBannerLocationMasterModal from "./modals/ManageBannerLocationMasterModal";
import AuditLogModal from "./modals/AuditLogModal";

const breadcrumb = listView.breadcrumb;

const additionDataConfig = listView.additionDataConfig;

const rbac = {
  add: ["AddBannerLocationMaster"],
};

const prepareFilterParams = (filter = {}, pagination = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  const s = (filter.search || "").trim();

  const r = { $regex: s, $options: "gi" };
  if (s) {
    p.filter.$or = [{ page: r }, { location: r }];
  }

  return p;
};

const BannerLocationMasterList = () => {
  const router = useNavigate();

  const { register } = useForm({
    defaultValues: { search: "", status: "" },
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageModal, setManageModal] = useState({ show: false, data: {} });

  const [auditLogsModal, setAuditLogsModal] = useState({
    show: false,
    data: {},
  });

  const paginationRef = useRef({ ...listView.pagination });

  const filterDataRef = useRef({
    search: "",
    status: "",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;

    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.resp || [];

    let tmp = [];
    setAdditionalData(d, additionDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const loadSummary = useCallback(async () => {
    setSummaryData(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = false;
        });
      })
    );

    const filter = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current
    );
    delete filter.page;
    delete filter.count;

    let pr = [];
    summaryDefaultData.forEach((x) => {
      let p = merge({}, filter, { filter: x.filter });
      pr.push(getCount(p));
    });

    const r = await Promise.all(pr);

    setSummaryData(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k]?.count || 0;
        });
      })
    );
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getData = (params) => {
    return BannerService.getBannerLocationMasterList(params);
  };

  const getCount = async (params) => {
    try {
      const r = await BannerService.getBannerLocationMasterListCount(params);
      return { count: r.resp || 0 };
    } catch (error) {
      return { count: 0 };
    }
  };

  const onCreate = () => {
    setManageModal({ show: true, data: {} });
  };

  const manageModalCb = (e) => {
    setManageModal({ show: false, data: {} });
    if (e.status == "submitted") {
      applyFilter();
    }
  };

  const searchCb = useCallback(
    debounce((e) => {
      const v = e.target.value;
      filterDataRef.current.search = v;
      applyFilter();
    }, 800),
    []
  );

  const tableEditCb = (id) => {
    setManageModal({ show: true, data: { _id: id } });
  };

  const auditCb = (d) => {
    setAuditLogsModal({ show: true, data: [...d?.auditLog] || [] });
  };

  const auditLogsModalCb = () => {
    setAuditLogsModal({ show: false, data: {} });
  };

  return (
    <>
      <div className="row my-2 align-items-center">
        <div className="col">
          <PageInfo
            title="Banner Location Master"
            breadcrumbs={breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <div className="p-1 mt-3">
        {/* <div className="mb-2 row">
          {summaryData.map((x, k) => (
            <div className="col-2" key={k}>
              <SummaryCard
                title={x.label}
                loading={x.loading}
                value={x.value}
                valueColor={x.color}
                template={3}
              />
            </div>
          ))}
        </div> */}

        <div className="row mb-2 align-items-end">
          <div className="col-4">
            <TextInput
              name="search"
              callback={searchCb}
              label="Search By Page/Location Name"
              register={register}
              placeholder="Enter Page/Location Name here.."
            />
          </div>
          <div className="col-auto ms-auto text-end">
            <Rbac roles={rbac.add}>
              <button className="btn btn-primary mb-3" onClick={onCreate}>
                <i className="bi bi-plus-square me-1"></i>
                Create
              </button>
            </Rbac>
          </div>
        </div>

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editCb={tableEditCb}
          auditCb={auditCb}
        />
      </div>

      <BusyLoader show={loadingData} />

      <ManageBannerLocationMasterModal
        show={manageModal.show}
        editId={manageModal.data?._id}
        callback={manageModalCb}
      />

      <AuditLogModal
        show={auditLogsModal.show}
        auditLog={auditLogsModal.data}
        callback={auditLogsModalCb}
      />
    </>
  );
};

export default BannerLocationMasterList;
