import { AppSlide, AppSwiper } from "@sk/uis";
import { useMemo } from "react";
import layoutData from "../layoutData";
import classNames from "classnames";

const blkStyle = {
  height: "320px",
  width: "320px",
};

const imgStyle = {
  objectFit: "contain",
};

const slideOptions = {
  slidesPerView: "auto",
  spaceBetween: 15,
  mousewheel: {
    forceToAxis: true,
  },
};

const Layouts = ({ feature, callback, selected, mode }) => {
  const layouts = useMemo(() => {
    return layoutData.filter((x) => {
      if (mode == "view") {
        return x.value == selected;
      } else {
        return x.allowed.indexOf(feature) != -1;
      }
    });
  }, [feature, mode, selected]);

  const onClick = (l) => {
    callback({ data: l });
  };

  return (
    <>
      <AppSwiper config={slideOptions}>
        {layouts.map((x, k) => (
          <AppSlide key={k} autoWidth={true}>
            <div
              onClick={() => onClick(x)}
              className={classNames(
                "border rounded bg-white d-flex align-items-center justify-content-center p-2 cursor-pointer",
                selected == x.value ? "border-success" : ""
              )}
              style={blkStyle}
              title={x.value.toLocaleUpperCase()}
            >
              <img
                src={x.img}
                className="w-100 h-100 d-inline-block object-fit-contain"
                style={imgStyle}
              />
            </div>
          </AppSlide>
        ))}
      </AppSwiper>
    </>
  );
};

export default Layouts;
