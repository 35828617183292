import { yupResolver } from "@hookform/resolvers/yup";
import { BannerService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppTitle,
  BooleanCheckboxInput,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import listView from "../../constantService";

const validationSchema = yup.object().shape({
  page: yup.string().required(),
  location: yup.string().required(),
  allowMultiImage: yup.boolean(),
  slideTimeSecs: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(20)
    .min(1)
    .label("Sliding Time")
    .required(),
  widthPxl: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(1000000000)
    .min(1)
    .label("Image Width")
    .required(),
  heightPxl: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(1000000000)
    .min(1)
    .label("Image Height")
    .required(),
  maxSizeBytes: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(1000000000)
    .min(1)
    .label("Image Max Size")
    .required(),
  totalImages: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("allowMultiImage", {
      is: (allowMultiImage) => allowMultiImage,
      then: (schema) => schema.label("Total Images").min(1).max(100).required(),
    }),
  status: yup.string().required(),
});

const canvasStyle = {
  width: "35%",
};

const defaultFormData = {
  page: "",
  location: "",
  widthPxl: null,
  heightPxl: null,
  maxSizeBytes: null,
  slideTimeSecs: null,
  allowMultiImage: false,
  totalImages: null,
  status: "Active",
};

const ManageBannerLocationMasterModal = ({ show, callback, editId = "" }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [allowMultiImage] = useWatch({ control, name: ["allowMultiImage"] });

  useEffect(() => {
    if (show) {
      reset({ ...defaultFormData });
      if (editId) {
        loadDetails(editId);
      } else {
        setLoading(false);
      }
    }
  }, [editId, loadDetails, reset, show]);

  const loadDetails = useCallback(
    async (id) => {
      setLoading(true);
      const r = await BannerService.getBannerLocationMasterDetailsById(id);

      if (r?.resp?._id) {
        let d = r.resp;
        setValue("page", d.page);
        setValue("location", d.location);
        setValue("widthPxl", d.assetAttributes.widthPxl);
        setValue("heightPxl", d.assetAttributes.heightPxl);
        setValue("maxSizeBytes", d.assetAttributes.maxSizeBytes);
        setValue("slideTimeSecs", d.assetAttributes.slideTimeSecs);
        setValue("allowMultiImage", d.assetAttributes.allowMultiImage);
        setValue("totalImages", d.assetAttributes.totalImages);
        setValue("status", d.status);

        setLoading(false);
      } else {
        setLoading(false);
        Toaster.error("Failed to load details");
        callback({ status: "closed" });
      }
    },
    [callback, setValue]
  );

  const onHide = () => {
    callback({ status: "closed" });
  };

  const onSubmit = async (f) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    const p = {
      page: f.page,
      location: f.location,
      assetAttributes: {
        widthPxl: f.widthPxl,
        heightPxl: f.heightPxl,
        maxSizeBytes: f.maxSizeBytes,
        slideTimeSecs: f.slideTimeSecs,
        allowMultiImage: f.allowMultiImage,
        totalImages: f.totalImages,
      },
      status: f.status,
    };

    setSubmitting(true);

    let r;
    if (editId) {
      r = await BannerService.updateBannerLocationMaster(editId, p);
    } else {
      r = await BannerService.createBannerLocationMaster(p);
    }
    setSubmitting(false);
    if (r.statusCode == 200) {
      reset({ ...defaultFormData });
      callback({ status: "submitted" });
      Toaster.success(r.resp.message || "Submitted succesfully");
    } else {
      Toaster.error(r.resp.message || "Failed to submit");
    }
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">
            {editId ? "Edit" : "Add"} Banner Location{" "}
            {editId ? "#" + editId : null}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <div className="text-center p-4">
            <Spinner />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-2">
                <div className="col-12">
                  <TextInput
                    isMandatory={true}
                    error={errors.page?.message}
                    label="Page Name"
                    type="text"
                    register={register}
                    name="page"
                    placeholder="Enter Banner Display Page Name Here"
                  />
                </div>

                <div className="col-12">
                  <TextInput
                    isMandatory={true}
                    error={errors.location?.message}
                    label="Location"
                    type="text"
                    register={register}
                    name="location"
                    placeholder="Enter Banner Location  Name Here"
                  />
                </div>

                <div className="p-2">
                  <AppCard title="Image Details">
                    <div className="row">
                      <div className="col-6">
                        <TextInput
                          isMandatory={true}
                          error={errors.widthPxl?.message}
                          label="Width (in px)"
                          type="number"
                          register={register}
                          name="widthPxl"
                          placeholder="Enter Width (in px)"
                        />
                      </div>

                      <div className="col-6">
                        <TextInput
                          isMandatory={true}
                          error={errors.heightPxl?.message}
                          label="Height (in px)"
                          type="number"
                          register={register}
                          name="heightPxl"
                          placeholder="Enter Height (in px)"
                        />
                      </div>

                      <div className="col-6">
                        <TextInput
                          isMandatory={true}
                          error={errors.maxSizeBytes?.message}
                          label="Max size (in MB)"
                          type="number"
                          register={register}
                          name="maxSizeBytes"
                          placeholder="Enter Max size (in MB)"
                        />
                      </div>

                      <div className="col-6">
                        <TextInput
                          isMandatory={true}
                          error={errors.slideTimeSecs?.message}
                          label="Sliding Time (in Sec)"
                          type="number"
                          register={register}
                          name="slideTimeSecs"
                          placeholder="Enter Sliding Timings (in Sec)"
                        />
                      </div>

                      <div className="col-6 pt-4">
                        <div className="pt-2">
                          <Controller
                            control={control}
                            name="allowMultiImage"
                            render={({ field: { onChange, value } }) => (
                              <BooleanCheckboxInput
                                label="Allow Multi Image"
                                name="allowMultiImage"
                                error={errors?.allowMultiImage?.message}
                                isMandatory={false}
                                callback={onChange}
                                value={value}
                                note="Do You Want To Allow Multiple Image"
                              />
                            )}
                          />
                        </div>
                      </div>

                      {allowMultiImage ? (
                        <div className="col-6">
                          <TextInput
                            isMandatory={true}
                            error={errors.totalImages?.message}
                            label="Total Images"
                            type="number"
                            register={register}
                            name="totalImages"
                            note="Max Allowed Images Count For Banner Slides"
                          />
                        </div>
                      ) : null}
                    </div>
                  </AppCard>
                </div>

                <div className="col-6">
                  <SelectInput
                    name="status"
                    register={register}
                    label="Status"
                    options={listView.statusOptions}
                  />
                </div>
              </div>

              <div className="text-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </form>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ManageBannerLocationMasterModal;
