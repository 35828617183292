import { useAttachAdditionalData } from "@sk/hooks";
import { DealService } from "@sk/services";
import {
  Alert,
  Amount,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Rbac,
  SwitchInput,
  Tabs,
  Toaster,
} from "@sk/uis";
import { format } from "date-fns";
import produce from "immer";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import AuditLogStoreDiscount from "../components/AuditLogStoreDiscount";
import ExcludeForTable from "../components/ExcludeForTable";
import OfferDealsTable from "./components/OfferDealsTable";
import OrderList from "./components/OrderList";
import RequestedList from "./components/RequestedList";
import RewardProductTable from "./components/RewardProductTable";
import CustomerDetailsListModal from "./CustomerDetailsListModal";
import OrderDetailsListModal from "./OrderDetailsListModal";

const tabs = [
  {
    tabName: "Requested List",
    key: "requestList",
  },
  {
    tabName: "POS Order List",
    key: "orderList",
  },
  {
    tabName: "Audit Logs",
    key: "audit",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const rbac = {
  editButton: ["EditStoreOffersConfig"],
};

const ViewStoreDiscountModal = ({
  show,
  closeModal,
  modalData,
  setBusyLoader,
}) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [excludeForData, setExcludeForData] = useState([]);

  const { setValue, getValues, control } = useForm({
    defaultValues: {
      status: true,
    },
  });

  // Customer Details
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState({
    show: false,
    data: {},
    configId: "",
  });

  // Order Details
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState({
    show: false,
    data: {},
    configId: "",
  });

  useEffect(() => {
    if (details.excludeFor) {
      Object.keys(details.excludeFor).forEach((key) => {
        const list = details.excludeFor[key].map((x) => ({ ...x, type: key }));
        setExcludeForData(
          produce((draft) => {
            draft.push(...list);
          })
        );
      });
    }
  }, [details.excludeFor]);

  const triggerCloseModal = useCallback(() => {
    closeModal();
    setExcludeForData([]);
  }, [closeModal]);

  const isValidDate = (dateString) => {
    // testing Both date String and ISO Stirng
    return /\(IST\)$/gi.test(dateString) || /000Z/.test(dateString);
  };

  useEffect(() => {
    if (show) {
      setActiveTab(tabs[0]);
      if (modalData._id) {
        const fetchDetails = async () => {
          setLoading(true);
          const r = await DealService.getStoreDiscountById(modalData._id);

          let d = r?.resp || {};

          // set value for status
          setValue("status", d.status == "Active");

          // for formatting the audit log date values
          if (d.auditLog) {
            (d.auditLog || []).forEach((x) => {
              try {
                if (isValidDate(x.oldData)) {
                  x.oldData = format(
                    new Date(x.oldData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
                if (isValidDate(x.newData)) {
                  x.newData = format(
                    new Date(x.newData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
              } catch (error) {}
            });
          }
          setDetails(d);

          setLoading(false);

          if (d._id) {
            let tmp = [];
            // Attach User Info
            setAdditionalData([d], attachAdditionalDataConfig, (x) => {
              tmp.push(x);
              if (tmp.length == attachAdditionalDataConfig.length) {
                const t = [...attachAllData([d], tmp)][0];
                setDetails((v) => ({ ...v, ...t }));
              }
            });
            // loadAnalyticsData(d._id);
          } else {
            Toaster.error("Failed to fetch data, please try again");
            triggerCloseModal();
          }
        };

        fetchDetails();
      }
    }
  }, [
    modalData._id,
    show,
    setValue,
    triggerCloseModal,
    setAdditionalData,
    attachAllData,
  ]);

  const markAsActive = useCallback(
    async (item) => {
      const r = await Alert.confirm({
        title: "Please confirm",
        text: `Do you want to mark Id :${item._id} as Active?`,
        okText: "Yes",
        cancelText: "No",
      });
      if (r.isConfirmed) {
        setBusyLoader({ show: true, msg: "" });
        const p = { offerCode: item.offerCode };
        p.status = "Active";
        const r = await DealService.updateStoreDiscount(item._id, p);
        setBusyLoader({ show: false });

        if (r.statusCode != 200) {
          Toaster.error(r.resp.message || "Failed to update");
          setValue("status", false);

          return;
        }
        setValue("status", true);
        Toaster.success("Updated Successfully");
      } else {
        setValue("status", false);
      }
    },
    [setBusyLoader, setValue]
  );

  const markAsInActive = useCallback(
    async (item) => {
      const r = await Alert.confirm({
        title: "Please confirm",
        text: `Do you want to mark Id :${item._id}  as In-Active?`,
        okText: "Yes",
        cancelText: "No",
      });
      if (r.isConfirmed) {
        setBusyLoader({ show: true, msg: "" });
        const p = { offerCode: item.offerCode };
        p.status = "Inactive";
        const r = await DealService.updateStoreDiscount(item._id, p);

        setBusyLoader({ show: false });

        if (r.statusCode != 200) {
          Toaster.error(r.resp.message || "Failed to update");
          setValue("status", true);
          return;
        }
        setValue("status", false);
        Toaster.success("Updated Successfully");
      } else {
        setValue("status", true);
      }
    },
    [setBusyLoader, setValue]
  );

  const onStatusChange = useCallback(
    () => () => {
      const status = getValues("status");
      if (status) {
        markAsInActive(details);
      } else {
        markAsActive(details);
      }
    },
    [details, getValues, markAsActive, markAsInActive]
  );

  // const hasAccess = useMemo(() => {
  //   return AuthService.isValidUserRole(rbac.editButton);
  // }, []);

  const viewCustomerDetailsCb = () => {
    setShowCustomerDetailsModal({
      show: true,
      configId: details?._id,
    });
  };

  const customerDetailsModalCb = () => {
    setShowCustomerDetailsModal({ show: false, data: {}, configId: "" });
  };

  const viewOrderDetailsCb = () => {
    setShowOrderDetailsModal({
      show: true,
      configId: details?._id,
    });
  };

  const OrderDetailsModalCb = () => {
    setShowOrderDetailsModal({ show: false, data: {}, configId: "" });
  };

  const tabCb = (d) => {
    setActiveTab(d.value);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          <div className="fs-val-lg text-dark px-2">
            {"Store Offer Details" + " - #" + modalData?._id}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {/* Store Details */}
                  <div className="p-4 bg-light">
                    <div className="row">
                      <div className="col-8">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Selected Smart Store
                        </div>
                        {/* Franchise Details */}
                        <div className="col-12">
                          <div className="fw-semibold fs-val-lg text-primary mb-3">
                            {details.franchiseName} - {details?.franchiseId}
                          </div>
                        </div>
                      </div>

                      {/* Status */}
                      <div className="col-2 mb-2">
                        <Rbac roles={rbac.editButton}>
                          <Controller
                            control={control}
                            name="status"
                            render={({ field: { value, onChange } }) => (
                              <SwitchInput
                                label="Status"
                                yesText="Active"
                                noText="Inactive"
                                name="status"
                                value={value}
                                callback={onStatusChange(onChange)}
                                size="wd-normal"
                              />
                            )}
                          />
                        </Rbac>
                      </div>

                      <div className="col-2 ms-auto">
                        {/* {!hasAccess && ( */}
                        <KeyVal label="Status" template="col">
                          <HighlightText
                            status={details.status}
                            isBadge={true}
                          />
                        </KeyVal>
                        {/* )} */}
                      </div>
                    </div>
                  </div>

                  {/* Offer Details */}
                  <div className="p-4 bg-white">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Offer Details
                        </div>
                      </div>

                      {/* Title */}
                      <div className="col-12 mb-4">
                        <KeyVal label="Title" template="col">
                          <span>{details.title}</span>
                        </KeyVal>
                      </div>

                      {/* Description */}
                      {/* <div className="col-12 mb-4">
                        <KeyVal label="Description" template="col">
                          {details.desc}
                        </KeyVal>
                      </div> */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Offer Type" template="col">
                          <span className="badge badge-soft-primary text-uppercase fw-bold">
                            {details.offerType}
                          </span>
                        </KeyVal>
                      </div>
                      {/* Offer Code */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Offer Code" template="col">
                          <span className="badge badge-soft-primary text-uppercase fw-bold text-wrap text-break">
                            {details.offerCode}
                          </span>
                        </KeyVal>
                      </div>
                      {/* Offer Start Date */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Offer Start Date" template="col">
                          <DateFormatter
                            date={details?.offerValidity?.validFrom}
                          />
                        </KeyVal>
                      </div>
                      {/* Offer End Date */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Offer End Date" template="col">
                          <DateFormatter
                            date={details?.offerValidity?.validTo}
                          />
                        </KeyVal>
                      </div>

                      {/* Reward Type  */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Reward Type" template="col">
                          {details.rewardType}
                        </KeyVal>
                      </div>
                      {/* Reward Offer Type  */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Reward Type Offer " template="col">
                          {details.rewardTypeOffer}
                        </KeyVal>
                      </div>
                      {details.rewardType == "DISCOUNT" && (
                        <div className="col-3 mb-4">
                          <KeyVal label="Reward Value" template="col">
                            {details.rewardTypeValue != "Percentage"
                              ? "Rs"
                              : null}
                            {details.rewardValue}
                            {details.rewardTypeValue == "Percentage"
                              ? "%"
                              : null}
                          </KeyVal>
                        </div>
                      )}
                      {/* Created At */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Created At" template="col">
                          <DateFormatter date={details.createdAt} />
                        </KeyVal>
                      </div>
                      {/* Created By */}
                      <div className="col-3 mb-4">
                        <KeyVal label="Created By" template="col">
                          <span> {details._createdBy?.name}</span>
                        </KeyVal>
                      </div>
                      {/* Last Updated */}
                      <div className="col-3">
                        <KeyVal label="Last Updated At" template="col">
                          <DateFormatter date={details.lastUpdated} />
                        </KeyVal>
                      </div>
                      {/* Last Updated By */}
                      {details._lastUpdatedBy?.name && (
                        <div className="col-3">
                          <KeyVal label="Last Updated By" template="col">
                            {details._lastUpdatedBy?.name ? (
                              <span>{details._lastUpdatedBy.name}</span>
                            ) : null}
                          </KeyVal>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* summary */}

                  <div className="row p-4 bg-light mt-3">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Sales Summary
                      </div>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Total Customers" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          {details?.analytics?.uniqueCustomersCount || 0}

                          {details?.analytics?.uniqueCustomersCount > 0 ? (
                            <span
                              className="text-decoration-underline ps-1 cursor-pointer"
                              onClick={() => {
                                viewCustomerDetailsCb();
                              }}
                            >
                              View
                            </span>
                          ) : null}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Total Orders" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          {details?.analytics?.totalOrders || 0}
                          {details?.analytics?.totalOrders > 0 ? (
                            <span
                              className="text-decoration-underline ps-1 cursor-pointer"
                              onClick={() => {
                                viewOrderDetailsCb();
                              }}
                            >
                              View
                            </span>
                          ) : null}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Total Order Value" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          <Amount
                            value={details?.analytics?.totalOrderValue || 0}
                          />
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Last Order Date" template="col">
                        <span className="ps-1 fs-val-lg">
                          <DateFormatter
                            date={details?.analytics?.latestOrderDate}
                            format="dd MMM yyyy"
                          />
                        </span>
                      </KeyVal>
                    </div>

                    {/* <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Main Deal Profit/Loss" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          <span
                            className={classNames({
                              "text-success":
                                details?.analytics?.totalProfitForMainDeal > 0,
                              "text-danger":
                                details?.analytics?.totalProfitForMainDeal < 0,
                              "text-primary":
                                details?.analytics?.totalProfitForMainDeal == 0,
                            })}
                          >
                            <Amount
                              value={
                                details?.analytics?.totalProfitForMainDeal || 0
                              }
                            />
                          </span>
                        </span>
                      </KeyVal>
                    </div> */}

                    {/* <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Offer Deal Profit/Loss" template="col">
                        <span className="ps-1 text-primary fs-val-lg">
                          <span
                            className={classNames({
                              "text-success":
                                details?.analytics?.totalProfitForOfferDeal > 0,
                              "text-danger":
                                details?.analytics?.totalProfitForOfferDeal < 0,
                              "text-primary":
                                details?.analytics?.totalProfitForOfferDeal ==
                                0,
                            })}
                          >
                            <Amount
                              value={
                                details?.analytics?.totalProfitForOfferDeal || 0
                              }
                            />
                          </span>
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Net Profit" template="col">
                        <span className="text-primary fs-val-lg">
                          <Amount
                            value={details?.analytics?.netProfit}
                            decimalPlace={2}
                          />
                        </span>
                      </KeyVal>
                    </div> 
                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Highest Order Amount" template="col">
                        <span className="ps-1 text-primary fs-val-lg ">
                          {analytics.loading ? (
                            <Spinner />
                          ) : (
                            <Amount
                              value={details?.analytics?.highestOrderValue || 0}
                            />
                          )}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Highest Order On" template="col">
                        {analytics.loading ? (
                          <Spinner />
                        ) : (
                          <span className="ps-1 fs-val-lg ">
                            <DateFormatter
                              date={details?.analytics?.highestOrderDate}
                              format="dd MMM yyyy"
                            />
                          </span>
                        )}
                      </KeyVal>
                    </div> */}
                  </div>

                  {/* Offer summary */}

                  <div className="row p-4 mt-3">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Offer Count Summary by Set Offer Limit or Stock Count
                      </div>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Total Offer Count" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          {details?.offerCount + details?.requestCount}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Requested Offer Count" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          {details?.requestCount || 0}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4 fw-semibold">
                      <KeyVal label="Remaining Offer Count" template="col">
                        <span className="ps-1 fs-val-lg text-primary">
                          {details?.offerCount || 0}
                        </span>
                      </KeyVal>
                    </div>
                  </div>

                  {/* Cart Details  */}
                  {details.offerType == "CARTOFFER" && (
                    <>
                      <div className="p-4 bg-light">
                        <div className="row">
                          <div className="col-12">
                            <div className="fw-semibold fs-val-lg mb-3">
                              Cart Details
                            </div>
                          </div>
                          <div className="col-3 mb-4">
                            <KeyVal label="Cart Min Value" template="col">
                              <Amount value={details.minValue} />
                            </KeyVal>
                          </div>

                          <div className="col-3 mb-4">
                            <KeyVal label="Cart Max Value" template="col">
                              <Amount value={details.maxValue} />
                            </KeyVal>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Main Products */}
                  {details.offerType != "CARTOFFER" && (
                    <>
                      <div className="p-4 pb-0  bg-light">
                        <div className="row">
                          <div className="col-12">
                            <div className="fw-semibold fs-val-lg mb-3">
                              Main Deals
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Products on Which there is we are giving offer */}
                      <OfferDealsTable
                        offerDeals={details.offerDeals}
                        mode="view"
                        fid={details?.franchiseId}
                      />
                    </>
                  )}

                  {/* Products what we are giving as reward */}
                  {details.rewardType == "PRODUCT" && (
                    <>
                      <div className="p-4 pb-0 bg-white border-bottom">
                        <div className="row">
                          <div className="col-12">
                            <div className="fw-semibold fs-val-lg mb-3">
                              Offer Deal
                            </div>
                          </div>
                        </div>
                      </div>

                      <RewardProductTable
                        dealList={details.productsList}
                        mode="view"
                        type={details?.productType}
                        fid={details?.franchiseId}
                      />
                    </>
                  )}

                  {/* Exclude For : for what products offer will not allowed  */}
                  {excludeForData.length ? (
                    <>
                      <div className="p-4 pb-0 bg-white">
                        <div className="row">
                          <div className="col-12">
                            <div className="fw-semibold fs-val-lg mb-3">
                              Excluded Items
                            </div>
                          </div>
                        </div>
                      </div>
                      <ExcludeForTable
                        mode="view"
                        excludeForData={excludeForData}
                      />
                    </>
                  ) : null}

                  {/* Tabs */}
                  <div>
                    <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
                    {/* Audit Logs For Store Discount */}
                    {activeTab.key == "audit" ? (
                      <AuditLogStoreDiscount
                        auditLogs={details.auditLog || []}
                      />
                    ) : null}

                    {activeTab.key == "orderList" ? (
                      <OrderList data={details} />
                    ) : null}

                    {activeTab.key == "requestList" ? (
                      <RequestedList details={details} />
                    ) : null}
                  </div>

                  <CustomerDetailsListModal
                    show={showCustomerDetailsModal.show}
                    data={showCustomerDetailsModal.data}
                    configId={showCustomerDetailsModal.configId}
                    callback={customerDetailsModalCb}
                  />

                  <OrderDetailsListModal
                    show={showOrderDetailsModal.show}
                    data={showOrderDetailsModal.data}
                    configId={showOrderDetailsModal.configId}
                    callback={OrderDetailsModalCb}
                  />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewStoreDiscountModal);
