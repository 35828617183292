import { appConfigs } from "@sk/services";
import {
  EntitySearchInput,
  FileUpload2,
  ImgRender,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { useMemo, useState } from "react";

const tableHeaderData = [
  {
    key: "slNo",
    label: "Sl No.",
    width: "10%",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "image",
    label: "Image",
    width: "20%",
  },
  {
    key: "action",
    label: "Action",
    width: "10%",
  },
];

const searchFilter = { select: "name" };

const CustomData = ({ type, callback, data = [], mode = "create" }) => {
  const [search, setSearch] = useState([]);

  const tableHeader = useMemo(() => {
    return tableHeaderData.filter((x) => {
      if (mode == "view" && x.key == "action") {
        return false;
      }
      return true;
    });
  }, [mode]);

  const cb = (e) => {
    setSearch(e);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!search.length) {
      Toaster.error("Please provide " + type);
      return;
    }

    // check for duplicate
    const i = data.findIndex((x) => x._id == search[0].value._id);
    if (i != -1) {
      Toaster.error(search[0].label + " already added");
      return;
    }

    callback({
      action: "add",
      data: { ...search[0].value, id: search[0].value._id },
    });

    setSearch([]);
  };

  const remove = (index) => {
    callback({ action: "remove", data: { index } });
  };

  const removeImg = (index) => {
    callback({ action: "removeImg", data: { index } });
  };

  const fileUploadCb = (e, index) => {
    if (e.status == "success") {
      callback({ action: "upload", data: { asset: e.id, index } });
    }
  };

  return (
    <>
      {mode != "view" ? (
        <>
          <div className="key-val-normal pt-3 text-muted mb-1">
            Add custom data to be displayed on the widget, you can customize the
            image
          </div>
          <div className="mb-3">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-8">
                  <EntitySearchInput
                    callback={cb}
                    gap={0}
                    type={type}
                    uid="cstmdatasrch"
                    value={search}
                    placeholder={"Search for " + type}
                    filterParams={searchFilter}
                  />
                </div>
                <div className="col-auto">
                  <button type="submit" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : null}
      <table className="table table-hover table-bordered">
        <TableHeader data={tableHeader} />
        <tbody>
          {!data.length ? (
            <tr>
              <td colSpan={tableHeader.length} className="text-center">
                No data found
              </td>
            </tr>
          ) : null}
          {(data || []).map((x, k) => (
            <tr key={x._id} className="fs-val-md">
              <td>{k + 1}</td>
              <td>{x.name}</td>
              <td>
                {x.image ? (
                  <div className="position-relative">
                    {mode != "view" ? (
                      <button
                        onClick={() => removeImg(k)}
                        className="top-0 end-0 position-absolute btn fs-val-lg p-1 text-danger"
                      >
                        <i className="bi bi-x-circle"></i>
                      </button>
                    ) : null}
                    <ImgRender assetId={x.image} />
                  </div>
                ) : (
                  <FileUpload2
                    url={appConfigs.ASSET_UPLOAD}
                    callback={(e) => fileUploadCb(e, k)}
                  >
                    <button className="btn btn-outline-primary btn-sm">
                      Upload
                    </button>
                  </FileUpload2>
                )}
              </td>
              {mode != "view" ? (
                <td>
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => remove(k)}
                  >
                    Remove
                  </button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CustomData;
