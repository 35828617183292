/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePickerInput, EntitySearchInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";

const createdOnDateConfig = { mode: "range" };

const canvasStyle = { width: "30%" };

const typeOptions = [
  { label: "All", value: "" },
  { label: "Allowed", value: "yes" },
  { label: "Restricted", value: "no" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const defaultFormValues = {
  isAllowed: "",
  status: "",
  brand: [],
  category: [],
  deal: [],
  createdAt: [],
};

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { register, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  const showOfferDate = useWatch({ control, name: "hasOffer" });

  useEffect(() => {
    // for autofilling the previous filtered values when the modal is openened
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onReset = () => {
    reset(defaultFormValues);
    callback({ status: "reset", formData: getValues() });
  };

  const onApply = () => {
    callback({ status: "applied", formData: getValues() });
  };

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg"> Advance Filter </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {show ? (
          <>
            <div className="row">
              {/* Type Option */}
              <div className="col-6 mb-2">
                <SelectInput
                  label="Type"
                  register={register}
                  name="isAllowed"
                  options={typeOptions}
                />
              </div>

              {/* STATUS */}
              <div className="col-6  mb-2">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                />
              </div>

              {/* franchise  */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="franchise"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="franchise"
                      label="Search By Franchise"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="franchise"
                      // filterParams={franchiseSearchFilter}
                      emptyLabel="Franchise Not Found"
                    />
                  )}
                />
              </div>

              {/* Category  */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="category"
                      label="Search By Category"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="category"
                      emptyLabel="Category Not Found"
                    />
                  )}
                />
              </div>

              {/* brand  */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="brand"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="brand"
                      label="Search By Brand"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="brand"
                      emptyLabel="Btand Not Found"
                    />
                  )}
                />
              </div>

              {/* CREATED ON DATE FILTER */}
              <div className="col-12 mb-3">
                <label className="fw-semibold mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              className="btn btn-warning btn-sm fs-val-md me-1"
              onClick={onReset}
            >
              Reset
            </button>
            <button
              className="btn btn-primary btn-sm fs-val-md"
              onClick={onApply}
            >
              Apply
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(AdvanceFilterModal);
