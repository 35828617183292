import { BannerService } from "@sk/services";
import {
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  KeyVal,
  Spinner,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "40%",
};

const ViewBannerDesignMasterDetailsModal = ({
  show,
  callback,
  editId = "",
}) => {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    if (show && editId) {
      loadDetails(editId);
    }
  }, [editId, show, loadDetails]);

  const viewImage = (x) => {
    if (x?.length > 0) {
      let d = [{ id: x }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  const loadDetails = useCallback(
    async (id) => {
      setLoading(true);
      const r = await BannerService.getBannerDesignMasterById(id);
      if (r?.resp?._id) {
        setData(r.resp);
        setLoading(false);
      } else {
        setLoading(false);
        Toaster.error("Failed to load details");
        callback({ status: "closed" });
      }
    },
    [callback, setLoading, setData]
  );

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">Banner Details #{data?._id}</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <div className="text-center p-4">
            <Spinner />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 mb-4">
              <KeyVal label="Banner Title" template="col">
                <span className="text-primary">{data.assetTitle} </span>
              </KeyVal>
            </div>

            <div className="col-6 mb-4">
              <KeyVal label="Image Size" template="col">
                {data.assetAttributes?.widthPxl}x
                {data.assetAttributes?.heightPxl} (WxH)
              </KeyVal>
            </div>

            <div className="col-6 mb-4">
              <KeyVal label="Status" template="col">
                <span className="text-primary">
                  <HighlightText status={data.status} isBadge={true} />
                </span>
              </KeyVal>
            </div>

            <div className="col-6 mb-4">
              <KeyVal label="Active Banner Count" template="col">
                0
              </KeyVal>
            </div>

            <div className="col-6 mb-4">
              <KeyVal label="Total Banner Clicks" template="col">
                0
              </KeyVal>
            </div>

            <div className="col-12 mb-4">
              <KeyVal label="Tags" template="col">
                {(data.tags || []).map((e, k) => (
                  <span className="badge bg-primary mx-1" key={k}>
                    {e}
                  </span>
                ))}
                {!data?.tags?.length ? "N/A" : ""}
              </KeyVal>
            </div>

            <div className="col-6">
              <div className="mb-1 fs-val-md">Banner Image</div>
              <div
                className="p-2 py-3"
                onClick={() => {
                  viewImage(data?.assetId);
                }}
              >
                <ImgRender assetId={data?.assetId || ""} />
              </div>
            </div>

            <ImgPreviewModal
              show={imgPreviewData.show}
              handleClose={closeImgPreviewModal}
              assets={imgPreviewData.data}
            />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewBannerDesignMasterDetailsModal;
