import { MarketingDashboardNavComponent } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import { DealService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  PageInfo,
  SelectInput,
  SummaryCard,
  TextInput,
} from "@sk/uis";
import produce from "immer";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ClubDealPurchaseLimitTable from "./components/ClubDealPurchaseLimitTable";
import ClubDealPurchaseLimitManageModal from "./modals/ClubDealPurchaseLimitManageModal";
import ClubDealPurchaseLimitViewModal from "./modals/ClubDealPurchaseLimitViewModal";

const breadcrumb = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Club Deal Purchase Limit",
  },
];

const additionDataConfig = [
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const prepareFilterParams = (filter = {}, pagination = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  const s = (filter.search || "").trim();
  if (s) {
    p.filter.$or = [
      {
        _id: s,
      },
      {
        dealId: s,
      },
      {
        dealName: {
          $regex: s,
          $options: "gi",
        },
      },
    ];
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (!Object.keys(p.filter).length) {
    delete p.filter;
  }

  return p;
};

const summaryDefaultData = [
  {
    label: "Total Count",
    value: 0,
    loading: true,
    filter: {},
    color: "primary",
  },
  {
    label: "Total Active",
    value: 0,
    loading: true,
    filter: {
      status: "Active",
    },
    color: "success",
  },
  {
    label: "Total Inactive",
    value: 0,
    loading: true,
    filter: {
      status: "Inactive",
    },
    color: "danger",
  },
];

const statusOptions = [
  {
    value: "",
    label: "All Status",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "In Active",
  },
];

const ClubDealPurchaseLimitList = () => {
  const router = useNavigate();

  const { register } = useForm({
    defaultValues: { search: "", status: "" },
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageModal, setManageModal] = useState({ show: false, data: {} });

  const [viewModal, setViewModal] = useState({ show: false, data: {} });

  const [summaryData, setSummaryData] = useState([...summaryDefaultData]);

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 15,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 15,
  });

  const filterDataRef = useRef({
    search: "",
    status: "",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;

    loadSummary();

    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummary]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];

    let tmp = [];
    setAdditionalData(d, additionDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const loadSummary = useCallback(async () => {
    setSummaryData(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = false;
        });
      })
    );

    const filter = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current
    );
    delete filter.page;
    delete filter.count;

    let pr = [];
    summaryDefaultData.forEach((x) => {
      let p = merge({}, filter, { filter: x.filter });
      pr.push(getCount(p));
    });

    const r = await Promise.all(pr);

    setSummaryData(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k]?.count || 0;
        });
      })
    );
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getData = async (params) => {
    const r = await DealService.getClubDealPurchaseLimit(params);
    const d = Array.isArray(r.resp) ? r.resp : [];
    return {
      data: d,
    };
  };

  const getCount = async (params) => {
    try {
      const r = await DealService.getClubDealPurchaseLimitCount(params);
      return { count: r.resp || 0 };
    } catch (error) {
      return { count: 0 };
    }
  };

  const onCreate = () => {
    setManageModal({ show: true, data: {} });
  };

  const manageModalCb = (e) => {
    setManageModal({ show: false, data: {} });
    if (e.status == "submitted") {
      applyFilter();
    }
  };

  const tableCb = (d) => {
    if (d.action == "edit") {
      setManageModal({ show: true, data: { _id: d.data._id } });
    }
    if (d.action == "view") {
      setViewModal({ show: true, data: { _id: d.data._id } });
    }
  };

  const searchCb = useCallback(
    debounce((e) => {
      const v = e.target.value;
      filterDataRef.current.search = v;
      applyFilter();
    }, 800),
    []
  );

  const statusCb = (e) => {
    filterDataRef.current.status = e;
    applyFilter();
  };

  const viewModalCb = () => {
    setViewModal({ show: false, data: {} });
  };

  return (
    <>
      <div className="row my-2 align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Club Deal Purchase Limit"
            breadcrumbs={breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="purchaseLimit"
        router={router}
        subTypeKey="config"
      />

      <div className="p-1 mt-3">
        <div className="mb-2 row">
          {summaryData.map((x, k) => (
            <div className="col-2" key={k}>
              <SummaryCard
                title={x.label}
                loading={x.loading}
                value={x.value}
                valueColor={x.color}
                template={3}
              />
            </div>
          ))}
        </div>

        <AppCard>
          <div className="row align-items-end">
            <div className="col-4">
              <TextInput
                name="search"
                callback={searchCb}
                register={register}
                placeholder="Search by ID/Deal ID/Name"
                gap={0}
              />
            </div>
            <div className="col-2">
              <SelectInput
                callback={statusCb}
                name="status"
                options={statusOptions}
                register={register}
                gap={0}
              />
            </div>
            <div className="col-auto ms-auto text-end">
              <button className="btn btn-primary" onClick={onCreate}>
                <i className="bi bi-plus-square me-1"></i>
                Create
              </button>
            </div>
          </div>
        </AppCard>

        <AppCard>
          <ClubDealPurchaseLimitTable
            data={data}
            loading={loadingData}
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            loadingTotalRecords={loadingTotalRecords}
            callback={tableCb}
          />
        </AppCard>
      </div>

      <ClubDealPurchaseLimitManageModal
        show={manageModal.show}
        callback={manageModalCb}
        editId={manageModal?.data?._id}
      />
      <ClubDealPurchaseLimitViewModal
        callback={viewModalCb}
        configId={viewModal.data._id}
        show={viewModal.show}
      />
      <BusyLoader show={loadingData} />
    </>
  );
};

export default ClubDealPurchaseLimitList;
