import { NotificationService } from "@sk/services";
import { AppTitle, NoDataFound, Spinner, TableHeader } from "@sk/uis";
import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";

const templateTableHeaders = [
  {
    label: "Sl No",
    width: "10%",
  },
  {
    label: "Type",
    width: "15%",
  },
  {
    label: "Template ID",
    width: "15%",
  },
  {
    label: "Template",
  },
];

const TemplateDetails = ({ tempData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (tempData?.length) {
      let d = tempData.map((e) => {
        return { ...e, loading: true };
      });
      setData(d);
      tempData.forEach((e, i) => {
        loadTemplateById(e, i);
      });
    }
  }, []);

  const loadTemplateById = useCallback(async (e, i) => {
    const r = await NotificationService.getDetailsById(e.templateId);
    if (r.resp) {
      setData(
        produce((draft) => {
          draft[i].details = r.resp;
          draft[i].loading = false;
        })
      );
    }
  }, []);

  return (
    <>
      <div className="col-12 fw-semibold mb-2">
        <AppTitle title="Config Notification Template" />
      </div>
      <div className="col-12">
        <table className="table table-bordered">
          <TableHeader data={templateTableHeaders} />

          <tbody className="fs-val-md">
            {data.length == 0 ? (
              <tr>
                <td colSpan={templateTableHeaders.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}
            {data?.map((x, k) => (
              <tr key={x._id}>
                <td className="text-center">{k + 1}</td>
                <td>{x?.type}</td>
                <td>{x?.templateId}</td>
                <td>
                  {x.loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <div>{x?.details?.name}</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TemplateDetails;
