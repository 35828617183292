import { MarketingDashboardNavComponent } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, DealService } from "@sk/services";
import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  AppSlide,
  AppSwiper,
  BooleanCheckboxInput,
  BusyLoader,
  EntitySearchInput,
  PageInfo,
  PaginationSummary,
  Rbac,
  SummaryCard,
  TableColumnBtn,
  TextInput,
  Toaster,
} from "@sk/uis";
import { isWithinInterval, parseISO, set } from "date-fns";

import { produce } from "immer";

import merge from "lodash/merge";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import Table from "./components/Table";

import ManageStoreDiscountModal from "./modals/ManageStoreDiscountModal";

import ViewStoreDiscountModal from "./modals/ViewStoreDiscountModal";

import debounce from "lodash/debounce";

import listView from "../constantService";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import CustomerDetailsListModal from "./modals/CustomerDetailsListModal";

import CloneOfferModal from "./modals/CloneOfferModal";
import OrderDetailsListModal from "./modals/OrderDetailsListModal";

// To Get The Brand List Data
const getData = async (params) => {
  const r = await DealService.getStoreDiscountList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await DealService.getStoreDiscountListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

const additionalTableDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];
// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { $or: [] },
  };

  let d = filter || {};
  if (d.status) {
    p["filter"]["status"] = d.status;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
    // p.sort = { [sort.key]: sort.value == "desc" ? -1 : 1 };
  }

  const configId = d._id.trim();

  if (configId) {
    p.filter._id = { $regex: configId, $options: "gi" };
  }

  if (d.franchise.length) {
    p.filter.$and = [{ franchiseId: d.franchise[0]?.value?._id }];
  }

  if (d?.mainDeal?.length) {
    // let z = { $regex: d.mainDeal, $options: "gi" };
    // if (!p.filter?.$and?.length) {
    //   p.filter.$and = [];
    // }
    if (p.filter.$and?.length > 0) {
      p.filter.$and.push({ "offerDeals.dealId": d.mainDeal[0]?.value?._id });
    } else {
      p.filter.$and = [{ "offerDeals.dealId": d.mainDeal[0]?.value?._id }];
    }
  }

  // when someone is searching for main deal
  // const deal = d.deal.trim();

  // const dealRegex = {
  //   $regex: CommonService.sanitizeRegex(deal),
  //   $options: "gi",
  // };

  // if (deal) {
  //   // if (p.filter?.$or?.length) {
  //   //   p.filter.$or = [];
  //   // }
  //   p.filter.$or = [
  //     {
  //       "offerDeals.dealId": dealRegex,
  //     },
  //     {
  //       "offerDeals.name": dealRegex,
  //     },
  //     {
  //       "productsList.dealId": dealRegex,
  //     },
  //     {
  //       "productsList.name": dealRegex,
  //     },
  //   ];
  // }
  if (d.brand?.length) {
    p.filter.$or.push({ "productsList.brand.id": d.brand[0]?.value?._id });
    p.filter.$or.push({ "offerDeals.brand.id": d.brand[0]?.value?._id });
  }
  if (d.category?.length) {
    p.filter.$or.push({
      "productsList.category.id": d.category[0]?.value?._id,
    });
    p.filter.$or.push({ "offerDeals.category.id": d.category[0]?.value?._id });
  }
  if (d.menu?.length) {
    p.filter.$or.push({ "productsList.menu.id": d.menu[0]?.value?._id });
    p.filter.$or.push({ "offerDeals.menu.id": d.menu[0]?.value?._id });
  }

  if (d.isExpiredOfferCount) {
    p.filter.offerCount = 0;
    p.filter.status == "Active";
    p.filter["offerValidity.validFrom"] = {
      $lte: new Date(),
    };

    p.filter["offerValidity.validTo"] = {
      $gte: new Date(),
    };
  }

  if (d.offerType) {
    p.filter.offerType = d.offerType;
  }
  if (d.rewardType) {
    p.filter.rewardType = d.rewardType;
  }
  if (filter.createdAt.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (filter.validityPeriod.length) {
    p.filter["offerValidity.validFrom"] = {
      $gte: set(filter.validityPeriod[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };

    p.filter["offerValidity.validTo"] = {
      $lte: set(filter.validityPeriod[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (!p.filter.$or.length) {
    delete p.filter.$or;
  }
  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const rbac = {
  addButton: ["AddStoreOffersConfig"],
};

const ListStoreDiscount = () => {
  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To Show Manage Modal
  const [showManageModal, setShowManageModal] = useState(false);

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // For Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Adv Filter Modal
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  // Customer Details
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState({
    show: false,
    configId: "",
  });

  // Order Details
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState({
    show: false,
    configId: "",
  });

  // To select More Columns
  const [selectedMoreTblColumns, setSelectedMoreTblColumns] = useState(() => {
    let t = {};
    listView.moreTableColumns
      .filter((x) => x.checked)
      .forEach((x) => {
        t[x.key] = x;
      });
    return t;
  });

  const [cloneModal, setCloneModal] = useState({
    show: false,
    configId: "",
    data: "",
  });

  // To Store Filter Data
  const filterDataRef = useRef({ ...listView.filterFromData });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  //   Show View Modal
  const [showViewModal, setShowViewModal] = useState(false);

  // To Store ManageBrand Modal Data
  const manageModalDataRef = useRef({ id: "", mode: "add" });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  const viewModalDataRef = useRef({});

  // Ref For Advance Filter Modal
  const advanceFilterRef = useRef({});

  // To Download Report
  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  // React Hook From For Handling Filter Form Data
  const { register, getValues, control } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...listView.pagination };

    // To load Summary
    loadList();

    // if (summaryRequired) {
    loadSummary();
    // }

    // To load Count
    loadCount();

    //  To prepare Filter labels
    prepareFilterLabels();
  }, [loadCount, loadList, loadSummary, prepareFilterLabels]);

  // Init
  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    d.forEach((e) => {
      e._mainDealId = e.offerDeals?.[0]?.dealId;
      let isActive = isWithinInterval(new Date(), {
        start: parseISO(e?.offerValidity.validFrom),
        end: parseISO(e?.offerValidity.validTo),
      });

      if (isActive && e.status == "Active" && e.offerCount == 0) {
        e._isExpiredOfferLimit = true;
      } else {
        e._isExpiredOfferLimit = false;
      }
    });

    let tmp = [];
    setAdditionalData(d, additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // const loadAnalyticsData = useCallback(async (d) => {
  //   let ids = d.map((e) => {
  //     return e._id;
  //   });
  //   let r = await OmsService.getStoreOfferAnalyticsDetails({ offerIds: ids });

  //   if (Array.isArray(r.resp) && r?.resp?.length) {
  //     let k = [...d];
  //     k.forEach((x) => {
  //       let a = (r?.resp || []).filter((k) => {
  //         return k?._id?.configId == x?._id;
  //       });
  //       if (a?.length) {
  //         x._anaylitcs = { ...a[0] };
  //         x._anaylitcsLoading = false;
  //       } else {
  //         x._anaylitcs = { ...a[0] };
  //         x._anaylitcsLoading = false;
  //       }
  //     });
  //     setData([...k]);
  //     attachAdditionalData([...k]);
  //   } else {
  //     let k = [...d];
  //     k.forEach((x) => {
  //       x._anaylitcs = {};
  //       x._anaylitcsLoading = false;
  //     });
  //     setData([...k]);
  //     attachAdditionalData([...k]);
  //   }
  // }, []);

  // const attachAdditionalData = useCallback(
  //   (d) => {
  //     // To Attach Additional Data
  //     let tmp = [];
  //     setAdditionalData(d, additionalTableDataConfig, (x) => {
  //       tmp.push(x);
  //       if (tmp.length == additionalTableDataConfig.length) {
  //         setData([...attachAllData(d, tmp)]);
  //       }
  //     });
  //   },
  //   [setData]
  // );

  // Preparing Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...getValues(), ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter(false);
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, getValues]
  );

  const closeViewModal = useCallback(() => {
    viewModalDataRef.current = {};
    setShowViewModal(false);
  }, []);

  // Summary Count card
  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // For Searching via Brand
  const filterCb = useCallback(() => {
    const values = getValues();
    filterDataRef.current = {
      ...filterDataRef.current,
      _id: values._id,
      franchise: values.franchise,
      deal: values.deal,
      mainDeal: values.mainDeal,
      isExpiredOfferCount: values.isExpiredOfferCount,
      status: values.isExpiredOfferCount ? "Active" : values.status,
    };

    applyFilter(false);
  }, [applyFilter, getValues]);

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.mainDeal;
    delete v.franchise;
    delete v._id;
    delete v.isExpiredOfferCount;
    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // To Close Manage Modal
  const closeManageModal = useCallback(
    (r) => {
      setShowManageModal(false);
      if (r.status == "submit") {
        applyFilter();
      }
    },
    [applyFilter]
  );

  // To Open Create Modal
  const openCreateModal = useCallback(() => {
    manageModalDataRef.current.mode = "add";
    manageModalDataRef.current.id = "";
    setShowManageModal(true);
  }, []);

  // To Open Edit Modal
  const openEditModal = useCallback((id) => {
    manageModalDataRef.current.mode = "edit";
    manageModalDataRef.current.id = id;
    setShowManageModal(true);
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const onSearch = debounce(() => {
    filterCb();
  }, 700);

  const onSelectCb = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      filterCb();
    },
    []
  );

  const openViewPosSmartCoinsModal = useCallback((x) => {
    viewModalDataRef.current = x;
    setShowViewModal(true);
  }, []);

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to mark Id :${item._id} as Active?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { offerCode: item.offerCode };
      p.status = "Active";
      const r = await DealService.updateStoreDiscount(item._id, p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to mark Id :${item._id}  as In-Active?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { offerCode: item.offerCode };
      p.status = "Inactive";
      const r = await DealService.updateStoreDiscount(item._id, p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Inactive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  // To apply More Columns on Table
  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const viewCustomerDetails = (d) => {
    setShowCustomerDetailsModal({
      show: true,
      configId: d._id,
    });
  };

  const customerDetailsModalCb = () => {
    setShowCustomerDetailsModal({ show: false, configId: "" });
  };

  const viewOrderDetails = (d) => {
    setShowOrderDetailsModal({
      show: true,
      configId: d?._id,
    });
  };

  const OrderDetailsModalCb = () => {
    setShowOrderDetailsModal({ show: false, configId: "" });
  };

  const cloneCb = (d) => {
    setCloneModal({ show: true, configId: d?._id, data: d });
  };

  const cloneModalCb = useCallback(
    (d) => {
      setCloneModal({ show: false, configId: "", data: {} });
      if (d.status == "completed") {
        init();
      }
    },
    [init]
  );

  return (
    <>
      {/* header with add Brand Button */}
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Store offers Config"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="bogo"
        router={router}
        subTypeKey="config"
      />

      {/* <div className="py-3 bg-white">
        <Tabs
          data={subTabs}
          callback={subTabCb}
          activeTab={{ key: "config" }}
          template="2"
        />
      </div> */}

      <div>
        {/* Summary card */}
        <AppSwiper
          config={{
            slidesPerView: 5.2,
            spaceBetween: 10,
            mousewheel: { forceToAxis: true },
          }}
        >
          {summaryCard.map((e, k) => (
            <AppSlide key={k}>
              <SummaryCard
                value={e.value}
                title={e.label}
                loading={e.loading}
                valueColor={e.color}
                img={e.img}
                template={2}
              />
            </AppSlide>
          ))}
        </AppSwiper>

        {/* Filter Block  */}
        <AppCard>
          <div className="row align-items-center">
            <div className="col-10">
              <div className="row">
                <div className="col-2">
                  <TextInput
                    name="_id"
                    register={register}
                    callback={onSearch}
                    placeholder="Search by Config  ID"
                    gap={0}
                  />
                </div>
                <div className="col-2">
                  {/* <TextInput
                  name="franchise"
                  register={register}
                  callback={onSearch}
                  placeholder="Search by Franchise Name / ID"
                /> */}
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        placeholder="Search by Franchise Name/ID"
                        value={value}
                        callback={onSelectCb(onChange)}
                        uid="franchise"
                        isMultiple={false}
                        isMandatory={true}
                        emptyLabel="No Franchise found"
                        filterParams={franchiseSearchFilter}
                        gap={0}
                      />
                    )}
                  />
                </div>

                <div className="col-3">
                  {/* <TextInput
                  name="mainDeal"
                  register={register}
                  callback={onSearch}
                  placeholder="Search by Main Deal / ID"
                /> */}

                  <Controller
                    control={control}
                    name="mainDeal"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="deal"
                        placeholder="Search by Main Deal Name/ID"
                        value={value}
                        callback={onSelectCb(onChange)}
                        uid="deal"
                        isMultiple={false}
                        isMandatory={true}
                        emptyLabel="No Deals found"
                        gap={0}
                      />
                    )}
                  />
                </div>
                <div className="col-2 align-self-end">
                  <Controller
                    control={control}
                    name="isExpiredOfferCount"
                    render={({ field: { onChange, value } }) => (
                      <BooleanCheckboxInput
                        label="Offer Limit Expired?"
                        name="isExpiredOfferCount"
                        isMandatory={false}
                        callback={onSelectCb(onChange)}
                        value={value}
                        gap={0}
                      />
                    )}
                  />
                </div>
                <div className="col-auto">
                  <button
                    className="btn app-filter-btn"
                    type="button"
                    onClick={onAdvanceFilterClickCb}
                  >
                    <i className="bi bi-funnel"></i> FILTER
                  </button>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="col-auto ms-auto align-self-center">
              <Rbac roles={rbac.addButton}>
                <button
                  className="btn btn-primary btn-sm fs-val-normal me-1"
                  onClick={openCreateModal}
                >
                  Create Config
                </button>
              </Rbac>

              <TableColumnBtn
                columns={listView?.moreTableColumns}
                callback={onMoreTableColApply}
              />
            </div>
          </div>
        </AppCard>

        <AppCard>
          <div className="row">
            {/* Filter selected Label */}
            <div className="col-10">
              {filterLabels.length > 0 ? (
                <AppliedFilterLabel className="mb-3" labels={filterLabels} />
              ) : null}
            </div>
          </div>

          {/* Table */}

          {/* PAGINATION SUMMARY */}
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
          <Table
            data={data}
            sort={sortRef.current}
            openViewModal={openViewPosSmartCoinsModal}
            sortCb={sortCb}
            loading={loadingData}
            moreColumns={selectedMoreTblColumns}
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            markAsActive={markAsActive}
            markAsInActive={markAsInActive}
            loadingTotalRecords={loadingTotalRecords}
            editCb={openEditModal}
            viewCustomerDetailsCb={viewCustomerDetails}
            viewOrderDetailsCb={viewOrderDetails}
            cloneCb={cloneCb}
          />
        </AppCard>
      </div>

      {/* FORM */}
      <ManageStoreDiscountModal
        show={showManageModal}
        closeModal={closeManageModal}
        id={manageModalDataRef.current.id}
        mode={manageModalDataRef.current.mode}
      />

      <ViewStoreDiscountModal
        show={showViewModal}
        closeModal={closeViewModal}
        modalData={viewModalDataRef.current}
        setBusyLoader={setBusyLoader}
      />

      <BusyLoader show={busyLoader.show} />

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <CustomerDetailsListModal
        show={showCustomerDetailsModal.show}
        data={showCustomerDetailsModal.data}
        configId={showCustomerDetailsModal.configId}
        callback={customerDetailsModalCb}
      />

      <OrderDetailsListModal
        show={showOrderDetailsModal.show}
        data={showOrderDetailsModal.data}
        configId={showOrderDetailsModal.configId}
        callback={OrderDetailsModalCb}
      />

      <CloneOfferModal
        show={cloneModal.show}
        configId={cloneModal.configId}
        title={cloneModal.data?.title}
        type={cloneModal.data?.offerType}
        similarOfferCode={cloneModal.data?.similarOfferCode}
        callback={cloneModalCb}
      />
    </>
  );
};

export default memo(ListStoreDiscount);
