import {
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import classNames from "classnames";
import { memo, useRef, useState } from "react";
import styles from "../../Widget.module.scss";
import WidgetManageModal from "../modals/manage/WidgetManageModal";
const rbac = {
  editButton: ["WidgetEdit"],
  viewButton: ["ListWidget"],
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", isCentered: true, width: "5%" },
  { label: "Config Type", isCentered: true, width: "13%" },
  { label: "Title", isCentered: true, width: "10%" },
  { label: "Details", width: "16%" },
  {
    label: "Priority",
    width: "8%",
    isCentered: true,
    enableSort: true,
    key: "priority",
  },
  { label: "Validity", width: "17%" },
  { label: "Status", isCentered: true, width: "7%" },
  // { label: "Created On", enableSort: true, key: "createdAt" },
  { label: "Modified On", enableSort: true, key: "lastUpdated" },
  { label: "Actions", isCentered: true, width: "5%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  editCb,
  sortCb,
  markAsActive,
  markAsInActive,
  managedConfigId,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const viewModalRef = useRef({ data: {} });

  const openViewModal = (data) => {
    viewModalRef.current.data = data;
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    viewModalRef.current.data = {};
    setShowViewModal(false);
  };

  return (
    <>
      <table className="table table-bordered table-hover bg-white mb-0 table-sms">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Configs to display </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr
                key={x._id}
                className={classNames(
                  "fs-val-md",
                  managedConfigId == x._id && styles.blinkRow
                )}
              >
                {/* Serial Number */}
                <td className="text-center ">
                  {paginationConfig.startSlNo + index}
                </td>
                <td className="text-center">{x._id}</td>

                <td>
                  <div className="fw-semibold mb-2">
                    <span className="badge badge-soft-primary text-uppercase">
                      {x?.configType || "N/A"}
                    </span>
                  </div>
                  {x.configType == "Franchise" && (
                    <div className="fs-val-md">
                      {x.franchiseLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <div>
                          <div>{x.franchise?.name}</div>
                          <div className="text-muted fs-val-sm">
                            <span>FID:</span>
                            <span> {x.franchise.id} </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </td>

                <td>{x.title}</td>

                <td>
                  <KeyVal label="Layout" labelCol="col-4" contentCol="col-8">
                    <span className="text-uppercase">: {x.layout || "--"}</span>
                  </KeyVal>
                  <KeyVal label="Feature" labelCol="col-4" contentCol="col-8">
                    :{" "}
                    <span className="text-uppercase">
                      {x.feature == "parentCategory" ? "Category" : x.feature}
                    </span>
                  </KeyVal>
                  {x.feature == "banner" ? (
                    <KeyVal label="Banner" labelCol="col-4" contentCol="col-8">
                      <span className="text-uppercase">
                        : HOME PAGE {x.bannerId}
                      </span>
                    </KeyVal>
                  ) : null}

                  <KeyVal
                    label="Sort By"
                    labelCol="col-4"
                    contentCol="col-8"
                    noBottomSpace={true}
                  >
                    : {x.sortBy}
                  </KeyVal>
                </td>

                <td className="text-center">
                  <span>{x.priority}</span>
                </td>

                <td>
                  <KeyVal label="From" labelCol="col-4" contentCol="col-8">
                    {x.validity?.from ? (
                      <>
                        : <DateFormatter date={x.validity?.from} />
                      </>
                    ) : (
                      "--"
                    )}
                  </KeyVal>

                  <KeyVal label="To" labelCol="col-4" contentCol="col-8">
                    {x.validity?.till ? (
                      <>
                        : <DateFormatter date={x.validity?.till} />
                      </>
                    ) : (
                      "--"
                    )}
                  </KeyVal>

                  <KeyVal
                    label="Status"
                    labelCol="col-4"
                    contentCol="col-8"
                    noBottomSpace={true}
                  >
                    :{" "}
                    <HighlightText status={x._validityStatus} isInline={true} />
                  </KeyVal>
                </td>

                {/* Status  */}
                <td className="text-center">
                  <HighlightText
                    status={x.status == "Active" ? "Active" : "InActive"}
                    isBadge
                  />

                  <Rbac roles={rbac.editButton}>
                    {/* FOR MARK AS IN-ACTIVE BUTTON  */}
                    {x.status == "Active" ? (
                      <div>
                        <button
                          className="btn btn-link text-danger fs-val-xs p-0 mt-2"
                          onClick={() => markAsInActive(x)}
                        >
                          Mark as Inactive
                        </button>
                      </div>
                    ) : null}

                    {/* FOR MARK AS ACTIVE BUTTON  */}
                    {x.status == "InActive" ? (
                      <button
                        className="btn btn-link text-success fs-val-xs p-0 mt-2"
                        onClick={() => markAsActive(x)}
                      >
                        Mark as Active
                      </button>
                    ) : null}
                  </Rbac>
                </td>
                {/* <td>
                  <DateFormatter
                    date={x?.createdAt}
                    format="dd MMM yyyy hh:mm a"
                  />
                  <div>
                    by :
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x._createdBy?.name
                    )}
                  </div>
                </td> */}
                <td>
                  <DateFormatter
                    date={x?.lastUpdated}
                    format="dd MMM yyyy hh:mm a"
                  />
                  <div>
                    by :
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x._modifiedBy?.name || "N/A"
                    )}
                  </div>
                </td>

                <td className="text-center p-0">
                  <Rbac roles={rbac.editButton}>
                    {x.status != "Ended" && (
                      <button
                        className="btn btn-outline-warning btn-sm me-1"
                        onClick={() => editCb(x._id, index)}
                      >
                        Edit
                      </button>
                    )}
                  </Rbac>
                  {/* <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => openViewModal(x)}
                    >
                      View
                    </button>
                  </Rbac> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        className="p-2"
      />

      <WidgetManageModal
        show={showViewModal}
        modalData={viewModalRef.current.data}
        callback={closeViewModal}
        mode="view"
      />
    </>
  );
};

export default memo(Table);
