import { Offcanvas } from "react-bootstrap";

import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
    key: "slNo",
    isCentered: true,
  },
  {
    label: "Key",
    width: "10%",
    key: "key",
  },
  {
    label: "Old Value",
    width: "10%",
    key: "oldValue",
  },
  {
    label: "New Value",
    width: "10%",
    key: "newValue",
  },
  {
    label: "Remarks",
    width: "10%",
    key: "remarks",
  },
  {
    label: "Created On",
    width: "10%",
    key: "createdOn",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "loggedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const canvasStyle = {
  width: "45%",
};

const AuditLogModal = ({ show, callback, auditLog = [] }) => {
  const onHide = () => {
    callback({ status: "closed" });
  };

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: 0,
  });

  useEffect(() => {
    if (show && auditLog.length) {
      paginationRef.current = {
        ...defaultPagination,
        totalRecords: auditLog.length,
      };
      setAuditLogs([...auditLog]);
      let d = auditLog?.length
        ? auditLog.slice(0, paginationRef.current.rowsPerPage)
        : [];
      setData(d);

      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    }
  }, [attachAllData, show, auditLog, setAdditionalData]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = auditLogs.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setData(d);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    },
    [attachAllData, auditLogs, setAdditionalData]
  );

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">Audit Logs</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          <div className="my-2">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={false}
            />
          </div>
          <div className="p-3">
            <table className="table table-bordered">
              <TableHeader data={tableHeaders} />
              <tbody className="fs-val-sm">
                {data.length == 0 ? (
                  <tr>
                    <td
                      colSpan={tableHeaders.length}
                      className="text-center py-2"
                    >
                      No Audit logs found
                    </td>
                  </tr>
                ) : null}
                {data.map((x, k) => (
                  <tr key={x._id}>
                    <td className="text-center">
                      {paginationRef.current.startSlNo + k}
                    </td>
                    <td>{x.paramName}</td>
                    <td>{x.oldData}</td>
                    <td>{x.newData}</td>
                    <td>{x.message}</td>
                    <td>
                      <DateFormatter date={x.loggedAt} />
                      <div>by {x._user?.name || x.loggedBy}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBlock
              paginationConfig={paginationRef.current}
              paginationCb={paginationCb}
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AuditLogModal;
