import { BannerListComponent } from "@sk/features";
import { PageInfo } from "@sk/uis";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { filterView, listView } from "../constantService";

function BannerList() {
  const { register, getValues } = useForm({
    defaultValues: filterView.filterFormData,
  });

  const router = useNavigate();

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Tool - Banner List"
            breadcrumbs={listView.breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      <BannerListComponent />
    </>
  );
}

export default BannerList;
