const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Banners",
      link: "/configs/market/dashboard/location-master",
    },
    {
      name: "Banner Location Master",
    },
  ],

  additionDataConfig: [
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdByLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  filterData: {},

  pagination: {
    totalRecords: 0,
    rowsPerPage: 25,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 25,
  },

  statusOptions: [
    { label: "Choose", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
};

export default listView;
