import {
  AppCard,
  BusyLoader,
  DateInput,
  EntitySearchInput,
  SelectInput,
  Spinner,
  SwitchInput,
  TabContent,
  Tabs,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { OmsService } from "@sk/services";
import { add } from "date-fns";
import produce from "immer";
import { sortOptions, widgetFeatures } from "../../../constantService";
import CustomData from "./components/CustomData";
import FilterData from "./components/FilterData";
import ImgUpload from "./components/ImgUpload";
import Layouts from "./components/Layouts";
import layoutData from "./layoutData";
import LangModal from "./modals/LangModal";
import StoreExclude from "./components/StoreExclude";

const offcanvasStyle = {
  width: "50%",
};

const validationSchema = yup.object().shape({
  title: yup.string().required().trim().label("Title"),
  configType: yup.string().required().label("Config Type"),
  feature: yup.string().required().label("Feature"),
  sortBy: yup.string().required().label("Sort By"),
  maxRecords: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .required(),
  franchise: yup.array().when("configType", {
    is: "Franchise",
    then: (schema) => schema.min(1, "Franchise is Required"),
  }),
  validFrom: yup
    .array()
    .of(yup.date().required())
    .min(1, "Valid From is required")
    .label("Valid From"),
  validTo: yup
    .array()
    .of(yup.date().required())
    .min(1, "Valid To is required")
    .label("Valid To"),
  rangeType: yup.string().when("sortBy", (sortBy, schema) => {
    return ["Discount Range", "Fixed Price Range"].indexOf(sortBy[0]) != -1
      ? schema.required().label("Range Type")
      : schema.notRequired();
  }),
  rangeFrom: yup
    .number()
    .integer()
    .positive()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["rangeType", "sortBy"], ([rangeType, sortBy], schema) => {
      return rangeType
        ? schema
            .required()
            .max(sortBy == "Discount Range" ? 100 : 1000000)
            .label(rangeType == "between" ? "Range From" : "Range Value")
        : schema.notRequired();
    }),
  rangeTo: yup
    .number()
    .integer()
    .positive()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(
      ["rangeType", "sortBy", "rangeFrom"],
      ([rangeType, sortBy, rangeFrom], schema) => {
        return rangeType == "between"
          ? schema
              .required()
              .min(rangeFrom || 0)
              .max(sortBy == "Discount Range" ? 100 : 1000000)
              .label("Range To")
          : schema.notRequired();
      }
    ),
  showDataName: yup.boolean(),
  bannerImage: "",
  showBorderImg: yup.boolean(),
});

const bgClrInfo = {
  content: "For multiple colors put comma separated. Ex: #F5F5F5, #E5E5E5",
};

const textClrInfo = {
  content: "Only one color is supported",
};

const defaultFormData = {
  title: "",
  description: "",
  configType: "Global",
  feature: "menu",
  sortBy: "Popular",
  maxRecords: 10,
  franchise: [],
  activeTab: {},
  customData: [],
  useOnlyCustomData: false,
  menuFilter: [],
  categoryFilter: [],
  brandFilter: [],
  status: "Active",
  layout: "",
  backgroundImage: "",
  icon: "",
  bannerId: "",
  period: 7,
  lang: {},
  validFrom: [],
  validTo: [],
  dealFilter: [],
  showDirectChild: false,
  rangeType: "",
  rangeFrom: "",
  rangeTo: "",
  bgClr: "",
  textClr: "",
  showDataName: false,
  gridCount: 3,
  bannerImage: "",
  showBorderImg: false,
  excludeStore: [],
};

const configTypeOptions = [
  {
    value: "Global",
    label: "Global",
  },
  {
    value: "Franchise",
    label: "Store",
  },
];

const statusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "InActive",
    label: "In Active",
  },
];

const featureOptions = widgetFeatures;

const sortOptionsData = sortOptions;

// const offersOptions = [
//   {
//     value: "buy1get1",
//     name: "Buy One Get One",
//   },
//   {
//     value: "buyget2",
//     name: "Buy Two Get Two",
//   },
//   {
//     value: "buy2get2",
//     name: "Buy Two Get Two",
//   },
//   {
//     value: "buy2get2",
//     name: "Buy Two Get Two",
//   },
// ];

const tabsData = [
  {
    tabName: "Custom Data",
    key: "custom",
  },
  {
    tabName: "Filter Data",
    key: "filter",
  },
  {
    tabName: "Background Image",
    key: "backgroundImage",
  },
  {
    tabName: "Icon",
    key: "icon",
  },
  {
    tabName: "Banner Image",
    key: "bannerImage",
  },
  {
    tabName: "Exclude Store",
    key: "excludeStore",
  },
  // {
  //   tabName: "Exlcude Data",
  //   key: "exclude",
  // },
];

const bannerOptions = Array.from({ length: 12 }, (_, e) => ({
  label: "Home Page - Level " + e,
  value: "LEVEL_" + e,
})).filter((_, k) => k > 1);
bannerOptions.unshift({ label: "Choose", value: "" });

const periodOptions = [
  {
    label: "7",
    value: 7,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "45",
    value: 45,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "90",
    value: 90,
  },
];

const rangeTypeOptions = [
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Less Than",
    value: "less",
  },
  {
    label: "Greater Than",
    value: "greater",
  },
  {
    label: "Exact",
    value: "exact",
  },
];

const dataNameInfo = {
  content: "This is used to show data name in each loop of the widget.",
};

const gridCountOptions = [
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

const prepareTabData = (feature, layout, configType) => {
  return tabsData.filter((x) => {
    if (x.key == "backgroundImage") {
      const l = layoutData.find((e) => e.value == layout);
      return l?.showBgImage;
    }

    if (x.key == "icon") {
      const l = layoutData.find((e) => e.value == layout);
      return l?.showIcon;
    }

    if (x.key == "bannerImage") {
      const l = layoutData.find((e) => e.value == layout);
      return l?.showBannerImg;
    }

    if (x.key == "filter") {
      return ["category", "brand", "deal"].indexOf(feature) != -1;
    }

    if (x.key == "excludeStore") {
      return configType === "Global";
    }

    if (x.key == "custom") {
      return feature == "comboDeals" ? false : true;
    }

    return true;
  });
};

const storeFilterParams = {
  filter: {
    groupType: {
      $in: ["COCO", "FOFO"],
    },
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

function WidgetManageModal({ show, editId, callback, mode = "create" }) {
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  const [sortOptions, setSortOptions] = useState(() => {
    return sortOptionsData.filter(
      (x) => x.allowed.indexOf(defaultFormData.feature) != -1
    );
  });

  const [showTab, setShowTab] = useState(true);

  const [loading, setLoading] = useState(false);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [tabs, setTabs] = useState(prepareTabData(defaultFormData.feature, ""));

  const [langModal, setLangModal] = useState({
    show: false,
    title: "",
    data: {},
    type: "",
  });

  const [
    configType,
    feature,
    activeTab,
    customData,
    layout,
    backgroundImage,
    icon,
    useOnlyCustomData,
    sortBy,
    rangeType,
    bannerImage,
    excludeStore,
  ] = useWatch({
    name: [
      "configType",
      "feature",
      "activeTab",
      "customData",
      "layout",
      "backgroundImage",
      "icon",
      "useOnlyCustomData",
      "sortBy",
      "rangeType",
      "bannerImage",
      "excludeStore",
    ],
    control,
  });

  const inpCond = useMemo(() => {
    const t = layoutData.find((e) => e.value == layout);
    return {
      canChangeBg: t?.canChangeBg,
      canChangeTitleColor: t?.canChangeTitleColor,
      showDataName: t?.showDataName,
      showGridCount: t?.showGridCount,
      showBorderImg: t?.showBorderImg,
      showCellBg: t?.showCellBg,
    };
  }, [layout]);

  const [validFromDateConfig, setValidFromDateConfig] = useState({});

  const [validToDateConfig, setValidToDateConfig] = useState({});

  useEffect(() => {
    if (show) {
      const t = prepareTabData(
        defaultFormData.feature,
        "",
        defaultFormData.configType
      );
      reset({ ...defaultFormData, activeTab: t[0] });
      setValidFromDateConfig({
        minDate: new Date(),
        enableTime: true,
        dateFormat: "d M Y, h:i K",
      });
      setValidToDateConfig({
        minDate: new Date(),
        enableTime: true,
        dateFormat: "d M Y, h:i K",
      });
      if (editId) {
        loadDetails(editId);
      }
    }
  }, [editId, loadDetails, reset, show]);

  useEffect(() => {
    setValue("activeTab", tabsData[0]);
  }, [setValue]);

  const loadDetails = useCallback(
    async (id) => {
      setLoading(true);
      const r = await OmsService.getAppLayoutConfigById(id);
      setLoading(false);

      const d = r.resp || {};

      if (d._id) {
        if (d.validity?.from && d.validity?.till) {
          const fromDt = new Date(d.validity.from);
          const toDt = new Date(d.validity.till);

          if (fromDt < new Date()) {
            setValidFromDateConfig(
              produce((draft) => {
                draft.minDate = fromDt;
              })
            );
          }

          setValidToDateConfig(
            produce((draft) => {
              draft.minDate = toDt;
            })
          );
          setValue("validFrom", [fromDt]);
          setValue("validTo", [toDt]);
        }

        let feature = d.feature;
        if (d.feature == "parentCategory") {
          feature = "category";
          setValue("showDirectChild", true);
        } else {
          setValue("showDirectChild", false);
        }

        setValue("configType", d.configType);
        setValue("status", d.status);
        setValue("title", d.title);
        setValue("description", d.description);
        setValue("feature", feature);
        setValue("sortBy", d.sortBy);
        setValue("priority", d.priority);
        setValue("maxRecords", d.maxRecords);
        setValue("layout", d.layout);
        setValue("backgroundImage", d.backgroundImage);
        setValue("icon", d.icon);
        setValue("useOnlyCustomData", d.useOnlyCustomData);
        setValue("period", d.period);
        setValue("lang", d.lang || {});
        setValue("bannerImage", d.bannerImage || "");
        setValue("showBorderImg", d.showBorderImg);
        setValue(
          "franchise",
          d.franchise?.id
            ? [
                {
                  label: d.franchise.name,
                  value: { _id: d.franchise.id, name: d.franchise.name },
                },
              ]
            : []
        );

        if (d.showDataName !== undefined) {
          setValue("showDataName", d.showDataName);
        }

        if (d.gridCount !== undefined) {
          setValue("gridCount", d.gridCount);
        }

        setValue(
          "customData",
          (d.customData || []).map((x) => ({
            name: x.name,
            _id: x.id,
            image: x.image,
          }))
        );

        setValue(
          "menuFilter",
          (d.menuFilter || []).map((x) => ({
            label: x.name,
            value: { _id: x.id, name: x.name },
          }))
        );

        setValue(
          "categoryFilter",
          (d.categoryFilter || []).map((x) => ({
            label: x.name,
            value: { _id: x.id, name: x.name },
          }))
        );

        setValue(
          "brandFilter",
          (d.brandFilter || []).map((x) => ({
            label: x.name,
            value: { _id: x.id, name: x.name },
          }))
        );

        setValue(
          "dealFilter",
          (d.dealFilter || []).map((x) => ({
            label: x.name,
            value: { _id: x.id, name: x.name },
          }))
        );

        if (d?.style?.wdgBg) {
          setValue("bgClr", d?.style?.wdgBg);
        }

        if (d?.style?.titleColor) {
          setValue("textClr", d?.style?.titleColor);
        }

        if (d?.style?.cellBg) {
          setValue("cellBg", d?.style?.cellBg);
        }

        setValue("bannerId", feature == "banner" ? d.bannerId : "");

        if (["Discount Range", "Fixed Price Range"].indexOf(d.sortBy) != -1) {
          let rStr = d.values[0].split(":");
          if (rStr[0]) {
            setValue("rangeType", rStr[0]);
          }
          if (rStr[1]) {
            setValue("rangeFrom", rStr[1]);
          }
          if (rStr[2]) {
            setValue("rangeTo", rStr[2]);
          }
        }

        setValue("excludeStore", d.excludeFranchises || []);

        setSortOptions(
          sortOptionsData.filter((x) => x.allowed.indexOf(feature) != -1)
        );

        handleShowTab(feature);

        const t = prepareTabData(feature, d.layout, d.configType);
        setValue("activeTab", t[0]);
        setTabs(t);
      }
    },
    [setValue]
  );

  const configTypeCb = () => {
    setValue("franchise", []);

    const currentFeature = getValues("feature");
    const currentLayout = getValues("layout");
    const newConfigType = getValues("configType");

    const tabs = prepareTabData(currentFeature, currentLayout, newConfigType);

    setTabs(tabs);
    setValue("activeTab", tabs[0]);
  };

  const handleShowTab = (f) => {
    setShowTab(
      ["banner", "offers", "coinStore"].indexOf(f) == -1 ? true : false
    );
  };

  const featureCb = () => {
    const v = getValues("feature");
    const currentConfigType = getValues("configType");

    handleShowTab(v);

    setValue("layout", "");

    setValue("showDirectChild", false);

    const tabs = prepareTabData(v, "", currentConfigType);

    setTabs(tabs);

    setValue("activeTab", tabs[0]);

    setValue("customData", []);

    const s = sortOptionsData
      .filter((x) => x.allowed.indexOf(v) != -1)
      .map((x) => ({ label: x.label, value: x.value }));
    setSortOptions(s);
  };

  const tabCb = (e) => {
    setValue("activeTab", e.value);
  };

  const customDataCb = (e) => {
    const c = getValues("customData");
    if (e.action == "remove") {
      c.splice(e.data.index, 1);
    } else if (e.action == "removeImg") {
      c[e.data.index].image = "";
    } else if (e.action == "upload") {
      c[e.data.index].image = e.data.asset;
    } else {
      c.push(e.data);
    }
    setValue("customData", [...c]);
  };

  const onSubmit = async (data) => {
    const f = data;

    if (
      !f.layout &&
      ["offers", "banner", "coinStore"].indexOf(f.feature) == -1
    ) {
      Toaster.error("Please choose Layout");
      return;
    }

    if (f.feature == "banner" && !f.bannerId?.length) {
      Toaster.error("Please choose Banner");
      return;
    }

    const layoutDetails = layoutData.find((e) => e.value == layout);

    let payload = {
      configType: f.configType,
      status: f.status,
      title: f.title,
      description: f.description,
      feature: f.feature,
      sortBy: f.sortBy,
      priority: f.priority,
      maxRecords: f.maxRecords,
      layout: f.layout,
      period: f.period,
      lang: f.lang || {},
      customData: (f.customData || []).map((x) => ({
        name: x.name,
        id: x._id,
        image: x.image,
      })),
      menuFilter: (f.menuFilter || []).map((x) => ({
        id: x.value._id,
        name: x.value.name,
      })),
      brandFilter: (f.brandFilter || []).map((x) => ({
        id: x.value._id,
        name: x.value.name,
      })),
      dealFilter: (f.dealFilter || []).map((x) => ({
        id: x.value._id,
        name: x.value.name,
      })),
      categoryFilter: (f.categoryFilter || []).map((x) => ({
        id: x.value._id,
        name: x.value.name,
      })),
      backgroundImage: f.backgroundImage,
      icon: f.icon,
      useOnlyCustomData: f.useOnlyCustomData,
      bannerId: f.bannerId || "",
      validity: {
        from: f.validFrom[0],
        till: f.validTo[0],
      },
      bannerImage: f.bannerImage || "",
      showDataName: f.showDataName,
      showBorderImg: f.showBorderImg,
      displayOn: layoutDetails?.displayOn || "Both",
    };

    if (f.showDirectChild && payload.feature == "category") {
      payload.feature = "parentCategory";
    }

    if (tabs.findIndex((x) => x.key == "filter") == -1) {
      payload.categoryFilter = [];
      payload.menuFilter = [];
      payload.brandFilter = [];
      payload.dealFilter = [];
    }

    if (f.franchise?.length > 0) {
      payload.franchise = {
        id: f.franchise[0].value._id,
        name: f.franchise[0].value.name,
      };
    }

    if (["Discount Range", "Fixed Price Range"].indexOf(f.sortBy) != -1) {
      let rStr = f.rangeType + ":" + f.rangeFrom;
      if (f.rangeType == "between") {
        rStr += ":" + f.rangeTo;
      }
      payload.values = [rStr];
    }

    // for styling
    payload.style = {
      wdgBg: "",
      titleColor: "",
      cellBg: "",
    };

    if (inpCond.canChangeBg) {
      payload.style.wdgBg = (f.bgClr || "").trim();
    }

    if (inpCond.canChangeTitleColor) {
      payload.style.titleColor = (f.textClr || "").trim();
    }

    if (inpCond.showCellBg) {
      payload.style.cellBg = (f.cellBg || "").trim();
    }

    if (inpCond.showGridCount) {
      payload.showGridCount = f.showGridCount;
    }

    if (showTab && tabs.findIndex((x) => x.key == "excludeStore") != -1) {
      payload.excludeFranchises = (f.excludeStore || []).map((x) => ({
        id: x.id,
        name: x.name,
        postcode: x.postcode,
        state: x.state,
        district: x.district,
        town: x.town,
      }));
    } else {
      payload.excludeFranchises = [];
    }

    let r;

    setBusyloader({ show: true });

    if (editId) {
      r = await OmsService.updateAppLayoutConfigById(editId, payload);
    } else {
      r = await OmsService.createAppLayoutConfig(payload);
    }

    setBusyloader({ show: false });

    if (r.statusCode == 200) {
      Toaster.success("Submitted succesfully");
      callback({ action: "submitted" });
    } else {
      Toaster.error(r.resp?.message || "Failed to submit");
    }
  };

  const layoutCb = (e) => {
    const v = e.data.value;

    setValue("showBorderImg", false);
    setValue("showDataName", false);
    setValue("gridCount", 3);

    setValue("layout", v);

    const tabs = prepareTabData(feature, v);

    handleShowTab(feature);

    setTabs(tabs);

    setValue("activeTab", tabs[0]);
  };

  const bgImageCb = (e) => {
    let v = e.data?.id || "";
    if (e.action == "remove") {
      v = "";
    }
    setValue("backgroundImage", v);
  };

  const iconCb = (e) => {
    let v = e.data?.id || "";
    if (e.action == "remove") {
      v = "";
    }
    setValue("icon", v);
  };

  const onClose = () => {
    callback({ action: "close" });
  };

  const addTitleLang = () => {
    const l = getValues("lang") || {};
    setLangModal({
      show: true,
      title: "Add Title in Other Language",
      data: l?.title || {},
      type: "title",
    });
  };

  const addDescLang = () => {
    const l = getValues("lang") || {};
    setLangModal({
      show: true,
      title: "Add Description in Other Language",
      data: l?.description || {},
      type: "description",
    });
  };

  const langModalCb = (e) => {
    if (e?.action == "submit") {
      const l = getValues("lang") || {};
      l[e.data.type] = { ...e.data.form };
      setValue("lang", l);
    }
    setLangModal({ show: false, title: "", data: {} });
  };

  const onValidFromDtChange = (chngFn) => (val) => {
    chngFn(val);
    if (val?.length) {
      const t = add(val[0], {
        minutes: 5,
      });
      setValue("validTo", [t]);
      setValidToDateConfig(
        produce((draft) => {
          draft.minDate = t;
        })
      );
    }
  };

  const bannerImageCb = (e) => {
    let v = e.data?.id || "";
    if (e.action == "remove") {
      v = "";
    }
    setValue("bannerImage", v);
  };

  const excludeStoreCb = (e) => {
    if (e.action == "remove") {
      const c = getValues("excludeStore") || [];
      const index = c.findIndex((x) => x.id == e.data._id);
      c.splice(index, 1);
      setValue("excludeStore", c);
    }
    if (e.action == "add") {
      const c = getValues("excludeStore") || [];
      if (c.findIndex((x) => x.id == e.data._id) == -1) {
        if (e.data._id) {
          c.unshift({
            id: e.data._id,
            name: e.data.name,
            postcode: e.data.pincode,
            state: e.data.state,
            district: e.data.district,
            town: e.data.town,
          });
        }
        setValue("excludeStore", c);
      } else {
        Toaster.error("Store already excluded");
      }
    }
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      style={offcanvasStyle}
      enforceFocus={false}
    >
      <Offcanvas.Header closeButton className="bg-white" onHide={onClose}>
        <Offcanvas.Title>
          <h4 className="m-0">Manage Widget Config</h4>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : null}

        {!loading ? (
          <>
            <AppCard>
              <div className="row">
                <div className="col-3">
                  <SelectInput
                    label="Config Type"
                    register={register}
                    name="configType"
                    options={configTypeOptions}
                    isMandatory={true}
                    callback={configTypeCb}
                    error={errors.configType?.message}
                    disabled={mode == "view"}
                  />
                </div>
                {configType == "Franchise" ? (
                  <div className="col-6">
                    <Controller
                      name="franchise"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <EntitySearchInput
                          label="Smart Store"
                          callback={onChange}
                          isMandatory={true}
                          type="franchise"
                          value={value}
                          uid="storeSrch"
                          error={errors.franchise?.message}
                          filterParams={storeFilterParams}
                          disabled={mode == "view"}
                        />
                      )}
                    />
                  </div>
                ) : null}

                <div className="col-3">
                  <SelectInput
                    label="Status"
                    register={register}
                    name="status"
                    options={statusOptions}
                    isMandatory={true}
                    error={errors.status?.message}
                    disabled={mode == "view"}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <TextInput
                    register={register}
                    name="title"
                    label="Title"
                    isMandatory={true}
                    error={errors.title?.message}
                    disabled={mode == "view"}
                  />
                  <div className="mb-3">
                    <button
                      className="fs-val-sm btn btn-link p-0"
                      onClick={addTitleLang}
                    >
                      Add Title in other Language
                    </button>
                  </div>
                </div>
                <div className="col-6">
                  <TextInput
                    register={register}
                    name="description"
                    label="Description"
                    error={errors.description?.message}
                    disabled={mode == "view"}
                  />
                  <div className="mb-3">
                    <button
                      className="fs-val-sm btn btn-link p-0"
                      onClick={addDescLang}
                    >
                      Add Description in other Language
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <SelectInput
                    label="Feature"
                    register={register}
                    name="feature"
                    options={featureOptions}
                    isMandatory={true}
                    callback={featureCb}
                    error={errors.feature?.message}
                    disabled={mode == "view"}
                  />
                </div>

                {feature == "banner" ? (
                  <div className="col-3">
                    {/* <BannerSearch control={control} /> */}
                    <SelectInput
                      label="Banner Slot"
                      register={register}
                      name="bannerId"
                      options={bannerOptions}
                      isMandatory={true}
                      error={errors.bannerId?.message}
                      disabled={mode == "view"}
                    />
                  </div>
                ) : null}

                {["banner", "offers", "coinStore", "comboDeals"].indexOf(
                  feature
                ) == -1 ? (
                  <div className="col-3">
                    <SelectInput
                      label="Sort By"
                      register={register}
                      name="sortBy"
                      options={sortOptions}
                      isMandatory={true}
                      error={errors.sortBy?.message}
                      disabled={mode == "view"}
                    />
                  </div>
                ) : null}

                {[
                  "High Discount",
                  "Discount Range",
                  "Fixed Price Range",
                ].indexOf(sortBy) == -1 &&
                ["banner", "offers", "coinStore", "comboDeals"].indexOf(
                  feature
                ) == -1 ? (
                  <div className="col-2">
                    <SelectInput
                      label="Within Days"
                      isMandatory={true}
                      name="period"
                      options={periodOptions}
                      register={register}
                    />
                  </div>
                ) : null}

                <div className="col-2">
                  <TextInput
                    type="number"
                    label="Priority"
                    register={register}
                    name="priority"
                    isMandatory={true}
                    error={errors.priority?.message}
                    disabled={mode == "view"}
                  />
                </div>

                <div className="col-2">
                  {feature != "banner" ? (
                    <TextInput
                      type="number"
                      label="Max Records"
                      register={register}
                      name="maxRecords"
                      isMandatory={true}
                      error={errors.maxRecords?.message}
                      disabled={mode == "view"}
                    />
                  ) : null}
                </div>
              </div>

              {["Discount Range", "Fixed Price Range"].indexOf(sortBy) != -1 ? (
                <div className="row">
                  <div className="col-3">
                    <SelectInput
                      label="Range Type"
                      register={register}
                      name="rangeType"
                      options={rangeTypeOptions}
                      isMandatory={true}
                      error={errors.rangeType?.message}
                    />
                  </div>
                  <div className="col-3">
                    <TextInput
                      type="number"
                      label={
                        "Range " +
                        (rangeType == "between" ? "From" : "Value") +
                        " " +
                        (sortBy == "Discount Range" ? "(in %)" : "(in Rs.)")
                      }
                      register={register}
                      name="rangeFrom"
                      isMandatory={true}
                      error={errors.rangeFrom?.message}
                    />
                  </div>
                  {rangeType == "between" ? (
                    <div className="col-3">
                      <TextInput
                        type="number"
                        label={
                          "Range To " +
                          (sortBy == "Discount Range" ? "(in %)" : "(in Rs.)")
                        }
                        register={register}
                        name="rangeTo"
                        isMandatory={true}
                        error={errors.rangeTo?.message}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="row">
                <div className="col-6">
                  {/* <BannerSearch control={control} /> */}
                  <Controller
                    control={control}
                    name="validFrom"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Valid From Date"
                        callback={onValidFromDtChange(onChange)}
                        value={value}
                        isMandatory={true}
                        config={validFromDateConfig}
                        error={errors.validFrom?.message}
                      />
                    )}
                  />
                </div>

                <div className="col-6">
                  {/* <BannerSearch control={control} /> */}
                  <Controller
                    control={control}
                    name="validTo"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Valid To Date"
                        callback={onChange}
                        value={value}
                        isMandatory={true}
                        config={validToDateConfig}
                        error={errors.validTo?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </AppCard>

            {["banner", "offers", "coinStore"].indexOf(feature) == -1 ? (
              <AppCard title="Layouts">
                <div className="mb-4">
                  <Layouts
                    feature={feature}
                    callback={layoutCb}
                    selected={layout}
                    mode={mode}
                  />
                </div>

                {/* style */}
                {inpCond?.canChangeBg || inpCond?.canChangeTitleColor ? (
                  <div className="row">
                    {inpCond.canChangeBg ? (
                      <div className="col">
                        <TextInput
                          register={register}
                          name="bgClr"
                          label="Background Color"
                          info={bgClrInfo}
                        />
                      </div>
                    ) : null}
                    {inpCond.canChangeTitleColor ? (
                      <div className="col">
                        <TextInput
                          register={register}
                          name="textClr"
                          label="Text Color"
                          info={textClrInfo}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="row">
                  {inpCond.showGridCount ? (
                    <div className="col">
                      <SelectInput
                        label="Grid Count"
                        register={register}
                        name="gridCount"
                        options={gridCountOptions}
                        isMandatory={true}
                        error={errors.gridCount?.message}
                        disabled={mode === "view"}
                      />
                    </div>
                  ) : null}
                  {inpCond.showDataName ? (
                    <div className="col">
                      <Controller
                        name="showDataName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SwitchInput
                            label="Show Data Name"
                            value={value}
                            callback={onChange}
                            info={dataNameInfo}
                          />
                        )}
                      />
                    </div>
                  ) : null}

                  {/* border image */}
                  {inpCond.showBorderImg ? (
                    <div className="col">
                      <Controller
                        name="showBorderImg"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SwitchInput
                            label="Show Border Image"
                            value={value}
                            callback={onChange}
                          />
                        )}
                      />
                    </div>
                  ) : null}

                  {/* cell color */}
                  {inpCond.showCellBg ? (
                    <div className="col">
                      <TextInput
                        label="Cell Background Color"
                        name="cellBg"
                        register={register}
                      />
                    </div>
                  ) : null}
                </div>
              </AppCard>
            ) : null}

            {/* tabs start */}
            {showTab ? (
              <AppCard noPad={true}>
                {console.log(tabs, showTab)}
                <Tabs data={tabs} activeTab={activeTab} callback={tabCb} />
                <TabContent>
                  {activeTab?.key == "custom" ? (
                    <>
                      <div className="ps-3 mt-4 mb-2 fs-val-sm">
                        <input
                          type="checkbox"
                          {...register("useOnlyCustomData")}
                          disabled={mode == "view"}
                        />
                        <span> Use only Custom Data </span>
                        <span className="text-muted">
                          (Only below added data will be displayed)
                        </span>
                      </div>
                      {useOnlyCustomData ? (
                        <div className="fs-val-sm text-danger ps-3">
                          Please note any config under <b>Filters</b> tab will
                          not be considered
                        </div>
                      ) : null}
                      <div className="p-3">
                        <CustomData
                          type={feature}
                          callback={customDataCb}
                          data={customData}
                          mode={mode}
                        />
                      </div>
                    </>
                  ) : null}

                  {activeTab?.key == "filter" ? (
                    <FilterData
                      control={control}
                      feature={feature}
                      mode={mode}
                      setValue={setValue}
                      showDirectChild={feature == "category"}
                    />
                  ) : null}

                  {activeTab?.key == "backgroundImage" ? (
                    <ImgUpload
                      callback={bgImageCb}
                      img={backgroundImage}
                      type="bg"
                      text="Upload Background Image"
                      mode={mode}
                    />
                  ) : null}

                  {activeTab?.key == "icon" ? (
                    <ImgUpload
                      callback={iconCb}
                      img={icon}
                      type="icon"
                      text="Upload Icon"
                      mode={mode}
                    />
                  ) : null}

                  {activeTab?.key == "bannerImage" ? (
                    <ImgUpload
                      callback={bannerImageCb}
                      img={bannerImage}
                      type="banner"
                      text="Upload Banner Image"
                      mode={mode}
                    />
                  ) : null}

                  {activeTab?.key == "excludeStore" ? (
                    <StoreExclude
                      callback={excludeStoreCb}
                      value={excludeStore}
                    />
                  ) : null}
                </TabContent>
              </AppCard>
            ) : null}
            {/* tabs end */}

            <div className="py-5"></div>

            <div className="position-absolute bottom-0 w-full end-0 start-0 z-100">
              <div className="text-end p-2 bg-light">
                <button
                  className="btn btn-success"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        ) : null}

        <LangModal
          show={langModal.show}
          title={langModal.title}
          callback={langModalCb}
          type={langModal.type}
          data={langModal.data}
        />

        <BusyLoader show={busyloader.show} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default WidgetManageModal;
