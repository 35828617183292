import { Amount, TableHeader } from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "40%" };

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Customer Details", isCentered: false },
  { label: "Order Details", isCentered: true },
];

const CustomerDetailsListModal = ({ show, data, callback }) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Customer Details List </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <table className="table table-bordered bg-white">
              <TableHeader data={headers} />
              <tbody className="fs-val-md">
                {[1, 2, 3, 4, 5, 6].map((x, index) => (
                  <tr key={index}>
                    {/* SL No */}
                    <td className="text-center">{1 + index}</td>

                    {/* Customer Details */}
                    <td className="text-cente">
                      <div className="mb-2 fw-semibold">Suryadev Mahender</div>
                      <div className="mb-1">
                        <small className="my-2 fs-val-sm text-muted">
                          Mobile No. : xxxxxx2345
                        </small>
                      </div>
                      <div className="mb-2 fs-val-sm">
                        Banglore, Karnatake - 560064
                      </div>
                    </td>

                    {/* Order Amount */}
                    <td className="text-center">
                      <div className="text-primary">
                        <Amount value="500" />
                      </div>
                      <div className="mb-2 my-2 fs-val-sm text-muted">
                        Purchased On : 5 May 2024
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(CustomerDetailsListModal);
