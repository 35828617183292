import {
  DateFormatter,
  DisplayFranchiseName,
  HighlightText,
  ImgRender,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { memo, useState } from "react";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { SliderRow } from "./SliderRow";
import { ImgPreviewModal } from "@sk/uis";

const headers = [
  { label: "Order", width: "auto", isCentered: true },
  { label: "Title", isCentered: false },
  { label: "Redirection To", isCentered: true },
  { label: "Franchise Type", isCentered: true },
  { label: "Banner Validity", isCentered: true },
  // { label: "Active Period", isCentered: true },
  { label: "Resolution", isCentered: true },
  // { label: "Access Level", isCentered: true },
  { label: "Preview", isCentered: true },
  // { label: "Input Criteria", isCentered: true },
  { label: "Status", isCentered: true },
  { label: "Actions", isCentered: true },
];

const BannerSliderTable = ({
  banners,
  editCb,
  deleteCb,
  cloneCb,
  viewCb,
  moveCardCb,
  resolutionHeight,
  resolutionWidth,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  const viewImageCb = (x) => {
    if (x?.image?.length > 0) {
      let d = [{ id: x.image }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />

        <tbody>
          {!banners?.length ? (
            <tr>
              <td colSpan={headers?.length}>
                <NoDataFound>Slider Data Not Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* List  */}
          {/* {banners?.length > 0 && (
          <DndProvider backend={HTML5Backend}>
            <tbody>
              {banners.map((card, index) => (
                <SliderRow
                  id={index}
                  key={index}
                  index={index}
                  data={card}
                  moveCard={moveCardCb}
                  editCb={editCb}
                  cloneCb={cloneCb}
                  deleteCb={deleteCb}
                  viewCb={viewCb}
                  viewImageCb={viewImageCb}
                  resolutionHeight={resolutionHeight}
                  resolutionWidth={resolutionWidth}
                />
              ))}
            </tbody>
          </DndProvider>
        )} */}

          {banners?.length > 0 &&
            banners.map((data, index) => (
              <tr key={index}>
                {/* SL No */}
                <td className="text-center">
                  <div>{index + paginationConfig?.startSlNo}</div>
                </td>

                {/*Banner  Name */}
                <td>
                  <div>{data?.title || "N/A"}</div>
                  <div className="mt-2 ">Id: {data?.id}</div>
                </td>

                {/* Banner Type*/}
                <td className="text-center">
                  <div>{data?._bannerType || "N/A"}</div>
                </td>

                {/* Franchise Type */}
                <td className="text-center">
                  <div className="mb-2">
                    {data?.filterByFranchiseType == "ByGroup"
                      ? "By Group"
                      : "By Franchise"}
                  </div>

                  {data.filterByFranchiseType == "ByGroup" ? (
                    <div className="mb-2">
                      Group Type : {data?.filterByFranchiseGroupType}
                    </div>
                  ) : (
                    <div>
                      <DisplayFranchiseName
                        fId={data?.filterByFranchiseId || ""}
                      />
                    </div>
                  )}
                </td>

                {/* Validity Period */}
                <td className="text-center">
                  <div className="mb-1">
                    <DateFormatter
                      date={data?.period?.date?.startDate}
                      format="dd MMM yy hh:mm a"
                    />
                  </div>
                  <div className="mb-1"> To</div>
                  <div className="mb-2">
                    <DateFormatter
                      date={data?.period?.date?.endDate}
                      format="dd MMM yy hh:mm a"
                    />
                  </div>
                  <div className="mb-2 mt-3">
                    <HighlightText
                      status={data?._activeStatus?.label}
                      isBadge={true}
                    />
                  </div>
                </td>

                {/*Active Period  */}
                {/* <td className="text-center">
              {!data?.allDayRange ? (
                <>
                  {data?.period?.timeRanges.length > 0 &&
                    data?.period?.timeRanges.map((e, i) => (
                      <div key={i}>
                        {e?.startTime?.length > 0 ? (
                          <>
                            <DateFormatter date={e?.startTime} format="hh:mm a" /> -
                            <DateFormatter date={e?.endTime} format="hh:mm a" />
                          </>
                        ) : null}
                      </div>
                    ))}
                </>
              ) : (
                <div>All Day </div>
              )}
            </td> */}

                <td className="text-center">
                  <div>
                    {data.resolution?.width}x{data.resolution?.height} (WxH)
                  </div>
                </td>

                {/*Access Level  */}
                {/* <td className="text-center">
              <button
                className="btn btn-sm btn-outline-primary me-1 fs-val-xs mb-3 mx-1"
                type="button"
                onClick={() => {
                  viewCb(index);
                }}
              >
                View
              </button>
            </td> */}

                {/* Image */}
                <td className="text-center">
                  {data.displayType != "text" ? (
                    <div
                      className="p-2 py-3"
                      onClick={() => {
                        viewImageCb(data);
                      }}
                    >
                      <ImgRender assetId={data?.image || ""} height="100" />
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Banner Input Criteria */}
                {/* <td className="text-center">{data?.filterByConditionType || ""}</td> */}

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={data.status} />
                </td>

                {/* Action block */}
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-outline-warning me-1 fs-val-xs mb-3 mx-1"
                    title="Edit"
                    type="button"
                    onClick={() => {
                      editCb(index);
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>

                  {/* <button
                    className="btn btn-sm btn-outline-success me-1 fs-val-xs mb-3 mx-1"
                    title="Clone"
                    type="button"
                    onClick={() => {
                      cloneCb(index);
                    }}
                  >
                    <i className="bi bi-clipboard-fill"></i>
                  </button>

                  <button
                    className="btn btn-sm btn-outline-danger me-1 fs-val-xs mb-3 mx-1"
                    title="Delete"
                    type="button"
                    onClick={() => {
                      deleteCb(index);
                    }}
                  >
                    <i className="bi bi-trash"></i>
                  </button> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <ImgPreviewModal
        show={imgPreviewData.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData.data}
      />
    </>
  );
};

export default memo(BannerSliderTable);
