import {
  DateFormatter,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useState } from "react";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", width: "5%", isCentered: true },
  { label: "Image", width: "8%", isCentered: true },
  { label: "Title/Tags", width: "25%", isCentered: false },
  { label: "Image Size", isCentered: true },
  {
    label: "Total Banners Count",
    isCentered: true,
    enableSort: true,
    key: "bannersCount",
  },
  {
    label: "Total Banner Clicks",
    isCentered: true,
    enableSort: true,
    key: "bannerClicks",
  },
  // { label: "Created Details", isCentered: true },
  { label: "Last Updated Details", isCentered: true },
  { label: "Status", isCentered: true },
  { label: "Actions", isCentered: true },
];

const rbac = {
  view: ["ViewBannerDesignMaster"],
  edit: ["EditBannerDesignMaster"],
  auditLog: ["ViewBannerDesignMasterAuditLog"],
  analytics: ["ViewBannerDesignMasterAnalytics"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  viewCb,
  auditCb,
  analyticsCb,
  sort,
  sortCb,
}) => {
  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  const viewImage = (x) => {
    if (x?.length > 0) {
      let d = [{ id: x }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No Data */}
          {!loading && !data?.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* List  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={index}>
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td className="text-center">{x?._id}</td>

                <td>
                  <div
                    className="p-2 py-3 cursor-pointer"
                    onClick={() => {
                      viewImage(x?.assetId);
                    }}
                  >
                    <ImgRender assetId={x?.assetId || ""} />
                  </div>
                </td>

                {/*Page */}
                <td>
                  <div className="mb-2">{x?.assetTitle || "N/A"}</div>
                  <div>
                    Tags :
                    {x?.tags.map((e, index) => (
                      <span className="badge bg-primary mx-1" key={index}>
                        {e}
                      </span>
                    ))}
                    {!x?.tags?.length ? "N/A" : ""}
                  </div>
                </td>

                <td className="text-center">
                  {x?.assetAttributes?.widthPxl}x{x?.assetAttributes?.heightPxl}{" "}
                  (WxH)
                </td>

                <td className="text-center">
                  <div>{x?.bannersCount ? x.bannersCount : 0}</div>
                </td>
                <td className="text-center">
                  <div> {x?.bannerClicks ? x.bannerClicks : 0}</div>
                </td>

                {/* Last Updated */}
                <td className="text-center">
                  <DateFormatter date={x.lastUpdated} />
                  <div>
                    By :
                    {x.modifiedLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x?._modifiedBy?.name || x?.modifiedBy || "N/A"
                    )}
                  </div>
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x?.status} isBadge={true} />
                </td>

                {/* Action block */}
                <td className="text-center">
                  <div className="mb-2">
                    <Rbac roles={rbac.edit}>
                      <button
                        className="btn btn-sm btn-outline-warning me-1 fs-val-xs mb-3 mx-1"
                        onClick={() => {
                          editCb(x?._id);
                        }}
                      >
                        Edit
                      </button>
                    </Rbac>
                    <Rbac roles={rbac.view}>
                      <button
                        className="btn btn-sm btn-outline-primary me-1 fs-val-xs mb-3 mx-1"
                        onClick={() => {
                          viewCb(x?._id);
                        }}
                      >
                        View
                      </button>
                    </Rbac>
                  </div>
                  <div className="mb-1">
                    <Rbac roles={rbac.view}>
                      <button
                        className="btn btn-sm btn-outline-info me-1 fs-val-xs mb-3 mx-1"
                        onClick={() => {
                          auditCb(x);
                        }}
                      >
                        Audit Logs
                      </button>
                    </Rbac>
                    <Rbac roles={rbac.analytics}>
                      <button
                        className="btn btn-sm btn-primary me-1 fs-val-xs mb-3 mx-1"
                        onClick={() => {
                          analyticsCb(x?._id);
                        }}
                      >
                        Analytics
                      </button>
                    </Rbac>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        showSummary={false}
      />

      <ImgPreviewModal
        show={imgPreviewData.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData.data}
      />
    </>
  );
};

export default memo(Table);
