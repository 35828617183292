import { AuthService, NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import StockDisplay from "./StockDisplay";

let rowBg = {
  backgroundColor: "#fac5c8",
};

const defaultHeaders = [
  { label: "Sl No", width: "5%", isCentered: true, priority: 1 },
  { label: "Title", priority: 3, width: "12%" },
  { label: "Franchise", priority: 4, width: "10%" },
  { label: "Offer Type", isCentered: true, priority: 4.1, width: "7%" },
  { label: "Main Deals", priority: 4.5, width: "15%" },
  { label: "Main Deal ID", priority: 4.6 },
  // { label: "Summary", isCentered: true, priority: 6, width: "16%" },

  {
    label: "Total Offer Count",
    isCentered: true,
    priority: 6,
  },
  {
    label: "Requested Offer Count",
    isCentered: true,
    priority: 6,
    key: "requestCount",
    enableSort: true,
  },
  {
    label: "Remaining Offer Count",
    isCentered: true,
    priority: 6,
    key: "offerCount",
    enableSort: true,
  },
  {
    label: "Total Customers",
    isCentered: true,
    priority: 6,
    key: "analytics.totalCustomers",
    enableSort: true,
  },
  {
    label: "Total Orders",
    isCentered: true,
    priority: 6,
    key: "analytics.totalOrders",
    enableSort: true,
  },
  {
    label: "Highest Order Value",
    isCentered: true,
    priority: 6,
    key: "analytics.highestOrderValue",
    enableSort: true,
  },
  // {
  //   label: "PNL",
  //   isCentered: true,
  //   priority: 7,
  //   key: "analytics.netProfit",
  //   enableSort: true,
  // },
  // {
  //   label: "Highest Order details",
  //   isCentered: true,
  //   priority: 8,
  // },
  { label: "Status / Actions", isCentered: true, width: "9%", priority: 100 },
];

const rbac = {
  editButton: ["EditStoreOffersConfig"],
  viewButton: ["ViewStoreOffersConfig"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  openViewModal,
  editCb,
  sortCb,
  moreColumns = {},
  markAsActive,
  markAsInActive,
  viewCustomerDetailsCb,
  viewOrderDetailsCb,
  cloneCb,
}) => {
  const [headers, setHeaders] = useState([...defaultHeaders]);

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    let t = [];
    each(moreColumns || {}, (x) => {
      t.push(x);
    });
    setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  }, [moreColumns]);

  const hasAccess = useMemo(() => {
    return AuthService.isValidUserRole(rbac.viewButton);
  }, []);

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered bg-white">
          <TableHeader data={headers} sort={sort} sortCb={sortCb} />

          {/* Table Body */}
          <tbody>
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No loading */}
            {!loading && !data?.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Store Discount Config Found </NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When Not Loading and Data is there  */}
            {!loading &&
              data.map((x, index) => (
                <tr
                  key={x._id}
                  className="fs-val-sm"
                  style={x?._isExpiredOfferLimit ? rowBg : {}}
                >
                  {/* Serial Number */}
                  <td className="text-center">
                    {paginationConfig.startSlNo + index}
                  </td>

                  {/* Title */}
                  <td>
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => (hasAccess ? openViewModal(x) : null)}
                      className="btn btn-link fs-val-md p-0"
                    >
                      {x.title}
                    </div>
                    <div className="fs-val-sm">
                      <span className="text-muted">id :</span>{" "}
                      <span className="text-muted"> {x._id} </span>
                    </div>
                  </td>

                  {/* Franchise  */}
                  <td>
                    <div className=" fw-semibold">
                      {x.franchiseName || "N/A"}
                    </div>
                    <div className="fs-val-sm">
                      <span className="text-muted">ID :</span>{" "}
                      <span className="text-muted"> {x.franchiseId} </span>
                    </div>
                  </td>

                  {/* Offer Type */}
                  <td>
                    <span className="fs-val-sm badge badge-soft-primary text-uppercase">
                      {x.offerType ?? "N/A"}
                    </span>
                  </td>

                  {/* Main Deal */}
                  <td>
                    <div
                      className="fw-semibold fs-val-sm cursor-pointer text-primary"
                      onClick={() => {
                        NavService.viewDeal({
                          id: x.offerDeals?.[0]?.dealId,
                        });
                      }}
                    >
                      {x.offerDeals?.[0]?.name || "N/A"}
                      {x.offerDeals.length > 1 && (
                        <button
                          className="btn btn-link fs-val-sm p-1"
                          onClick={() => (hasAccess ? openViewModal(x) : null)}
                        >
                          ( {x.offerDeals.length - 1} +)
                        </button>
                      )}
                    </div>
                    {x.offerDeals.length ? (
                      <StockDisplay
                        fid={x?.franchiseId}
                        dealId={x?.offerDeals[0]?.dealId}
                      />
                    ) : null}
                  </td>

                  <td>
                    <div
                      className="cursor-pointer text-primary"
                      onClick={() => {
                        NavService.viewDeal({
                          id: x.offerDeals?.[0]?.dealId,
                        });
                      }}
                    >
                      {x.offerDeals?.[0]?.dealId || "N/A"}
                    </div>
                  </td>
                  {/* Validity */}
                  {moreColumns?.validFrom?.checked && (
                    <td>
                      <div className="text-muted fs-val-sm">Start Date </div>
                      <div className="mb-1">
                        <DateFormatter
                          date={x?.offerValidity?.validFrom}
                          format="dd MMM yyyy"
                        />
                      </div>
                      <div className="mb-3">
                        <DateFormatter
                          date={x?.offerValidity?.validFrom}
                          format="hh:mm a"
                        />
                      </div>
                      <div className="text-muted fs-val-sm">End Date</div>
                      <div className="mb-1">
                        <DateFormatter
                          date={x?.offerValidity?.validTo}
                          format="dd MMM yyyy"
                        />
                      </div>
                      <div className="mb-1">
                        <DateFormatter
                          date={x?.offerValidity?.validTo}
                          format="hh:mm a"
                        />
                      </div>
                    </td>
                  )}

                  {/* total Remaining Offer Count */}
                  <td className="text-center">
                    {x?.offerCount + x?.requestCount}
                  </td>

                  {/* total Request Count */}
                  <td className="text-center">{x?.requestCount}</td>

                  {/* total Offer Count */}
                  <td className="text-center">{x?.offerCount}</td>

                  {/* Total Customers */}
                  <td className="text-center">
                    <div className="ps-1 text-primary fw-semibold  cursor-pointer  fs-val-md">
                      {x?.analytics?.uniqueCustomersCount || 0}

                      {x?.analytics?.uniqueCustomersCount > 0 ? (
                        <span
                          className="text-decoration-underline ps-1 cursor-pointer "
                          onClick={() => {
                            viewCustomerDetailsCb(x);
                          }}
                        >
                          View
                        </span>
                      ) : null}
                    </div>
                  </td>

                  {/* Total Order */}
                  <td className="text-center">
                    <div className="ps-1 text-primary fw-semibold  fs-val-md">
                      {x?.analytics?.totalOrders || 0}
                      {x?.analytics?.totalOrders > 0 ? (
                        <span
                          className="text-decoration-underline ps-1 cursor-pointer"
                          onClick={() => {
                            viewOrderDetailsCb(x);
                          }}
                        >
                          View
                        </span>
                      ) : null}
                    </div>
                  </td>

                  {/* Highest Order Value */}
                  <td className="text-center">
                    <div className="mb-2 ps-1  fw-bold">
                      <Amount value={x?.analytics?.highestOrderValue || 0} />
                    </div>
                    <div className="mb-2 ps-1">
                      {x?.analytics?.highestOrderValue ? (
                        <span>
                          On :
                          <DateFormatter
                            date={x?.analytics?.highestOrderDate}
                            format="dd MMM yyyy"
                          />
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </td>

                  {/* Offer Code */}
                  {moreColumns?.offerCode?.checked && (
                    <td className="text-center">
                      <span className="fs-val-sm badge badge-soft-primary text-uppercase">
                        {x.offerCode ?? "N/A"}
                      </span>
                    </td>
                  )}

                  {/* Profit/Loss */}
                  {/* <td className="text-center">
                  <>
                    <div className="mb-1">Main Deal : </div>
                    <div className="fw-semibold fs-val-lg mb-3">
                      <span
                        className={classNames({
                          "text-success":
                            x?.analytics?.totalProfitForMainDeal > 0,
                          "text-danger":
                            x?.analytics?.totalProfitForMainDeal < 0,
                          "text-primary":
                            x?.analytics?.totalProfitForMainDeal == 0,
                        })}
                      >
                        <Amount
                          value={x?.analytics?.totalProfitForMainDeal || 0}
                        />
                      </span>
                    </div>

                    <div className="mb-1">Offer Deal</div>
                    <div className="fw-semibold fs-val-lg mb-3">
                      <span
                        className={classNames({
                          "text-success":
                            x?.analytics?.totalProfitForOfferDeal > 0,
                          "text-danger":
                            x?.analytics?.totalProfitForOfferDeal < 0,
                          "text-primary":
                            x?.analytics?.totalProfitForOfferDeal == 0,
                        })}
                      >
                        <Amount
                          value={x?.analytics?.totalProfitForOfferDeal || 0}
                        />
                      </span>
                    </div>

                    <div className="mb-1">Net Value</div>
                    <div className="fw-semibold fs-val-lg">
                      <span
                        className={classNames({
                          "text-success": x?.netProfit > 0,
                          "text-danger": x?.netProfit < 0,
                          "text-primary": x?.netProfit == 0,
                        })}
                      >
                        <Amount value={x?.netProfit || 0} />
                      </span>
                    </div>
                  </>
                </td> */}

                  {/* Highest Order details */}
                  {/* <td className="text-center">
                  <div className="fs-val-sm text-muted mb-1">
                    Order Amount :
                  </div>
                  {x?._anaylitcsLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <div className="mb-3 text-primary fw-semibold fs-val-md">
                      <Amount value={x?.analytics?.highestOrderValue || 0} />
                    </div>
                  )}

                  <div className="fs-val-sm text-muted mb-1">Order On :</div>
                  {x?._anaylitcsLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className="mb-3">
                      {x?.analytics?.highestOrderDate ? (
                        <DateFormatter
                          date={x?.analytics?.highestOrderDate}
                          format="dd MMM yyyy"
                        />
                      ) : (
                        "N/A"
                      )}
                    </span>
                  )}
                </td> */}

                  {/* Status  */}
                  {/* {moreColumns?.status?.checked && (
                  <td className="text-center">
                    <HighlightText status={x.status} isBadge={false} />

                    <Rbac roles={rbac.editButton}>
                      {x.status == "Active" ? (
                        <div>
                          <button
                            className="btn btn-link text-danger fs-val-xs p-0"
                            onClick={() => markAsInActive(x)}
                          >
                            Mark as Inactive
                          </button>
                        </div>
                      ) : null}

                      {x.status == "Inactive" ? (
                        <button
                          className="btn btn-link text-success fs-val-xs p-0"
                          onClick={() => markAsActive(x)}
                        >
                          Mark as Active
                        </button>
                      ) : null}
                    </Rbac>
                  </td>
                )} */}

                  {/* Action */}
                  <td className="text-center">
                    <div className="text-center mb-3">
                      <div className="mb-2">
                        <HighlightText status={x.status} isBadge={true} />
                      </div>

                      <Rbac roles={rbac.editButton}>
                        {/* FOR MARK AS IN-ACTIVE BUTTON  */}
                        {x.status == "Active" ? (
                          <div>
                            <button
                              className="btn btn-link text-danger fs-val-xs p-0 w-100 mb-1"
                              onClick={() => markAsInActive(x)}
                            >
                              Mark as Inactive
                            </button>
                          </div>
                        ) : null}

                        {/* FOR MARK AS ACTIVE BUTTON  */}
                        {x.status == "Inactive" ? (
                          <button
                            className="btn btn-link text-success fs-val-xs p-0 w-100 mb-1"
                            onClick={() => markAsActive(x)}
                          >
                            Mark as Active
                          </button>
                        ) : null}
                      </Rbac>
                    </div>
                    <Rbac roles={rbac.viewButton}>
                      <button
                        onClick={() => openViewModal(x)}
                        className="btn btn-sm fs-val-xs btn-outline-primary me-1 w-100 mb-1"
                      >
                        View
                      </button>
                    </Rbac>

                    <Rbac roles={rbac.editButton}>
                      <button
                        className="btn btn-sm btn-outline-warning fs-val-xs w-100 mb-1"
                        onClick={() => editCb(x._id)}
                      >
                        Edit
                      </button>
                    </Rbac>

                    {x?.status != "Expired" ? (
                      <Rbac roles={rbac.editButton}>
                        <button
                          className="btn btn-sm btn-outline-info fs-val-xs w-100"
                          onClick={() => cloneCb(x)}
                        >
                          Clone
                        </button>
                      </Rbac>
                    ) : null}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
