import { yupResolver } from "@hookform/resolvers/yup";
import {
  AjaxService,
  FranchiseService,
  NotificationService,
} from "@sk/services";
import {
  AppCard,
  AppTitle,
  DateInput,
  EntitySearchInput,
  PageLoader,
  SelectInput,
  Spinner,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { format, set } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import formView from "../../constantService";
import NotificationTypeDetails from "./components/NotificationTypeDetails";

const custSegOptions = NotificationService.getSegmentOptions();
custSegOptions.unshift({ label: "Choose", value: "" });

const canvasStyle = {
  width: "60%",
};

const dtOption = {
  minDate: new Date(),
};

const franchiseSearchFilter = {
  filter: {
    groupType: "COCO",
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSFCOCO", "SMARTSF"],
    },
  },
};

const validationSchema = yup.object({
  franchise: yup.array().when("type", {
    is: "Others",
    then: () => yup.array().nullable(),
    otherwise: () =>
      yup.array().min(1, "Please Add Franchise").label("Franchise").required(),
  }),
  type: yup.string().trim().required("Promotion Type is Required"),
  title: yup.string().when("type", {
    is: "Others",
    then: () => yup.string().nullable(),
    otherwise: () =>
      yup
        .string()
        .trim()
        .max(50, "Promotion Title Max Character is 50")
        .required("Promotion Title is Required"),
  }),
  isActive: yup.string().trim(),
  executeTime: yup.array().when("type", {
    is: "Others",
    then: () => yup.array().nullable(),
    otherwise: () =>
      yup
        .array()
        .min(1, "Execute Time is Required")
        .required("Execute Time is Required"),
  }),
  notifcationTypes: yup.array(),
  criteriaDetails: yup.object().when("type", {
    is: "Others",
    then: () => yup.object().nullable(),
    otherwise: () =>
      yup.object({
        customerStatus: yup
          .string()
          .trim()
          .required("Customer Status is Required"),
        eventType: yup.string().trim().required("Event Type is Required"),
        executeType: yup.string().trim().required("Execute Type is Required"),
        executeUnit: yup.string().when("executeType", {
          is: (val) => ["Start Of", "End Of", "Custom"].includes(val),
          then: () => yup.string().required("Execute Unit is Required"),
          otherwise: () => yup.string().nullable(),
        }),
        executeTypeCustomValue: yup.number().when("executeType", {
          is: "Custom",
          then: () =>
            yup
              .number()
              .required()
              .min(1, "Execute Type Custom Value Required")
              .max(100)
              .label("Execute Type Custom Value"),
          otherwise: () => yup.number().nullable(),
        }),
        executeDate: yup.array().when("executeType", {
          is: "Date",
          then: () => yup.array().required("Execute Date is Required"),
          otherwise: () => yup.array().nullable(),
        }),
      }),
  }),
  executeOption: yup.string().when("type", {
    is: "Others",
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().required("Execute Option is Required"),
  }),
  eventOptionList: yup.array(),
  customerSegment: yup.string().when("type", {
    is: "Others",
    then: () => yup.string().required("Segment is Required"),
    otherwise: () => yup.string().nullable(),
  }),
  smsStatus: yup.boolean(),
  smsTemplate: yup.array().when("smsStatus", {
    is: true,
    then: () =>
      yup
        .array()
        .min(1, "Please Choose SMS Template")
        .label("SMS Template")
        .required(),
    otherwise: () => yup.array().nullable(),
  }),
  whatsappStatus: yup.boolean(),
  whatsappTemplate: yup.array().when("whatsappStatus", {
    is: true,
    then: () =>
      yup
        .array()
        .min(1, "Please Choose WhatsApp Template")
        .label("WhatsApp Template")
        .required(),
    otherwise: () => yup.array().nullable(),
  }),
  emailStatus: yup.boolean(),
  emailTemplate: yup.array().when("emailStatus", {
    is: true,
    then: () =>
      yup
        .array()
        .min(1, "Please Choose Email Template")
        .label("Email Template")
        .required(),
    otherwise: () => yup.array().nullable(),
  }),
});

// default From Values
const defaultFromData = {
  title: "",
  type: "",
  isActive: true,
  executeTime: [],
  smsStatus: false,
  smsTemplateId: "",
  whatsappStatus: false,
  whatsappTemplatId: "",
  emailStatus: false,
  emailTemplateId: "",
  franchise: [],
  executeOption: "",
  eventOptionList: [],
  customerSegment: "",
  templates: [],
};

function ManageMarketingPromotionModal({ closeModal, show, mode, pid }) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultFromData },
  });

  const [submitting, setSubmitting] = useState(false);

  const [dateOption, setDateOption] = useState({ ...dtOption });
  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  const [executeType, type, eventOptionList, executeOption, templates] =
    useWatch({
      control: methods.control,
      name: [
        "criteriaDetails.executeType",
        "type",
        "eventOptionList",
        "executeOption",
        "templates",
      ],
    });

  const executeTypeOptions = useMemo(() => {
    return formView.criteriaExecuteTypeOptions.filter((e) => {
      if (!e.value) {
        return true;
      } else if (executeOption == "Scheduled") {
        return e.value == "Date" ? true : false;
      } else if (executeOption == "Auto") {
        return ["Date", "Custom"].indexOf(e.value) == -1 ? true : false;
      } else {
        return true;
      }
    });
  }, [executeOption]);

  const customerStatusOptions = useMemo(() => {
    return formView.criteriaCustomerStatusOptions.filter((e) => {
      if (!e.value) {
        return true;
      } else if (type == "Inactive") {
        return e.value == "Inactive" ? true : false;
      } else {
        return true;
      }
    });
  }, [type]);

  useEffect(() => {
    if (show) {
      methods.reset();

      setSubmitting(false);
      setDateOption({ ...dtOption });
      if (mode == "edit") {
        if (pid) {
          loadEditData(pid);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
    }
  }, [mode, pid, show]);

  // To get Edit Data When Id Is There
  const loadEditData = async (id) => {
    setPageLoading(true);

    const r = await NotificationService.getPromotionTemplateById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }

    await prepareFromValues(d);
    setPageLoading(false);
  };

  // To Prepare Form Values From LoadEditData
  const prepareFromValues = useCallback(
    async (r) => {
      // Fields
      [
        "title",
        "type",
        "isActive",
        "criteriaDetails.customerStatus",
        "executeOption",
      ].forEach((x) => methods.setValue(x, r[x] || ""));

      // Parse input time string
      if (r?.executeTime) {
        let t = r?.executeTime || "";
        const a = [t.slice(0, 2), t.slice(3)];

        // set array values below
        const dt = set(new Date(), {
          hours: a[0] * 1,
          minutes: a[1] * 1,
        });
        methods.setValue("executeTime", [dt]);
      }

      // methods.setValue("criteriaDetails.type", r?.criteriaDetails?.type || "");
      methods.setValue(
        "criteriaDetails.customerStatus",
        r?.criteriaDetails?.customerStatus
      );

      // Even options list
      let opt = [{ label: "Choose", value: "" }];
      let list = formView.eventOptions.filter((e) => {
        return e.type.indexOf(r.type) != -1;
      });
      opt = opt.concat(list);
      methods.setValue("eventOptionList", opt);

      methods.setValue(
        "criteriaDetails.eventType",
        r?.criteriaDetails?.eventType
      );

      methods.setValue(
        "criteriaDetails.executeAction",
        r?.criteriaDetails?.executeAction || ""
      );
      methods.setValue(
        "criteriaDetails.executeType",
        r?.criteriaDetails?.executeType
      );
      methods.setValue(
        "criteriaDetails.executeUnit",
        r?.criteriaDetails?.executeUnit
      );

      if (
        r?.criteriaDetails?.executeType == "Date" &&
        r?.criteriaDetails?.executeDate
      ) {
        setDateOption({
          minDate: new Date(r?.criteriaDetails?.executeDate),
        });
        methods.setValue("criteriaDetails.executeDate", [
          new Date(r?.criteriaDetails?.executeDate),
        ]);
      }
      if (r?.criteriaDetails?.executeType == "Custom") {
        methods.setValue(
          "criteriaDetails.executeTypeCustomValue",
          r.criteriaDetails.executeTypeCustomValue
        );
      }

      if (r.promotionConfigType) {
        methods.setValue("customerSegment", r.promotionConfigType);
      }

      if (r.franchiseId) {
        const franchise = await FranchiseService.getFranchise(r.franchiseId);
        if (franchise?.resp?._id) {
          methods.setValue("franchise", [
            { value: franchise.resp, label: franchise.resp.name },
          ]);
        }
      }

      if (r.notifcationTypes.length) {
        const ids = r.notifcationTypes.map((e) => e.templateId);
        const templates = await NotificationService.getList({
          filter: {
            _id: { $in: ids },
          },
          select: "name,title,default",
        });
        methods.setValue(
          "templates",
          r.notifcationTypes
            .filter((e) => e.status)
            .map((e) => {
              const t = templates.resp.find((t) => t._id == e.templateId);
              return {
                value: {
                  _id: t._id,
                  name: t.name,
                  default: { body: t.default?.body },
                },
                label: t.name,
                channelType: e.type,
              };
            })
        );
      }
    },
    [methods]
  );

  const onSubmit = async () => {
    const f = methods.getValues();

    if (!f.templates.length) {
      Toaster.error("Please configure Channel Details");
      return;
    }

    setSubmitting(true);

    const p = prepareParams();

    let r = "";
    if (mode == "add") {
      r = await NotificationService.createPromotionTemplate(p);
    } else {
      r = await NotificationService.updatePromotionTemplate(pid, p);
    }
    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error?.msg[0] || "Failed to update");
      return;
    }

    methods.reset();

    Toaster.success(
      `Promotion ${mode == "add" ? "has been Created" : "Updated"} Successfully`
    );

    triggerCloseModal("submit");
  };

  const prepareParams = () => {
    let f = methods.getValues();
    let p = {
      title: f.title,
      type: f.type,
      franchiseId: f.franchise?.length ? f.franchise[0].value._id : "",
      criteriaDetails: {
        ...f.criteriaDetails,
        executeDate: f.criteriaDetails?.executeDate?.length
          ? new Date(f.criteriaDetails.executeDate[0])
          : "",
      },
      isActive: f.isActive,
      executeTime: f.executeTime.length
        ? format(new Date(f.executeTime[0]), "HH:mm")
        : "",
      executeOption: f.executeOption,
      notifcationTypes: f.templates.map((t) => ({
        type: t.channelType,
        templateId: t.value._id,
        status: true,
      })),
    };

    if (f.type === "Others") {
      p.franchiseId = "";
    }

    if (f?.criteriaDetails?.executeType != "Custom") {
      delete f.criteriaDetails.executeTypeCustomValue;
    }
    if (f?.criteriaDetails?.executeType != "Date") {
      delete f.criteriaDetails.executeDate;
    }
    if (f?.criteriaDetails?.executeType == "Date") {
      delete f.criteriaDetails.executeUnit;
    }

    if (f.type == "Others") {
      p.promotionConfigType = f.customerSegment;
      p.title = NotificationService.getSegmentOptions().find(
        (e) => e.value == f.customerSegment
      )?.label;
      p.criteriaDetails = {};
    } else {
      p.promotionConfigType = "";
    }

    return p;
  };

  // To Close Modal
  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  const onInputChange = useCallback(
    (chngFn, key) => {
      return (e) => {
        chngFn(e);
        methods.setValue(key, e);
        methods.trigger(key);
      };
    },
    [methods]
  );

  const onExecuteTypeInputChange = () => {
    methods.setValue("criteriaDetails.executeTypeCustomValue", null);
    methods.setValue("criteriaDetails.executeDate", []);
    methods.setValue("criteriaDetails.executeUnit", "Day");
    methods.trigger([
      "criteriaDetails.executeTypeCustomValue",
      "criteriaDetails.executeDate",
      "criteriaDetails.executeUnit",
    ]);
  };

  const onExecutTypeCustomValueChange = async () => {
    await methods.trigger("criteriaDetails.executeTypeCustomValue");
  };

  const onPromotionChange = useCallback(
    (val) => {
      let opt = [...formView.eventOptions].filter((e) => {
        return e.type.indexOf(val) != -1;
      });
      opt.unshift({ label: "Choose", value: "" });
      methods.setValue("eventOptionList", opt);
      methods.setValue("criteriaDetails.customerStatus", "");
    },
    [methods]
  );

  const onExecuteOptionChange = () => {
    methods.setValue("criteriaDetails.executeType", "");
  };

  const templateCb = (templateData) => {
    const { action, data } = templateData;

    if (action === "add") {
      const { channel, templateId, content, name } = data;

      // Get current templates array
      const currentTemplates = methods.getValues("templates") || [];

      // Add new template to array
      const updatedTemplates = [
        ...currentTemplates,
        {
          value: {
            _id: templateId,
            name: name,
            default: { body: content },
          },
          label: name,
          channelType: channel,
        },
      ];

      // Update templates array in form
      methods.setValue("templates", updatedTemplates);
    }

    if (action === "remove") {
      const { id } = data;

      // Get current templates
      const currentTemplates = methods.getValues("templates") || [];

      // Remove template at index
      const updatedTemplates = currentTemplates.filter(
        (template) => template.value._id !== id
      );

      // Update templates array in form
      methods.setValue("templates", updatedTemplates);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={triggerCloseModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <AppTitle
            title={
              mode === "edit"
                ? "Edit Message Config" + `  #${pid}`
                : "Add Message Config"
            }
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="border-top modal-bg">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <AppCard title="Basic Details">
                    <div className="row">
                      {/* Prormotion Type */}
                      <div className="col-3">
                        <SelectInput
                          register={methods.register}
                          name="type"
                          label="Promotion Type"
                          options={formView.typOptions}
                          isMandatory={true}
                          error={methods?.formState?.errors?.type?.message}
                          callback={onPromotionChange}
                          disabled={mode == "edit"}
                          info={{ content: formView.tagline.type }}
                        />
                      </div>

                      {type == "Others" ? (
                        <div className="col-6 mb-2">
                          <SelectInput
                            register={methods.register}
                            name="customerSegment"
                            label="Segment"
                            options={custSegOptions}
                            isMandatory={true}
                            error={
                              methods?.formState?.errors?.customerSegment
                                ?.message
                            }
                          />
                        </div>
                      ) : (
                        <div className="col-6 mb-2">
                          <TextInput
                            register={methods.register}
                            name="title"
                            placeholder="Enter Promotion Title"
                            label="Promotion Title"
                            info={{ content: formView.tagline.title }}
                            isMandatory={true}
                            error={methods?.formState?.errors?.title?.message}
                            disabled={mode == "edit"}
                            note="Max 100 Character Allowed"
                            maxLength={100}
                          />
                        </div>
                      )}

                      {/* Promotion Status */}
                      <div className="col-2 ps-4">
                        <Controller
                          control={methods.control}
                          name="isActive"
                          render={({ field: { onChange, value } }) => (
                            <SwitchInput
                              label="Active"
                              isMandatory={false}
                              value={value}
                              callback={onChange}
                            />
                          )}
                        />
                      </div>

                      {/* Franchise */}
                      {type !== "Others" && (
                        <div className="col-9 mb-2">
                          <Controller
                            control={methods.control}
                            name="franchise"
                            key="franchise"
                            render={({ field: { onChange, value } }) => (
                              <EntitySearchInput
                                type="franchise"
                                label="Search for Franchise"
                                name="franchise"
                                placeholder="Search by  Id/Name"
                                value={value}
                                isMandatory={true}
                                callback={onChange}
                                uid="franchise"
                                emptyLabel="No Franchise Found"
                                error={
                                  methods?.formState?.errors?.franchise?.message
                                }
                                disabled={mode == "edit"}
                                info={{ content: formView.tagline.franchise }}
                                filterParams={franchiseSearchFilter}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </AppCard>

                  {/* Only show these sections if type is not Others */}
                  {type !== "Others" && (
                    <>
                      <AppCard title="Configure Rule">
                        <div className="row">
                          {/* Criteria Event Type */}
                          <div className="col-6 pe-0">
                            <SelectInput
                              register={methods.register}
                              name="criteriaDetails.eventType"
                              label="Event Type"
                              options={eventOptionList}
                              isMandatory={true}
                              error={
                                methods?.formState?.errors?.criteriaDetails
                                  ?.eventType?.message
                              }
                              disabled={
                                (!type && mode != "edit") || mode == "edit"
                              }
                              info={{ content: formView.tagline.eventType }}
                            />
                          </div>

                          {/* Criteria Customer Status */}
                          <div className="col-3 ps-4">
                            <SelectInput
                              register={methods.register}
                              name="criteriaDetails.customerStatus"
                              label="Customer Status"
                              info={{
                                content: formView.tagline.customerStatus,
                              }}
                              options={customerStatusOptions}
                              error={
                                methods?.formState?.errors?.criteriaDetails
                                  ?.customerStatus?.message
                              }
                              isMandatory={true}
                              disabled={mode == "edit"}
                            />
                          </div>
                        </div>
                      </AppCard>

                      <AppCard title="When to Send Notification">
                        <div className="row">
                          {/* Execute Options */}
                          <div className="col-3">
                            <SelectInput
                              register={methods.register}
                              name="executeOption"
                              label="Execute Option"
                              options={formView.executeOptions}
                              isMandatory={true}
                              error={
                                methods?.formState?.errors?.executeOption
                                  ?.message
                              }
                              info={{ content: formView.tagline.executeOption }}
                              callback={onExecuteOptionChange}
                            />
                          </div>

                          {/* Criteria Execute Type */}
                          <div className="col-3">
                            <SelectInput
                              register={methods.register}
                              name="criteriaDetails.executeType"
                              label="Execute Type"
                              info={{ content: formView.tagline.executeType }}
                              options={executeTypeOptions}
                              isMandatory={true}
                              callback={onExecuteTypeInputChange}
                              error={
                                methods?.formState?.errors?.criteriaDetails
                                  ?.executeType?.message
                              }
                            />
                          </div>

                          {/* Criteria Date */}
                          {executeType == "Date" ? (
                            <div className="col-3">
                              <Controller
                                control={methods.control}
                                name="criteriaDetails.executeDate"
                                render={({ field: { onChange, value } }) => (
                                  <DateInput
                                    label="Execute Date"
                                    value={value}
                                    callback={onInputChange(
                                      onChange,
                                      "criteriaDetails.executeDate"
                                    )}
                                    isMandatory={true}
                                    config={dateOption}
                                    error={
                                      methods?.formState?.errors
                                        ?.criteriaDetails?.executeDate?.message
                                    }
                                  />
                                )}
                              />
                            </div>
                          ) : null}

                          {/* Criteria Execute Custome Value */}
                          {executeType == "Custom" ? (
                            <div className="col-3">
                              <TextInput
                                type="number"
                                register={methods.register}
                                name="criteriaDetails.executeTypeCustomValue"
                                placeholder="Enter value here"
                                label="Execute Type Custom Value(in Days)"
                                info={{
                                  content:
                                    formView.tagline.executeTypeCustomValue,
                                }}
                                isMandatory={true}
                                error={
                                  methods?.formState?.errors?.criteriaDetails
                                    ?.executeTypeCustomValue?.message
                                }
                                callback={onExecutTypeCustomValueChange}
                              />
                            </div>
                          ) : null}

                          {/* Criteria Execute Unit */}
                          {["Start Of", "End Of", "Custom"].indexOf(
                            executeType
                          ) != -1 ? (
                            <div className="col-3">
                              <SelectInput
                                register={methods.register}
                                name="criteriaDetails.executeUnit"
                                label="Execute Unit"
                                info={{ content: formView.tagline.executeUnit }}
                                options={formView.criteriaExecuteUnitOptions}
                                isMandatory={true}
                                error={
                                  methods?.formState?.errors?.criteriaDetails
                                    ?.executeUnit?.message
                                }
                              />
                            </div>
                          ) : null}

                          {/* Execute Time */}
                          <div className="col-3">
                            <div style={{ maxWidth: "300px" }}>
                              <Controller
                                control={methods.control}
                                name="executeTime"
                                render={({ field: { onChange, value } }) => (
                                  <DateInput
                                    label="Trigger Notification Time"
                                    callback={onInputChange(
                                      onChange,
                                      "executeTime"
                                    )}
                                    value={value}
                                    config={{
                                      noCalendar: true,
                                      enableTime: true,
                                      dateFormat: "h:i K",
                                    }}
                                    isMandatory={true}
                                    error={
                                      methods?.formState?.errors?.executeTime
                                        ?.message
                                    }
                                    info={{
                                      content: formView.tagline.executeTime,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </AppCard>
                    </>
                  )}

                  <AppCard title="Configure Channel">
                    <NotificationTypeDetails
                      templates={templates}
                      callback={templateCb}
                    />
                  </AppCard>

                  <div className="text-end py-3 px-2">
                    <button
                      className="btn btn-primary"
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={submitting}
                    >
                      Submit {submitting ? <Spinner isSmall={true} /> : null}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default memo(ManageMarketingPromotionModal);
