import { Amount, KeyVal, TableHeader } from "@sk/uis";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const defaultHeaders = [
  { label: "Name", width: "50%" },
  //   { label: "No of deals", width: "10%" },
  { label: "Profit/Loss", width: "50%", isCentered: true },
];

const PreviewStoreOfferModal = ({ show, closeModal, data }) => {
  const [details, setDetails] = useState({
    type: "",
    data: data,
    mainDeal: { skPnl: 0, storePnl: 0, deals: 0 },
    offerProduct: { skPnl: 0, storePnl: 0, deals: 0 },
    total: { skPnl: 0, storePnl: 0, deals: 0 },
  });

  useEffect(() => {
    if (show && data) {
      let m = { skPnl: 0, storePnl: 0, deals: 0 };
      let o = { skPnl: 0, storePnl: 0, deals: 0 };
      let t = { skPnl: 0, storePnl: 0, deals: 0 };
      let d = { ...data };
      d.productList.forEach((e) => {
        let offDisc = 0;
        if (e?.discountType == "Percentage") {
          offDisc =
            e?._raw?.retailerSellingPrice * (1 - e?.discountValue / 100);
        } else if (e?.discountType == "Fixed") {
          offDisc = e?._raw?.retailerSellingPrice - e?.discountValue;
        } else if (e?.isFree) {
          offDisc = 0;
        } else {
          offDisc = e?._raw?.retailerSellingPrice;
        }
        let skPnl = (offDisc - e?._raw?.slc) * e?.qty;
        let storePnl = (offDisc - e?._raw?.b2bprice) * e?.qty;
        e._skPnl = skPnl;
        e._storePnl = storePnl;
        o.skPnl += skPnl;
        o.storePnl += storePnl;
        o.deals++;
      });
      if (["Buy-A Get-A", "Buy-A Get-B"].indexOf(d.offerType) != -1) {
        d.mainDeal.forEach((e) => {
          let offDisc = e?.value?.retailerSellingPrice;
          let skPnl = (offDisc - e?.value?.slc) * data.qty;
          let storePnl = (offDisc - e?.value?.b2bprice) * data.qty;
          e._skPnl = skPnl;
          e._storePnl = storePnl;
          m.skPnl += skPnl;
          m.storePnl += storePnl;
          m.deals++;
        });
      }
      if (["Buy-N Get-N"].indexOf(d.offerType) != -1) {
        d.offerDeals.forEach((e) => {
          let offDisc = 0;
          if (e?.discountType == "Percentage") {
            offDisc =
              e?._raw?.retailerSellingPrice * (1 - e?.discountValue / 100);
          } else if (e?.discountType == "Fixed") {
            offDisc = e?._raw?.retailerSellingPrice - e?._raw?.discountValue;
          } else if (e?.isFree) {
            offDisc = 0;
          } else {
            offDisc = e?._raw?.retailerSellingPrice;
          }
          let skPnl = (offDisc - e?._raw?.slc) * e?.qty;
          let storePnl = (offDisc - e?._raw?.b2bprice) * e?.qty;
          e._skPnl = skPnl;
          e._storePnl = storePnl;
          m.skPnl += skPnl;
          m.storePnl += storePnl;
          m.deals++;
        });
      }
      t.skPnl = o.skPnl + m.skPnl;
      t.storePnl = o.storePnl + m.storePnl;
      t.deals = o.deals + m.deals;
      setDetails({
        type: d.offerType,
        data: d,
        mainDeal: m,
        offerProduct: o,
        total: t,
      });
    }
  }, [show, setDetails, data]);

  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  const onSubmit = () => {
    closeModal({ status: "success", data: data });
  };

  return (
    <Modal show={show} backdrop="static" size="lg" onHide={triggerCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0 fw-bold">
          Store Offer Summary{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <TableHeader data={defaultHeaders} />
          <tbody>
            {(details?.data?.rewardTypeOffer == "ONEPRODUCT" &&
              details?.data?.productList?.length < 2) ||
            details?.data?.rewardTypeOffer != "ONEPRODUCT" ? (
              <>
                {/* Main  */}
                {details.type != "CARTOFFER" ? (
                  <tr className="fs-val-md">
                    <td>Main Deal</td>
                    {/* <td>{details?.mainDeal?.deals}</td> */}
                    <td>
                      <div className="row">
                        <div className="col-6 text-center">
                          <KeyVal label="Store PNL" template="col">
                            <span
                              className={classNames({
                                "text-success": details?.mainDeal?.storePnl > 0,
                                "text-danger": details?.mainDeal?.storePnl < 0,
                                "text-primary":
                                  details?.mainDeal?.storePnl == 0,
                              })}
                            >
                              <Amount
                                value={details?.mainDeal?.storePnl}
                                decimalPlace={2}
                              />
                            </span>
                          </KeyVal>
                        </div>
                        <div className="col-6 text-center">
                          <KeyVal label="SK PNL" template="col">
                            <span
                              className={classNames({
                                "text-success": details?.mainDeal?.skPnl > 0,
                                "text-danger": details?.mainDeal?.skPnl < 0,
                                "text-primary": details?.mainDeal?.skPnl == 0,
                              })}
                            >
                              {" "}
                              <Amount
                                value={details?.mainDeal?.skPnl}
                                decimalPlace={2}
                              />
                            </span>
                          </KeyVal>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
                {/* Offer Deal  */}
                <tr className="fs-val-md">
                  <td>Offer Deal</td>
                  {/* <td>{details?.offerProduct?.deals}</td> */}
                  <td>
                    <div className="row">
                      <div className="col-6 text-center">
                        <KeyVal label="Store PNL" template="col">
                          <span
                            className={classNames({
                              "text-success":
                                details?.offerProduct?.storePnl > 0,
                              "text-danger":
                                details?.offerProduct?.storePnl < 0,
                              "text-primary":
                                details?.offerProduct?.storePnl == 0,
                            })}
                          >
                            <Amount
                              value={details?.offerProduct?.storePnl}
                              decimalPlace={2}
                            />
                          </span>
                        </KeyVal>
                      </div>
                      <div className="col-6 text-center">
                        <KeyVal label="SK PNL" template="col">
                          <span
                            className={classNames({
                              "text-success": details?.offerProduct?.skPnl > 0,
                              "text-danger": details?.offerProduct?.skPnl < 0,
                              "text-primary": details?.offerProduct?.skPnl == 0,
                            })}
                          >
                            <Amount
                              value={details?.offerProduct?.skPnl}
                              decimalPlace={2}
                            />
                          </span>
                        </KeyVal>
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Total Summary  */}
                {details.type != "CARTOFFER" ? (
                  <tr className="fs-val-md">
                    <td>Total Summary</td>
                    {/* <td>{details?.total?.deals}</td> */}
                    <td>
                      <div className="row">
                        <div className="col-6 text-center">
                          <KeyVal label="Store PNL" template="col">
                            <span
                              className={classNames({
                                "text-success": details?.total?.storePnl > 0,
                                "text-danger": details?.total?.storePnl < 0,
                                "text-primary": details?.total?.storePnl == 0,
                              })}
                            >
                              <Amount
                                value={details?.total?.storePnl}
                                decimalPlace={2}
                              />
                            </span>
                          </KeyVal>
                        </div>
                        <div className="col-6 text-center">
                          <KeyVal label="SK PNL" template="col">
                            <span
                              className={classNames({
                                "text-success": details?.total?.skPnl > 0,
                                "text-danger": details?.total?.skPnl < 0,
                                "text-primary": details?.total?.skPnl == 0,
                              })}
                            >
                              <Amount
                                value={details?.total?.skPnl}
                                decimalPlace={2}
                              />
                            </span>
                          </KeyVal>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </>
            ) : (
              <>
                {details.data?.mainDeal?.length > 0 &&
                details.type != "CARTOFFER" ? (
                  <>
                    {details.data?.mainDeal.map((e, index) => (
                      <tr className="fs-val-md" key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>
                          {e?.value?.name}-{e?.value?._id}
                          <div className="mt-2 text-primary fs-val-sm">
                            Main Deal
                          </div>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-6 text-center">
                              <KeyVal label="Store PNL" template="col">
                                <span
                                  className={classNames({
                                    "text-success": e?._storePnl > 0,
                                    "text-danger": e?._storePnl < 0,
                                    "text-primary": e?._storePnl == 0,
                                  })}
                                >
                                  <Amount
                                    value={e._storePnl}
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>
                            <div className="col-6 text-center">
                              <KeyVal label="SK PNL" template="col">
                                <span
                                  className={classNames({
                                    "text-success": e?._skPnl > 0,
                                    "text-danger": e?._skPnl < 0,
                                    "text-primary": e?._skPnl == 0,
                                  })}
                                >
                                  {" "}
                                  <Amount value={e?._skPnl} decimalPlace={2} />
                                </span>
                              </KeyVal>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : null}

                {details.data?.offerDeals?.length > 0 &&
                  details.type != "CARTOFFER" &&
                  details.data?.offerDeals.map((e, index) => (
                    <tr className="fs-val-md" key={index}>
                      {/* <td>{index + 1}</td> */}
                      <td>
                        {e?.name}-{e?.dealId}
                        <div className="mt-2 text-primary fs-val-sm">
                          Main Deals
                        </div>
                      </td>
                      <td>
                        <div className="row">
                          <div className="col-6 text-center">
                            <KeyVal label="Store PNL" template="col">
                              <span
                                className={classNames({
                                  "text-success": e?._storePnl > 0,
                                  "text-danger": e?._storePnl < 0,
                                  "text-primary": e?._storePnl == 0,
                                })}
                              >
                                <Amount value={e._storePnl} decimalPlace={2} />
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-6 text-center">
                            <KeyVal label="SK PNL" template="col">
                              <span
                                className={classNames({
                                  "text-success": e?._skPnl > 0,
                                  "text-danger": e?._skPnl < 0,
                                  "text-primary": e?._skPnl == 0,
                                })}
                              >
                                {" "}
                                <Amount value={e?._skPnl} decimalPlace={2} />
                              </span>
                            </KeyVal>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                {details.data?.productList?.length > 0 &&
                  details.data?.productList.map((e, index) => (
                    <tr className="fs-val-md" key={index}>
                      {/* <td>{index + 1}</td> */}
                      <td>
                        {e?.name}-{e?.dealId}
                        <div className="mt-2 text-primary fs-val-sm">
                          Offer Deal
                        </div>
                      </td>
                      <td>
                        <div className="row">
                          <div className="col-6 text-center">
                            <KeyVal label="Store PNL" template="col">
                              <span
                                className={classNames({
                                  "text-success": e?._storePnl > 0,
                                  "text-danger": e?._storePnl < 0,
                                  "text-primary": e?._storePnl == 0,
                                })}
                              >
                                <Amount value={e._storePnl} decimalPlace={2} />
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-6 text-center">
                            <KeyVal label="SK PNL" template="col">
                              <span
                                className={classNames({
                                  "text-success": e?._skPnl > 0,
                                  "text-danger": e?._skPnl < 0,
                                  "text-primary": e?._skPnl == 0,
                                })}
                              >
                                {" "}
                                <Amount value={e?._skPnl} decimalPlace={2} />
                              </span>
                            </KeyVal>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn  btn-outline-danger me-3"
          onClick={triggerCloseModal}
        >
          Cancel
        </button>
        <button className="btn  btn-primary fw-semibold" onClick={onSubmit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewStoreOfferModal;
