import { EntitySearchInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

const searchTypeDropdownOptions = [
  {
    label: "Deal",
    value: "deal",
    inputLabel: "Search by Deal Name/ID ",
  },
  {
    label: "Category",
    value: "category",
    inputLabel: "Search by Category Name/ID",
  },
  { label: "Brand", value: "brand", inputLabel: "Search by Brand Name/ID" },
];

const FilterForm = ({ callback, openAdvFilterModal }) => {
  const { register, setValue, getValues, control } = useFormContext();

  const [searchType, setSearchType] = useState(searchTypeDropdownOptions[0]);

  const onSearch = useCallback(
    debounce(() => {
      const formValues = getValues();
      if (formValues.franchise?.length) {
        setValue("groupType", "");
      }
      callback({ formData: getValues() });
    }, 700),
    [getValues, callback]
  );

  const getSearchType = (x) => {
    setSearchType(x);
    ["brand", "category", "franchise", "deal"].forEach((x) => {
      setValue(x, "");
    });
  };

  const getSearchByGroupContent = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="none">
          {searchType.label} <i className="bi bi-chevron-down"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {searchTypeDropdownOptions.map((x) => (
            <Dropdown.Item key={x.value} onClick={() => getSearchType(x)}>
              {x.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="row">
      <div className="col-5">
        {/* <TextInput
          name={searchType.value}
          register={register}
          callback={onSearch}
          placeholder={searchType.inputLabel}
          // groupContent={getSearchByGroupContent()}
        /> */}
        <Controller
          control={control}
          name="deal"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="deal"
              label="Search By Deal"
              placeholder="Search by Name/ID"
              value={value}
              callback={onChange}
              uid="deal"
              emptyLabel="Deal Not Found"
            />
          )}
        />
      </div>
      {/* Filter Button */}
      <div className="col-auto pt-1">
        <button
          className="btn app-filter-btn mt-4"
          type="button"
          onClick={openAdvFilterModal}
        >
          <i className="bi bi-funnel"></i> FILTER
        </button>
      </div>
    </div>
  );
};

export default memo(FilterForm, (prev, next) => {
  return prev.isDirty === next.isDirty;
});
