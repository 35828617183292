import { NotificationService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  KeyVal,
  Rbac,
  Spinner,
  TableHeader,
} from "@sk/uis";
import { produce } from "immer";
import { useCallback, useEffect, useState } from "react";
import CustomerDetailsListModal from "../modals/CustomerDetailsListModal";
import ViewChannelLogsModal from "../modals/ViewChannelLogsModal";
import classNames from "classnames";

const tableHeaders = [
  {
    label: "",
    isCentered: true,
  },
  {
    label: "Last 24 hrs",
    isCentered: true,
  },
  {
    label: "Last 7 Days",
    isCentered: true,
  },
  {
    label: "Last 30 Days",
    isCentered: true,
  },
  {
    label: "Last 3 Months",
    isCentered: true,
  },
];

const list = [
  { label: "Sent" },
  { label: "Delivered" },
  { label: "Failed" },
  // { label: "Customer Notified" },
  // { label: "Customer Visited" },
  // { label: "Customer Orders" },
];

const rbac = {
  viewMarketPromotion: ["ViewMarketingPromotion", "ViewBrand"],
  editMarketPromotion: ["EditMarketingPromotion", "ViewBrand"],
};

const TemplateDetails = ({ tempData }) => {
  const [data, setData] = useState([]);

  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState({
    show: false,
    data: {},
  });

  const [showViewChannelLogsModal, setShowViewChannelLogsModal] = useState({
    show: false,
    data: {},
  });

  useEffect(() => {
    if (tempData?.length) {
      let d = tempData.map((e) => {
        return { ...e, loading: true };
      });
      setData(d);
      tempData.forEach((e, i) => {
        loadTemplateById(e, i);
      });
    }
  }, []);

  const loadTemplateById = useCallback(async (e, i) => {
    const r = await NotificationService.getDetailsById(e.templateId);
    if (r.resp) {
      setData(
        produce((draft) => {
          draft[i].details = r.resp;
          draft[i].loading = false;
        })
      );
    }
  }, []);

  const viewCustomerList = () => {
    setShowCustomerDetailsModal({ show: true, data: {} });
  };

  const customerListModalCb = () => {
    setShowCustomerDetailsModal({ show: false, data: {} });
  };

  const viewChannelModal = () => {
    setShowViewChannelLogsModal({ show: true, data: data });
  };

  const viewChannelLogsModalCb = () => {
    setShowViewChannelLogsModal({ show: false, data: {} });
  };

  return (
    <>
      <div>
        {data?.map((x, k) => (
          <div key={k}>
            {x?.status ? (
              <div className="row mb-1">
                <div className="col-12 text-align-center mb-1">
                  <div className="text-primary fw-semibold fs-val-lg">
                    <i
                      className={classNames(
                        "bi text-success align-middle me-1 fs-val-xxl",
                        x.type == "WhatsApp" ? "bi-whatsapp" : "bi-envelope"
                      )}
                    ></i>
                    <span className="align-middle"> {x?.type} </span>
                  </div>
                </div>
                <div className="col-6">
                  <AppCard title="Template Details">
                    {x?.templateId ? (
                      <div style={{ height: "175px" }}>
                        <KeyVal label="ID">
                          :{" "}
                          <span className="fw-semibold ps-1">
                            {x?.templateId}{" "}
                          </span>
                        </KeyVal>
                        <KeyVal label="Name">
                          :{" "}
                          {x.loading ? (
                            <Spinner type="dots" />
                          ) : (
                            <span className="text-primary fw-semibold ps-1">
                              {x?.details?.name}
                            </span>
                          )}
                        </KeyVal>

                        <KeyVal label="Message">
                          <div className="d-flex" style={{ height: "42px" }}>
                            <div>: </div>
                            <div className="ps-2 three-lines-ellipsis">
                              {x?.details?.default?.body}
                            </div>
                          </div>
                        </KeyVal>

                        <KeyVal label="Last Updated On">
                          :{" "}
                          <span className="ms-1">
                            <DateFormatter date={x.details?.lastUpdated} />
                          </span>
                        </KeyVal>

                        {/* <div className="row pt-2">
                          <Rbac roles={rbac.viewMarketPromotion}>
                            <div className="col-auto ms-auto">
                              <button
                                className="btn btn-sm btn-outline-primary me-1 fs-val-xs mb-3 mx-1"
                                onClick={viewChannelModal}
                              >
                                View Logs
                              </button>
                            </div>
                          </Rbac>
                        </div> */}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </AppCard>
                </div>
                <div className="col-6">
                  <AppCard title="Notification Details">
                    {x?.templateId ? (
                      <>
                        <table className="table table-bordered">
                          <TableHeader data={tableHeaders} />
                          <tbody className="fs-val-sm">
                            {list.map((x, k) => (
                              <tr key={k}>
                                <td
                                  className={classNames({
                                    "text-center": true,
                                    "text-primary":
                                      [
                                        "Customer Notified",
                                        "Customer Visited",
                                        "Customer Orders",
                                      ].indexOf(x?.label) != -1,
                                  })}
                                >
                                  {x?.label || ""}
                                </td>
                                <td className="text-center">
                                  0
                                  {[
                                    "Customer Notified",
                                    "Customer Visited",
                                  ].indexOf(x?.label) != -1 ? (
                                    <span
                                      className="text-primary ps-1 cursor-pointer fs-val-xs text-decoration-underline"
                                      onClick={viewCustomerList}
                                    >
                                      View
                                    </span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  0
                                  {[
                                    "Customer Notified",
                                    "Customer Visited",
                                  ].indexOf(x?.label) != -1 ? (
                                    <span
                                      className="text-primary ps-1 cursor-pointer  fs-val-xs text-decoration-underline"
                                      onClick={viewCustomerList}
                                    >
                                      View
                                    </span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  0
                                  {[
                                    "Customer Notified",
                                    "Customer Visited",
                                  ].indexOf(x?.label) != -1 ? (
                                    <span
                                      className="text-primary ps-1 cursor-pointer  fs-val-xs  text-decoration-underline"
                                      onClick={viewCustomerList}
                                    >
                                      View
                                    </span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  0
                                  {[
                                    "Customer Notified",
                                    "Customer Visited",
                                  ].indexOf(x.label) != -1 ? (
                                    <span
                                      className="text-primary ps-1 cursor-pointer fs-val-xs text-decoration-underline"
                                      onClick={viewCustomerList}
                                    >
                                      View
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </AppCard>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <CustomerDetailsListModal
        show={showCustomerDetailsModal.show}
        data={showCustomerDetailsModal.data}
        callback={customerListModalCb}
      />

      <ViewChannelLogsModal
        show={showViewChannelLogsModal.show}
        data={showViewChannelLogsModal.data}
        callback={viewChannelLogsModalCb}
      />
    </>
  );
};

export default TemplateDetails;
