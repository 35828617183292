import { useAttachAdditionalData } from "@sk/hooks";
import { BannerService, CommonService } from "@sk/services";
import {
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  Rbac,
  SelectInput,
  SummaryCard,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import produce from "immer";
import { debounce, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Table from "./components/Table";
import listView from "./constantService";
import AnalyticsModal from "./modals/AnalyticsModal";
import AuditLogModal from "./modals/AuditLogModal";
import BannerDesignAdvanceFilterModal from "./modals/BannerDesignAdvanceFilterModal";
import ManageBannerDesignMasterModal from "./modals/ManageBannerDesignMasterModal";
import ViewBannerDesignMasterDetailsModal from "./modals/ViewBannerDesignMasterDetailsModal";

const breadcrumb = listView.breadcrumb;

const additionDataConfig = listView.additionDataConfig;

const summaryDefaultData = listView.summaryDefaultData;

const rbac = {
  add: ["AddBannerDesignMaster"],
};

const defaultSortOpt = {
  key: "bannerClicks",
  value: "desc",
};

const prepareFilterParams = (filter = {}, pagination = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    sort: {},
    //   bannerClicks: -1,
    //   bannersCount: 1,
    // },
  };

  const s = (filter.search || "").trim();

  const r = { $regex: s, $options: "gi" };
  if (s) {
    p.filter.$or = [{ assetTitle: r }, { tags: r }, { _id: r }];
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter?.dateRange?.length) {
    p.filter["createdAt"] = {
      $gte: set(filter.dateRange[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };
    p.filter["createdAt"] = {
      $lte: set(filter.dateRange[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter?.width?.length && filter?.width?.trim()?.length) {
    p.filter["assetAttributes.widthPxl"] = filter.width * 1;
  }

  if (filter?.height?.length && filter?.height?.trim()?.length) {
    p.filter["assetAttributes.heightPxl"] = filter.height * 1;
  }

  if (sort.key) {
    p.sort[sort.key] = sort.value == "desc" ? -1 : 1;
  }

  return p;
};

const BannerDesignMasterList = () => {
  const router = useNavigate();

  const { register, handleSubmit, reset, control, setValue, getValues } =
    useForm({
      defaultValues: listView.filterData,
    });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageModal, setManageModal] = useState({ show: false, data: "" });

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const [analyticsModal, setAnalyticsModal] = useState({
    show: false,
    data: {},
  });

  const [auditLogsModal, setAuditLogsModal] = useState({
    show: false,
    data: {},
  });

  const [detailsModal, setDetailsModal] = useState({
    show: false,
    data: {},
  });

  const [summaryData, setSummaryData] = useState([...summaryDefaultData]);

  const paginationRef = useRef({ ...listView.pagination });

  const sortRef = useRef({ ...defaultSortOpt });

  const filterDataRef = useRef({
    search: "",
    status: "Active",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;

    loadList();
    // for total records
    setLoadingTotalRecords(true);
    let p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    const c = await getCount(p);
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);

    prepareFilterLabels();
  }, [loadList, prepareFilterLabels]);

  const init = useCallback(async () => {
    applyFilter();
    loadSummary();
  }, [applyFilter, loadSummary]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    let p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    const r = await getData(p);
    const d = r.resp || [];

    let tmp = [];
    setAdditionalData(d, additionDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const loadSummary = useCallback(async () => {
    setSummaryData(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = false;
        });
      })
    );

    const filter = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    delete filter.page;
    delete filter.count;

    let pr = [];
    summaryDefaultData.forEach((x) => {
      // let p = merge({}, filter, { filter: x.filter });
      pr.push(getCount({ filter: x.filter }));
    });

    const r = await Promise.all(pr);

    setSummaryData(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k]?.count || 0;
        });
      })
    );
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getData = (params) => {
    return BannerService.getBannerDesignMasterList(params);
  };

  const getCount = async (params) => {
    try {
      const r = await BannerService.getBannerDesignMasterListCount(params);
      return { count: r.resp || 0 };
    } catch (error) {
      return { count: 0 };
    }
  };

  const onCreate = () => {
    setManageModal({ show: true, data: "" });
  };

  const manageModalCb = useCallback(
    (e) => {
      setManageModal({ show: false, data: "" });
      if (e.status == "submitted") {
        init();
      }
    },
    [init]
  );

  const prepareFilterLabels = useCallback(() => {
    let v = { ...(filterDataRef.current || {}) };
    delete v.status;
    delete v.search;
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const searchCb = useCallback(
    debounce((e) => {
      const v = e.target.value;
      filterDataRef.current.search = v;
      applyFilter();
    }, 800),
    []
  );

  const editCb = (d) => {
    setManageModal({ show: true, data: d });
  };

  const viewCb = (d) => {
    setDetailsModal({ show: true, data: d });
  };

  const viewModalCb = (d) => {
    setDetailsModal({ show: false, data: {} });
  };

  const auditCb = (d) => {
    setAuditLogsModal({ show: true, data: [...d?.auditLog] || [] });
  };

  const auditLogsModalCb = () => {
    setAuditLogsModal({ show: false, data: {} });
  };

  const analyticsCb = (d) => {
    setAnalyticsModal({ show: true, data: d });
  };

  const analyticsModalCb = () => {
    setAnalyticsModal({ show: false, data: {} });
  };

  const statusCb = (e) => {
    filterDataRef.current.status = e;
    applyFilter();
  };

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // To Handle Advance Filter
  const advFilterCb = useCallback((data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    setShowAdvFilterModal(false);
  }, []);

  return (
    <>
      <div className="row my-2 align-items-center">
        <div className="col">
          <PageInfo
            title="Banner Master"
            breadcrumbs={breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <div className="p-1 mt-3">
        <div className="mb-2 row">
          {summaryData.map((e, k) => (
            <div className="col-2" key={k}>
              <SummaryCard
                value={e.value}
                title={e.label}
                loading={e.loading}
                valueColor={e.color}
                img={e.img}
                template={2}
              />
            </div>
          ))}
        </div>
        <div className="row mb-2 align-items-end">
          <div className="col-4">
            <TextInput
              name="search"
              callback={searchCb}
              label="Search By Banner ID / Title / Tags"
              register={register}
              placeholder="Enter Banners ID / Title / Tags Name Here"
            />
          </div>
          <div className="col-2">
            <SelectInput
              callback={statusCb}
              label="Status"
              name="status"
              options={listView.statusOptions}
              register={register}
            />
          </div>
          <div className="col-auto pb-3">
            <button
              className="btn app-filter-btn"
              type="button"
              onClick={openAdvFilterModal}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>

          <div className="col-auto ms-auto text-end">
            <Rbac roles={rbac.add}>
              <button className="btn btn-primary mb-3" onClick={onCreate}>
                <i className="bi bi-plus-square me-1"></i>
                Create
              </button>
            </Rbac>
          </div>
        </div>

        {/* Filter selected Label */}
        <div className="my-3">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editCb={editCb}
          viewCb={viewCb}
          auditCb={auditCb}
          analyticsCb={analyticsCb}
          sortCb={sortCb}
          sort={sortRef.current}
        />
        <ManageBannerDesignMasterModal
          show={manageModal.show}
          editId={manageModal.data}
          callback={manageModalCb}
        />
        <AnalyticsModal
          show={analyticsModal.show}
          editId={analyticsModal.data}
          callback={analyticsModalCb}
        />
        <AuditLogModal
          show={auditLogsModal.show}
          auditLog={auditLogsModal.data}
          callback={auditLogsModalCb}
        />
        <ViewBannerDesignMasterDetailsModal
          show={detailsModal.show}
          editId={detailsModal.data}
          callback={viewModalCb}
        />

        {/* Filter Modal*/}
        <BannerDesignAdvanceFilterModal
          callback={advFilterCb}
          show={showAdvFilterModal}
        />
      </div>

      <BusyLoader show={loadingData} />
    </>
  );
};

export default BannerDesignMasterList;
