import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  BusyLoader,
  EntitySearchInput,
  PageInfo,
  PaginationSummary,
  Rbac,
  SummaryCard,
  Tabs,
  TextInput,
  Toaster,
} from "@sk/uis";

import { useNavigate } from "react-router-dom";

import { useCallback, useEffect, useRef, useState } from "react";

import { CommonService, FranchiseService, OmsService } from "@sk/services";

import listView from "../constantService";

import { set } from "date-fns";

import { useAttachAdditionalData } from "@sk/hooks";
import produce from "immer";

import debounce from "lodash/debounce";

import merge from "lodash/merge";

import { Controller, useForm } from "react-hook-form";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import WidgetManageModal from "./modals/manage/WidgetManageModal";

import Table from "./components/Table";

import { MarketingDashboardNavComponent } from "@sk/features";

const breadcrumbs = listView.breadcrumb;

const defaultPagination = listView.pagination;

const defaultFilterData = listView.filterFromData;

const defaultSortOpt = { key: "priority", value: "asc" };

const franFilterParams = FranchiseService.getDarkstoreFilter();

const rbac = {
  addButton: ["WidgetCreate"],
};

const tabs = [
  {
    tabName: "All",
    key: "all",
  },
  {
    tabName: "Live",
    key: "live",
  },
  {
    tabName: "Upcoming",
    key: "upcoming",
  },
  {
    tabName: "Expired",
    key: "expired",
  },
  {
    tabName: "Inactive",
    key: "inactive",
  },
];

const getData = async (params) => {
  const r = await OmsService.getAppLayoutConfig(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  try {
    const r = await OmsService.getAppLayoutConfigCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  const search = d.search.trim();
  const searchRegex = { $regex: search, $options: "gi" };
  if (d.search) {
    p.filter.$or = [{ _id: searchRegex }, { title: searchRegex }];
  }

  if (d?.status) {
    p["filter"]["status"] = d.status;
  }

  if (d?.layout) {
    p.filter.layout = d.layout;
  }

  if (d?.page) {
    p.filter.page = d.page;
  }

  if (d?.feature) {
    if (d.feature == "category") {
      p.filter.feature = { $in: ["category", "parentCategory"] };
    } else {
      p.filter.feature = d.feature;
    }
  }

  if (d?.configType) {
    p.filter.configType = d.configType;
  }

  if (d?.bannerSlot && d?.feature == "banner") {
    p.filter.bannerId = d.bannerSlot;
  }

  if (d?.sortBy) {
    p.filter.sortBy = d.sortBy;
  }

  if (d.tab?.key == "inactive") {
    p.filter.status = "InActive";
  } else {
    p.filter.status = "Active";
  }

  if (/^[0-9]+$/gi.test(d?.priority)) {
    p.filter.priority = 1 * d.priority;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  if (d?.franchise?.length) {
    p.filter["franchise.id"] = d.franchise[0].value._id;
  }

  if (d.tab?.key == "live") {
    p.filter.validityStatus = "Running";
  }

  if (d.tab?.key == "upcoming") {
    p.filter.validityStatus = "Upcoming";
  }

  if (d.tab?.key == "expired") {
    p.filter.validityStatus = "Expired";
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter?.validity?.length == 2) {
    p.filter["validity.from"] = {
      $gte: set(filter.validity[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };
    p.filter["validity.till"] = {
      $lte: set(filter.validity[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const WidgetList = () => {
  const router = useNavigate();

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const { register, getValues, control, setValue } = useForm({
    defaultValues: defaultFilterData,
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageConfigId, setManageConfigId] = useState("");

  const [showManageModal, setShowManageModal] = useState(false);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFilterData, tab: tabs[0] });

  const advanceFilterRef = useRef({});

  const paginationRef = useRef({ ...defaultPagination });

  const manageModalRef = useRef({ mode: "add", id: "" });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const onSummaryCardClick = useCallback(
    (data) => {
      if (!data?.isClickable) {
        return;
      }
      let isActive = data.filter?.isActive ?? "";
      advanceFilterRef.current.status = String(isActive);

      filterDataRef.current = {
        ...filterDataRef.current,
        ...advanceFilterRef.current,
      };
      applyFilter();
    },
    [applyFilter]
  );

  const applyFilter = useCallback(
    async (summaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...defaultPagination };

      // To load Summary
      loadList();

      if (summaryRequired) {
        loadSummary();
      }

      // To load Count
      loadCount();

      prepareFilterLabels();
    },
    [loadCount, loadList, loadSummary, prepareFilterLabels]
  );

  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    delete filterParams.filter.status;
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSearch = debounce(() => {
    filterCb();
  }, 700);

  const filterCb = useCallback(() => {
    const values = getValues();
    filterDataRef.current = { ...filterDataRef.current, ...values };
    applyFilter();
  }, [applyFilter, getValues]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      loadList();
    },
    [loadList]
  );

  const openManageModal = (mode, id = "", editIndex = -1) => {
    manageModalRef.current.mode = mode;
    manageModalRef.current.id = id;
    manageModalRef.current.editIndex = editIndex;
    setShowManageModal(true);
  };

  const manageModalCb = useCallback(
    (payload) => {
      if (payload.action == "submitted") {
        init();

        if (manageModalRef.current.id) {
          setManageConfigId(manageModalRef.current.id);
        } else {
          setManageConfigId("");
        }

        const t = setTimeout(() => {
          setManageConfigId(() => "");
          clearTimeout(t);
        }, 0);
      }

      manageModalRef.current.mode = "add";
      manageModalRef.current.id = "";
      setShowManageModal(false);
    },
    [init]
  );

  const editCb = useCallback((id, index) => {
    openManageModal("edit", id, index);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "reset") {
        setValue("franchise", []);
        setValue("search", "");
      }

      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = {
          ...filterDataRef.current,
          ...getValues(),
          ...r.formData,
        };
        setShowAdvanceFilter(false);
        applyFilter(false);
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, getValues, setValue]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.franchise;
    delete v.id;
    delete v.tab;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }

    if (v.feature) {
      v.feature = v.feature.toUpperCase();
    }

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);

    l.forEach((x) => {
      if (x.key == "configType") {
        x.label = "Config Type";
        x.value = x.value.toUpperCase();
      }
    });

    setFilterLabels(l);
  }, []);

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Active",
      text: `Do you want to mark "${item._id}" config as Active, please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        status: "Active",
      };
      const r = await OmsService.updateAppLayoutConfigById(item._id, p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Inactive",
      text: `Do you want to mark "${item._id}" config as In-Active, please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        status: "InActive",
      };
      const r = await OmsService.updateAppLayoutConfigById(item._id, p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "InActive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  const onFranchiseChange = (chngFn) => (val) => {
    chngFn(val);
    filterCb();
  };

  const tabCb = (e) => {
    filterDataRef.current = { ...filterDataRef.current, tab: e.value };
    applyFilter();
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Widget Config"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      <div className="mb-4">
        <MarketingDashboardNavComponent
          activeTab="widget"
          router={router}
          subTypeKey="config"
        />
      </div>

      {/* Summary Cards  */}
      <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              data={e}
              template={2}
              isClickable={e.isClickable}
              onSummaryCardClick={onSummaryCardClick}
            />
          </div>
        ))}
        <div className="col-auto ms-auto align-self-center">
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-normal"
              onClick={() => openManageModal("add")}
            >
              <span className="bi bi-plus-square me-1"></span>
              Create Config
            </button>
          </Rbac>
        </div>
      </div>

      {/* Filter Block  */}
      <div className="mb-4">
        <AppCard>
          <div className="row align-items-center">
            <div className="col-10">
              <div className="row">
                <div className="col-3">
                  <TextInput
                    name="search"
                    register={register}
                    callback={onSearch}
                    placeholder="Search by Config ID/Title"
                    gap={0}
                  />
                </div>

                <div className="col-4">
                  <Controller
                    control={control}
                    name="franchise"
                    key="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        name="ids"
                        placeholder="Search for Smart Store  Id / Name"
                        value={value}
                        isMandatory={true}
                        callback={onFranchiseChange(onChange)}
                        uid="franchise-ids"
                        emptyLabel="No franchise found"
                        filterParams={franFilterParams}
                        gap={0}
                      />
                    )}
                  />
                </div>

                <div className="col-2">
                  <TextInput
                    name="priority"
                    type="number"
                    register={register}
                    callback={onSearch}
                    placeholder="Search by Priority"
                    gap={0}
                  />
                </div>

                <div className="col-auto">
                  <button
                    className="btn app-filter-btn"
                    type="button"
                    onClick={onAdvanceFilterClickCb}
                  >
                    <i className="bi bi-funnel"></i> FILTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AppCard>
      </div>

      {/* PAGINATION SUMMARY */}
      <AppCard noPad={true}>
        <Tabs
          data={tabs}
          activeTab={filterDataRef.current?.tab}
          callback={tabCb}
        />

        {/* Filter selected Label */}
        <div className="my-3 px-2">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>

        <div className="px-2 mb-1 fs-val-md">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editCb={editCb}
          markAsActive={markAsActive}
          markAsInActive={markAsInActive}
          managedConfigId={manageConfigId}
        />
      </AppCard>

      <WidgetManageModal
        show={showManageModal}
        callback={manageModalCb}
        mode={manageModalRef.current.mode}
        editId={manageModalRef.current.id}
      />

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default WidgetList;
