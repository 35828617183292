import { FranchiseService } from "@sk/services";
import {
  Amount,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Tabs,
  TextInput,
  Toaster,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const canvasStyle = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
  alertStyle: {
    zIndex: 2000,
  },
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Name", width: "35%" },
  { label: "Deal Id" },
  { label: "MRP" },
  { label: "RSP" },
  { label: "Stock Qty" },
  { label: "Reserve Qty" },
  { label: "Sales in Last 15 days", width: "7%" },
  { label: "Sales in Last 30 days", width: "7%" },
  { label: "Action" },
];

const tabsData = [
  {
    tabName: "Slow Moving",
    key: "slowMoving",
  },
  {
    tabName: "Non Moving",
    key: "nonMoving",
  },
];

const getData = async (params) => {
  const r = await FranchiseService.getFranchiseInventorySummary(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  params.showCount = true;

  try {
    const r = await FranchiseService.getFranchiseInventorySummary(params);
    return {
      count:
        r.statusCode == 200 && Array.isArray(r.resp) && r.resp.length
          ? r.resp[0].totalSku || 0
          : 0,
    };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { _id: filter.fid },
    groupbycond: "deal",
    groupbycondName: "name",
  };

  if (filter?.tab?.key == "nonMoving") {
    p.stockType = "unSoldDeals";
  } else {
    p.stockType = "slowMoving";
  }

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (searchKey) {
    p.dealFilter = {
      $or: [{ name: searchKey }, { _id: searchKey }],
    };
  }

  //   if (sort.key) {
  //     p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  //   }

  return p;
};

const defaultSortOpt = { key: "createdAt", value: "dec" };

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const MovableDealModal = ({ show, callback, fid }) => {
  const { register, getValues } = useForm({
    defaultValues: { name: "" },
  });

  const [data, setData] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [loadingData, setLoadingData] = useState(true);

  const [activeTab, setActiveTab] = useState(tabsData[0]);

  const filterDataRef = useRef({ name: "", fid: "" });

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    if (show && fid) {
      filterDataRef.current = {
        ...filterDataRef.current,
        fid: fid,
      };
      applyFilter();
    }
  }, [applyFilter, show, fid]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...defaultPagination };

    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  // Get Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSubmit = useCallback(
    (d) => {
      if (selectedData._id) {
        Toaster.error("Please Select Deal");
        return;
      }
      callback({ status: "submit", data: selectedData });
    },
    [callback, selectedData]
  );

  const triggerCloseModal = useCallback(() => {
    callback({ status: "closed" });
  }, [callback]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = { ...filterDataRef.current, ...getValues() };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  const onSelect = (d) => {
    // setSelectedData(d);
    callback({ action: "selected", data: { deal: d } });
  };

  const onDeselect = (d) => {
    setSelectedData({ d });
  };

  const tabCb = (e) => {
    const t = e.value;
    filterDataRef.current = { ...filterDataRef.current, tab: t };
    setActiveTab(t);
    applyFilter();
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={triggerCloseModal}
      style={canvasStyle.offCanvasStyle}
      backdropClassName=""
    >
      <Offcanvas.Header
        closeButton
        className="bg-light"
        // style={canvasStyle.offCanvasHeaderStyle}
      >
        <Offcanvas.Title className="mb-0 pb-0">
          Choose Main Deal From Below{" "}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <Tabs data={tabsData} activeTab={activeTab} callback={tabCb} />
        <div className="p-4">
          {activeTab.key == "slowMoving" ? (
            <div className="text-primary fs-val-sm">
              List of products that have not been sold in 15 days
            </div>
          ) : null}

          {activeTab.key == "nonMoving" ? (
            <div className="text-primary fs-val-sm">
              List of products that have not been sold in 30 days
            </div>
          ) : null}

          <div className="row my-2">
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By  Deal Name /ID"
              />
            </div>
          </div>

          <div className="mb-3">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={loadingTotalRecords}
            />
          </div>

          <table className="table table-bordered bg-white">
            <TableHeader data={headers} />

            {/* Table Body */}
            <tbody>
              {/*  When Table is Loading  */}
              {loadingData && (
                <TableSkeletonLoader
                  rows={10}
                  cols={headers.length}
                  height={40}
                />
              )}

              {/* When No loading */}
              {!loadingData && !data.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Cash Deposit Found </NoDataFound>
                  </td>
                </tr>
              ) : null}
              {/* When Not Loading and Data is there  */}
              {!loadingData &&
                data.map((x, index) => (
                  <tr key={x._id} className="fs-val-md">
                    {/* Serial Number */}
                    <td className="text-center">
                      {paginationRef.current.startSlNo + index}
                    </td>
                    <td>
                      <div className="text-primary">{x.name || "N/A"}</div>
                      <div className="row mt-2">
                        <div className="col-4">
                          <KeyVal
                            label="Menu"
                            template="col"
                            className="fs-val-xs"
                          >
                            <span
                              className="fs-val-xs"
                              title={x?.menuName + " - " + x?.menuId}
                            >
                              {x?.menuName}
                            </span>
                          </KeyVal>
                        </div>

                        <div className="col-4">
                          <KeyVal
                            label="Brand"
                            template="col"
                            className="fs-val-xs"
                          >
                            <span
                              className="fs-val-xs"
                              title={x?.brandName + " - " + x?.brandId}
                            >
                              {x?.brandName}
                            </span>
                          </KeyVal>
                        </div>

                        <div className="col-4">
                          <KeyVal
                            label="Category"
                            template="col"
                            className="fs-val-xs"
                          >
                            <span
                              className="fs-val-xs"
                              title={x?.categoryName + " - " + x?.categoryId}
                            >
                              {x?.categoryName}
                            </span>
                          </KeyVal>
                        </div>
                      </div>
                    </td>
                    <td>{x?._id}</td>
                    <td>
                      <Amount value={x.mrp} decimalPlace={2} />
                    </td>
                    <td>
                      <Amount value={x.retailerSellingPrice} decimalPlace={2} />
                    </td>

                    <td>
                      {x?._qty || 0} {x?._displayUnitKey}
                    </td>

                    <td>
                      {x?.reserveConfig?._available || 0} {x?._displayUnitKey}
                    </td>

                    <td>
                      {x.salesData?.fifteenday?._qty || 0} {x._displayUnitKey}
                    </td>
                    <td>
                      {x.salesData?.thirtyday?._qty || 0} {x._displayUnitKey}
                    </td>
                    <td>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          onSelect(x);
                        }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* Pagination Block */}
          <PaginationBlock
            paginationConfig={paginationRef.current}
            paginationCb={paginationCb}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
      </Offcanvas.Body>
      {/* <Modal.Footer>
        <button className="btn btn-sm btn-primary" onClick={onSubmit}>
          Submit
        </button>
      </Modal.Footer> */}
    </Offcanvas>
  );
};

export default memo(MovableDealModal);
