import { NotificationService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { memo, useEffect, useState } from "react";

const PromotionSummaryCard = ({
  label,
  filterParams,
  color,
  img,
  type,
  callback,
}) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    loadCount(filterParams);
  }, [filterParams]);

  const loadCount = async (filter) => {
    setLoading(true);
    const r = await NotificationService.getPromotionTemplateListCount({
      filter,
    });
    setValue(!isNaN(Number(r?.resp)) ? r?.resp : 0);
    setLoading(false);
  };

  return (
    <div
      onClick={() => {
        callback(type);
      }}
    >
      <SummaryCard
        value={value}
        title={label}
        loading={loading}
        valueColor={color}
        img={img}
        template={2}
      />
    </div>
  );
};

export default memo(PromotionSummaryCard);
