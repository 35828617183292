import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Rbac,
  SummaryCard,
  TextInput,
  Toaster,
} from "@sk/uis";

import { useNavigate } from "react-router-dom";

import { useCallback, useEffect, useRef, useState } from "react";

import {
  CommonService,
  CustomerService,
  NavService,
  appConfigs,
} from "@sk/services";

import listView from "../constantService";

import { set } from "date-fns";

import { useAttachAdditionalData } from "@sk/hooks";
import produce from "immer";

import debounce from "lodash/debounce";

import merge from "lodash/merge";

import { useForm } from "react-hook-form";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import Table from "./components/Table";

import SmartCoinRewardManageModal from "./modals/SmartCoinRewardManageModal";
const breadcrumbs = listView.breadcrumb;

const defaultPagination = listView.pagination;

const defaultFilterData = listView.filterFromData;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const smartCoinSubTabs = [...appConfigs.MARKETDASHABOARD.smartCoinSubTabs];

const rbac = {
  addButton: ["AddLuckyDraw"],
};

const getData = async (params) => {
  const r = await CustomerService.getSmartCoinRewardList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return {
          ...x,
          franchiseLoading: true,
          userLoading: true,
          modifiedLoading: true,
        };
      })
    : [];
};

const getCount = async (params) => {
  try {
    const r = await CustomerService.getSmartCoinRewardCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  const id = d.id.trim();
  const idRegex = { $regex: id, $options: "gi" };
  if (d.id) {
    p.filter.$or = [{ _id: idRegex }, { couponCode: idRegex }];
  }
  if (d.status) {
    p["filter"]["status"] = d.status;
  }

  if (d.type) {
    p.filter.type = d.type;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  if (d.franchise.length) {
    p.filter.franchiseId = d.franchise[0].value._id;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter?.validityDate?.length) {
    p.filter["validityDate.from"] = {
      $gte: set(filter.validityDate[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };

    p.filter["validityDate.to"] = {
      $lte: set(filter.validityDate[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const SmartCoinRewardList = () => {
  const router = useNavigate();

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const { register, getValues, setValue } = useForm({
    defaultValues: defaultFilterData,
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showManageModal, setShowManageModal] = useState(false);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFilterData });

  const advanceFilterRef = useRef({});

  const paginationRef = useRef({ ...defaultPagination });

  const manageModalRef = useRef({ mode: "add", id: "" });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const applyFilter = useCallback(
    async (summaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...defaultPagination };

      // To load Summary
      loadList();

      if (summaryRequired) {
        loadSummary();
      }

      // To load Count
      loadCount();

      prepareFilterLabels();
    },
    [loadCount, loadList, loadSummary, prepareFilterLabels]
  );

  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);
    d.map((x) => {
      x._displayRuleType = listView.getRuleType(x.type);
      return x;
    });

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSearch = debounce(() => {
    filterCb();
  }, 700);

  const filterCb = useCallback(() => {
    const values = getValues();
    filterDataRef.current = { ...filterDataRef.current, ...values };
    applyFilter();
  }, [applyFilter, getValues]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );
  const onFranchiseChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      filterCb();
    },
    [filterCb]
  );

  const openManageModal = (mode, id = "") => {
    manageModalRef.current.mode = mode;
    manageModalRef.current.id = id;
    setShowManageModal(true);
  };

  const manageModalCb = useCallback(
    (payload) => {
      if (payload.status == "submit") {
        init();
      }
      manageModalRef.current.mode = "add";
      manageModalRef.current.id = "";
      setShowManageModal(false);
    },
    [init]
  );

  const smartCoinRewardEditCb = useCallback((id) => {
    openManageModal("edit", id);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...getValues(), ...r.formData };
        if (status == "reset") {
          setValue("id", "");
          filterDataRef.current.id = "";
        }
        setShowAdvanceFilter(false);
        applyFilter(false);
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, getValues]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.franchise;
    delete v.id;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Active",
      text: `Do you want to mark config as Active for "${item._id}", please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        isActive: true,
        status: "Active",
      };
      const r = await CustomerService.updateSmartCoinReward(item._id, p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message?.[0] || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].isActive = true;
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Inactive",
      text: `Do you want to mark config as In-Active for "${item._id}", please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        isActive: false,
        status: "Inactive",
      };
      const r = await CustomerService.updateSmartCoinReward(item._id, p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message?.[0] || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].isActive = false;
            draft[i].status = "Inactive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  const subTabCb = (d) => {
    NavService.to(router, d.value.navTo, {
      tab: d.value.tab,
      subType: d.value.key,
    });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Manage StoreKing Coins Reward"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
        <div className="col-auto">
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-normal me-1"
              onClick={() => openManageModal("add")}
            >
              Create Config
            </button>
          </Rbac>
        </div>
      </div>

      <div className="row">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <div className="row">
          <div className="col-4">
            <TextInput
              name="id"
              register={register}
              callback={onSearch}
              placeholder="Search by Config ID /Coupon Code"
              gap={0}
            />
          </div>
          <div className="col-auto">
            <button
              className="btn app-filter-btn"
              type="button"
              onClick={onAdvanceFilterClickCb}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>
        </div>
      </AppCard>

      {/* Filter selected Label */}
      <AppCard>
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}

        {/* PAGINATION SUMMARY */}
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          editCb={smartCoinRewardEditCb}
          markAsActive={markAsActive}
          markAsInActive={markAsInActive}
        />

        <SmartCoinRewardManageModal
          show={showManageModal}
          callback={manageModalCb}
          mode={manageModalRef.current.mode}
          id={manageModalRef.current.id}
        />

        {/* ADVANCE FILTER */}
        <AdvanceFilterModal
          show={showAdvanceFilter}
          callback={onAdvanceFilterCb}
          formData={advanceFilterRef.current}
        />
      </AppCard>
    </>
  );
};

export default SmartCoinRewardList;
