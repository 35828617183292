import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput, Toaster } from "@sk/uis";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  kn: yup.string().required().trim().label("Kannada"),
  hi: yup.string().required().label("Hindi"),
});

const defaultFormData = {
  kn: "",
  hi: "",
};

const LangModal = ({ show = false, callback, title, type, data = {} }) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (show) {
      setValue("kn", data.kn || "");
      setValue("hi", data.hi || "");
    }
  }, [data.hi, data.kn, setValue, show]);

  const save = () => {
    callback({ action: "submit", data: { form: getValues(), type } });
    Toaster.success("Added successfully");
  };

  return (
    <Modal show={show} onHide={callback}>
      <Modal.Header closeButton={true}>
        <Modal.Title className="mb-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextInput
            register={register}
            name="kn"
            label="Kannada"
            placeholder="Enter in Kannada"
            isMandatory={true}
            error={errors?.kn?.message}
          />
        </div>
        <div>
          <TextInput
            register={register}
            name="hi"
            label="Hindi"
            placeholder="Enter in Hindi"
            isMandatory={true}
            error={errors?.hi?.message}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={handleSubmit(save)}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LangModal;
