import { useAttachAdditionalData } from "@sk/hooks";
import { NavService } from "@sk/services";
import {
  Amount,
  HighlightText,
  KeyVal,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import StockDisplay from "../components/StockDisplay";

const tableHeader = [
  { label: "Name", width: "30%" },
  { label: "ID" },
  // { label: "MRP", isCentered: true },
  // { label: "RSP", isCentered: true },
  { label: "Qty", isCentered: true },
  { label: "Pack Size", isCentered: true, width: "auto" },
  { label: "Discount", isCentered: true, width: "15%" },
  { label: "Deal Details", isCentered: true },
  // { label: "Reserve Qty", isCentered: true },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const getDefaultPaginationConfig = (totalRecords) => {
  return {
    ...defaultPagination,
    totalRecords,
  };
};

const RewardProductTable = ({
  dealList = [],
  mode = "add",
  deleteCb,
  disableStartDate = true,
  type = "deal",
  fid,
}) => {
  const [data, setData] = useState([]);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const paginationRef = useRef(getDefaultPaginationConfig(data.length));

  const fidRef = useRef("");

  const header = useMemo(() => {
    let list = [{ label: "Sl.no", isCentered: true }];
    if (["category", "category-brand"].indexOf(type) != -1) {
      list = [...list, { label: "Category ", width: "40%" }];
    }
    if (["brand", "category-brand"].indexOf(type) != -1) {
      list = [...list, { label: "Brand", width: "40%" }];
    } else if (type == "deal") {
      list = [...list, ...tableHeader];
    }
    if (["brand", "category-brand", "category"].indexOf(type) != -1) {
      list = [...list, { label: "MRP", width: "30%", isCentered: true }];
    }
    if (mode == "add" || !disableStartDate) {
      list.push({ label: "Actions", isCentered: true });
    }
    return list;
  }, [type, mode, disableStartDate]);

  useEffect(() => {
    if (fid) {
      fidRef.current = fid || "";
      paginationRef.current = getDefaultPaginationConfig(dealList.length);
      loadList();
    }
  }, [dealList, fid]);

  const loadList = useCallback(() => {
    const d = dealList.slice(
      paginationRef.current.startSlNo - 1,
      paginationRef.current.endSlNo
    );
    setData(d);
  }, [dealList]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      loadList();
    },
    [loadList]
  );

  return (
    <>
      <table className="table table-bordered table-sm px-4 mx-0">
        <TableHeader data={header} />
        <tbody>
          {data.length == 0 && (
            <tr>
              <td colSpan={header?.length} className="text-center py-2">
                No data found
              </td>
            </tr>
          )}

          {data.length > 0 &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-md">
                <td className="text-center">
                  {paginationRef.current.startSlNo + i}
                </td>
                {/* Deal */}
                {type == "deal" ? (
                  <>
                    <td>
                      <div
                        className="fw-semibold fs-val-sm cursor-pointer text-primary"
                        onClick={() => {
                          NavService.viewDeal({
                            id: x.offerDeals?.[0]?.dealId,
                          });
                        }}
                      >
                        {x?.name}
                      </div>
                    </td>
                    <td>
                      <div
                        className="fw-semibold fs-val-sm cursor-pointer text-primary"
                        onClick={() => {
                          NavService.viewDeal({
                            id: x.offerDeals?.[0]?.dealId,
                          });
                        }}
                      >
                        {x?.dealId}
                      </div>
                    </td>
                    {/* <td className="text-center">
                      <Amount value={x.mrp} decimalPlace={2} />
                    </td>
                    <td className="text-center">
                      <Amount value={x.price} decimalPlace={2} />
                    </td> */}
                    <td className="text-center">
                      {x.qty} {x?.unitType}
                    </td>
                    <td className="text-center">{x.packSize ?? "N/A"}</td>

                    <td>
                      <div className="text-center">
                        <HighlightText
                          status={x.isFree ? "Free" : null}
                          isBadge={true}
                        />
                      </div>
                      {!x.isFree && (
                        <>
                          <div className="row justify-content-between">
                            <div className="col-6">
                              <KeyVal label="Type" template="col">
                                {x.discountType}
                              </KeyVal>
                            </div>

                            <div className="col-6">
                              <KeyVal label="Value" template="col">
                                {x.discountType == "Fixed" && "₹"}{" "}
                                {x.discountValue}{" "}
                                {x.discountType == "Percentage" && "%"}
                              </KeyVal>
                            </div>
                          </div>
                        </>
                      )}
                    </td>

                    {/* Stock /Reserve Qty */}
                    <td className="text-center">
                      <StockDisplay
                        fid={fidRef.current}
                        type={2}
                        dealData={x}
                      />
                    </td>
                  </>
                ) : null}

                {/* Category */}
                {["category", "category-brand"].indexOf(type) != -1 ? (
                  <>
                    <td>
                      {x?.categoryName} - {x?.categoryId}
                    </td>
                  </>
                ) : null}

                {/* Brand */}
                {["brand", "category-brand"].indexOf(type) != -1 ? (
                  <>
                    <td>
                      {x?.brandName} - {x?.brandId}
                    </td>
                  </>
                ) : null}

                {/*  */}
                {["brand", "category-brand", "category"].indexOf(type) != -1 ? (
                  <>
                    <td className="text-center">
                      {!x.isFixedMrp ? (
                        <Amount value={x.mrpValue || 0} decimalPlace={2} />
                      ) : (
                        "Fixed MRP"
                      )}
                    </td>
                  </>
                ) : null}

                {(mode == "add" || !disableStartDate) && (
                  <td className="text-center">
                    <button
                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-danger border shadow-sm"
                      type="button"
                      onClick={() => deleteCb(x, i)}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </>
  );
};

export default memo(RewardProductTable);
