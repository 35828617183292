import { appConfigs } from "@sk/services";
import { FileUpload2, ImgRender } from "@sk/uis";

const bgImgStyle = { width: "320px" };

const iconImgStyle = { width: "120px" };

const ImgUpload = ({ img, callback, type, text, mode = "create" }) => {
  const remove = () => {
    callback({ action: "remove" });
  };

  const uploadCb = (e) => {
    if (e.status == "success") {
      callback({ action: "upload", data: { id: e.id } });
    }
  };

  return (
    <div className="p-4">
      {img ? (
        <div
          className="position-relative border rounded p-1 mb-2"
          style={type == "icon" ? iconImgStyle : bgImgStyle}
        >
          <button
            onClick={remove}
            className="top-0 end-0 position-absolute btn fs-val-lg p-1 text-danger"
          >
            <i className="bi bi-x-circle"></i>
          </button>

          <ImgRender assetId={img} />
        </div>
      ) : null}
      {mode != "view" ? (
        <FileUpload2 url={appConfigs.ASSET_UPLOAD} callback={uploadCb}>
          <button className="btn btn-primary">{text}</button>
        </FileUpload2>
      ) : null}
    </div>
  );
};

export default ImgUpload;
