import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "35%",
};

const AnalyticsModal = ({ show, callback, editId = "" }) => {
  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">Analytics</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>Comming Soon..!</div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AnalyticsModal;
