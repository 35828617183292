import { FranchiseService } from "@sk/services";
import { EntitySearchInput, NoDataFound, TableHeader } from "@sk/uis";
import { merge } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const headers = [
  { label: "S.No", width: "10%" },
  { label: "Franchise ID", width: "20%" },
  { label: "Franchise Name", width: "40%" },
  { label: "Location", width: "40%" },
  { label: "Actions", width: "20%" },
];

const franchiseFilter = merge(
  {},
  {
    select: "name,district,state",
  },
  FranchiseService.getDarkstoreFilter()
);

const StoreExclude = ({ callback, value = [] }) => {
  const { control } = useForm();

  const onFranchiseCb = useCallback(
    (onChange) => (val) => {
      onChange([]);
      callback({ action: "add", data: val?.[0]?.value || {} });
    },
    [callback]
  );

  const handleRemove = (franchise) => {
    callback({ action: "remove", data: franchise });
  };

  return (
    <div className="p-3">
      <div className="mb-3">
        <Controller
          name="franchise"
          control={control}
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="franchise"
              label="Search Store"
              value={value}
              callback={onFranchiseCb(onChange)}
              placeholder="Search by franchise name"
              filterParams={franchiseFilter}
            />
          )}
        />
      </div>

      <table className="table table-sm table-striped">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {!value.length ? (
            <tr>
              <td colSpan={headers.length} className="text-center">
                <NoDataFound>No stores excluded</NoDataFound>
              </td>
            </tr>
          ) : (
            value.map((franchise, index) => (
              <tr key={franchise._id}>
                <td>{index + 1}</td>
                <td>{franchise.id}</td>
                <td>{franchise.name}</td>
                <td>{`${franchise.district}, ${franchise.state}`}</td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleRemove(franchise)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StoreExclude;
