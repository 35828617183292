const tooltips = {
  info: {
    title: "Title for Offer",
    desc: "Extra details Regarding offer",
    franchise: "For which Franchise you want to create Offer",
    offerCode: "Unique offer code to make difference for other",
    maxValue: "Max to value of Cart to get Offer",
    minValue: "Min Value of Cart to unlock offer",
    mainDeal: "For this product we are giving reward",
    qty: "How many quantity customer need to buy", // main Deal Qty
    rewardValue: "Offering Discount By Percentage / Value",
    offerType: "Which type of offer you  want to configure",
    rewardType: "What type of reward you want to give",
    rewardTypeOffer: "It will tell how you want to give you reward",
    // Manage Reward Product Form thing
    productQty: "How much Qty you want to give as reward", // Product Qty
    isFree: "If you want to give product for free enable this",
    product: "Product which you want to give as reward to customer",
    discountValue:
      "How much discount you want to give to that specific product",
    limitBy: "Configure max offer limit by Quantity / Deal Value",
    limitValue: "Configure max offer limit by Quantity / Deal Value",
  },
};

export default tooltips;
