import { FranchiseService } from "@sk/services";
import { Amount, KeyVal, Spinner, Rbac } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

const rbac = {
  viewSlc: ["ViewSkLandingCost"],
};

const StockDisplay = ({
  fid,
  displayMrp = false,
  displayRsp = false,
  type = 1,
  dealData,
  showOfferRsp = true,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const dealFormDataRef = useRef({});

  useEffect(() => {
    if (fid && dealData) {
      dealFormDataRef.current = dealData;
      loadPosStockDetails();
    }
  }, [loadPosStockDetails, fid, dealData]);

  const loadPosStockDetails = useCallback(async () => {
    let p = {
      groupbycond: "deal",
      groupbycondName: "name",
      dealFilter: { _id: { $in: [dealFormDataRef.current?.dealId] } },
      filter: { _id: fid },
    };
    setLoading(true);
    setData({});
    let m = await FranchiseService.getFranchiseInventorySummary(p);
    let d = Array.isArray(m.resp) && m.resp.length > 0 ? m.resp[0] : {};

    if (dealFormDataRef.current?.dealId) {
      let offDisc = 0;
      if (dealFormDataRef.current?.discountType == "Percentage") {
        offDisc =
          d.retailerSellingPrice *
          (1 - dealFormDataRef.current?.discountValue / 100);
      } else if (dealFormDataRef.current?.discountType == "Fixed") {
        offDisc =
          d.retailerSellingPrice - dealFormDataRef.current?.discountValue;
      } else if (dealFormDataRef.current?.isFree) {
        offDisc = 0;
      } else {
        offDisc = d?.retailerSellingPrice;
      }
      d._offerDiscount = offDisc;
      d._skOfferPnl = (offDisc - d.slc) * dealFormDataRef.current?.qty;
      d._storeOfferPnl = (offDisc - d.b2bprice) * dealFormDataRef.current?.qty;
    }
    setData(d);
    setLoading(false);
  }, [setData, setLoading]);

  return (
    <div>
      {type == 1 ? (
        <div className="row mt-2">
          <div className="col-6 text-start ">
            <KeyVal label="Stock Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?._qty || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
          <div className="col-5 text-start">
            <KeyVal label="Reserve Qty" template="col">
              {loading ? (
                <Spinner type="dots" />
              ) : (
                <span className="fw-semibold text-primary ps-1">
                  {data?.reserveConfig?._available || 0} {data?._displayUnitKey}
                </span>
              )}
            </KeyVal>
          </div>
        </div>
      ) : (
        <div className="row mt-2">
          <div className="col-auto p-1">
            <div className="row border rounded p-2 m-1">
              <div className="col-auto ">
                <KeyVal label="MRP" template="col">
                  {loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className="fw-semibold ps-1">
                      <Amount value={data?.mrp || 0} decimalPlace={2} />
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto ">
                <KeyVal label="RSP" template="col">
                  {loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className="fw-semibold ps-1">
                      <Amount
                        value={data?.retailerSellingPrice}
                        decimalPlace={2}
                      />
                    </span>
                  )}
                </KeyVal>
              </div>

              {showOfferRsp ? (
                <div className="col-auto ">
                  <KeyVal label="Offer RSP" template="col">
                    {loading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span className="fw-semibold text-primary ps-1">
                        <Amount value={data?._offerDiscount} decimalPlace={2} />
                      </span>
                    )}
                  </KeyVal>
                </div>
              ) : null}
            </div>
          </div>

          <Rbac roles={rbac.viewSlc}>
            <div className="col-auto p-1">
              <div className="row border rounded p-2 m-1">
                <div className="col-auto ">
                  <KeyVal label="Store Landing" template="col">
                    {loading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span className="fw-semibold text-primary ps-1">
                        <Amount value={data?.b2bprice} decimalPlace={2} />
                      </span>
                    )}
                  </KeyVal>
                </div>

                <div className="col-auto">
                  <KeyVal label="Store PNL" template="col">
                    {loading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span
                        className={classNames({
                          "fs-val-md": true,
                          "text-success": data?._storeOfferPnl > 0,
                          "text-danger": data?._storeOfferPnl < 0,
                          "text-primary": data?._storeOfferPnl == 0,
                        })}
                      >
                        <Amount value={data?._storeOfferPnl} decimalPlace={2} />
                      </span>
                    )}
                  </KeyVal>
                </div>
              </div>
            </div>

            <div className="col-auto p-1">
              <div className="row border rounded p-2 m-1">
                <div className="col-auto">
                  <KeyVal label="SK Landing Cost" template="col">
                    {loading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span className="text-primary">
                        <Amount value={data?.slc} decimalPlace={2} />
                      </span>
                    )}
                  </KeyVal>
                </div>

                <div className="col-auto">
                  <KeyVal label="SK PNL" template="col">
                    {loading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span
                        className={classNames({
                          "fs-val-md": true,
                          "text-success": data?._skOfferPnl > 0,
                          "text-danger": data?._skOfferPnl < 0,
                          "text-primary": data?._skOfferPnl == 0,
                        })}
                      >
                        <Amount value={data?._skOfferPnl} decimalPlace={2} />
                      </span>
                    )}
                  </KeyVal>
                </div>
              </div>
            </div>
          </Rbac>

          <div className="col-auto p-1">
            <div className="row border rounded p-2 m-1">
              <div className="col-auto">
                <KeyVal label="Stock Qty" template="col">
                  {loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className="fw-semibold ps-1">
                      {data?._qty || 0} {data?._displayUnitKey}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto ">
                <KeyVal label="Reserve Qty" template="col">
                  {loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className="fw-semibold  ps-1">
                      {data?.reserveConfig?._available || 0}{" "}
                      {data?._displayUnitKey}
                    </span>
                  )}
                </KeyVal>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(StockDisplay);
