import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import {
  CommonService,
  NavService,
  NotificationService,
  appConfigs,
} from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  Rbac,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import formView, { filterView, listView } from "../constantService";
import ManageMarketingPromotionModal from "../modals/manage-modal/ManageMarketingPromotionModal";
import ViewPromotionModal from "../modals/view/ViewPromotionModal";
import PromotionSummaryCard from "./components/PromotionSummaryCard";
import Table from "./components/Table";
import PromotionAdvanceFilterModal from "./modals/PromotionAdvanceFilterModal";

const defaultSort = { key: "createdAt", value: "desc" };

const tabs = [
  { tabName: "Regular", key: "Regular" },
  { tabName: "Inactive", key: "Inactive" },
  { tabName: "Campaign/Promotion", key: "Campaign" },
];

const rbac = {
  addButton: ["AddMarketingPromotion", "ViewBrand"],
};

let marketingSubTabs = [...appConfigs.MARKETDASHABOARD.marketingSubTabs];

const getData = async (params) => {
  const r = await NotificationService.getPromotionTemplateList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await NotificationService.getPromotionTemplateListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

function MarketingPromotionList() {
  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const { register, getValues, setValue } = useForm({
    defaultValues: { ...listView.filterFormData },
  });

  const [showManageModal, setShowManageModal] = useState({
    show: false,
    id: "",
    mode: "add",
  });

  const [data, setData] = useState([]);

  // For  List Data Loader
  const [loadingData, setLoadingData] = useState(true);

  // For Pagination Count Loader
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryCard, setSummaryCard] = useState([]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [showViewModal, setShowViewModal] = useState({ show: false, id: "" });

  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  const filterDataRef = useRef({ type: "" });

  const sortRef = useRef(defaultSort);

  const paginationRef = useRef({ ...listView.pagination });

  const router = useNavigate();

  const closeManageModal = useCallback(
    (e) => {
      setShowManageModal({ show: false, id: "", mode: "add" });
      if (e.status == "submit") {
        init();
      }
    },
    [init]
  );

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
    };
    if (query.search) {
      filterDataRef.current.name = query.search;
      setValue("name", query.search);
    }
    init();
  }, [init, query.search, setValue]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...listView.pagination };

    loadList();

    loadSummary();

    prepareFilterLabels();

    // for total Pagination records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList]);

  // List
  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);

    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    d.forEach((e) => {
      // To Set Exectue Time
      let t = e.executeTime || "";
      const a = [t.slice(0, 2), t.slice(3)];
      e._activeTemplates = e.notifcationTypes.filter((e) => {
        return e.status;
      });
      // set array values below
      const dt = set(new Date(), {
        hours: a[0] * 1,
        minutes: a[1] * 1,
      });
      e._executeTime = dt || e.executeTime;

      // Criteria Event Type
      let eveList = formView.eventOptions.filter((k) => {
        return e?.criteriaDetails?.eventType == k.value;
      });
      e._eventType = eveList.length ? eveList[0]?.label : "N/A";

      // For executeType display text
      if (e?.criteriaDetails?.executeType) {
        let s = filterView.criteriaExecuteTypeOptions.find(
          (k) => e.criteriaDetails.executeType === k.value
        );
        e._executeType = s.label;
      }

      // For executeAction display text
      if (e?.criteriaDetails?.executeAction) {
        let s = filterView.criteriaExecuteActionOptions.find(
          (k) => e.criteriaDetails.executeAction === k.value
        );
        e._executeAction = s.label;
      }

      // For customerstatus display text
      if (e?.criteriaDetails?.customerStatus) {
        let s = filterView.criteriaCustomerStatusOptions.find(
          (k) => e.criteriaDetails.customerStatus === k.value
        );
        e._customerstatus = s.label;
      }

      // notifcationTypes
      let n = e.notifcationTypes.filter((k) => {
        return k.status;
      });
      let list = n.map((k) => {
        return k.type;
      });
      e._notifcationTypes = list.join(", ");
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Summary
  const loadSummary = useCallback(async () => {
    let k = [...listView.summaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter) {
        delete p.filter?.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(k);
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;

    if (v.executeType) {
      let s = filterView.criteriaExecuteTypeOptions.find(
        (k) => v.executeType === k.value
      );
      v.executeType = s.label;
    }

    if (v.eventType) {
      let s = filterView.eventOptions.find((k) => v.eventType === k.value);
      v.eventType = s.label;
    }

    if (v.executeAction) {
      let s = filterView.criteriaExecuteActionOptions.find(
        (k) => v.executeAction === k.value
      );
      v.executeAction = s.label;
    }
    if (v.customerstatus) {
      let s = filterView.criteriaCustomerStatusOptions.find(
        (k) => v.customerstatus === k.value
      );
      v.customerstatus = s.label;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
    let p = {
      page: pagination.activePage,
      count: pagination.rowsPerPage,
      filter: {},
    };

    let d = filter || {};

    let searchKey = d.name?.length
      ? { $regex: d.name.trim(), $options: "i" }
      : "";

    if (searchKey) {
      p.filter = {
        $or: [{ title: searchKey }, { _id: searchKey }],
      };
    }

    if (d?.status?.length) {
      p.filter.isActive = d.status == "Active";
    }

    if (d?.createdAt?.length) {
      p.filter.createdAt = {
        $gte: set(filter.createdAt[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(filter.createdAt[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }
    if (d?.franchise?.length) {
      p.filter.franchiseId = d.franchise[0]?.value?._id || "";
    }

    if (d?.type) {
      p.filter.type = d.type;
    }

    if (d?.eventType) {
      p.filter["criteriaDetails.eventType"] = d.eventType;
    }

    if (d?.eventActionType) {
      p.filter["criteriaDetails.eventTypeAction"] = d.eventActionType;
    }

    if (d?.executeType) {
      p.filter["criteriaDetails.executeType"] = d.executeType;
    }

    if (d?.executeOption) {
      p.filter.executeOption = d.executeOption;
    }

    if (d?.executeUnit) {
      p.filter["criteriaDetails.executeUnit"] = d.executeUnit;
    }

    if (d?.executeAction) {
      p.filter["criteriaDetails.executeAction"] = d.executeAction;
    }

    if (d?.criteriaType) {
      p.filter["criteriaDetails.type"] = d.criteriaType;
    }

    if (d?.customerstatus) {
      p.filter["criteriaDetails.customerStatus"] = d.customerstatus;
    }

    return p;
  };

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  const summaryCardCb = useCallback((d) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      status: d,
    };
    applyFilter();
  }, []);

  // To Handle Advance Filter
  const advFilterCb = useCallback((data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    setShowAdvFilterModal(false);
  }, []);

  const editCb = useCallback((id) => {
    setShowManageModal({ show: true, id: id, mode: "edit" });
  }, []);

  const viewCb = useCallback((id) => {
    NavService.openInNewTab("/configs/marketing/promotion/view", {
      id: id,
    });
    // setShowViewModal({ show: true, id: id });
  }, []);

  const addPromotion = () => {
    setShowManageModal({ show: true, id: "", mode: "add" });
  };

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  const closeViewModal = useCallback(() => {
    setShowViewModal({ show: false, id: "" });
  }, []);

  const subTabCb = (d) => {
    NavService.to(router, d.value.navTo, {
      tab: d.value.tab,
      subType: d.value.key,
    });
  };

  // Tab Callback
  const tabCb = useCallback((data) => {
    setActiveTab({ ...data.value });
    setValue("type", data.value.key);
    filterDataRef.current = {
      ...filterDataRef.current,
      type: data.value.key,
    };
    applyFilter();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Message Config"
            breadcrumbs={listView.breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* <MarketingDashboardNavComponent
        activeTab="marketing"
        router={router}
        subTypeKey="config"
      /> */}

      {/* <div className="py-3 bg-white">
        <Tabs
          data={marketingSubTabs}
          callback={subTabCb}
          activeTab={{ key: "dashboard" }}
          template="2"
        />
      </div> */}

      <div className="mt-3">
        {/* SUMMARY OVERVIEW */}
        <div className="row mb-2">
          {summaryCard.map((card, i) => (
            <div className="col-2" key={i}>
              <PromotionSummaryCard
                label={card.label}
                color={card.color}
                filterParams={card.filterParams}
                img={card.img}
                callback={summaryCardCb}
                type={card.type}
              />
            </div>
          ))}
        </div>
        {/* Filter  Block  */}
        <AppCard>
          <div className="row align-items-center">
            <div className="col-6">
              <div className="row">
                {/* Name/ID Search Input */}
                <div className="col-8 align-self-center">
                  <TextInput
                    name="name"
                    type="text"
                    register={register}
                    callback={onSearch}
                    placeholder="Search By  Config Title /ID"
                    gap={0}
                  />
                </div>

                {/* Filter Button */}
                <div className="col-auto">
                  <button
                    className="btn app-filter-btn"
                    type="button"
                    onClick={openAdvFilterModal}
                  >
                    <i className="bi bi-funnel"></i> FILTER
                  </button>
                </div>
              </div>
            </div>

            {/* Add Promotion */}
            <div className="col-auto ms-auto align-self-center">
              <Rbac roles={rbac.addButton}>
                <button
                  className="btn btn-primary btn-sm fs-val-md me-1"
                  onClick={addPromotion}
                >
                  Add Message Config
                </button>
              </Rbac>
            </div>
          </div>
        </AppCard>

        {/* Filter selected Label */}

        <AppCard>
          {/* <Tabs data={tabs} callback={tabCb} activeTab={activeTab} /> */}
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}

          <div className="mt-2">
            <Table
              data={data}
              loading={loadingData}
              paginationConfig={paginationRef.current}
              paginationCb={paginationCb}
              loadingTotalRecords={loadingTotalRecords}
              editCb={editCb}
              viewCb={viewCb}
            />
          </div>
        </AppCard>
      </div>

      {/* Edit Modal */}
      <ManageMarketingPromotionModal
        show={showManageModal.show}
        closeModal={closeManageModal}
        pid={showManageModal.id}
        mode={showManageModal.mode}
      />

      {/* Filter Modal*/}
      <PromotionAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* View  Modal*/}
      <ViewPromotionModal
        show={showViewModal.show}
        pid={showViewModal.id}
        closeModal={closeViewModal}
      />
    </>
  );
}

export default MarketingPromotionList;
