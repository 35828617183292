import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "StoreKing Coins Reward",
    },
  ],
  summaryData: [
    {
      label: "Total Config",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Pending Config",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Active Config",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Config",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Expired Config",
      value: 0,
      loading: true,
      filter: { status: "Expired" },
      color: "danger",
      img: inactiveImg,
    },
  ],

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },

  filterFromData: {
    franchise: [],
    id: "",
  },

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created On",
      type: "dateRange",
    },
    validityDate: {
      label: "Offer Validity",
      type: "dateRange",
    },
    type: {
      label: "Rule Type",
    },
  },

  formKeys: {
    status: "status",
    type: "type",
    groupType: "groupType",
    smartStore: "smartStore",
    validFrom: "validFrom",
    validTo: "validTo",
    ruleType: "ruleType",
    minOrderValue: "minOrderValue",
    parentSmartReward: "parentSmartReward",
    childSmartReward: "childSmartReward",
    couponCode: "couponCode",
    customer: "customer",
    customersList: "customersList",
    orderLimit: "orderLimit",
  },

  ruleTypeInfo: {
    NoInviteFirstorder: "",
    DirectInviteFirstorder: "",
    IndirectInviteFirstorder: "",
    ParentCoinReward: "",
    ReferralCoupon: "",
  },
  getRuleType(type) {
    const ruleTypes = {
      NoInviteFirstorder: "Joining Bonus",
      DirectInviteFirstorder: "Refer a Friend with Link",
      IndirectInviteFirstorder: "Refer a Friend without Link",
      ParentCoinReward: "Reward for Child Order",
      ReferralCoupon: "Coupon Reward",
    };
    return ruleTypes[type];
  },
};

export default listView;
