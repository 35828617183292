import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import React from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID", width: "5%" },
  { label: "Deal ID" },
  { label: "Deal Name" },
  { label: "Per Order Limit" },
  { label: "Per Day Limit" },
  { label: "Per Month Limit" },
  { label: "Status" },
  { label: "Last Updated" },
  { label: "Action", width: "10%" },
];

const ClubDealRestrictTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) => {
  return (
    <>
      <PaginationSummary
        loadingTotalRecords={loadingTotalRecords}
        paginationConfig={paginationConfig}
      />
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}
          {(data || []).map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x._id}</td>
              <td>{x.dealId || "--"}</td>
              <td>{x.dealName || "--"}</td>
              <td>
                {x._perOrderLimit || 0} {x._unitType}
              </td>
              <td>
                {x._perDayLimit || 0} {x._unitType}
              </td>
              <td>
                {x._perMonthLimit || 0} {x._unitType}
              </td>
              <td>
                <HighlightText status={x.status}>{x.status}</HighlightText>
              </td>
              <td>
                <div>{x._modifiedBy?.name}</div>
                <div>
                  <DateFormatter date={x.lastUpdated} />
                </div>
              </td>
              <td>
                <button
                  className="btn btn-sm btn-outline-primary me-2"
                  onClick={() => callback({ action: "view", data: x })}
                >
                  View
                </button>
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => callback({ action: "edit", data: x })}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default ClubDealRestrictTable;
