import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import totalImg from "../../assets/imgs/total.svg";

const typeOptions = [
  {
    value: "Regular",
    label: "Regular",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
  {
    value: "Campaign",
    label: "Campaign",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const formView = {
  typOptions: [{ label: "Choose", value: "" }, ...typeOptions],
  criteriaTypeOptions: [
    { label: "Choose", value: "" },
    { label: "Reminder", value: "Reminder" },
    { label: "Store Event", value: "Store Event" },
    { label: "Promotion", value: "Promotion" },
  ],

  criteriaCustomerStatusOptions: [
    { label: "Choose", value: "" },
    { label: "Active Customers", value: "Active" },
    { label: "Inactive Customers", value: "Inactive" },
    { label: "All Customers", value: "Both" },
  ],

  criteriaExecuteTypeOptions: [
    { label: "Choose", value: "" },
    { label: "On Start Of", value: "Start Of" },
    { label: "On End Of", value: "End Of" },
    { label: "Specific Date", value: "Date" },
    { label: "Custom", value: "Custom" },
  ],

  criteriaExecuteUnitOptions: [
    {
      label: "Day",
      value: "Day",
    },
    {
      label: "Month",
      value: "Month",
    },
  ],

  criteriaExecuteActionOptions: [
    { label: "Choose ", value: "" },
    {
      label: "After the Criteria",
      value: "After",
    },
    {
      label: "Before the Criteria",
      value: "Before",
    },
    {
      label: "Exact the Criteria",
      value: "Exact",
    },
  ],

  criteriaEventTypeActionOptions: [
    { label: "Choose ", value: "" },
    { label: "Last Order", value: "Last Order" },
    { label: "Event", value: "Event" },
  ],

  notificationTypeOptions: [
    { label: "Choose", value: "" },
    { label: "Email", value: "Email" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WhatsApp" },
  ],

  executeOptions: [
    { label: "Choose", value: "" },
    { label: "Auto", value: "Auto" },
    { label: "Scheduled", value: "Scheduled" },
  ],

  eventOptions: [
    {
      label: "Choose",
      value: "",
      type: "",
    },
    {
      label: "Customer Order Placed But Not Visited (Revisit reminder)",
      value: "Not Visited",
      type: ["Inactive"],
    },
    {
      label: "Product Promotion",
      value: "Product Promotion",
      type: ["Inactive", "Campaign"],
    },
    {
      label: "Lucky Draw",
      value: "Lucky Draw",
      type: ["Campaign"],
    },
    {
      label: "Smart Coins Redeem",
      value: "SmartCoins Redeem",
      type: ["Campaign"],
    },
    {
      label: "Smart Coins Redeem Reminder",
      value: "Smart Coins Redeem Reminder",
      type: ["Inactive"],
    },
    {
      label: "Deal Offers",
      value: "Deal Offers",
      type: ["Campaign"],
    },
    {
      label: "Festive Offers",
      value: "Festive Offers",
      type: ["Campaign"],
    },
    {
      label: "Registration",
      value: "Registration",
      type: ["Regular"],
    },
    {
      label: "Order placed",
      value: "Order placed",
      type: ["Regular"],
    },
  ],

  tagline: {
    title: "Title for your Promotion",
    type: "which type Promotion you want to configure",
    franchise: "Select franchise, for which franchise your enabling",
    eventType: "Event Type",
    executeOption: "Execute Option",
    executeType: "Execute Type",
    executeTypeCustomValue: "Execute Type Custom Value(in Days)",
    executeUnit: "Execute Unit",
    executeAction: "Execute Action",
    executeTime: "Execute Time",
  },
};

export const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Marketing Message Config",
    },
  ],

  summaryData: [
    {
      label: "Total Configs",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
      type: "",
    },
    {
      label: "Active Configs",
      value: 0,
      loading: true,
      filter: { isActive: true },
      color: "success",
      img: activeImg,
      type: "Active",
    },
    {
      label: "Inactive Configs",
      value: 0,
      loading: true,
      filter: { isActive: false },
      color: "danger",
      img: inactiveImg,
      type: "Inactive",
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  },

  filterFormData: {
    type: "",
    createdAt: [],
    status: "",
    eventType: "",
    eventActionType: "",
    executeType: "",
    executeUnit: "",
    executeAction: "",
    criteriaType: "",
    customerstatus: "",
    franchise: [],
    executeOption: "",
  },

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedUserLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  formLabels: {
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    status: {
      label: "Status",
    },
    type: {
      label: "Type",
    },
    eventType: {
      label: "Event Type",
    },
    eventActionType: {
      label: "Event Action Type",
    },
    executeType: {
      label: "Execute Type",
    },
    executeUnit: {
      label: "Execute Unit",
    },
    executeAction: {
      label: "Execute Action",
    },
    criteriaType: {
      label: "Criteria Type",
    },
    customerstatus: {
      label: "Customer status",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    executeOption: {
      label: "Execute Option",
    },
  },
};
// List View End

export const filterView = {
  statusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],

  filterFormData: {
    createdAt: [],
    status: "",
    eventType: "",
    eventActionType: "",
    executeType: "",
    executeUnit: "",
    executeAction: "",
    criteriaType: "",
    customerstatus: "",
    franchise: [],
    executeOption: "",
  },

  typOptions: [{ label: "All", value: "" }, ...typeOptions],

  criteriaTypeOptions: [
    { label: "All", value: "" },
    { label: "Reminder", value: "Reminder" },
    { label: "Store Event", value: "Store Event" },
    { label: "Promotion", value: "Promotion" },
  ],

  criteriaCustomerStatusOptions: [
    { label: "Select", value: "" },
    { label: "Active Customers", value: "Active" },
    { label: "Inactive Customers", value: "Inactive" },
    { label: "All Customers", value: "Both" },
  ],

  criteriaExecuteTypeOptions: [
    { label: "All", value: "" },
    { label: "On Start Of", value: "Start Of" },
    { label: "On End Of", value: "End Of" },
    { label: "Specific Date", value: "Date" },
    { label: "Custom", value: "Custom" },
  ],

  criteriaExecuteUnitOptions: [
    { label: "All ", value: "" },
    {
      label: "Day",
      value: "Day",
    },
    {
      label: "Month",
      value: "Month",
    },
  ],

  criteriaExecuteActionOptions: [
    { label: "All ", value: "" },
    {
      label: "After the Criteria",
      value: "After",
    },
    {
      label: "Before the Criteria",
      value: "Before",
    },
    {
      label: "Exact the Criteria",
      value: "Exact",
    },
  ],

  criteriaEventTypeActionOptions: [
    { label: "All ", value: "" },
    { label: "Last Order", value: "Last Order" },
    { label: "Event", value: "Event" },
  ],

  notificationTypeOptions: [
    { label: "All", value: "" },
    { label: "Email", value: "Email" },
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WhatsApp" },
  ],

  executeOptions: [
    { label: "All", value: "" },
    { label: "Auto", value: "Auto" },
    { label: "Scheduled", value: "Scheduled" },
  ],

  eventOptions: [
    {
      label: "All",
      value: "",
      type: "",
    },
    {
      label: "Customer Order Placed But Not Visited (Revisit reminder)",
      value: "Not Visited",
      type: ["Inactive", "Regular"],
    },
    {
      label: "Product Promotion",
      value: "Product Promotion",
      type: ["Inactive", "Campaign"],
    },
    {
      label: "Lucky Draw",
      value: "Lucky Draw",
      type: ["Campaign"],
    },
    {
      label: "Smart Coins Redeem",
      value: "SmartCoins Redeem",
      type: ["Campaign"],
    },
    {
      label: "Smart Coins Redeem Reminder",
      value: "Smart Coins Redeem Reminder",
      type: ["Inactive"],
    },
    {
      label: "Deal Offers",
      value: "Deal Offers",
      type: ["Campaign"],
    },
    {
      label: "Festive Offers",
      value: "Festive Offers",
      type: ["Campaign"],
    },
    // {
    //   label: "Registration",
    //   value: "Registration",
    //   type: ["Regular"],
    // },
    // {
    //   label: "Order placed",
    //   value: "Order placed",
    //   type: ["Regular"],
    // },
  ],
};
// Filter View End

export const detailsView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Marketing Message Config",
      link: "/configs/marketing/promotion",
      params: { type: "marketing", subType: "dashboard" },
    },
    {
      name: "View Marketing Message Config",
    },
  ],
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },

    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedUserLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
};

export const promotionView = {
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },

    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedUserLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
  ],
};

export default formView;
