/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePickerInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const createdOnDateConfig = { mode: "range" };

const validityDateConfig = { mode: "range" };

const canvasStyle = { width: "25%" };

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Ended", value: "Ended" },
  { label: "Pending", value: "Pending" },
];

const defaultFormValues = {
  createdAt: [],
  validityPeriod: [],
  status: "",
};

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { register, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    // for auto filling the previous filtered values when the modal open
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onReset = () => {
    reset(defaultFormValues);
    callback({ status: "reset", formData: getValues() });
  };

  const onApply = () => {
    callback({ status: "applied", formData: getValues() });
  };

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg"> Advance Filter </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {show ? (
          <>
            {/* STATUS */}

            <div className="row">
              <div className="col-12  mb-2">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                />
              </div>

              {/* CREATED ON DATE FILTER */}
              <div className="col-12 mb-3">
                <label className="fw-semibold mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>

              <div className="col-12 mb-2">
                <label className="fw-semibold mb-1 fw-semibold mb-1 fs-val-md">
                  Event Validity Period
                </label>
                <Controller
                  name="validityPeriod"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Select Validity Period"
                      value={value}
                      inpChange={onChange}
                      config={validityDateConfig}
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : null}
      </Offcanvas.Body>
      <Offcanvas.Header>
        <div className="col-12">
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-warning btn-sm fs-val-md me-1"
                onClick={onReset}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm fs-val-md"
                onClick={onApply}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default memo(AdvanceFilterModal);
