import { set } from "lodash";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import expiredImg from "../../assets/imgs/not-linked.svg";
import totalImg from "../../assets/imgs/total.svg";
// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Store Offer's List",
    },
  ],
  // Breadcrumbs Ends Here
  defaultFromData: {
    disableStartDate: false,
    franchise: [],
    offerImages: [],
    title: "",
    desc: "",
    offerCode: "",
    status: "Pending",
    repeatOfferToCustomer: false,
    productList: [],
    maxValue: "",
    minValue: "",
    mainDeal: [],
    qty: "",
    offerDeals: [],
    rewardValue: "",
    offerType: "Buy-A Get-A",
    rewardType: "PRODUCT",
    rewardTypeOffer: "ONEPRODUCT",
    excludeFor: {
      brand: [],
      category: [],
      menu: [],
      deal: [],
    },
    brand: [],
    category: [],
    menu: [],
    deal: [],
    searchType: "brand",
  },

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Offer Limit Expired",
      value: 0,
      loading: true,
      filter: {
        offerCount: 0,
        status: "Active",
        "offerValidity.validFrom": {
          $lte: new Date(),
        },
        "offerValidity.validTo": {
          $gte: new Date(),
        },
      },

      color: "danger",
      img: expiredImg,
    },
    {
      label: "Inactive",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Expired",
      value: 0,
      loading: true,
      filter: { status: "Expired" },
      color: "danger",
      img: expiredImg,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "warning",
      img: expiredImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    offerType: {
      label: "Offer Type",
    },
    rewardType: {
      label: "Reward Type",
    },
    createdAt: {
      label: "Created On",
      type: "dateRange",
    },
    validityPeriod: {
      label: "Offer Validity",
      type: "dateRange",
    },
    _id: {
      label: "Config Id",
    },
    menu: {
      label: "Menu",
      valuePath: "[0].value.name",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    soldType: {
      label: "Sold Type",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    status: "Active",
    franchise: "",
    deal: "",
    _id: "",
    createdAt: [],
    validityPeriod: [],
    offerType: "",
    rewardType: "",
  },
  // Filter From Data Ends Here
  moreTableColumns: [
    {
      label: "Validity",
      priority: 5,
      checked: true,
      key: "validFrom",
      width: "7%",
    },
    // {
    //   label: "Status",
    //   isCentered: true,
    //   width: "8%",
    //   priority: 9,
    //   key: "status",
    //   checked: true,
    // },
  ],
  //
};
// LIST VIEW ENDS HERE

export const detailsView = {
  summaryData: [
    {
      label: "Total Customers",
      value: 87,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Total Orders",
      value: 92,
      loading: true,
      filter: {},
      color: "success",
      img: activeImg,
    },
    {
      label: "Offered Discount Value (%)",
      value: 10,
      loading: true,
      filter: { "banners.status": "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
  ],
};

export default listView;
