import { useAttachAdditionalData } from "@sk/hooks";
import { NotificationService } from "@sk/services";
import {
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  Spinner,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { format, set } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import formView, { promotionView, filterView } from "../../constantService";
import PromoAuditLog from "./components/PromoAuditLog";
import TemplateDetails from "./components/TemplateDetails";

const canvasStyle = {
  width: "50%",
};

const getData = async (id) => {
  const r = await NotificationService.getPromotionTemplateById(id);
  return r.resp;
};

const ViewPromotionModal = ({ show, pid, closeModal }) => {
  const [display, setDisplay] = useState("loading");

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState({});

  useEffect(() => {
    const loadDetails = async () => {
      setDisplay("loading");
      let d = await getData(pid);

      if (d._id) {
        let tmp = [];

        setAdditionalData([d], promotionView.additionalTableDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == promotionView.additionalTableDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });

        let t = d.executeTime;
        const a = [t.slice(0, 2), t.slice(3)];

        // set array values below
        const dt = set(new Date(), {
          hours: a[0] * 1,
          minutes: a[1] * 1,
        });

        const time = format(dt, "hh:mm aa");
        d._executeTime = time;
        d._notificationTypes = d.notifcationTypes.filter((e) => {
          return e.status;
        });

        // Criteria Event Type
        let eveList = formView.eventOptions.filter((k) => {
          return d?.criteriaDetails?.eventType == k.value;
        });
        d._eventType = eveList.length ? eveList[0]?.label : "N/A";

        // For executeType display text
        if (d.criteriaDetails.executeType) {
          let s = filterView.criteriaExecuteTypeOptions.find(
            (k) => d.criteriaDetails.executeType === k.value
          );
          d._executeType = s.label;
        }

        // For executeAction display text
        if (d.criteriaDetails.executeAction) {
          let s = filterView.criteriaExecuteActionOptions.find(
            (k) => d.criteriaDetails.executeAction === k.value
          );
          d._executeAction = s.label;
        }

        // For customerstatus display text
        if (d.criteriaDetails.customerStatus) {
          let s = filterView.criteriaCustomerStatusOptions.find(
            (k) => d.criteriaDetails.customerStatus === k.value
          );
          d._customerstatus = s.label;
        }
        setData(d);
        setDisplay("details");
      } else {
        Toaster.error("Failed to fetch data, please try again");
        setDisplay("notFound");
        closeModal();
      }
    };

    if (pid && show) {
      loadDetails();
    } else {
      setData({});
      setDisplay("notFound");
    }
  }, [pid, show]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle title={"Promotion Details #" + pid} />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-3">
          {show ? (
            <>
              {display == "loading" ? <PageLoader animation="border" /> : null}

              {/* DETAILS */}
              {display == "details" ? (
                <>
                  {/* Basic Details */}
                  <div className="row px-4 py-3 ">
                    <div className="col-12  mb-2">
                      <AppTitle title="Basice Details" />
                    </div>

                    {/* Title */}
                    <div className="col-12 mb-4">
                      <KeyVal label="Franchise" template="col">
                        <span className="fw-semibold text-primary">
                          {data?.franchiseId ? (
                            <>
                              {data?._franchise?.name} - {data?._franchise?._id}
                            </>
                          ) : (
                            "N/A"
                          )}
                        </span>
                        {/* <small className="mt-2 fs-val-sm text-muted">
                          {data?._franchise?.state},{data?._franchise?.state},{" "}
                          {data?._franchise?.town || data?._franchise?.city}
                        </small> */}
                      </KeyVal>
                    </div>

                    <div className="col-12 mb-4">
                      <KeyVal label="Promotion Title" template="col">
                        <span className="fw-semibold text-primary">
                          {data.title}
                        </span>
                      </KeyVal>
                    </div>

                    {/* Promotion Type */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Promotion Type" template="col">
                        {data.type}
                      </KeyVal>
                    </div>

                    {/* Status */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Status" template="col">
                        <HighlightText
                          status={data?.isActive ? "Active" : "Inactive"}
                        />
                      </KeyVal>
                    </div>

                    {/* Created By */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Created By" template="col">
                        {data?._createdBy?.name || "N/A"}
                      </KeyVal>
                    </div>

                    {/* Date Formatter */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Created On" template="col">
                        <DateFormatter date={data?.createdAt} />
                      </KeyVal>
                    </div>

                    {/* Modified By Name */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Modified By" template="col">
                        {data?._modifieddBy?.name || "N/A"}
                      </KeyVal>
                    </div>

                    {/* Lastupdated  */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Last Modified On" template="col">
                        <DateFormatter date={data?.lastUpdated} />
                      </KeyVal>
                    </div>
                  </div>

                  {/* Criteria Details */}
                  <div className="row px-4 py-3  bg-light">
                    <div className="col-12  mb-2">
                      <AppTitle title="Config Rules" />
                    </div>

                    <div className="col-4 mb-4">
                      <KeyVal label="Criteria Type" template="col">
                        <span className="fw-semibold">
                          {data.criteriaDetails?.type}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-6 mb-4">
                      <KeyVal label="Event Type" template="col">
                        {data._eventType}
                      </KeyVal>
                    </div>

                    <div className="col-6 mb-4">
                      <KeyVal label="Customer Status" template="col">
                        {data._customerstatus}
                      </KeyVal>
                    </div>
                  </div>

                  {/* When To Send Notifications */}
                  <div className="row px-4 py-3">
                    <div className="col-12  mb-2">
                      <AppTitle title="When to Send Notification" />
                    </div>

                    <div className="col-4 mb-4">
                      <KeyVal label="Execute Option" template="col">
                        {data?.executeOption || "N/A"}
                      </KeyVal>
                    </div>

                    <div className="col-4 mb-4">
                      <KeyVal label="Execute Type" template="col">
                        {data?._executeType}
                      </KeyVal>
                    </div>

                    {/* Execute Date */}
                    {data.criteriaDetails?.executeType == "Date" ? (
                      <>
                        <div className="col-4 mb-4">
                          <KeyVal label="Execut Date" template="col">
                            <DateFormatter
                              date={data?.criteriaDetails?.executeDate}
                              format="dd MMM yyyy"
                            />
                          </KeyVal>
                        </div>
                      </>
                    ) : null}

                    {/* Execute Type Custom Value */}
                    {data.criteriaDetails?.executeType == "Custom" ? (
                      <>
                        <div className="col-4 mb-4">
                          <KeyVal label="Execute Custom Value" template="col">
                            {data.criteriaDetails?.executeTypeCustomValue}
                          </KeyVal>
                        </div>
                      </>
                    ) : null}

                    {/* Event Unit */}
                    {["Start Of", "End Of", "Custom"].indexOf(
                      data.criteriaDetails?.executeType
                    ) != -1 ? (
                      <div className="col-4 mb-4">
                        <KeyVal label="Event Unit" template="col">
                          {data.criteriaDetails?.executeUnit}
                        </KeyVal>
                      </div>
                    ) : null}

                    {/* <div className="col-4 mb-4">
                      <KeyVal label="Event Action Type" template="col">
                        {data.criteriaDetails?.eventTypeAction}
                      </KeyVal>
                    </div> */}

                    {/* Execute Action */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Execute Action" template="col">
                        {data._executeAction}
                      </KeyVal>
                    </div>
                    {/* Execute Time */}
                    <div className="col-4 mb-4">
                      <KeyVal label="Execute Time" template="col">
                        {data._executeTime}
                      </KeyVal>
                    </div>
                  </div>

                  {/* Template Details */}
                  <div className="row px-4 py-3 bg-light">
                    <TemplateDetails tempData={data._notificationTypes} />
                  </div>

                  {/* Audit Log */}
                  {/* <div className="row px-4 py-3  bg-light">
                    <PromoAuditLog auditLogs={data?.auditLog} />
                  </div> */}
                </>
              ) : null}

              {/* NO RECORD */}
              {display === "notFound" && (
                <NoDataFound> No Data Found </NoDataFound>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewPromotionModal);
