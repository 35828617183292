import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { BannerService, NavService } from "@sk/services";
import {
  AppCard,
  BusyLoader,
  DateFormatter,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  KeyVal,
  NoDataFound,
  PageInfo,
  Rbac,
  Spinner,
  SummaryCard,
} from "@sk/uis";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { detailsView } from "../constantService";
import AccessLevelCard from "./components/AccessLevelCard";
import ApplyBannerOnDetails from "./components/ApplyBannerOnDetails";
import ViewMultiDeals from "./modals/ViewMultiDeals";
import SlideTypeDetails from "./components/SlideTypeDetails";
import CountDownTimer from "./components/CountDownTimer";
import AuditLogModal from "./modals/AuditLogModal";

const rbac = {
  editButton: ["EditBanner"],
};
const bannerTypeList = [
  { label: "No Action", value: "NOACTION" },
  { label: "Deal", value: "deal" },
  { label: "Brand", value: "brand" },
  { label: "Category", value: "category" },
  { label: "Brand & Category", value: "brandAndCategory" },
  { label: "Marketplace Seller", value: "marketPlaceSeller" },
  { label: "KingSale", value: "kingsale" },
  { label: "Keyword", value: "keyword" },
  { label: "Recharge", value: "recharge" },
  { label: "DMT", value: "dmt" },
  { label: "Wallet", value: "wallet" },
  { label: "Menu", value: "menu" },
  { label: "Multi Deals", value: "multiDeals" },
  { label: "Multi Categories", value: "multiCategories" },
  { label: "Multi Brands", value: "multiBrands" },
  { label: "Service Partner", value: "servicePartner" },
  { label: "Enrollment", value: "enrollment" },
];

const ViewBanner = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([...detailsView.breadcrumbs]);
  // data for View Banner
  const [data, setData] = useState({});

  // Loading State For Data
  const [loading, setLoading] = useState(true);

  const [auditLogsModal, setAuditLogsModal] = useState({
    show: false,
    data: {},
  });

  const [showDealDetailsModal, setShowDealDetailsModal] = useState({
    status: false,
    ids: [],
    type: "",
  });

  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  // To Get all Search Params
  const [searchParams] = useSearchParams();

  // NAvigation Object
  const router = useNavigate();

  // To get Query Params
  const query = useFetchUrlQueryString(searchParams);

  // Custom Hook To attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Use Effect To Load Details
  useEffect(() => {
    if (query.id) {
      loadBannerDetails(query.id, query.type);
    } else {
      setLoading(false);
    }
  }, [attachAllData, query.id, setAdditionalData, loadBannerDetails]);

  // To Load Banner Data
  const loadBannerDetails = useCallback(
    async (id, type) => {
      setLoading(true);

      let params = {
        page: 1,
        count: 1,
        filter: {
          _id: id,
          // platformType: "ClubApp"
        },
        outputType: "List",
      };

      const r = await BannerService.getBannerList(params);
      const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

      // Page && Page Location
      let l = [...BannerService.getBannerLocationCode()];
      let k = l.filter((x) => {
        return x.value == d.bannerLocationCode;
      });
      d._bannerLocation = k.length ? k[0]?.location || "" : "";
      d._bannerPage = k.length ? k[0]?.page || "" : "";

      d._total = 0;
      d._upcoming = 0;
      d._live = 0;
      d._expired = 0;

      d.banners.forEach((r) => {
        if (["Brand"].indexOf(r?.filterByConditionType) != -1) {
          r.filterBrand = r.filterByCondition[0];
        } else if (["Category"].indexOf(r?.filterByConditionType) != -1) {
          r.filterCategory = r.filterByCondition[0];
        } else if (data?.filterByConditionType == "Deal") {
          r.filterDeal = r.filterByCondition[0];
        } else if (data?.filterByConditionType == "Brand-Category") {
          let ids = data?.filterByCondition[0].split("-");
          r.filterBrand = ids[0];
          r.filterCategory = ids[0];
        }

        r._activeStatus = BannerService.getBannerStatusByDate(
          r?.period?.date?.startDate,
          r?.period?.date?.endDate
        );
        if (r._activeStatus?.key == "upcoming") {
          d._upcoming++;
        }
        if (r._activeStatus?.key == "active") {
          d._live++;
        }
        if (r._activeStatus?.key == "expired") {
          d._expired++;
        }
        d._total++;

        if (
          differenceInDays(new Date(r?.period?.date?.endDate), new Date()) <=
            1 &&
          r._activeStatus?.key != "expired" &&
          r._activeStatus?.key == "active"
        ) {
          r._isExpiringSoon = true;
        } else {
          r._isExpiringSoon = false;
        }
      });
      // adding attachUser loadingKey
      d.userLoading = true;
      d.modifiedLoading = true;
      let lbl = BannerService.getBannerLocationCode().find((e) => {
        return e.value == d.bannerLocationCode;
      });
      d._bannerName = lbl?.label || d.bannerLocationCode;
      // Attach additional Info
      if (d._id) {
        let tmp = [];
        setAdditionalData(
          [d],
          [...detailsView.attachAdditionalDataConfig],
          (x) => {
            tmp.push(x);
            if (tmp.length == detailsView.attachAdditionalDataConfig.length) {
              const a = [...attachAllData([d], tmp)][0];
              setData((v) => ({ ...v, ...a }));
            }
          }
        );
        // Setting Data
        setData(d);
        setLoading(false);
      } else {
        setLoading(false);
      }

      if (type == "ClubApp") {
        let p = breadcrumbs.map((e) => {
          if (e.name == "Banner List") {
            e.link = "/configs/marketing/dashboard";
            e.params = { tab: "banner" };
          }
          return e;
        });
        setBreadcrumbs([...p]);
      }
    },
    [attachAllData, setAdditionalData]
  );

  // To Open Edit Modal
  const editBanner = useCallback(() => {
    let url = "/configs/banner/manage";
    NavService.replace(router, url, { id: query.id, type: query.type });
  }, []);

  const getBannerLabel = (value) => {
    var label = bannerTypeList.filter((v) => {
      return v.value == value;
    });
    return label.length ? label[0].label : "";
  };

  const viewDealDetails = (d, type) => {
    let ids = [];
    if (type == "multiDeals") {
      ids = d.bannerTypeNames?.multiDeals?.length
        ? d.bannerTypeNames?.multiDeals
        : [];
    } else if (type == "multiCategories") {
      ids = d.bannerTypeNames?.multiCategories?.length
        ? d.bannerTypeNames?.multiCategories
        : [];
    } else if (type == "multiBrands") {
      ids = d.bannerTypeNames?.multiBrands?.length
        ? d.bannerTypeNames?.multiBrands
        : [];
    }
    setShowDealDetailsModal({ status: true, ids: ids, type: type });
  };

  const onCloseDealDetailsModal = (d) => {
    setShowDealDetailsModal({ status: false, ids: [], type: "" });
  };

  const viewImgPreviewModal = (x) => {
    if (x?.image?.length > 0) {
      let d = [{ id: x.image }];
      setImgPreviewData({ show: true, data: d });
    }
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  const viewDeal = (id) => {
    NavService.to(router, "/cms/deal/view", { id: id });
  };

  const viewCategory = (id) => {
    NavService.to(router, "/cms/category/view", { id: id });
  };

  const viewBrand = (id) => {
    NavService.to(router, "/cms/brand/view", { id: id });
  };

  const viewAuditLog = () => {
    let d = data?.banners[0] || {};
    setAuditLogsModal({
      show: true,
      data: d?.auditLog?.length ? [...d?.auditLog] : [],
    });
  };

  const auditLogsModalCb = () => {
    setAuditLogsModal({ show: false, data: {} });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Banner Details"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* No Data Found */}

      {!loading && !data?._id ? (
        <AppCard>
          <NoDataFound> No Data Found </NoDataFound>
        </AppCard>
      ) : null}

      {data?._id ? (
        <>
          {/* Main Details starts (left side) */}
          <AppCard>
            <div className="row   border-bottom pb-2 ">
              <div className="col-12">
                <div className="fs-val-lg text-primary mb-3 fw-semibold">
                  Banner ID : #{data?._id}
                </div>
              </div>

              <div className="col-3 mb-2">
                <KeyVal
                  label="Platform Type :"
                  labelCol="col-5"
                  contentCol="col-7"
                >
                  <span className=" fw-semibold">
                    {data?.platformType || "N/A"}
                  </span>
                </KeyVal>
              </div>

              <div className="col-3 mb-2">
                <KeyVal
                  label="Page :"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="text-primary"
                >
                  <span className=" fw-semibold">{data?.page}</span>
                </KeyVal>
              </div>

              <div className="col-3 mb-2">
                <KeyVal
                  label="Location :"
                  labelCol="col-5"
                  contentCol="col-7"
                  className="text-primary"
                >
                  <span className=" fw-semibold">{data?.location}</span>
                </KeyVal>
              </div>

              {/* Slider Timed */}
              <div className="col-3  mb-2 ">
                <KeyVal
                  label="Slider Time (s) :"
                  labelCol="col-5"
                  contentCol="col-7"
                >
                  <span className=" fw-semibold">{data.sliderTime}</span>
                </KeyVal>
              </div>

              {/* Resolution  */}
              <div className="col-3  mb-2 ">
                <KeyVal
                  label="Resolution (px):"
                  labelCol="col-5"
                  contentCol="col-7"
                >
                  <span className="fw-semibold">
                    {data.resolution.width}*{data.resolution.height} (WxH)
                  </span>
                </KeyVal>
              </div>

              {/* Max Img Sixe  */}
              <div className="col-3 mb-2">
                <KeyVal
                  label="Max Img Size :"
                  labelCol="col-5"
                  contentCol="col-7"
                >
                  <span className="fw-semibold">
                    {data.maxImageSize || "N/A"}MB
                  </span>
                </KeyVal>
              </div>
              {/* Max Img Sixe  */}

              <div className="col-3 mb-2">
                <KeyVal label="Status:" labelCol="col-5" contentCol="col-7">
                  <HighlightText
                    status={data?.status || "Active"}
                    isBadge={true}
                  />
                </KeyVal>
              </div>

              {/* <ApplyBannerOnDetails data={data.banners?.[0]} /> */}
            </div>
            {/* Retailer App */}
            {/* <div className="row   border-bottom pb-2 ">
                <div className="col-12">
                  <div className="fs-val-lg text-primary mb-3 fw-semibold">
                    {data.title}
                  </div>
                </div>

                <div className="col-3 mb-2">
                  <KeyVal
                    label="Banner ID :"
                    labelCol="col-5"
                    contentCol="col-7"
                    className="text-primary"
                  >
                    <span className=" fw-semibold">{data?._id}</span>
                  </KeyVal>
                </div>

                <div className="col-3 mb-2">
                  <KeyVal
                    label="Banner For :"
                    labelCol="col-5"
                    contentCol="col-7"
                  >
                    <span className=" fw-semibold">{data?.applicableFor}</span>
                  </KeyVal>
                </div>

                {data?.platformType ? (
                  <div className="col-3 mb-2">
                    <KeyVal
                      label="Platform Type :"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <span className=" fw-semibold">{data?.platformType}</span>
                    </KeyVal>
                  </div>
                ) : null}

                {data?.platformType == "ClubApp" ? (
                  <div className="col-3 mb-2">
                    <KeyVal
                      label="Franchise Type :"
                      labelCol="col-5"
                      contentCol="col-7"
                    >
                      <span className=" fw-semibold">
                        {data?.filterByFranchiseType == "ByGroup"
                          ? "By Group"
                          : "By Franchise"}
                      </span>
                    </KeyVal>
                  </div>
                ) : null}

                {data?.filterByFranchiseType == "ByGroup" ? (
                  <div className="col-3  mb-2">
                    <KeyVal
                      label=" Group Type :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data?.filterByFranchiseGroupType || "N/A"}
                      </span>
                    </KeyVal>
                  </div>
                ) : null}

                {data?.filterByFranchiseType == "ByFranchise" ? (
                  <div className="col-3  mb-2">
                    <KeyVal
                      label="Franchise:"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data?._franchise?.name || "N/A"} -(
                        {data?._franchise?._id})
                      </span>
                    </KeyVal>
                  </div>
                ) : null}

                {data?.platformType == "ClubApp" ? (
                  <div className="col-3  mb-2">
                    <KeyVal
                      label="Banner Location :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data?._bannerName || "N/A"}
                      </span>
                    </KeyVal>
                  </div>
                ) : null}

                <div className="col-3  mb-2 ">
                  <KeyVal
                    label="Slider Time (s) :"
                    labelCol="col-5"
                    contentCol="col-7"
                  >
                    <span className=" fw-semibold">{data.sliderTime}</span>
                  </KeyVal>
                </div>

                <div className="col-3  mb-2 ">
                  <KeyVal
                    label="Resolution (px):"
                    labelCol="col-5"
                    contentCol="col-7"
                  >
                    <span className="fw-semibold">
                      {data.resolution.width}*{data.resolution.height} (w*H)
                    </span>
                  </KeyVal>
                </div>

                <div className="col-3 mb-2">
                  <KeyVal
                    label="Max Img Size (kB):"
                    labelCol="col-5"
                    contentCol="col-7"
                  >
                    <span className="fw-semibold">
                      {data.maxImageSize || "N/A"}
                    </span>
                  </KeyVal>
                </div>

                <div className="col-3 mb-2">
                  <KeyVal label="Status:" labelCol="col-5" contentCol="col-7">
                    <HighlightText status={data?.status || "Active"} />
                  </KeyVal>
                </div>

                <div className="col-6  mb-2">
                  <KeyVal
                    label="Description :"
                    labelCol="col-2"
                    contentCol="col-10"
                  >
                    <span className="ps-4 fw-semibold">
                      {data?.shortDescription}
                    </span>
                  </KeyVal>
                </div>
              </div> */}

            <div className="row align-items-center mt-3">
              <div className="col-auto me-4">
                <KeyVal
                  label="Created By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._createdBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto pe-5 border-end">
                <KeyVal
                  label="Created At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold "
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              <div className="col-auto ps-4 pe-3">
                <KeyVal
                  label="Modified By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._modifiedBy?.name || "NA"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto me-auto">
                <KeyVal
                  label="Modified At:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.lastUpdated} />
                </KeyVal>
              </div>

              <div className="col-auto">
                {/* <Rbac roles={rbac.editButton}>
                  <button
                    className={classNames({
                      "btn btn-sm  fs-val-sm me-3": true,
                      "btn-success": data?.status != "Active",
                      "btn-danger": data?.status == "Active",
                    })}
                  >
                    Mark as Inactive
                  </button>
                </Rbac> */}
                {/* <Rbac roles={rbac.editButton}>
                  <button
                    className="btn btn-primary fs-val-sm"
                    onClick={editBanner}
                  >
                    Edit Banner
                  </button>
                </Rbac> */}
              </div>
            </div>
          </AppCard>

          {/* Banner Count cards */}
          {/* <div className="pb-1">
            <div className="row">
              {[...detailsView.summaryData].map((i) => (
                <div className="col-3">
                  <SummaryCard
                    value={data?.[i.key]}
                    title={i.label}
                    loading={false}
                    valueColor={i.color}
                    img={i.img}
                    template={2}
                  />
                </div>
              ))}
            </div>
          </div> */}
          {/* Banner Slides */}
          <AppCard title="Banner Additional Info">
            <div className="px-2">
              {data?.banners.length &&
                data.banners.map((x, i) => (
                  <div className="row bg-white mb-3 p-2" key={x?._id}>
                    <div className="col-2 cursor-pointer">
                      {/* <div className="mb-2 fs-semibold">Slide {i + 1}</div> */}
                      <div
                        onClick={() => {
                          viewImgPreviewModal(x);
                        }}
                      >
                        <ImgRender
                          assetId={x.image || ""}
                          width="200"
                          height="200"
                        />
                      </div>
                      <div className="mt-3 text-center fs-val-md">
                        Conversions :
                        <span className="fw-semibold text-primary ps-1">
                          {x?.bannerClicks || 0} Clicks
                        </span>
                      </div>
                    </div>

                    {/* Basic Details */}
                    <div className="col-5">
                      <AppCard title="Basic Details">
                        {/* Order */}
                        <div className="mb-2">
                          <KeyVal
                            label="Order :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="text-primary"
                          >
                            <span className=" fw-semibold">{x?.ordering}</span>
                          </KeyVal>
                        </div>

                        {/* NAme */}
                        <div className="mb-2">
                          <KeyVal
                            label="Name :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="text-primary"
                          >
                            <span className=" fw-semibold">{x?.title}</span>
                          </KeyVal>
                        </div>

                        {/* AppType */}
                        {/* <div className="mb-2">
                        <KeyVal
                          label="Apptype :"
                          labelCol="col-5"
                          contentCol="col-7"
                          className="text-primary"
                        >
                          <span className=" fw-semibold">
                            {x?.appType?.length ? x.appType.join(",") : "N/A"}
                          </span>
                        </KeyVal>
                      </div> */}

                        {/* Status */}
                        <div className="mb-2">
                          <KeyVal
                            label="Status :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="fw-semibold"
                          >
                            <div
                              className={classNames(
                                x?._activeStatus.color,
                                "text-semibold my-1 "
                              )}
                            >
                              <HighlightText
                                status={x?._activeStatus?.label}
                                isBadge={true}
                              />

                              {x?._isExpiringSoon ? (
                                <span className="text-warning fs-val-sm ps-3">
                                  <CountDownTimer
                                    targetDate={x?.period?.date?.endDate}
                                  />
                                </span>
                              ) : null}
                            </div>
                          </KeyVal>
                        </div>

                        {/* Start Date */}
                        <div className="mb-3">
                          <KeyVal
                            label="Validity Start Date :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="fw-semibold"
                          >
                            <DateFormatter
                              date={x?.period?.date?.startDate}
                              format="dd MMM yyyy hh:mm a"
                            />
                          </KeyVal>
                        </div>

                        {/* Validity End Date */}
                        <div className="mb-3">
                          <KeyVal
                            label="Validity End Date :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="fw-semibold"
                          >
                            <DateFormatter
                              date={x?.period?.date?.endDate}
                              format="dd MMM yyyy hh:mm a"
                            />
                          </KeyVal>
                        </div>

                        <div className="mb-3">
                          <KeyVal
                            label="View Audit Logs :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="fw-semibold"
                          >
                            <button
                              className="btn btn-outline-primary fs-val-sm btn-sm"
                              onClick={viewAuditLog}
                            >
                              Audit Log
                            </button>
                          </KeyVal>
                        </div>

                        {/* Active Period */}
                        {/* <div className="mb-2">
                        <KeyVal
                          label="Active Period :"
                          labelCol="col-5"
                          contentCol="col-7"
                          className="fw-semibold"
                        >
                          {!x?.allDayRange ? (
                            <>
                              {x?.period?.timeRanges?.length &&
                                x?.period?.timeRanges.map((e, i) => (
                                  <div key={i} className="mt-2 fs-val-md">
                                    <DateFormatter
                                      date={e.startTime}
                                      format="hh:mm a"
                                    />{" "}
                                    :{" "}
                                    <DateFormatter
                                      date={e.endTime}
                                      format="hh:mm a"
                                    />
                                  </div>
                                ))}
                            </>
                          ) : (
                            "All Days"
                          )}
                        </KeyVal>
                      </div> */}
                      </AppCard>
                    </div>

                    {/* Franchise /Creiteria Details */}
                    <div className="col-5">
                      <ApplyBannerOnDetails data={x} />

                      <AppCard title="Redirection Details">
                        <div className="mb-2">
                          <KeyVal
                            label="Redirect To :"
                            labelCol="col-5"
                            contentCol="col-7"
                            className="fw-semibold"
                          >
                            <span className="text-primary">
                              {getBannerLabel(x?.bannerType)}
                            </span>
                          </KeyVal>
                        </div>

                        <SlideTypeDetails data={x} />

                        {x?.bannerTypeNames.enrollmentType && (
                          <div className="mb-2">
                            <KeyVal
                              label="Enrollment Type :"
                              labelCol="col-5"
                              contentCol="col-7"
                              className="fw-semibold"
                            >
                              <span className="text-primary">
                                {x?.bannerTypeNames?.enrollmentType}
                              </span>
                            </KeyVal>
                          </div>
                        )}

                        {[
                          "multiDeals",
                          "multiCategories",
                          "multiBrands",
                        ].indexOf(x?.bannerType) != -1 && (
                          <div className="mb-2">
                            <KeyVal
                              label={getBannerLabel(x?.bannerType)}
                              labelCol="col-5"
                              contentCol="col-7"
                              className="fw-semibold"
                            >
                              <button
                                className="btn btn-sm btn-outline-primary fs-val-sm"
                                onClick={() => {
                                  viewDealDetails(x, x?.bannerType);
                                }}
                              >
                                View
                              </button>
                            </KeyVal>
                          </div>
                        )}
                      </AppCard>
                    </div>

                    {/* Access Levels (state) */}
                    {/* <div className="col-3">
                    <AccessLevelCard data={x} type={data.platformType} />
                  </div> */}

                    {/* Actions /Conversions */}
                    {/* <div className="col-3">
                    <div className="text-primary mb-2">200 Clicks</div>
                    <div className=""></div>
                  </div> */}
                  </div>
                ))}
            </div>
          </AppCard>
        </>
      ) : null}

      {/* Manage Modal Page */}

      <BusyLoader show={loading} />

      <ViewMultiDeals
        show={showDealDetailsModal.status}
        callback={onCloseDealDetailsModal}
        ids={showDealDetailsModal.ids}
        type={showDealDetailsModal.type}
      />

      <ImgPreviewModal
        show={imgPreviewData.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData.data}
      />

      <AuditLogModal
        show={auditLogsModal.show}
        auditLog={auditLogsModal.data}
        callback={auditLogsModalCb}
      />
    </>
  );
};

export default ViewBanner;
