import { DatePickerInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  bannerSlotOptions,
  sortOptions,
  widgetFeatures,
} from "../../constantService";

const createdOnDateConfig = { mode: "range" };

const canvasStyle = { width: "25%" };

// const statusOptions = [
//   { label: "All", value: "" },
//   { label: "Active", value: "Active" },
//   { label: "Inactive", value: "InActive" },
// ];

const configTypeOptions = [
  { label: "All", value: "" },
  { label: "Global", value: "Global" },
  { label: "Franchise", value: "Franchise" },
];

const defaultFormValues = {
  createdAt: [],
  status: "",
  layout: "",
  feature: "",
  page: "",
  configType: "",
  widgetType: "",
  sortBy: "",
  // priority: "",
  validity: [],
  bannerSlot: "",
};

// const layoutOptions = [
//   { label: "Select layout", value: "" },
//   ...listView.layouts,
// ];

// const pagesOptions = listView.pages;

const featureOptions = [{ label: "All", value: "" }, ...widgetFeatures];

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { register, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  const [feature] = useWatch({
    control,
    name: ["feature"],
  });

  useEffect(() => {
    // for auto filling the previous filtered values when the modal open
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onFeatureChange = () => {
    setValue("bannerSlot", "");
  };

  const onReset = () => {
    reset(defaultFormValues);
    callback({ status: "reset", formData: getValues() });
  };

  const onApply = () => {
    callback({ status: "applied", formData: getValues() });
  };

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg"> Advance Filter </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {show ? (
          <>
            {/* STATUS */}

            <div className="row">
              {/* <div className="col-12  mb-2">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                />
              </div> */}

              {/* <div className="col-12">
                <SelectInput
                  name="widget"
                  placeholder="Widget"
                  options={layoutOptions}
                  register={register}
                  label="Select Widget"
                />
              </div> */}

              {/* <div className="col-12">
                <SelectInput
                  name="page"
                  placeholder="Page"
                  options={pagesOptions}
                  label="Select Page"
                  register={register}
                />
              </div> */}

              <div className="col-12">
                <SelectInput
                  name="configType"
                  placeholder="Config On"
                  options={configTypeOptions}
                  label="Config Type"
                  register={register}
                />
              </div>

              <div className="col-12">
                <SelectInput
                  name="feature"
                  placeholder="Select Feature"
                  options={featureOptions}
                  label="Select Feature"
                  register={register}
                  callback={onFeatureChange}
                />
              </div>

              {feature == "banner" ? (
                <div className="col-12">
                  <SelectInput
                    name="bannerSlot"
                    options={bannerSlotOptions}
                    label="Select Banner"
                    register={register}
                  />
                </div>
              ) : null}

              <div className="col-12">
                <SelectInput
                  name="sortBy"
                  options={sortOptions}
                  label="Select Sort By"
                  register={register}
                />
              </div>

              {/* <div className="col-12">
                <TextInput
                  name="priority"
                  placeholder="Priorty ex: 1"
                  label="Priority"
                  register={register}
                  type="number"
                />
              </div> */}

              {/* CREATED ON DATE FILTER */}
              <div className="col-12 mb-3">
                <label className="mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>

              <div className="col-12 mb-3">
                <label className="mb-1 fs-val-md">Validity Date Range</label>
                <Controller
                  name="validity"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : null}
      </Offcanvas.Body>
      <Offcanvas.Header>
        <div className="col-12">
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-warning btn-sm fs-val-md me-1"
                onClick={onReset}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm fs-val-md"
                onClick={onApply}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default memo(AdvanceFilterModal);
