import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, DealService, UtilityService } from "@sk/services";
import { PageInfo, PaginationSummary } from "@sk/uis";
import { debounce } from "lodash";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import listView from "../constantService";

import Table from "./components/Table";

import { FormProvider, useForm } from "react-hook-form";

import { Alert, AppliedFilterLabel, BusyLoader, Toaster } from "@sk/uis";

import FilterForm from "./components/FilterForm";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import ManageDealRestrictionModal from "./modals/ManageDealRestrictionModal";

const breadcrumbs = listView.breadcrumb;

const defaultFilterFormData = listView.filterFromData;

const defaultSummaryData = listView.summaryData;

const defaultSortOpt = { key: "createdAt", value: "desc" };

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

const defaultPaginationData = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

// To get  List
const getData = async (params) => {
  const r = await DealService.getRestrictedConfigList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.page;
  delete params.count;
  const r = await DealService.getRestrictedConfigList(params);
  return { count: r.statusCode == 200 && r.resp ? 10 : 0 };
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let d = filter || {};

  let p = {
    page: pagination.activePage || 1,
    count: pagination.rowsPerPage || 10,
    type: "data",
    filter: {},
    sort: { createdAt: -1 },
  };

  if (d.status) {
    p["filter"]["is_active"] = d.status == "Active" ? 1 : 0;
  }

  // const deal = d.deal.trim();
  // const dealRegex = { $regex: deal, $options: "gi" };

  if (d.deal.length) {
    // p["filter"]["$or"] = [{ _id: dealRegex }, { name: dealRegex }];
    p.filter.deal_id = { $in: [d.brand[0].value._id] };
  }

  if (d.isAllowed) {
    p.filter.isAllowed = d.isAllowed == "yes";
  }

  if (d.brand?.length) {
    p.filter.brand_id = { $in: [d.brand[0].value._id] };
  }

  if (d.category?.length) {
    p.filter.cat_id = { $in: [d.category[0].value._id] };
  }

  if (d.franchise?.length) {
    p.filter.franchise_id = { $in: [d.franchise[0].value._id] };
  }

  if (d.createdAt.length) {
    p.filter.createdAt = {
      $gte: set(d.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(d.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  // if (sort.key) {
  //   p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  // }
  return p;
};

const DealRestrictionList = () => {
  // Custom Hook To Attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [manageDealRestrictionModal, setManageDealRestrictionModal] = useState({
    show: false,
  });

  const [filterLabels, setFilterLabels] = useState([]);

  // Accecc level input component data for location details
  const stateListRef = useRef([]);

  const rmfSubtypeOptionsRef = useRef([]);

  const rfSubtypeOptionsRef = useRef([]);

  const sfSubtypeOptionsRef = useRef([]);

  //   const [summaryCard, setSummaryCard] = useState(
  //     [...defaultSummaryData].map((x) => {
  //       let p = prepareFilterParams(
  //         defaultPaginationData,
  //         defaultFilterFormData,
  //         defaultSortOpt
  //       );
  //       if (p.filter) {
  //         delete p.filter?.status;
  //       }
  //       return {
  //         ...x,
  //         filterParams: { ...p.filter, ...x.defaultFilterParams },
  //       };
  //     })
  //   );

  const methods = useForm({
    defaultValues: listView.filterFromData,
  });

  //  REFs To Store Data
  const filterDataRef = useRef({ ...defaultFilterFormData });

  const paginationRef = useRef({ ...defaultPaginationData });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Apply Filter
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationData };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    delete p.filter.dealsFor;
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  // Run Only For First Time
  const init = useCallback(async () => {
    applyFilter();

    loadStateList();
    loadRmfSubtypeOptions();
    loadRfSubtypeOptions();
    loadSfSubtypeOptions();
  }, [applyFilter]);

  //  To Load  List  and To attach Additional data
  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    setLoadingData(false);
    setData(d);

    let tmp = [];
    // Attach User Info
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(() => {
    filterDataRef.current = { ...methods.getValues() };
    paginationRef.current = { ...defaultPaginationData };
    applyFilter();
  }, [applyFilter, methods]);

  const addRestricition = () => {
    setManageDealRestrictionModal({ show: true });
  };

  const manageDealRestrictionModalCb = (x) => {
    setManageDealRestrictionModal({ show: false });
  };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSearch = debounce(() => {
    filterFormCb();
  }, 700);

  const filterCb = useCallback(() => {
    filterDataRef.current = {
      ...eethods.getValues(),
      ...advanceFilterRef.current,
    };
    applyFilter();
  }, [applyFilter, methods]);

  const advFilterCb = (data) => {
    if (data.status == "applied" || data.status == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.deal;

    if (v.isAllowed) {
      v.isAllowed = v.isAllowed ? "Allowed" : "Restricted";
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  //Fetching  State List for Location details
  const loadStateList = useCallback(async () => {
    let r = await UtilityService.getStateList();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All", value: "all" });
    list.unshift({ label: "Select", value: "" });
    stateListRef.current = list;
  }, []);

  //RMF Subtype  Options  for Location details
  const loadRmfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getRmfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All RMF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    rmfSubtypeOptionsRef.current = list;
  }, []);

  //RF Subtype  Options  for Location details
  const loadRfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getRfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All RF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    rfSubtypeOptionsRef.current = list;
  }, []);

  //SF Subtype  Options  for Location details
  const loadSfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getSfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All SF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    sfSubtypeOptionsRef.current = list;
  }, []);

  const statusToggelBtnCb = useCallback(
    async (x) => {
      let text = x?.is_active ? "Disable" : "Enable";
      const res = await Alert.confirm({
        title: "Please confirm",
        text: "Do you want to " + text + " Status",
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      setBusyLoader({
        msg: "please,wait submitting your response",
        loading: true,
      });

      let params = {
        is_active: x.is_active ? 0 : 1,
      };

      let r = await DealService.updateRestrictedConfig(x._id, params);
      setBusyLoader({ msg: "", loading: false });

      if (r.statusCode == 200) {
        Toaster.success("Status Updated Successfully");
        init();
      } else {
        let error = AjaxService.parseError(r.resp);
        Toaster.error(error.msg[0]);
      }
    },
    [init]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    openAdvFilterModal(true);
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo title="Deal List" breadcrumbs={breadcrumbs} />
        </div>
      </div>

      {/* Summary Card Overview */}
      {/* <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-3" key={i}>
            <SummaryCardBox
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div> */}

      {/* Filter  Block  */}
      <div className="row align-items-center mb-2">
        <div className="col-6">
          <FormProvider {...methods}>
            <FilterForm
              callback={filterCb}
              openAdvFilterModal={onAdvanceFilterClickCb}
              isDirty={methods.formState.isDirty}
            />
          </FormProvider>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-primary btn-sm fs-val-md me-1"
            onClick={addRestricition}
          >
            Add Restriction
          </button>
        </div>
      </div>

      {/* Advance Filter Modal  */}
      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <Table
        data={data}
        loading={loadingData}
        sort={sortRef.current}
        sortCb={sortCb}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        statusToggelBtnCb={statusToggelBtnCb}
      />

      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>

      <ManageDealRestrictionModal
        show={manageDealRestrictionModal.show}
        callback={manageDealRestrictionModalCb}
        stateListOptions={stateListRef.current}
        rmfSubtypeOptions={rmfSubtypeOptionsRef.current}
        rfSubtypeOptions={rfSubtypeOptionsRef.current}
        sfSubtypeOptions={sfSubtypeOptionsRef.current}
      />
    </>
  );
};

export default memo(DealRestrictionList);
