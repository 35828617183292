import {
  BrandService,
  CategoryService,
  DealService,
  FranchiseService,
} from "@sk/services";
import { AppCard, KeyVal, Spinner } from "@sk/uis";
import { useCallback, useEffect, useState } from "react";

const ApplyBannerOnDetails = ({ data }) => {
  const [deal, setDeal] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [franchise, setFranchise] = useState({ loading: false, data: "" });

  useEffect(() => {
    if (data?._id) {
      if (data?.filterByConditionType == "Brand") {
        loadBrandDetails(data?.filterByCondition, "filterBrand");
      } else if (
        ["Category", "Menu"].indexOf(data?.filterByConditionType) != -1
      ) {
        loadCategoryDetails(data?.filterByCondition, "filterCategory");
      } else if (data?.filterByConditionType == "Deal") {
        loadDealDetails(data?.filterByCondition, "filterDeal");
      } else if (data?.filterByConditionType == "Brand-Category") {
        let ids = data?.filterByCondition[0].split("-");
        loadBrandDetails([ids[0]], "filterBrand");
        loadCategoryDetails([ids[1]], "filterCategory");
      }
      if (data.filterByFranchiseType == "ByFranchise") {
        loadFranchiseDetails(data?.filterByFranchiseId);
      }
    }
  }, []);

  const loadBrandDetails = useCallback(async (ids) => {
    let m = await BrandService.getList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setBrand(x);
    }
  }, []);

  const loadCategoryDetails = useCallback(async (ids, key) => {
    let m = await CategoryService.getList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setCategory(x);
    }
  }, []);

  const loadDealDetails = useCallback(async (ids, key) => {
    let m = await DealService.getDeals({ filter: { _id: { $in: ids } } });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setDeal(x);
    }
  }, []);

  const loadFranchiseDetails = useCallback(async (id) => {
    setFranchise({ loading: true, data: "" });

    let m = await FranchiseService.getFranchise(id);
    if (m.resp?._id) {
      let x = m.resp?.name + " (" + m.resp?._id + ") ";
      setFranchise({ loading: false, data: x });
    } else {
      setFranchise({ loading: false, data: "" });
    }
  }, []);

  return (
    <>
      <AppCard title="Banner Franchise And Criteria Details">
        {/* Filter By Franchise Type  */}

        <KeyVal label="Franchise Type :" labelCol="col-5" contentCol="col-7">
          <span className=" fw-semibold">
            {data?.filterByFranchiseType == "ByGroup"
              ? "By Group"
              : "By Franchise"}
          </span>
        </KeyVal>

        {/* filterByFranchiseGroupType */}
        {data?.filterByFranchiseType == "ByGroup" ? (
          <KeyVal
            label=" Group Type :"
            labelCol="col-5"
            contentCol="col-7"
            className="text-primary"
          >
            <span className=" fw-semibold">
              {data?.filterByFranchiseGroupType || "N/A"}
            </span>
          </KeyVal>
        ) : null}

        {data?.filterByFranchiseType == "ByFranchise" ? (
          <div className="mb-2">
            <KeyVal
              label="Franchise:"
              labelCol="col-5"
              contentCol="col-7"
              className="text-primary"
            >
              {franchise.loading ? (
                <Spinner type="dots" />
              ) : (
                <span className=" fw-semibold">{franchise.data}</span>
              )}
            </KeyVal>
          </div>
        ) : null}

        <div className="mb-2">
          <KeyVal
            label="Criteria Type :"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            <span>{data?.filterByConditionType || "N/A"}</span>
          </KeyVal>
        </div>

        {brand?.length > 0 ? (
          <div className="mb-2">
            <KeyVal
              label="Brand:"
              labelCol="col-5"
              contentCol="col-7"
              className="fw-semibold"
            >
              <span>{brand || ""} </span>
            </KeyVal>
          </div>
        ) : null}

        {category?.length > 0 ? (
          <div className="mb-2">
            <KeyVal
              label="Category:"
              labelCol="col-5"
              contentCol="col-7"
              className="fw-semibold"
            >
              <span>{category || ""} </span>
            </KeyVal>
          </div>
        ) : null}

        {deal?.length > 0 ? (
          <div className="mb-2">
            <KeyVal
              label="Deal:"
              labelCol="col-5"
              contentCol="col-7"
              className="fw-semibold"
            >
              <span>{deal || ""} </span>
            </KeyVal>
          </div>
        ) : null}
      </AppCard>
    </>
  );
};

export default ApplyBannerOnDetails;
