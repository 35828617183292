import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import WidgetList from "./pages/app-config/widget/list/WidgetList";
import ListBanner from "./pages/banner/list/BannerList";
import ManageBanner from "./pages/banner/manage/ManageBanner";
import ViewBanner from "./pages/banner/view/ViewBanner";
import ClubDealPurchaseLimitList from "./pages/club-deal-purchase-limit/list/ClubDealPurchaseLimitList";
import DealRestrictionList from "./pages/deal-restriction/list/DealRestrictionList";
import LuckyDrawList from "./pages/lucky-draw/list/LuckyDrawList";
import MarketingDashboard from "./pages/marketing-promotion/dashboard/MarketingDashboard";
import MarketingPromotionList from "./pages/marketing-promotion/list/MarketingPromotionList";
import ViewMarketingPromotion from "./pages/marketing-promotion/view/ViewMarketingPromotion";
import SmartCoinRewardList from "./pages/smartcoin-reward/list/SmartCoinRewardList";
import ListStoreDiscount from "./pages/store-discount/list/ListStoreDiscount";
import BannerLocationMasterList from "./pages/banner-location-master/list/BannerLocationMasterList";
import BannerDesignMasterList from "./pages/banner-design-master/BannerDesignMasterList";

const rbac = {
  promotion: {
    list: ["ListPromotionalconfig"],
    view: ["ViewPromotionalconfig"],
    manage: ["EditPromotionalconfig"],
  },
  widget: {
    list: ["ListWidget", "WidgetEdit", "WidgetCreate"],
  },
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "configs",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/configs/list"></Navigate>,
          },
          {
            path: "store-discount",
            element: (
              <ProtectedRoute
                roles={[
                  "EditStoreOffersConfig",
                  "ViewStoreOffersConfig",
                  "AddStoreOffersConfig",
                ]}
              >
                <ListStoreDiscount />
              </ProtectedRoute>
            ),
          },
          {
            path: "store/lucky-draw",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <LuckyDrawList />
              </ProtectedRoute>
            ),
          },
          {
            path: "marketing/promotion",
            element: (
              <ProtectedRoute roles={rbac.promotion.list}>
                <MarketingPromotionList />
              </ProtectedRoute>
            ),
          },

          {
            path: "marketing/promotion/view",
            element: (
              <ProtectedRoute roles={rbac.promotion.view}>
                <ViewMarketingPromotion />
              </ProtectedRoute>
            ),
          },
          {
            path: "app-config/widget/list",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <WidgetList />
              </ProtectedRoute>
            ),
          },
          {
            path: "banner/list",
            element: (
              <ProtectedRoute
                rolesArray={["ViewBrand", "ViewBanner", "ListBanner"]}
              >
                <ListBanner />
              </ProtectedRoute>
            ),
          },
          {
            path: "marketing/dashboard",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <MarketingDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "banner/view",
            element: (
              <ProtectedRoute rolesArray={["ViewBrand", "ViewBanner"]}>
                <ViewBanner />
              </ProtectedRoute>
            ),
          },
          {
            path: "smartcoin-reward",
            element: (
              <ProtectedRoute roles={["ListReferralConfig"]}>
                <SmartCoinRewardList />
              </ProtectedRoute>
            ),
          },
          {
            path: "banner/manage",
            element: (
              <ProtectedRoute
                rolesArray={["EditBanner", "AddBanner", "ListBanner"]}
              >
                <ManageBanner />
              </ProtectedRoute>
            ),
          },
          {
            path: "app-config/widget/list",
            element: (
              <ProtectedRoute roles={rbac.widget.list}>
                <WidgetList />
              </ProtectedRoute>
            ),
          },
          {
            path: "deal/restriction",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <DealRestrictionList />
              </ProtectedRoute>
            ),
          },
          {
            path: "club-deal-purchase-limit",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <ClubDealPurchaseLimitList />
              </ProtectedRoute>
            ),
          },
          {
            path: "market/dashboard/location-master",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <BannerLocationMasterList />
              </ProtectedRoute>
            ),
          },
          {
            path: "market/dashboard/design-master",
            element: (
              <ProtectedRoute roles={["EditCategory", "AddCategory"]}>
                <BannerDesignMasterList />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
