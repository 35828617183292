import { BrandService, CategoryService, DealService } from "@sk/services";
import { NoDataFound, PageLoader, TableHeader } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "35%" };

const headers = [
  { label: "Sl No", width: "10%", isCentered: true },
  { label: "ID", isCentered: false },
  { label: "Name", isCentered: false },
];

const getData = async (type, params) => {
  let r = "";
  if (type == "multiDeals") {
    r = await DealService.getDeals(params);
  } else if (type == "multiCategories") {
    r = await CategoryService.getList(params);
  } else if (type == "multiBrands") {
    r = await BrandService.getList(params);
  }
  return Array.isArray(r.resp) ? r.resp : [];
};

const ViewMultiDeals = ({ show, callback, ids, type }) => {
  const [data, setData] = useState([]);

  const [title, setTitle] = useState(type);

  const [display, setDisplay] = useState("loading");

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && ids?.length) {
      loadDealDetails(type, ids);
      loadLabel();
    } else {
      setDisplay("notFound");
    }
  }, [show, ids, loadLabel]);

  const loadDealDetails = useCallback(
    async (type, idsList) => {
      setDisplay("loading");
      const p = { select: "name,_id", filter: { _id: { $in: idsList } } };
      const d = await getData(type, p);
      setData(d);
      setDisplay("details");
    },
    [data]
  );

  const loadLabel = (type) => {
    let t = "";
    if (type == "multiDeals") {
      t = "Multi Deals";
    } else if (type == "multiCategories") {
      t = "Multi Categories";
    } else if (type == "multiBrands") {
      t = "Multi Brands";
    }
    setTitle(t);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> View {title} Deatils </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            {display == "loading" ? <PageLoader animation="border" /> : null}

            {/* NO RECORD */}
            {display === "notFound" && (
              <NoDataFound> No Data Found </NoDataFound>
            )}
            {display == "details" ? (
              /* Table */
              <>
                <table className="table table-bordered bg-white">
                  <TableHeader data={headers} />
                  <tbody className="fs-val-md">
                    {!data?.length && (
                      <tr>
                        <td colSpan={headers.length}>
                          <NoDataFound>No Data Found</NoDataFound>
                        </td>
                      </tr>
                    )}
                    {data?.length &&
                      data.map((x, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{x?._id}</td>
                          <td>{x?.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewMultiDeals);
