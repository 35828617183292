import { DatePickerInput, SelectInput, EntitySearchInput } from "@sk/uis";
import { sub, set, setDate } from "date-fns";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const createdOnDateConfig = {
  mode: "range",
  maxDate: new Date(),
  defaultDate: [
    set(new Date(2024, 0, 1), { hours: 0, minutes: 0 }),
    set(new Date(), { hours: 23, minutes: 59 }),
  ],
};

const candidateOptions = [
  { label: "All Franchise/Customer", value: "" },
  { label: "Franchise", value: "Franchise" },
  { label: "Customer", value: "Customer" },
];

const defaultFormData = {
  typeOfCandidate: "",
  mobileNo: "",
  createdAt: [
    set(new Date(2024, 0, 1), { hours: 0, minutes: 0 }),
    set(new Date(), { hours: 23, minutes: 59 }),
  ],
};

const FilterForm = ({ callback }) => {
  const { handleSubmit, getValues, register, control } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const onSearch = useCallback(
    debounce((ev) => {
      callback({ formData: getValues() });
    }, 700),
    []
  );

  const onDateChange = useCallback((chnFn) => {
    return (val) => {
      chnFn(val);
      callback({ formData: getValues() });
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          {/* Search By Customer Mobile No */}
          <div className="col-4 ms-auto">
            <Controller
              control={control}
              name="franchise"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="franchise"
                  placeholder="Search Franchise By Name/ID"
                  value={value}
                  callback={onSearch(onChange)}
                  uid="franchise"
                  isMultiple={false}
                  isMandatory={false}
                  emptyLabel="No Smart Stores found"
                />
              )}
            />
          </div>

          <div className="col-auto pt-0">
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Choose Date Range"
                  value={value}
                  inpChange={onDateChange(onChange)}
                  config={createdOnDateConfig}
                />
              )}
            />
          </div>

          {/* Franchise / Customer */}
          {/* <div className="col-auto">
            <SelectInput
              label=""
              register={register}
              name="typeOfCandidate"
              callback={onSearch}
              options={candidateOptions}
            />
          </div> */}
        </div>
      </form>
    </>
  );
};

export default memo(FilterForm);
