import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";
import layoutOne from "../../../assets/imgs/layoutOne.png";
import layoutTwo from "../../../assets/imgs/layoutTwo.png";
import layoutThree from "../../../assets/imgs/layoutThree.png";
import layoutFour from "../../../assets/imgs/layoutFour.png";
import layoutFive from "../../../assets/imgs/layoutFive.png";
import layoutSix from "../../../assets/imgs/layoutSix.png";
import layoutSeven from "../../../assets/imgs/layoutSeven.png";
import layoutEight from "../../../assets/imgs/layoutEight.png";
import layoutNine from "../../../assets/imgs/layoutNine.png";
import layoutEleven from "../../../assets/imgs/layoutEleven.png";
import layoutTwelve from "../../../assets/imgs/layoutTwelve.png";
import layoutThirteen from "../../../assets/imgs/layoutThirteen.png";
import layoutFourteen from "../../../assets/imgs/layoutFourteen.png";
import layoutFifteen from "../../../assets/imgs/layoutFifteen.png";
import layoutSixteen from "../../../assets/imgs/layoutSixteen.png";
import layoutSeventeen from "../../../assets/imgs/layoutSeventeen.png";
import layoutEighteen from "../../../assets/imgs/layoutEighteen.png";

export const widgetFeatures = [
  {
    value: "menu",
    label: "Menu",
  },
  {
    value: "category",
    label: "Category",
  },
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "deal",
    label: "Deal",
  },
  {
    value: "banner",
    label: "Banner",
  },
  {
    value: "offers",
    label: "Offers",
  },
  {
    value: "coinStore",
    label: "Coin Store",
  },
  {
    value: "comboDeals",
    label: "Combo Deals",
  },
];

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Widget Config's List",
    },
  ],
  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      isClickable: true,
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active",
      value: 0,
      loading: true,
      isClickable: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive",
      value: 0,
      loading: true,
      isClickable: true,
      filter: { status: "InActive" },
      color: "danger",
      img: inactiveImg,
    },
  ],
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "franchise.id",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 30,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 30,
  },

  filterFromData: {
    search: "",
    franchise: [],
    priority: "",
    // status: "Active",
  },

  formLabels: {
    status: {
      label: "Status",
      type: "Boolean",
    },
    createdAt: {
      label: "Created On",
      type: "dateRange",
    },
    page: {
      label: "Page",
    },
    priority: {
      label: "Priority",
    },
    feature: {
      label: "Feature",
    },
    widget: {
      label: "Widget",
    },
    bannerSlot: {
      label: "Banner Slot",
    },
    validity: {
      label: "Validity Date Range",
      type: "dateRange",
    },
    sortBy: {
      label: "Sort By",
    },
    search: {
      label: "Search",
    },
  },

  layouts: [
    {
      label: "Layout One",
      value: "layoutOne",
      image: layoutOne,
      canUpdateAsset: true,
      canUpdateBg: true,
    },
    {
      label: "Layout Two",
      value: "layoutTwo",
      image: layoutTwo,
      canUpdateAsset: true,
      canUpdateBg: true,
    },
    {
      label: "Layout Three",
      value: "layoutThree",
      image: layoutThree,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Four",
      value: "layoutFour",
      image: layoutFour,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Five",
      value: "layoutFive",
      image: layoutFive,
      canUpdateAsset: true,
      canUpdateBg: true,
    },
    {
      label: "Layout Six",
      value: "layoutSix",
      image: layoutSix,
      canUpdateAsset: true,
      canUpdateBg: true,
    },
    {
      label: "Layout Seven",
      value: "layoutSeven",
      image: layoutSeven,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Eight",
      value: "layoutEight",
      image: layoutEight,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Nine",
      value: "layoutNine",
      image: layoutNine,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Eleven",
      value: "layoutEleven",
      image: layoutEleven,
      canUpdateAsset: true,
      canUpdateBg: true,
    },
    {
      label: "Layout Twelve",
      value: "layoutTwelve",
      image: layoutTwelve,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Thirteen",
      value: "layoutThirteen",
      image: layoutThirteen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Fourteen",
      value: "layoutFourteen",
      image: layoutFourteen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Fifteen",
      value: "layoutFifteen",
      image: layoutFifteen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Sixteen",
      value: "layoutSixteen",
      image: layoutSixteen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Seventeen",
      value: "layoutSeventeen",
      image: layoutSeventeen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
    {
      label: "Layout Eighteen",
      value: "layoutEighteen",
      image: layoutEighteen,
      canUpdateAsset: false,
      canUpdateBg: true,
    },
  ],

  widgetType: [
    {
      label: "Select Widget",
      value: "",
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
      ],
    },
    {
      label: "Menu Widget",
      value: "menu",
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",

        "layoutThirteen",
        "layoutFourteen",
        "layoutFifteen",
        "layoutSixteen",
        "layoutSeventeen",
        "layoutEighteen",
      ],
    },
    {
      label: "Category Widget",
      value: "category",
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
        "layoutThirteen",
        "layoutFourteen",
        "layoutFifteen",
        "layoutSixteen",
        "layoutSeventeen",
        "layoutEighteen",
      ],
    },
    {
      label: "Brand Widget",
      value: "brand",
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
        "layoutThirteen",
        "layoutFourteen",
        "layoutFifteen",
        "layoutSixteen",
        "layoutSeventeen",
        "layoutEighteen",
      ],
    },
    {
      label: "Deal Widget",
      value: "deal",
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
        "layoutThirteen",
        "layoutFourteen",
        "layoutFifteen",
        "layoutSixteen",
        "layoutSeventeen",
        "layoutEighteen",
      ],
    },
    {
      label: "Banner Widget",
      value: "banner", // no layout no features
      features: [
        "",
        "menu",
        "category",
        "brand",
        "categoryUnderMenu",
        "popularProduct",
        "slowMoving",
        "nonMoving",
        "recentlyPurchased",
        "dealsUnderMenu",
        "dealsUnderCategory",
        "newlyAdded",
        "recentlySold",
        "ConsumerOffer",
        "bogoOffers",
        "kingSlabOffers",
      ],
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
        "layoutThirteen",
        "layoutFourteen",
        "layoutFifteen",
        "layoutSixteen",
        "layoutSeventeen",
        "layoutEighteen",
      ],
    },
  ],

  pages: [
    { label: "Select Page", value: "" },
    { label: "Home Page", value: "HOME_PAGE" },
    // { label: "Product List", value: "PRODUCT_LIST" },
    // { label: "Product Detail", value: "PRODUCT_DETAIL" },
  ],
  configTypes: [
    // { label: "Select config", value: "" },
    { label: "Global", value: "global" },
    { label: "Smart Store", value: "franchise" },
  ],
  features: [
    {
      label: "Select Feature",
      value: "",
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFour",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEight",
        "layoutNine",
        "layoutEleven",
        "layoutTwelve",
      ],
    },
    {
      label: "Menu",
      value: "menu",
      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEleven",
      ],
    },
    {
      label: "Category",
      value: "category",

      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFive",
        "layoutEleven",
        "layoutSix",
        "layoutSeven",
      ],
    },
    {
      label: "Brand",
      value: "brand",

      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
        "layoutEleven",
      ],
    },
    {
      label: "Category Under Menu",
      value: "categoryUnderMenu",

      widgets: [
        "layoutOne",
        "layoutTwo",
        "layoutThree",
        "layoutFive",
        "layoutSix",
        "layoutSeven",
      ],
    },
    {
      label: "Popular Products (Sold last 15 / 30 days )",
      value: "popularProducts",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },
    {
      label: "Slow Moving",
      value: "slowMoving",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },
    {
      label: "Non Moving",
      value: "nonMoving",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },
    {
      label: "Recently Purchased",
      value: "recentlyPurchased",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },
    {
      label: "Deals Under Menu",
      value: "dealsUnderMenu",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },
    {
      label: "Deals Under Category",
      value: "dealsUnderCategory",

      widgets: ["layoutFour", "layoutEight", "layoutNine", "layoutTwelve"],
    },

    { label: "Newly Added  (last 7 days) ", value: "newlyAdded", widgets: [] },

    {
      label: "Recently Sold  (last 24 / 48 hours) ",
      value: "recentlySold",
      widgets: [],
    },

    { label: "Consumer Offer", value: "ConsumerOffer", widgets: [] },

    { label: "BOGO Offers", value: "bogoOffers", widgets: [] },

    { label: "King Slab Offers", value: "kingSlabOffers", widgets: [] },
  ],

  info: {
    priority: "",
    feature: "",
  },
};

export const sortOptions = [
  {
    value: "Popular",
    label: "Popular",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Newly Launched",
    label: "Newly Launched",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Slow Moving",
    label: "Slow Moving",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Non Moving",
    label: "Non Moving",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Recently Purchased",
    label: "Recently Purchased",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Recently Sold",
    label: "Recently Sold",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "High Discount",
    label: "High Discount",
    allowed: ["menu", "category", "brand", "deal"],
  },
  {
    value: "Discount Range",
    label: "Discount Range",
    allowed: ["deal"],
  },
  {
    value: "Fixed Price Range",
    label: "Fixed Price Range",
    allowed: ["deal"],
  },
  // {
  //   value: "Coin Store",
  //   label: "Coin Store",
  //   allowed: ["deal"],
  // },
];

export default listView;

const bs = Array.from({ length: 10 }, (_, e) => ({
  label: "Home Page - Level " + e,
  value: "LEVEL_" + e,
})).filter((_, k) => k > 1);
bs.unshift({ label: "All", value: "" });
export const bannerSlotOptions = bs;
