/* eslint-disable jsx-a11y/label-has-associated-control */
import { memo, useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm, useWatch } from "react-hook-form";

import { add, differenceInSeconds } from "date-fns";

import {
  AjaxService,
  CommonService,
  CustomerService,
  FranchiseService,
} from "@sk/services";
import {
  Alert,
  AppTitle,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  PageLoader,
  SelectInput,
  Spinner,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { cloneDeep, orderBy } from "lodash";
import { Offcanvas } from "react-bootstrap";
import * as yup from "yup";
import listView from "../../constantService";
import BulkCustomerUpload from "./BulkCustomerUploadModal";
import SmartCoinRewardSlab from "../components/SmartCoinRewardSlab";

const formKey = listView.formKeys;

const headers = [
  { label: "Sl.no", isCentered: true },
  { label: "Customer ID" },
  { label: "Action", isCentered: true },
];

const info = {
  orderLimit: { content: "Rewards will be provided up to this limit." },
  monthlyLimit: {
    content: "Rewards will be provided up to this limit per Month.",
  },
};

const validationSchema = yup.object({
  // [formKey.type]: yup.string().required("Type is required"),

  [formKey.ruleType]: yup.string().required("Rule Type is required"),

  [formKey.smartStore]: yup.array().when(formKey.type, {
    is: (type) => type == "smartstore",
    then: (schema) =>
      schema
        .label("Smart Store")
        .min(1, "Please add Smart Store")
        .required("Please add Smart Store"),
  }),

  [formKey.groupType]: yup.string().when(formKey.type, {
    is: (type) => type == "groupType",
    then: (schema) =>
      schema.label("Group Type").required("Group Type is Required"),
  }),

  [formKey.minOrderValue]: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when([formKey.ruleType], ([ruleType], schema) => {
      if (ruleType == "ParentCoinReward") {
        return schema.notRequired();
      } else {
        return schema
          .required()
          .moreThan(0, "Order Value must be greater than zero");
      }
    }),

  [formKey.parentSmartReward]: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("ruleType", {
      is: (ruleType) =>
        ["NoInviteFirstorder", "ParentCoinReward"].indexOf(ruleType) == -1,
      then: (schema) =>
        schema
          .label("SK Coins")
          .moreThan(0, "coins must be greater than zero")
          .required(" SK Coins are required "),
    }),

  [formKey.childSmartReward]: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("ruleType", {
      is: (ruleType) => ruleType != "ParentCoinReward",
      then: (schema) =>
        schema
          .label()
          .moreThan(0, "coins must be greater than zero")
          .required("SK Coins are required "),
    }),

  // [formKey.orderLimit]: yup
  //   .number()
  //   .nullable()
  //   .transform((v) => (isNaN(v) ? null : v))
  //   .when("ruleType", {
  //     is: (ruleType) => ruleType == "ParentCoinReward",
  //     then: (schema) =>
  //       schema
  //         .label()
  //         .moreThan(0, "Order limit must be greater than 0")
  //         .required("Order limit is required"),
  //   }),

  // [formKey.couponCode]: yup
  //   .string()
  //   .trim()
  //   .when("ruleType", {
  //     is: (ruleType) => ruleType == "ReferralCoupon",
  //     then: (schema) =>
  //       schema.label("Coupon Code").required("Coupon Code is required"),
  //   }),

  validFrom: yup
    .date()
    .typeError("Valid From Date is Required")
    .required("Valid From Date is Required "),

  validTo: yup
    .date()
    .min(
      add(new Date(), { minutes: 1 }),
      "Must be greater than current Date and Time"
    )
    .required("Valid to Date is Required"),

  parentEligibleLimit: yup
    .number()
    .transform((v) => (!isNaN(v) ? v : null))
    .nullable()
    .when("ruleType", {
      is: "ReferralCoupon",
      then: (schema) =>
        schema.required().min(1).positive().integer().label("Network Size"),
    }),

  slabs: yup.array().label("Slabs"),

  monthlyLimit: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .nullable()
    .when(["ruleType", "orderLimit"], ([ruleType, orderLimit], schema) => {
      if (ruleType == "ParentCoinReward") {
        return (
          schema
            .integer()
            .positive()
            // .max(orderLimit, "Cannot be greater than Order Limit")
            .required()
        );
      } else {
        return schema.notRequired();
      }
    })
    .label("Monthly Limit"),
});

const defaultFormData = {
  [formKey.type]: "",
  [formKey.groupType]: "",
  [formKey.smartStore]: [],
  [formKey.minOrderValue]: "",
  [formKey.parentSmartReward]: "",
  [formKey.childSmartReward]: "",
  [formKey.couponCode]: "",
  [formKey.customer]: [],
  [formKey.customersList]: [],
  [formKey.orderLimit]: "",
  parentEligibleLimit: 1,
  disableEdit: false,
};

const typeOptions = [
  { label: "Select Type", value: "" },
  { label: "Group Type", value: "groupType" },
  { label: "Smart Store", value: "smartstore" },
];

const groupTypeOptions = [
  { label: "Select group Type", value: "" },
  { label: "COCO", value: "COCO" },
  { label: "FOFO", value: "FOFO" },
];

const ruleTypeOptions = [
  { label: "Select Rule Type", value: "" },
  { label: "Joining Bonus", value: "NoInviteFirstorder" },
  { label: "Refer a Friend with Link", value: "DirectInviteFirstorder" },
  { label: "Refer a Friend without Link", value: "IndirectInviteFirstorder" },
  {
    label: "Reward for Child Order",
    value: "ParentCoinReward",
  },
  { label: "Coupon Reward", value: "ReferralCoupon" },
  {
    label: "Reward for Guest Order",
    value: "GuestReferredfirstorder",
  },
];

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
  defaultDate: new Date(),
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "45%",
  },
  alertStyle: {
    zIndex: 2000,
  },
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

const resetDefaultOptions = () => {
  startDateConfig = {
    minDate: new Date(),
    enableTime: true,
    dateFormat: "d M Y G:i K",
    defaultDate: new Date(),
  };
};

const getEntitySearchResult = async (type, id) => {
  if (type == "franchise") {
    const r = await FranchiseService.getList({
      filter: {
        _id: id,
        ...franchiseSearchFilter.filter,
      },
    });
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }
};

const SmartCoinRewardManageModal = ({ show, callback, mode, id }) => {
  const [submitting, setSubmitting] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);

  const [showBulkUploadModal, setShowBulkUpload] = useState(false);

  const editDataRef = useRef({});

  const {
    formState: { errors },
    setValue,
    getValues,
    control,
    register,
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultFormData },
  });

  const [type, ruleType, customersList, disableEdit, slabs] = useWatch({
    control,
    name: [
      formKey.type,
      formKey.ruleType,
      formKey.customersList,
      "disableEdit",
      "slabs",
    ],
  });

  useEffect(() => {
    if (show) {
      // when the modal get opened clear the form data
      reset(defaultFormData);

      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
      // when mode is add we need to re-configure Date
      if (mode == "add") {
        resetDefaultOptions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id, show]);

  const triggerCloseModal = (status = "close") => {
    editDataRef.current = {};
    callback({ status });
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      if (!val.length) {
        return;
      }

      chngFn(val);

      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = {
          ...endDateConfig,
          minDate: add(val[0], { minutes: 10 }),
        };
      }
      trigger("validTo");
    };
  };

  const onParentRewardChange = (e) => {
    onNumberInputChange(e, 4);
  };
  const onChildRewardChange = (e) => {
    onNumberInputChange(e, 4);
  };

  const onNumberInputChange = (e, digit = 6) => {
    let val = e.target.value?.slice(0, digit) * 1;
    val = CommonService.roundedByDecimalPlace(val, 0);
    if (val <= 0) {
      val = "";
    }
    e.target.value = val;
  };

  const onSubmit = async (data) => {
    // asking confirm to submit
    // if (data.ruleType == "ReferralCoupon" && !data?.customersList?.length) {
    //   Toaster.error("Please add Customer before saving");
    //   return;
    // }

    console.log(data);

    if (data.ruleType == "ParentCoinReward" && !data.slabs?.length) {
      Toaster.error("Please provide slabs");
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to ${mode == "edit" ? "update " + id : "proceed"} ? `,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);

    let r = {};

    if (mode == "add") {
      r = await CustomerService.createSmartCoinReward(p);
    } else {
      r = await CustomerService.updateSmartCoinReward(id, p);
    }

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  const resetFormData = () => {
    reset(defaultFormData);
    //  to enable Config
    endDateConfig = {
      enableTime: true,
      dateFormat: "d M Y G:i K",
    };
  };

  const prepareFormValues = async (data) => {
    setValue(formKey.ruleType, data.type);

    setValue(formKey.minOrderValue, data.slab[0].min);

    setValue(formKey.parentSmartReward, data.slab[0].senderValue);

    setValue(formKey.childSmartReward, data.slab[0].reciverValue);

    if (data.groupType) {
      setValue(formKey.type, "groupType");

      setValue(formKey.groupType, data.groupType);
    }

    if (data.validityDate) {
      [
        { formKey: "validFrom", apiKey: "from" },
        { formKey: "validTo", apiKey: "to" },
      ].forEach((x) => {
        setValue(x.formKey, new Date(data.validityDate[x.apiKey]));
      });

      const startDate = new Date(data.validityDate.from);

      endDateConfig = {
        ...endDateConfig,
        minDate: add(startDate, { minutes: 10 }),
      };

      startDateConfig = { ...startDateConfig, minDate: startDate };

      if (differenceInSeconds(startDate, new Date()) < 0) {
        setValue("disableEdit", true);
      }
    }

    if (data.franchiseId) {
      const resp = await getEntitySearchResult("franchise", data.franchiseId);
      setValue(formKey.smartStore, resp.data);
      setValue(formKey.type, "smartStore");
    }

    if (data[formKey.couponCode]) {
      setValue(formKey.couponCode, data[formKey.couponCode]);
    }

    if (data[formKey.customersList].length) {
      let list = data[formKey.customersList].map((x) => ({ id: x }));
      setValue(formKey.customersList, list);
    }
    if (data.orderLimitValidity.orderLimit) {
      setValue(formKey.orderLimit, data.orderLimitValidity.orderLimit);
    }

    if (data.type == "ParentCoinReward") {
      setValue("monthlyLimit", data?.monthlyOrderLimitValidity?.orderLimit);
      setValue(
        "slabs",
        (data.slab || []).map((e) => ({
          min: e.min,
          max: e.max,
          coins: e.senderValue,
        }))
      );
    }

    setValue("parentEligibleLimit", data.parentEligibleLimit);
  };

  const loadEditData = async (id) => {
    setPageLoading(true);
    const r = await CustomerService.getSmartCoinRewardById(id);
    const d = r.resp || {};

    editDataRef.current = cloneDeep(d);

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }
    await prepareFormValues(d);

    setPageLoading(false);
  };

  const preparePayload = (data) => {
    const d = cloneDeep(data);

    let smartStore = d[formKey.smartStore];

    const p = {
      ...editDataRef.current,
      [formKey.type]: d[formKey.ruleType],
      validityDate: {
        from: d[formKey.validFrom],
        to: d[formKey.validTo],
      },
    };

    if (d.ruleType == "ReferralCoupon") {
      p.parentEligibleLimit = d.parentEligibleLimit;
    }

    if (p[formKey.type] == "groupType") {
      p[formKey.groupType] = d[formKey.groupType];
    }

    if (p[formKey.type] == "smartstore") {
      p[formKey.smartStore] = smartStore[0]?.value?._id;
    }

    if (data[formKey.couponCode]) {
      p[formKey.couponCode] = data[formKey.couponCode];
    }

    if (data[formKey.customersList].length) {
      p[formKey.customersList] = data[formKey.customersList].map((x) => x.id);
    }

    if (mode == "add") {
      const slab = [
        {
          min: d[formKey.minOrderValue],
          max: 50000,
          senderValue: d[formKey.parentSmartReward],
          reciverValue: d[formKey.childSmartReward],
        },
      ];

      p.slab = slab;
    }

    if (mode == "edit") {
      p.slab[0].min = d[formKey.minOrderValue];
      p.slab[0].senderValue = d[formKey.parentSmartReward];
      p.slab[0].reciverValue = d[formKey.childSmartReward];
    }

    if (d[formKey.ruleType] == "NoInviteFirstorder") {
      p.slab[0].senderValue = 0;
    }

    // if (d[formKey.ruleType] == "ParentCoinReward") {
    //   p.slab[0].reciverValue = 0;
    // }

    if (
      data[formKey.orderLimit] &&
      data[formKey.ruleType] != "ParentCoinReward"
    ) {
      p.orderLimitValidity = { orderLimit: data[formKey.orderLimit] };
    }

    if (data[formKey.ruleType] == "ParentCoinReward") {
      p.monthlyOrderLimitValidity = { orderLimit: data.monthlyLimit };
      p.slab = orderBy(
        data.slabs.map((e) => ({
          min: e.min,
          max: e.max,
          senderValue: e.coins,
        })),
        ["min"],
        ["asc"]
      );
    } else {
      delete p.monthlyOrderLimitValidity;
    }

    return p;
  };

  const addCustomers = () => {
    const customer = getValues(formKey.customer);
    const list = getValues(formKey.customersList);

    if (!customer.length) {
      Toaster.error("Please search for customer before adding ");
      return;
    }

    let obj = {
      name: customer[0].value.fame,
      id: customer[0].value._id,
    };

    const isExists = list.filter((x) => x.id == obj.id);

    if (isExists.length) {
      Toaster.error(obj.id + "is  already in list");
      return;
    }
    list.push(obj);
    setValue(formKey.customersList, list);

    setValue(formKey.customer, []);
  };

  const deleteCustomer = (index) => {
    const list = getValues(formKey.customersList);

    list.splice(index, 1);

    setValue(formKey.customersList, list);
  };

  const onRuleTypeChange = () => {
    [formKey.orderLimit, formKey.childSmartReward, formKey.couponCode].forEach(
      (key) => {
        setValue(key, "");
      }
    );
    setValue(formKey.customersList, []);
  };

  const onCouponCodeChange = (e) => {
    let val = e?.target?.value?.trim()?.toUpperCase();
    e.target.value = val;
  };

  const cusBulkUploadCb = (payload) => {
    const { action, data } = payload;
    if (action == "submit") {
      const cIds = data.cIds.split(",").map((x) => ({ id: x }));
      const list = getValues(formKey.customersList);
      list.push(...cIds);
      setValue(formKey.customersList, list);
    }

    setShowBulkUpload(false);
  };

  const rewardSlabCb = (e) => {
    setValue("slabs", e.slabs);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={
              mode === "edit"
                ? "Edit StoreKing Coins Reward Config" + `  #${id}`
                : "Add StoreKing Coins Reward Config"
            }
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div className="p-4 bg-light border-bottom">
                <div className="row mb-2">
                  {/* Smart Store */}

                  {/* <div className="col-6">
                    <SelectInput
                      name={formKey.type}
                      label="Select Type"
                      isMandatory
                      register={register}
                      options={typeOptions}
                      error={errors?.[formKey.type]?.message}
                    />
                  </div> */}

                  <div className="col-6">
                    <SelectInput
                      name={formKey.ruleType}
                      label="Select Rule Type"
                      isMandatory
                      register={register}
                      options={ruleTypeOptions}
                      callback={onRuleTypeChange}
                      disabled={mode == "edit" && disableEdit}
                      info={listView.ruleTypeInfo[ruleType]}
                      error={errors?.[formKey.ruleType]?.message}
                    />
                  </div>

                  {/* {ruleType == "ReferralCoupon" && (
                    <div className="col-6">
                      <TextInput
                        label="Coupon Code"
                        name={formKey.couponCode}
                        register={register}
                        isMandatory
                        callback={onCouponCodeChange}
                        placeholder="Enter Coupon Code"
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.couponCode]?.message}
                      />
                    </div>
                  )} */}

                  {type == "groupType" && (
                    <div className="col-12">
                      <SelectInput
                        name={formKey.groupType}
                        label="Select Group Type"
                        register={register}
                        isMandatory
                        options={groupTypeOptions}
                        error={errors?.[formKey.groupType]?.message}
                        disabled={mode == "edit" && disableEdit}
                      />
                    </div>
                  )}

                  {type == "smartstore" && (
                    <div className="col-12 mb-2">
                      <Controller
                        control={control}
                        name={formKey.smartStore}
                        render={({ field: { onChange, value } }) => (
                          <EntitySearchInput
                            type="franchise"
                            label="Smart Store"
                            placeholder="Search by Store Name / ID"
                            value={value}
                            callback={onChange}
                            uid="franchise"
                            emptyLabel={"No Smart Store found"}
                            gap={0}
                            disabled={mode == "edit" && disableEdit}
                            filterParams={franchiseSearchFilter}
                            isMandatory={true}
                            error={errors?.franchise?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  {/*  Valid From Date   */}
                  <div className="col-6 mb-3">
                    <label className=" mb-1 fs-val-md">
                      Validity From
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name={formKey.validFrom}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePickerInput
                            placeholder="Choose"
                            value={[value]}
                            disabled={mode == "edit" && disableEdit}
                            inpChange={dateChangeCb(onChange, "validFrom")}
                            config={startDateConfig}
                          />
                        );
                      }}
                    />
                    <InputErrorMsg msg={errors?.validFrom?.message} />
                  </div>

                  {/* Valid To Date */}
                  <div className="col-6  mb-3">
                    <label className="mb-1 fs-val-md">
                      Validity To
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name={formKey.validTo}
                      render={({ field: { onChange, value } }) => (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          disabled={mode == "edit" && disableEdit}
                          inpChange={dateChangeCb(onChange, "validTo")}
                          config={endDateConfig}
                        />
                      )}
                    />
                    <InputErrorMsg msg={errors?.validTo?.message} />
                  </div>

                  {ruleType != "ParentCoinReward" ? (
                    <div className="col-4 mb-2">
                      <TextInput
                        register={register}
                        name={formKey.minOrderValue}
                        type="number"
                        label="Minimum Order Value (Rs.)"
                        maxLength={5}
                        callback={onNumberInputChange}
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.minOrderValue]?.message}
                        placeholder="Enter Min Order Value Here"
                      />
                    </div>
                  ) : null}

                  {ruleType != "NoInviteFirstorder" &&
                  ruleType != "ParentCoinReward" ? (
                    <div className="col-4 mb-2">
                      <TextInput
                        register={register}
                        name={formKey.parentSmartReward}
                        type="number"
                        label="Parent Coins Reward"
                        callback={onParentRewardChange}
                        maxLength={4}
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.parentSmartReward]?.message}
                        placeholder="Enter Reward Here"
                      />
                    </div>
                  ) : null}

                  {ruleType != "ParentCoinReward" && (
                    <div className="col-4 mb-2">
                      <TextInput
                        register={register}
                        name={formKey.childSmartReward}
                        type="number"
                        label={
                          ruleType == "NoInviteFirstorder"
                            ? "StoreKing Coins Reward"
                            : "Child Coins Reward"
                        }
                        callback={onChildRewardChange}
                        maxLength={4}
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.childSmartReward]?.message}
                        placeholder="Enter Reward Here"
                      />
                    </div>
                  )}

                  {/* {ruleType == "ParentCoinReward" ? (
                    <div className="col-4 mb-2">
                      <TextInput
                        register={register}
                        name={formKey.orderLimit}
                        type="number"
                        label="Order Limit"
                        callback={onChildRewardChange}
                        maxLength={4}
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.orderLimit]?.message}
                        placeholder="Enter limit Here"
                      />
                    </div>
                  ) : null} */}

                  {ruleType == "ReferralCoupon" ? (
                    <div className="col-4 mb-2">
                      <TextInput
                        register={register}
                        name="parentEligibleLimit"
                        type="number"
                        label="Network Size"
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.parentEligibleLimit?.message}
                        placeholder="For eg: 10"
                      />
                    </div>
                  ) : null}
                </div>

                {ruleType == "ParentCoinReward" ? (
                  <div className="row">
                    {/* <div className="col-6 mb-2">
                      <TextInput
                        register={register}
                        name={formKey.orderLimit}
                        type="number"
                        label="Order Limit"
                        callback={onChildRewardChange}
                        maxLength={4}
                        isMandatory={true}
                        disabled={mode == "edit" && disableEdit}
                        error={errors?.[formKey.orderLimit]?.message}
                        placeholder="Enter limit Here"
                        info={info.orderLimit}
                      />
                    </div> */}
                    <div className="col-6 mb-2">
                      <TextInput
                        register={register}
                        name="monthlyLimit"
                        type="number"
                        label="Monthly Limit"
                        maxLength={4}
                        isMandatory={true}
                        callback={onNumberInputChange}
                        // disabled={mode == "edit" && disableEdit}
                        error={errors?.monthlyLimit?.message}
                        placeholder="Enter Monthly Limit"
                        info={info.monthlyLimit}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {ruleType == "ParentCoinReward" ? (
                <div className="pt-1">
                  <SmartCoinRewardSlab
                    slabData={slabs}
                    callback={rewardSlabCb}
                  />
                </div>
              ) : null}

              {ruleType == "ReferralCoupon" && (
                <>
                  {/* <div className="row m-0 align-items-end py-4 px-2">
                    <div className="col-12 fs-val-lg mb-3">
                      Customer Details Table
                    </div>
                    <div className="col-8 mb-2">
                      <Controller
                        control={control}
                        name={formKey.customer}
                        render={({ field: { onChange, value } }) => (
                          <EntitySearchInput
                            type="customer"
                            label="Customer Name"
                            placeholder="Search by Customer Name"
                            value={value}
                            callback={onChange}
                            uid="user"
                            emptyLabel={"No Customer found"}
                            gap={0}
                            disabled={mode == "edit" && disableEdit}
                            isMandatory={true}
                            error={errors?.[formKey.customer]?.message}
                          />
                        )}
                      />
                    </div>

                    <div className="col-auto">
                      <button
                        className="btn btn-primary mb-2 fs-val-md me-2"
                        onClick={addCustomers}
                        disabled={mode == "edit" && disableEdit}
                      >
                        ADD
                      </button>

                      <button
                        className="btn btn-outline-dark mb-2 fs-val-md"
                        onClick={() => setShowBulkUpload(true)}
                        disabled={mode == "edit" && disableEdit}
                      >
                        Bulk Upload
                      </button>
                    </div>
                  </div>

                  <table className="table table-bordered">
                    <TableHeader data={headers} />
                    <tbody>
                      {!customersList.length ? (
                        <tr className="fs-val-md text-center">
                          <td colSpan={headers.length}> No Data Found </td>
                        </tr>
                      ) : null}
                      {customersList.length
                        ? customersList.map((cus, i) => (
                            <tr key={cus.id} className="fs-val-md">
                              <td className="text-center">{i + 1}</td>
                              <td className="">{cus.id}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-outline-danger"
                                  onClick={() => deleteCustomer(i)}
                                  disabled={mode == "edit" && disableEdit}
                                >
                                  delete
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table> */}
                </>
              )}
            </>
          )}
        </Offcanvas.Body>
        <Offcanvas.Header>
          {!pageLoading && (
            <div className="my-3 w-100 row justify-content-between">
              <div className="col-12mb-2   text-end">
                <button
                  className="btn btn-primary"
                  // type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                >
                  Submit {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </div>
          )}
        </Offcanvas.Header>
      </Offcanvas>

      <BulkCustomerUpload
        show={showBulkUploadModal}
        customers={customersList}
        callback={cusBulkUploadCb}
      />
    </>
  );
};

export default memo(SmartCoinRewardManageModal);
