import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";

const defaultHeaders = [
  { label: "Sl.No", width: "5%", isCentered: true },
  { label: "Restriction Type", width: "15%", isCentered: true },
  { label: "Restriction", width: "20%" },
  { label: "Location", isCentered: true, checked: true },
  { label: "Level", isCentered: true, checked: true },
  { label: "Franchise", isCentered: true, checked: true },
  { label: "Created On", isCentered: true, checked: true },
  { label: "Modified On", isCentered: true, checked: true },
  { label: "Status", isCentered: true, checked: true },
  { label: "Actions", isCentered: true, width: "10%" },
];

const Table = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  statusToggelBtnCb,
}) => {
  const [headers, setHeaders] = useState([...defaultHeaders]);

  const navigate = useNavigate();

  const viewDetails = (id) => {
    NavService.openInNewTab("/cms/deal/view", { id: id });
  };

  const editDetails = (id) => {
    NavService.openInNewTab("/cms/deal/manage", { id: id });
  };
  console.log("d", data);
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {/*  When Table is Loading  */}

          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Deals Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-sm">
                {/* Priority 1 */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Restriction Type */}
                <td className="text-center">
                  <HighlightText
                    status={x.isAllowed ? "Allowed" : "Restricted"}
                    isBadge={true}
                    size="sm"
                  />
                </td>

                {/* Restriction */}
                <td>
                  {/* Category */}

                  {x.categoryLoading && x.cat_id ? (
                    <div>
                      <Spinner type="dots" />
                    </div>
                  ) : null}

                  {x?.cat_id && !x.categoryLoading ? (
                    <div>
                      Category :
                      <span
                        className="text-primary cursor-pointer"
                        role="button"
                        tabIndex={-1}
                        onClick={() =>
                          NavService.navToCategory(navigate, {
                            id: x._category?._id,
                          })
                        }
                      >
                        {x?._category?.name} - {x?._category?._id}
                      </span>
                    </div>
                  ) : null}

                  {/* brand */}
                  {x.brandLoading && x?.brand_id ? (
                    <div>
                      <Spinner type="dots" />
                    </div>
                  ) : null}

                  {!x.brandLoading && x?.brand_id ? (
                    <div>
                      <span>Brand : </span>
                      <span
                        className="text-primary cursor-pointer"
                        role="button"
                        tabIndex={-1}
                        onClick={() =>
                          NavService.navToBrand(navigate, {
                            id: x._brand?._id,
                          })
                        }
                      >
                        {x?._brand?.name}-{x._brand?._id}
                      </span>
                    </div>
                  ) : null}

                  {/* deal */}
                  {x.dealLoading && x.deal_id ? (
                    <div>
                      <Spinner type="dots" />
                    </div>
                  ) : null}

                  {!x.dealLoading && x.deal_id ? (
                    <div>
                      <span>Deal : </span>
                      <span
                        className="text-primary cursor-pointer"
                        role="button"
                        tabIndex={-1}
                        onClick={() =>
                          NavService.navToDeal(navigate, {
                            id: x._deal?._id,
                          })
                        }
                      >
                        {x?._deal?.name} - {x.deal_id}
                      </span>
                    </div>
                  ) : null}
                </td>

                {/* Location */}
                <td className="text-center">
                  {x?.state_id || x?.territory_id || x?.town_id ? (
                    <div>
                      {x?.state_id} {x?.territory_id} {x?.town_id}
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Level */}
                <td className="text-center">{x?.level}</td>

                {/* Franchise */}
                <td>
                  {x.franchiseLoading && x?.franchise_id ? (
                    <Spinner type="dots" />
                  ) : null}

                  {!x.franchiseLoading && x.franchise_id ? (
                    <>
                      <div className="text-primary" role="button" tabIndex={-1}>
                        {x?._franchise?.name}
                      </div>
                      <div className="text-muted">
                        ID : {x?._franchise?._id}
                      </div>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/*  Created At */}
                <td className="text-center">
                  <div className="text-primary mb-2">
                    {x?.createdBy?.name || "N/A"}
                  </div>
                  <DateFormatter date={x.createdAt} />
                </td>

                {/*  Modified on */}
                <td className="text-center">
                  <DateFormatter date={x.createdAt} />
                </td>

                {/* Status */}
                <td className="text-center">
                  {
                    <HighlightText
                      status={x.is_active ? "Active" : "Inactive"}
                      isBadge={true}
                      size="sm"
                    />
                  }
                </td>

                <td className="text-center">
                  <button
                    className={classNames({
                      "btn btn-sm  me-1 fs-val-xs fw-semibold": true,
                      "btn-danger": x?.is_active ? true : false,
                      "btn-primary": x?.is_active ? false : true,
                    })}
                    onClick={() => {
                      statusToggelBtnCb(x);
                    }}
                  >
                    {x?.is_active ? "Disable" : "Enable"}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
