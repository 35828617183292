import {
  EntitySearchInput,
  PageInfo,
  PaginationSummary,
  SummaryCard,
  TextInput,
  AppliedFilterLabel,
  Alert,
  Toaster,
  Rbac,
} from "@sk/uis";

import { useNavigate } from "react-router-dom";

import { useCallback, useEffect, useRef, useState } from "react";

import { DealService, CommonService } from "@sk/services";

import listView from "../constantService";

import { set } from "date-fns";

import { useAttachAdditionalData } from "@sk/hooks";
import produce from "immer";

import debounce from "lodash/debounce";

import merge from "lodash/merge";

import { Controller, useForm } from "react-hook-form";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import LuckyDrawManageModal from "./modals/LuckyDrawManageModal";

import Table from "./components/Table";

const breadcrumbs = listView.breadcrumb;

const defaultPagination = listView.pagination;

const defaultFilterData = listView.filterFromData;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

const rbac = {
  addButton: ["AddLuckyDraw"],
};

const getData = async (params) => {
  const r = await DealService.getLuckyDrawList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return {
          ...x,
          franchiseLoading: true,
          userLoading: true,
          modifiedLoading: true,
        };
      })
    : [];
};

const getCount = async (params) => {
  try {
    const r = await DealService.getLuckyDrawListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  const id = d.id.trim();
  const idRegex = { $regex: id, $options: "gi" };
  if (d.id) {
    p.filter._id = idRegex;
  }

  if (d?.status) {
    p["filter"]["status"] = d.status;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  if (d.franchise.length) {
    p.filter.franchiseId = d.franchise[0].value._id;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter?.validityPeriod?.length) {
    p.filter["offerValidity.validFrom"] = {
      $gte: set(filter.validityPeriod[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };

    p.filter["offerValidity.validTo"] = {
      $lte: set(filter.validityPeriod[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const LuckyDrawList = () => {
  const router = useNavigate();

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const { register, getValues, control } = useForm({
    defaultValues: defaultFilterData,
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showManageModal, setShowManageModal] = useState(false);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFilterData });

  const advanceFilterRef = useRef({});

  const paginationRef = useRef({ ...defaultPagination });

  const manageModalRef = useRef({ mode: "add", id: "" });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const applyFilter = useCallback(
    async (summaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...defaultPagination };

      // To load Summary
      loadList();

      if (summaryRequired) {
        loadSummary();
      }

      // To load Count
      loadCount();

      prepareFilterLabels();
    },
    [loadCount, loadList, loadSummary, prepareFilterLabels]
  );

  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSearch = debounce(() => {
    filterCb();
  }, 700);

  const filterCb = useCallback(() => {
    const values = getValues();
    filterDataRef.current = { ...filterDataRef.current, ...values };
    applyFilter();
  }, [applyFilter, getValues]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );
  const onFranchiseChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      filterCb();
    },
    [filterCb]
  );
  const openManageModal = (mode, id = "") => {
    manageModalRef.current.mode = mode;
    manageModalRef.current.id = id;
    setShowManageModal(true);
  };

  const manageModalCb = useCallback(
    (payload) => {
      if (payload.status == "submit") {
        init();
      }
      manageModalRef.current.mode = "add";
      manageModalRef.current.id = "";
      setShowManageModal(false);
    },
    [init]
  );

  const luckyDrawEditCb = useCallback((id) => {
    openManageModal("edit", id);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...getValues(), ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter(false);
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, getValues]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.franchise;
    delete v.id;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Active",
      text: `Do you want to mark event as Active for "${item._franchise.name} - ${item._id}", please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        status: "Active",
        // minValue: item.minValue,
      };
      const r = await DealService.updateLuckyDraw(item._id, p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Mark as Inactive",
      text: `Do you want to mark event as In-Active for "${item._franchise.name} - ${item._id}", please confirm?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = {
        status: "Inactive",
        // minValue: item.minValue,
      };
      const r = await DealService.updateLuckyDraw(item._id, p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Inactive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Manage Lucky Draw"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* Summary Cards  */}

      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      {/* Filter Block  */}
      <div className="row align-items-center">
        <div className="col-10">
          <div className="row">
            <div className="col-2">
              <TextInput
                name="id"
                register={register}
                callback={onSearch}
                placeholder="Search by Event ID"
              />
            </div>

            <div className="col-5">
              <Controller
                control={control}
                name="franchise"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="franchise"
                    placeholder="Search for Smart Store  Name/ID"
                    value={value}
                    callback={onFranchiseChange(onChange)}
                    uid="franchise"
                    emptyLabel={"No Franchise found"}
                    gap={0}
                    filterParams={franchiseSearchFilter}
                    isMandatory={true}
                  />
                )}
              />
            </div>

            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={onAdvanceFilterClickCb}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-normal me-1"
              onClick={() => openManageModal("add")}
            >
              Add Event
            </button>
          </Rbac>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />
      <Table
        data={data}
        sort={sortRef.current}
        sortCb={sortCb}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        editCb={luckyDrawEditCb}
        markAsActive={markAsActive}
        markAsInActive={markAsInActive}
      />

      <LuckyDrawManageModal
        show={showManageModal}
        callback={manageModalCb}
        mode={manageModalRef.current.mode}
        id={manageModalRef.current.id}
      />

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />
    </>
  );
};

export default LuckyDrawList;
