/* eslint-disable jsx-a11y/label-has-associated-control */
import { memo, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm } from "react-hook-form";

import { add } from "date-fns";

import {
  AjaxService,
  CommonService,
  DealService,
  FranchiseService,
} from "@sk/services";
import {
  Alert,
  AppTitle,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  PageLoader,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { Offcanvas } from "react-bootstrap";
import * as yup from "yup";

const manageLuckyDrawValidationSchema = yup.object({
  franchise: yup
    .array()
    .min(1, "Please add Smart Store")
    .required("Please add Smart Store"),
  title: yup.string().trim().required("Title is required"),
  // desc: yup.string().trim(),
  minValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .moreThan(0, "coins must be greater than zero")
    .required("Eligible Coins are required "),
  validFrom: yup
    .date()
    .typeError("Valid From Date is Required")
    .required("Valid From Date is Required "),

  validTo: yup
    .date()
    .min(
      add(new Date(), { minutes: 1 }),
      "Must be greater than current Date and Time"
    )
    .required("Valid to Date is Required"),
});

const defaultFormData = {
  franchise: [],
  title: "",
  // desc: "",
  minValue: "",
  status: "",
};

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
  defaultDate: new Date(),
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "45%",
  },
  alertStyle: {
    zIndex: 2000,
  },
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

const resetDefaultOptions = () => {
  startDateConfig = {
    minDate: new Date(),
    enableTime: true,
    dateFormat: "d M Y G:i K",
    defaultDate: new Date(),
  };
};

const getEntitySearchResult = async (type, id, franchiseId) => {
  if (type == "franchise") {
    const r = await FranchiseService.getList({
      filter: {
        _id: id,
        ...franchiseSearchFilter.filter,
      },
    });
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }
};

const LuckyDrawManageModal = ({ show, callback, mode, id }) => {
  const [submitting, setSubmitting] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);

  const {
    formState: { errors },
    setValue,
    getValues,
    control,
    register,
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(manageLuckyDrawValidationSchema),
    defaultValues: { ...defaultFormData },
  });

  useEffect(() => {
    if (show) {
      // when the modal get opened clear the form data
      reset(defaultFormData);

      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
      // when mode is add we need to re-configure Date
      if (mode == "add") {
        resetDefaultOptions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id, show]);

  const triggerCloseModal = (status = "close") => {
    callback({ status });
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      if (!val.length) {
        return;
      }

      chngFn(val);

      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = {
          ...endDateConfig,
          minDate: add(val[0], { minutes: 10 }),
        };
      }
      trigger("validTo");
    };
  };

  const onEligibleCoinsChange = (e) => {
    let val = e.target.value?.slice(0, 6) * 1;
    val = CommonService.roundedByDecimalPlace(val, 0);
    if (val <= 0) {
      val = "";
    }
    e.target.value = val;
  };

  const onSubmit = async (data) => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to ${mode == "edit" ? "update " + id : "proceed"} ? `,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);

    let r = {};

    if (mode == "add") {
      r = await DealService.createLuckyDraw(p);
    } else {
      r = await DealService.updateLuckyDraw(id, p);
    }

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  const resetFormData = () => {
    reset(defaultFormData);
    //  to enable Config
    endDateConfig = {
      enableTime: true,
      dateFormat: "d M Y G:i K",
    };
  };

  const prepareFormValues = async (data) => {
    ["title", "minValue", "status"].forEach((x) => {
      setValue(x, data[x] || "");
    });

    if (data.offerValidity) {
      ["validFrom", "validTo"].forEach((x) => {
        setValue(x, new Date(data.offerValidity[x]));
      });

      const startDate = new Date(data.offerValidity.validFrom);

      endDateConfig = {
        ...endDateConfig,
        minDate: add(startDate, { minutes: 10 }),
      };

      startDateConfig = { ...startDateConfig, minDate: startDate };
    }

    if (data.franchiseId) {
      const resp = await getEntitySearchResult("franchise", data.franchiseId);
      setValue("franchise", resp.data);
    }
  };

  const loadEditData = async (id) => {
    setPageLoading(true);
    const r = await DealService.getLuckyDrawById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }
    await prepareFormValues(d);

    setPageLoading(false);
  };

  const preparePayload = (data) => {
    const franchise = data.franchise[0].value._id;
    const p = {
      franchiseId: franchise,
      title: data.title,
      // desc: data.desc,
      offerValidity: {
        validFrom: data.validFrom,
        validTo: data.validTo,
      },
      // maxValue: data.maxValue,
      minValue: data.minValue,
    };
    if (mode == "edit") {
      p.status = data.status;
    }
    return p;
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={
              mode === "edit"
                ? "Edit Lucky Draw Event" + `  #${id}`
                : "Add Lucky Draw Event"
            }
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div className="p-4 bg-white">
                <div className="row">
                  <div className="col-12">
                    <div className="fw-semibold fs-val-lg mb-3">
                      Choose Smart Store <span className="text-danger">*</span>
                    </div>
                  </div>
                  {/* Franchise */}
                  <div className="col-12">
                    <Controller
                      control={control}
                      name="franchise"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="franchise"
                          placeholder="Search by Store Name / ID"
                          value={value}
                          callback={onChange}
                          uid="franchise"
                          emptyLabel={"No Smart Store found"}
                          gap={0}
                          disabled={mode == "edit"}
                          filterParams={franchiseSearchFilter}
                          isMandatory={true}
                          error={errors?.franchise?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* Offer Details */}
              <div className="px-4 bg-white ">
                <div className="row">
                  {/* Title  */}
                  <div className="col-12 mb-2">
                    <TextInput
                      register={register}
                      name="title"
                      placeholder="Enter title here"
                      label="Event Title"
                      isMandatory={true}
                      error={errors?.title?.message}
                      maxLength={70}
                      disabled={mode == "edit"}
                      note="Maximum allowed Character 70"
                    />
                  </div>
                  {/* Description */}
                  {/* <div className="col-12 mb2">
                    <TextareaInput
                      register={register}
                      name="desc"
                      label="Description"
                      rows={2}
                      placeholder="Enter description here"
                      // isMandatory={true}
                      maxLength={250}
                      error={errors?.desc?.message}
                      note="Maximum allowed Character 250"
                    />
                  </div> */}

                  <div className="col-4">
                    <TextInput
                      register={register}
                      name="minValue"
                      type="number"
                      label="Eligible SmartCoins"
                      callback={onEligibleCoinsChange}
                      maxLength={5}
                      isMandatory={true}
                      disabled={mode == "edit"}
                      error={errors?.minValue?.message}
                      placeholder="Enter Smart Coins"
                    />
                  </div>

                  {/*  Offer Start Date   */}
                  <div className="col-4">
                    <label className=" mb-1 fs-val-md">
                      Event Start Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="validFrom"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePickerInput
                            placeholder="Choose"
                            value={[value]}
                            disabled={mode == "edit"}
                            inpChange={dateChangeCb(onChange, "validFrom")}
                            config={startDateConfig}
                          />
                        );
                      }}
                    />
                    <InputErrorMsg msg={errors?.validFrom?.message} />
                  </div>

                  {/* Offer End Date */}
                  <div className="col-4">
                    <label className="mb-1 fs-val-md">
                      Event End Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="validTo"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange, "validTo")}
                          config={endDateConfig}
                        />
                      )}
                    />
                    <InputErrorMsg msg={errors?.validTo?.message} />
                  </div>
                </div>
              </div>
            </>
          )}
        </Offcanvas.Body>
        <Offcanvas.Header>
          {!pageLoading && (
            <div className="my-3 w-100 row justify-content-between">
              <div className="col-12 text-end">
                <button
                  className="btn btn-primary"
                  // type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                >
                  Submit {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </div>
          )}
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(LuckyDrawManageModal);
