import { yupResolver } from "@hookform/resolvers/yup";
import { CommonService, DealService } from "@sk/services";
import {
  EntitySearchInput,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  deal: yup.array().min(1, "Please provide Deal").required().label("Deal"),
  perOrder: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .nullable()
    .required()
    .label("Per Order Limit"),
  perMonth: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .nullable()
    .required()
    .label("Per Month Limit"),
  perDayLimit: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .nullable()
    .required()
    .label("Per Day Limit"),
});

const canvasStyle = {
  width: "35%",
};

const defaultFormData = {
  deal: [],
  perOrder: "",
  perMonth: "",
  perDayLimit: "",
  status: "Active",
  sellInLooseQty: false,
  unitType: "units",
};

const statusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "In Active",
  },
];

const ClubDealRestrictManageModal = ({ show, callback, editId = "" }) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [unitType] = useWatch({
    name: ["unitType"],
    control,
  });

  useEffect(() => {
    if (show) {
      reset({ ...defaultFormData });
      if (editId) {
        loadDetails(editId);
      } else {
        setLoading(false);
      }
    }
  }, [editId, loadDetails, reset, show]);

  const loadDetails = useCallback(
    async (id) => {
      setLoading(true);

      const r = await DealService.getClubDealPurchaseLimit({
        filter: { _id: id },
      });

      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      if (d._id) {
        const dealId = d.dealId || "";
        if (dealId) {
          const r2 = await DealService.getDeals({
            filter: { _id: dealId },
            select: "name",
          });
          if (Array.isArray(r2.resp) && r2.resp.length > 0) {
            const deal = r2.resp[0];
            setValue("deal", [{ label: deal.name, value: { ...deal } }]);
          }
        }

        setValue("perOrder", d._perOrderLimit);
        setValue("perMonth", d._perMonthLimit);
        setValue("perDayLimit", d._perDayLimit);
        setValue("status", d.status);
        setValue("unitType", d.unitType == "gm" ? "kg" : d.unitType);
        setLoading(false);
      } else {
        setLoading(false);
        Toaster.error("Failed to load details");
        callback({ status: "closed" });
      }
    },
    [callback, setValue]
  );

  const onHide = () => {
    callback({ status: "closed" });
  };

  const numCb = (e) => {
    const n = e.target.name;
    const v = Number(e.target.value);
    if (v && !isNaN(v)) {
      setValue(
        n,
        unitType == "kg"
          ? CommonService.roundedByDecimalPlace(Math.abs(v), 2)
          : CommonService.roundedByDecimalPlace(Math.abs(v), 0)
      );
    }
  };

  const onSubmit = async (data) => {
    if (!data.perDayLimit || data.perDayLimit < 0) {
      Toaster.error("Please provide Per Day Limit");
      return;
    }

    if (!data.perOrder || data.perOrder < 0) {
      Toaster.error("Please provide Per Order Limit");
      return;
    }

    if (!data.perMonth || data.perMonth < 0) {
      Toaster.error("Please provide Per Month Limit");
      return;
    }

    if (data.perOrder > data.perMonth) {
      Toaster.error("Per Order limit cannot be greater than Per Month Limit");
      return;
    }

    if (data.perDayLimit > data.perMonth) {
      Toaster.error("Per Day limit cannot be greater than Per Month Limit");
      return;
    }

    if (data.perOrder > data.perDayLimit) {
      Toaster.error("Per Order limit cannot be greater than Per Day Limit");
      return;
    }

    let perOrder = data.perOrder;
    let perMonth = data.perMonth;
    let perDayLimit = data.perDayLimit;

    if (data.unitType == "kg") {
      perOrder *= 1000;
      perMonth *= 1000;
      perDayLimit *= 1000;
    }

    const p = {
      dealId: data.deal[0].value._id,
      dealName: data.deal[0].value.name,
      perOrderLimit: perOrder,
      perMonthLimit: perMonth,
      perDayLimit: perDayLimit,
      status: data.status,
      unitType: data.unitType == "kg" ? "gm" : data.unitType,
    };

    setSubmitting(true);

    // check already deal config exists
    if (!editId) {
      let existResp = await DealService.getClubDealPurchaseLimit({
        filter: { dealId: p.dealId },
      });
      if (Array.isArray(existResp.resp) && existResp.resp.length > 0) {
        Toaster.error("For this deal config already exists");
        setSubmitting(false);
        return;
      }
    }

    let r;
    if (editId) {
      r = await DealService.updateClubDealPurchaseLimit(editId, p);
    } else {
      r = await DealService.clubDealPurchaseLimit(p);
    }
    setSubmitting(false);
    if (r.statusCode == 200) {
      reset({ ...defaultFormData });
      callback({ status: "submitted" });
      Toaster.success(r.resp.message || "Submitted succesfully");
    } else {
      Toaster.error(r.resp.message || "Failed to submit");
    }
  };

  const onDealSelect = (chngFn) => (val) => {
    chngFn(val);
    if (Array.isArray(val) && val.length > 0) {
      const d = val[0].value;
      setValue("unitType", d.sellInLooseQty ? "kg" : "unit");
    } else {
      setValue("unitType", "units");
    }
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">Manage Purchase Limit</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <div className="text-center p-4">
            <Spinner />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="row mb-2">
                <div className="col-12">
                  <Controller
                    name="deal"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <EntitySearchInput
                        label="Deal"
                        isMandatory={true}
                        callback={onDealSelect(onChange)}
                        error={errors?.deal?.message}
                        placeholder="Search by Name/ID"
                        type="deal"
                        uid="clubdealpurlimit"
                        value={value}
                        disabled={editId}
                      />
                    )}
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    isMandatory={true}
                    error={errors.perOrder?.message}
                    label="Per Order Limit"
                    type="number"
                    register={register}
                    name="perOrder"
                    callback={numCb}
                    groupContent={<span className="px-2">{unitType}</span>}
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    isMandatory={true}
                    error={errors.perDayLimit?.message}
                    label="Per Day Limit"
                    type="number"
                    register={register}
                    name="perDayLimit"
                    callback={numCb}
                    groupContent={<span className="px-2">{unitType}</span>}
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    isMandatory={true}
                    error={errors.perMonth?.message}
                    label="Per Month Limit"
                    type="number"
                    register={register}
                    name="perMonth"
                    callback={numCb}
                    groupContent={<span className="px-2">{unitType}</span>}
                  />
                </div>
                <div className="col-12">
                  <SelectInput
                    error={errors.status?.message}
                    isMandatory={true}
                    label="Status"
                    name="status"
                    register={register}
                    options={statusOptions}
                  />
                </div>
              </div>

              <div className="text-end">
                <button
                  className="btn btn-danger me-2"
                  type="button"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </form>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ClubDealRestrictManageModal;
