import { OmsService } from "@sk/services";
import {
  Amount,
  BooleanCheckboxInput,
  DateFormatter,
  EntitySearchInput,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { debounce, orderBy } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
const canvasStyle = { width: "60%" };

const headers = [
  { label: "Sl No", width: "auto", isCentered: true },
  { label: "Order ID", isCentered: true },
  { label: "Order Amount", isCentered: true },
  { label: "Order Date", isCentered: true },
  { label: "Customer ID", isCentered: false },
  { label: "Customer Name", isCentered: false },
  { label: "Total Qty", isCentered: true },
  { label: "Total Deal Value", isCentered: true },
  // { label: "Profit & Loss", isCentered: true },
];

const pagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const OrderDetailsListModal = ({ show, configId, callback }) => {
  const { register, getValues, control, reset } = useForm({
    defaultValues: { id: "", customer: "", customerRequesteId: "" },
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const paginationConfigRef = useRef({ ...pagination });
  const detailsRef = useRef({});
  const filteredDataRef = useRef([]);

  useEffect(() => {
    if (show && configId) {
      paginationConfigRef.current = { ...pagination };
      filteredDataRef.current = [];
      reset();
      loadAnalyticsData(configId);
    }
  }, [show, configId, loadAnalyticsData, reset]);

  const loadAnalyticsData = useCallback(
    async (id) => {
      setLoading(true);
      let r = await OmsService.getStoreOfferAnalyticsDetails({
        offerIds: [id],
      });

      let d = Array.isArray(r.resp) && r?.resp?.length ? r.resp[0] : {};
      let or = orderBy(
        [...d.orders],
        ["orderPlacedOn", "orderId"],
        ["desc", "desc"]
      );
      detailsRef.current = [...or];
      filteredDataRef.current = [...detailsRef.current];
      let l = or ? or.splice(0, paginationConfigRef.current.rowsPerPage) : [];
      paginationConfigRef.current = {
        ...paginationConfigRef.current,
        totalRecords: detailsRef.current?.length,
      };

      setList([...l]);
      setLoading(false);
    },
    [setList, setLoading]
  );

  const paginationCb = useCallback((d) => {
    paginationConfigRef.current.startSlNo = d.startSlNo;
    paginationConfigRef.current.endSlNo = d.endSlNo;
    paginationConfigRef.current.activePage = d.activePage;
    let data = [...filteredDataRef.current];
    let k = (data || []).splice(
      paginationConfigRef.current.startSlNo - 1,
      paginationConfigRef.current.rowsPerPage
    );
    setList([...k]);
  }, []);

  const applyFilter = useCallback(() => {
    paginationConfigRef.current = { ...pagination };
    let f = getValues();
    let l = detailsRef.current;
    let fl = [...l];
    if (f.id?.length) {
      fl = fl.filter((e) => {
        return e.orderId.match(new RegExp(f.id, "i"));
      });
    }
    if (f.customer?.length) {
      fl = fl.filter((e) => {
        return e.customerId == f.customer[0].value._id;
      });
    }

    if (f.customerRequesteId) {
      fl = fl.filter((e) => {
        return e?.customerRequestId;
      });
    }
    let x = fl;
    let k = fl?.length
      ? [...fl].splice(0, paginationConfigRef.current.rowsPerPage)
      : [];
    filteredDataRef.current = x;
    setList([...k]);

    paginationConfigRef.current = {
      ...paginationConfigRef.current,
      totalRecords: x?.length,
    };
  }, [getValues]);

  const onSearch = debounce(() => {
    applyFilter();
  }, 700);

  const onSelectCb = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg">#{configId} - Order Details List</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row align-items-center">
              <div className="col-2">
                <TextInput
                  name="id"
                  register={register}
                  callback={onSearch}
                  placeholder="Search by Order ID"
                />
              </div>

              <div className="col-4">
                <Controller
                  control={control}
                  name="customer"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="customer"
                      placeholder="Search by Customer Name/ID"
                      value={value}
                      callback={onSelectCb(onChange)}
                      uid="customer"
                      isMultiple={false}
                      isMandatory={true}
                      emptyLabel="Customer Not found"
                    />
                  )}
                />
              </div>

              <div className="col-4 pt-2">
                <Controller
                  control={control}
                  name="customerRequesteId"
                  render={({ field: { onChange, value } }) => (
                    <BooleanCheckboxInput
                      label="Show Only Club Orders"
                      name="customerRequesteId"
                      isMandatory={false}
                      callback={onSelectCb(onChange)}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>

            <PaginationSummary
              paginationConfig={paginationConfigRef.current}
              loadingTotalRecords={false}
            />

            <table className="table table-bordered bg-white">
              <TableHeader data={headers} />
              <tbody className="fs-val-md">
                {/*  When Table is Loading  */}
                {loading && (
                  <TableSkeletonLoader
                    rows={10}
                    cols={headers.length}
                    height={40}
                  />
                )}

                {/* When No loading */}
                {!loading && !list?.length ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <NoDataFound>No Data Found </NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {(list || []).map((x, index) => (
                  <tr key={index}>
                    {/* SL No */}
                    <td className="text-center">
                      {paginationConfigRef.current.startSlNo + index}
                    </td>

                    <td className="text-center">{x?.orderId}</td>

                    {/* Order Amount */}
                    <td className="text-center">
                      <div className="text-primary fw-semibold">
                        <Amount value={x?.orderValue} />
                      </div>
                    </td>

                    <td className="text-center">
                      <DateFormatter
                        date={x?.orderPlacedOn}
                        format="dd MMM yyyy"
                      />
                    </td>

                    {/* Customer Details */}
                    <td className="text-center">{x?.customerId}</td>
                    <td className="text-center">{x?.customerName}</td>

                    <td className="text-center">{x?.nonOfferDealQuantity}</td>
                    <td className="text-center">
                      <Amount value={x?.orderValue} decimalPlace={2} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBlock
              paginationConfig={paginationConfigRef.current}
              paginationCb={paginationCb}
              loadingTotalRecords={false}
            />
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(OrderDetailsListModal);
