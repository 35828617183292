/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  DealService,
  DigitalPartnerService,
  SourcingService,
  UtilityService,
} from "@sk/services";
import {
  AppCard,
  AutoCompleteInput,
  DateInput,
  DatePickerInput,
  EntitySearchInput,
  ImgPreviewModal,
  ImgRender,
  InputErrorMsg,
  Rbac,
  SelectInput,
  SwitchInput,
  TextInput,
  TextareaInput,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { manageView } from "../../../constantService";
import BannerImgSelectModal from "../../modals/BannerImgSelectModal";
import { isAfter } from "date-fns";

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

const rbac = {
  activation: ["BannerConfigActivatiion"],
};

const BannerDetails = ({ mode, selectedLocation, canEdit }) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
    register,
  } = useFormContext({});

  const [startDateOpt, setStartDateOpt] = useState({ ...startDateConfig });

  const [imgPreviewData, setImgPreviewData] = useState({
    show: false,
    data: [],
  });

  const [imgSelectModal, setImgSelectModal] = useState({
    show: false,
    data: [],
    selectedId: "",
  });

  const selectedLocationRef = useRef([]);

  const [
    bannerType,
    filterByFranchiseType,
    displayType,
    activePeriod,
    images,
    startTime2,
    startDate,
    mobileImages,
    filterByConditionType,
    bannerMasterData,
  ] = useWatch({
    control: control,
    name: [
      "bannerType",
      "filterByFranchiseType",
      "displayType",
      "activePeriod",
      "image",
      "startTime2",
      "startDate",
      "mobileImage",
      "filterByConditionType",
      "bannerMasterData",
    ],
  });

  useEffect(() => {
    // if (mode == "Add") {
    setStartDateOpt({ ...startDateConfig });
    // } else {
    //   setStartDateOpt({ ...startDateConfig, minDate: new Date(startDate) });
    // }
    if (selectedLocation._id) {
      selectedLocationRef.current = selectedLocation;
    }
  }, [mode, selectedLocation]);

  const onMarketplaceSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = { $regex: val, $options: "i" };
      filter = { $or: [{ name: v }, { _id: v }] };
    }
    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };

    const r = await SourcingService.getList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  }, []);

  const onKingsaleSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = { $regex: val, $options: "i" };
      filter = { $or: [{ name: v }, { _id: v }] };
    }
    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };

    const r = await UtilityService.getKingSaleList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  }, []);

  const onRechargeSearch = useCallback(async (val, bannerType, callback) => {
    let filter = {};

    if (val) {
      let v = { $regex: val, $options: "i" };
      filter = { $or: [{ name: v }, { _id: v }] };
    }

    if (bannerType == "dmt") {
      filter["services.dmt.isEnabled"] = true;
    }

    if (bannerType == "wallet") {
      let v = { $regex: val, $options: "i" };
      filter["$or"] = [
        { name: v },
        { "services.wallet.creat": true },
        { "services.wallet.debit": true },
        { "services.wallet.load": true },
      ];
    }

    if (bannerType == "servicePartner") {
      let v = { $regex: val, $options: "i" };
      filter["status"] = "active";
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };

    const r = await DigitalPartnerService.getlist(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  }, []);

  const onMultiDealSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let x = { $regex: val, $options: "gi" };
      filter = { $or: [{ name: x }, { _id: x }], dealsFor: "erp" };
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
      select: "name,_id",
    };

    const r = await DealService.getDeals(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  }, []);

  const getBannerLabel = (value) => {
    var d = manageView.bannerTypeOptions.filter((v) => {
      return v.value == value;
    });
    return d.length ? d[0].label : "";
  };

  const onBannerTypeChange = useCallback((e) => {
    setValue("deal", []);
    setValue("category", []);
    setValue("marketPlaceSeller", []);
    setValue("kingsale", []);
    setValue("keyword", "");
    setValue("digitalPartner", []);
    setValue("multiDeals", []);
    setValue("ids", "");
    setValue("enrollment", "");
  }, []);

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      setValue(key, val[0] || "");

      if (key == "startDate") {
        let ed = getValues("endDate");
        if (isAfter(new Date(val[0]), new Date(ed)) || ed == undefined) {
          endDateConfig = { ...endDateConfig, minDate: val[0] };
          setValue("endDate", val[0] || "");
        }
      }
      trigger(["endDate", "startDate"]);
    };
  };

  const imagesCb = async (e) => {
    setValue("image", e.asset);
    trigger("image");
  };

  const mobImageCb = async (e) => {
    setValue("mobileImage", e.asset);
    trigger("mobileImage");
  };

  const removeImg = useCallback(
    async (key) => {
      setValue(key, "");
      await trigger(key);
    },
    [getValues, setValue, trigger]
  );

  const viewImage = (x) => {
    let d = [{ id: x }];
    setImgPreviewData({ show: true, data: d });
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData({ show: false, data: [] });
  };

  const onFilterByFranchiseTypeChanges = () => {
    setValue("filterByFranchiseGroupType", "");
    setValue("franchise", []);
    setValue("selectedFranchises", []); // Clear selectedFranchises when group type changes
  };

  const onFilterByConditionTypeChange = (d) => {
    setValue("filterBrand", []);
    setValue("filterDeal", []);
    setValue("filterCategory", []);
  };

  const selectBannerImg = () => {
    setImgSelectModal({
      show: true,
      data: selectedLocationRef.current,
      selectedId: bannerMasterData._id,
    });
  };

  const imgSelectModalCb = useCallback(
    (d) => {
      if (d.status == "submit" && d.index != -1) {
        setValue("bannerMasterId", d.data.data._id);
        setValue("bannerMasterData", d.data.data);
        setValue("title", d.data.data?.assetTitle);
        trigger();
      }
      setImgSelectModal({ show: false, data: {} });
    },
    [setValue, trigger, setImgSelectModal]
  );

  return (
    <>
      <AppCard title="Banner Franchise and Criteria Details">
        <div className="row">
          <div className="col-4 mb-2">
            <SelectInput
              type="text"
              label="Filter By Franchise Type"
              register={register}
              name="filterByFranchiseType"
              error={errors?.filterByFranchiseType?.message}
              options={[...manageView.filterByFranchiseTypeOptions]}
              isMandatory={true}
              callback={onFilterByFranchiseTypeChanges}
              disabled={!canEdit}
            />
          </div>
          {filterByFranchiseType == "ByGroup" ? (
            <div className="col-4 mb-2">
              <SelectInput
                type="text"
                label="Filter By Franchise  Group Type"
                register={register}
                name="filterByFranchiseGroupType"
                error={errors?.filterByFranchiseGroupType?.message}
                options={[...manageView.filterByFranchiseGroupTypeOptions]}
                isMandatory={true}
                disabled={!canEdit}
              />
            </div>
          ) : null}

          {/* Select Franchise  */}
          {filterByFranchiseType == "ByFranchise" ? (
            <div className="col-6">
              <label className="fw-semibold mb-1 fs-val-md">Franchise</label>
              <Controller
                control={control}
                name="franchise"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="franchise"
                    placeholder="Search by Franchise Id/Name"
                    value={value}
                    name="franchise"
                    callback={onChange}
                    uid="franchise"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="Franchise Not found"
                    error={errors?.franchise?.message}
                    disabled={!canEdit}
                    filterParams={{
                      filter: {
                        groupType: {
                          $in: ["COCO", "FOFO"],
                        },
                        "sk_franchise_details.franchise_sub_type": {
                          $in: ["SMARTSF", "SMARTSFCOCO"],
                        },
                      },
                    }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-3 mb-2">
            <SelectInput
              type="text"
              label="Input Criteria Type"
              register={register}
              name="filterByConditionType"
              error={errors?.filterByConditionType?.message}
              options={[...manageView.filterByConditionTypeOptions]}
              isMandatory={true}
              callback={onFilterByConditionTypeChange}
              disabled={!canEdit}
            />
          </div>

          {/*  Brand  */}
          {["Brand", "Brand-Category"].indexOf(filterByConditionType) != -1 ? (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">
                Criteria Brand <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="filterBrand"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="brand"
                    placeholder="Search by Brand Id/Name"
                    value={value}
                    callback={onChange}
                    uid="filterBrand"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="Brand Not found"
                    error={errors?.filterBrand?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          ) : null}
          {/*  Category */}
          {["Category", "Brand-Category"].indexOf(filterByConditionType) !=
          -1 ? (
            <div className="col-4">
              <label className="mb-1 fs-val-md">
                Criteria Category <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="filterCategory"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="category"
                    placeholder="Search by Category Id/Name"
                    value={value}
                    callback={onChange}
                    uid="filterCategory"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="category Not found"
                    error={errors?.filterCategory?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          ) : null}
          {/*  Category */}
          {["Menu"].indexOf(filterByConditionType) != -1 ? (
            <div className="col-4">
              <label className="mb-1 fs-val-md">
                Criteria Category <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="filterCategory"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="menu"
                    placeholder="Search by Category Id/Name"
                    value={value}
                    callback={onChange}
                    uid="filterCategory"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="category Not found"
                    error={errors?.filterCategory?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          ) : null}

          {/*  Deal */}
          {filterByConditionType == "Deal" ? (
            <div className="col-4">
              <label className="mb-1 fs-val-md">
                Criteria Deal <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="filterDeal"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="deal"
                    placeholder="Search by Deal Id/Name"
                    value={value}
                    name="filterDeal"
                    callback={onChange}
                    uid="filterDeal"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="Deal Not found"
                    error={errors?.filterDeal?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </AppCard>

      {filterByFranchiseType === "ByGroup" ? (
        <AppCard title="Exclude Franchises">
          <div className="row">
            <div className="col-12">
              <label className="fw-semibold mb-1 fs-val-md">
                Select Franchises <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="selectedFranchises"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="franchise"
                    placeholder="Search by Franchise Id/Name"
                    value={value}
                    name="selectedFranchises"
                    callback={onChange}
                    uid="selectedFranchises"
                    isMultiple={true}
                    emptyLabel="Franchise Not found"
                    error={errors?.selectedFranchises?.message}
                    disabled={!canEdit}
                    filterParams={{
                      filter: {
                        groupType: {
                          $in: ["COCO", "FOFO"],
                        },
                        "sk_franchise_details.franchise_sub_type": {
                          $in: ["SMARTSF", "SMARTSFCOCO"],
                        },
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </AppCard>
      ) : null}

      <AppCard title="Banner Image from Banner Master Details">
        <div className="mb-3">
          <div className="row">
            {bannerMasterData?.assetId ? (
              <div className="col-4">
                <div className="mb-2 fw-semibold fs-val-md">
                  Selected Banner from Banner Master
                </div>

                <div
                  className="d-inline"
                  onClick={() => {
                    viewImage(bannerMasterData?.assetId);
                  }}
                >
                  <ImgRender
                    assetId={bannerMasterData?.assetId || ""}
                    height="200"
                  />
                </div>

                <div className="my-2 fs-val-md">
                  Title : {bannerMasterData?.assetTitle}
                </div>
                <div className="my-2 fs-val-md">
                  Tags :
                  {bannerMasterData?.tags.map((e) => (
                    <span className="badge bg-primary mx-1">{e}</span>
                  ))}
                  {!bannerMasterData?.tags?.length ? "N/A" : ""}
                </div>

                <div className="my-2 fs-val-md">
                  Resolution : {bannerMasterData?.assetAttributes?.widthPxl}x
                  {bannerMasterData?.assetAttributes?.heightPxl} (WxH)
                </div>
              </div>
            ) : null}

            <div className="col-8 p-5">
              {canEdit ? (
                <button
                  className="btn btn-lg  btn-outline-primary"
                  type="button"
                  onClick={selectBannerImg}
                >
                  {!bannerMasterData?.assetId
                    ? "Select Banner from Banner Master"
                    : "Change Banner from Banner Master"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </AppCard>

      <AppCard title="Banner / Redirection Details">
        <div className="row">
          {/* Title */}
          <div className="col-6">
            <TextInput
              type="text"
              label="Title"
              placeholder="Enter Banner name Here"
              register={register}
              name="title"
              error={errors?.title?.message}
              isMandatory={true}
              disabled={!canEdit}
            />
          </div>
          {/* Banner  App Type */}
          {/* <div className="col-4 mb-2">
          <SelectInput
            label="App Type"
            register={register}
            name="appType"
            isMandatory={true}
            options={manageView.appTypeOptions}
            error={errors?.appType?.message}
          />
        </div> */}

          {/* Display Type */}
          {/* <div className="col-3">
            <SelectInput
              label="Display Type"
              register={register}
              name="displayType"
              isMandatory={true}
              options={manageView.displayTypeOptions}
              error={errors?.displayType?.message}
            />
          </div> */}
        </div>

        <div className="row">
          <div className="col-2">
            <TextInput
              type="number"
              label="Slide Order Value"
              placeholder="Enter Priority value"
              register={register}
              name="ordering"
              error={errors?.ordering?.message}
              isMandatory={true}
              disabled={!canEdit}
            />
          </div>

          {/* Banner Type */}
          <div className="col-3">
            <SelectInput
              label="Redirection To"
              register={register}
              name="bannerType"
              isMandatory={true}
              callback={onBannerTypeChange}
              options={manageView.bannerTypeOptions}
              error={errors?.bannerType?.message}
              disabled={!canEdit}
            />
          </div>

          {/* Sub Type */}
          {/* {[
          "deal",
          "category",
          "brand",
          "brandAndCategory",
          "marketPlaceSeller",
          "kingsale",
        ].indexOf(bannerType) != -1 && (
          <div className="col-3">
            <SelectInput
              label="Sub Type"
              register={register}
              name="subType"
              isMandatory={true}
              options={manageView.subTypeOptions}
              error={errors?.subType?.message}
            />
          </div>
        )} */}

          {bannerType == "deal" && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">Deal</label>
              <Controller
                control={control}
                name="deal"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="deal"
                    placeholder="Search by Deal Id/Name"
                    value={value}
                    name="deal"
                    callback={onChange}
                    uid="deal"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="Deal Not found"
                    error={errors?.deal?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["category", "brandAndCategory"].indexOf(bannerType) != -1 && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">Category</label>
              <Controller
                control={control}
                name="category"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="category"
                    placeholder="Search by Category Id/Name"
                    value={value}
                    callback={onChange}
                    uid="category"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="category Not found"
                    error={errors?.category?.message}
                    filterParams={
                      bannerType == "menu"
                        ? { filter: { hasParentCategory: false } }
                        : {}
                    }
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["menu"].indexOf(bannerType) != -1 ? (
            <div className="col-4">
              <label className="mb-1 fs-val-md">
                Menu <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="category"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="menu"
                    placeholder="Search by Category Id/Name"
                    value={value}
                    callback={onChange}
                    uid="category"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="category Not found"
                    error={errors?.category?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          ) : null}

          {["brand", "brandAndCategory"].indexOf(bannerType) != -1 && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">Brand</label>
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="brand"
                    placeholder="Search by Brand Id/Name"
                    value={value}
                    callback={onChange}
                    uid="brand"
                    isMultiple={false}
                    isMandatory={true}
                    emptyLabel="Brand Not found"
                    error={errors?.brand?.message}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["marketPlaceSeller"].indexOf(bannerType) != -1 && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">
                Marketplace Seller <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="marketPlaceSeller"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    error={errors?.marketPlaceSeller?.message}
                    placeholder="Search by Marketplace Seller Name /Id"
                    value={value}
                    isMandatory={true}
                    onSearch={onMarketplaceSearch}
                    callback={onChange}
                    uid="marketPlaceSeller"
                    isMultiple={false}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["kingsale"].indexOf(bannerType) != -1 && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">
                Kingsale <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="kingsale"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    error={errors?.kingsale?.message}
                    placeholder="Search by Kingsale"
                    value={value}
                    isMandatory={true}
                    onSearch={onKingsaleSearch}
                    callback={onChange}
                    uid="kingsale"
                    isMultiple={false}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["keyword"].indexOf(bannerType) != -1 && (
            <div className="col-6">
              <TextInput
                type="text"
                label="Keyword"
                placeholder="Enter keyword here.."
                register={register}
                name="keyword"
                error={errors?.keyword?.message}
                isMandatory={true}
                disabled={!canEdit}
              />
            </div>
          )}

          {["recharge", "dmt", "wallet", "servicePartner"].indexOf(
            bannerType
          ) != -1 && (
            <div className="col-4">
              <label className="fw-semibold mb-1 fs-val-md">
                {getBannerLabel(bannerType)}{" "}
                <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="digitalPartner"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    error={errors?.digitalPartner?.message}
                    placeholder="Search by Name"
                    value={value}
                    isMandatory={true}
                    onSearch={(e, callback) => {
                      onRechargeSearch(e, bannerType, callback);
                    }}
                    callback={onChange}
                    uid="digitalPartner"
                    isMultiple={false}
                    disabled={!canEdit}
                  />
                )}
              />
            </div>
          )}

          {["multiDeals", "multiCategories", "multiBrands"].indexOf(
            bannerType
          ) != -1 && (
            <div className="col-12">
              <label className="fw-semibold mb-1 fs-val-md">
                {bannerType == "multiDeals"
                  ? "Multi Deals"
                  : bannerType == "multiBrands"
                  ? "Multi BRands "
                  : "Multi Categories"}{" "}
                ID's <span className="text-danger">*</span>
              </label>
              {/* <Controller
                control={control}
                name="multiDeals"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    error={errors?.multiDeals?.message}
                    placeholder="Search by Deals Name/Id"
                    value={value}
                    isMandatory={true}
                    onSearch={onMultiDealSearch}
                    callback={onChange}
                    uid="multiDeals"
                    isMultiple={true}
                    disabled={!canEdit}
                  />
                )}
              /> */}
              <div>
                <TextareaInput
                  name="ids"
                  placeholder="Please Enter , seperated IDs"
                  error={errors?.ids?.message}
                  register={register}
                  isMandatory={true}
                  rows="5"
                  disabled={!canEdit}
                />
              </div>
            </div>
          )}

          {bannerType == "enrollment" && (
            <div className="col-3">
              <SelectInput
                label="Enorllment Type"
                register={register}
                name="enrollment"
                isMandatory={true}
                options={manageView.enrollmentTypeOptions}
                error={errors?.enrollment?.message}
                disabled={!canEdit}
              />
            </div>
          )}

          {/* Banner Text  */}
          {displayType == "text" && (
            <div className="col-12 mb-3">
              <TextInput
                type="text"
                label="Banner Text"
                placeholder="Enter Banner name Here"
                register={register}
                name="bannerText"
                error={errors?.bannerText?.message}
                isMandatory={true}
                disabled={!canEdit}
              />
            </div>
          )}

          {/*Banner Image  */}
          {/* {displayType == "image" && (
            <>
              <div className="row mt-3">
                <div className="col-6">
                  <label className="fs-val-md mb-2 ">Banner Image</label>
                  <div className="mb-5">
                    <div className="row">
                      <div className="col-auto">
                        {!images?.length ? (
                          <div className="upload-placeholder-t1">
                            <i className="bi bi-image"></i>
                          </div>
                        ) : (
                          <div className="uploaded-t1-img-cnt">
                            <i
                              tabIndex={-1}
                              role="button"
                              className="bi bi-x-circle close"
                              onClick={() => {
                                removeImg("image");
                              }}
                            ></i>
                            <div
                              onClick={() => {
                                viewImage(images);
                              }}
                            >
                              <ImgRender
                                assetId={images?.length ? images || "" : ""}
                                width="100"
                                height="100"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col col-ms-auto align-self-end">
                        <label className="col-form-label fs-val-md">
                          Image <span className="text-danger"> * </span>
                        </label>
                        <div>
                          <FileUpload
                            template={2}
                            callback={imagesCb}
                            maxSize={10}
                          />
                        </div>
                      </div>
                    </div>

                    {errors?.image?.message && (
                      <div className="col-12">
                        <InputErrorMsg msg={errors?.image?.message} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <label className="fs-val-md mb-2">Mobile Banner Image</label>
                  <div className="mb-5">
                    <div className="row">
                      <div className="col-auto">
                        {!mobileImages?.length ? (
                          <div className="upload-placeholder-t1">
                            <i className="bi bi-image"></i>
                          </div>
                        ) : (
                          <div className="uploaded-t1-img-cnt">
                            <i
                              tabIndex={-1}
                              role="button"
                              className="bi bi-x-circle close"
                              onClick={() => {
                                removeImg("mobileImage");
                              }}
                            ></i>

                            <div
                              onClick={() => {
                                viewImage(mobileImages);
                              }}
                            >
                              <ImgRender
                                assetId={
                                  mobileImages?.length ? mobileImages : ""
                                }
                                width="100"
                                height="100"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col col-ms-auto align-self-end">
                        <label className="col-form-label fs-val-md">
                          Mobile Image <span className="text-danger"> * </span>
                        </label>
                        <div>
                          <FileUpload template={2} callback={mobImageCb} />
                        </div>
                      </div>
                    </div>

                    {errors?.mobileImage?.message && (
                      <div className="col-12">
                        <InputErrorMsg msg={errors?.mobileImage?.message} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )} */}

          {/* URL */}
          <div className="col-12 mb-3">
            <TextInput
              type="text"
              label="Redirection URL ( If Any Required )"
              placeholder="Enter URL Here"
              register={register}
              name="url"
              error={errors?.url?.message}
              isMandatory={false}
              disabled={!canEdit}
            />
          </div>
        </div>
      </AppCard>

      <AppCard title="Banner Validity / Active Period Details">
        <div className="row">
          {/* Validity Period  */}
          <div className="col-4 mb-3">
            <label className=" mb-0 fs-val-md">
              Validity Start Date
              <span className="text-danger">*</span>
            </label>

            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePickerInput
                    placeholder="Choose"
                    value={[value]}
                    inpChange={dateChangeCb(onChange, "startDate")}
                    config={startDateOpt}
                    disabled={!canEdit}
                  />
                );
              }}
            />
            <InputErrorMsg msg={errors?.startDate?.message} />
          </div>
          <div className="col-4 mb-3">
            <label className="mb-0 fs-val-md">
              Validity End Date
              <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => (
                <DatePickerInput
                  placeholder="Choose"
                  value={[value]}
                  inpChange={dateChangeCb(onChange, "endDate")}
                  config={endDateConfig}
                  disabled={!startDate || !canEdit}
                />
              )}
            />
            <InputErrorMsg msg={errors?.endDate?.message} />
          </div>

          {/* Status */}
          <div className="col-4 ps-2">
            <Rbac roles={rbac.activation}>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <SwitchInput
                    label="Active"
                    isMandatory={false}
                    value={value}
                    callback={onChange}
                  />
                )}
              />
            </Rbac>
          </div>

          {/* Active Period */}
          {/* <div className="col-3">
            <SelectInput
              label="Active Period"
              register={register}
              name="activePeriod"
              isMandatory={true}
              options={manageView.activePeriodTypeOpotions}
              error={errors?.activePeriod?.message}
              disabled={!canEdit}
            />
          </div> */}
          {activePeriod == "range" && (
            <div className="col-9">
              <div className="row">
                {/* Start Time */}
                <div className="col-3">
                  <Controller
                    control={control}
                    name="startTime1"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Slot 1st Start Time"
                        callback={onChange}
                        value={value}
                        error={errors?.startTime1?.message}
                        config={{
                          noCalendar: true,
                          enableTime: true,
                          dateFormat: "h:i K",
                        }}
                        disabled={!canEdit}
                      />
                    )}
                  />
                </div>
                <div className="col-2 text-center mt-4">to</div>
                {/* End Time */}
                <div className="col-3">
                  <Controller
                    control={control}
                    name="endTime1"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Slot 1st End Time"
                        error={errors?.endTime1?.message}
                        callback={onChange}
                        value={value}
                        config={{
                          noCalendar: true,
                          enableTime: true,
                          dateFormat: "h:i K",
                        }}
                        disabled={!canEdit}
                      />
                    )}
                  />
                </div>
              </div>

              {/* second */}
              <div className="row">
                {/* Start Time */}
                <div className="col-3">
                  <Controller
                    control={control}
                    name="startTime2"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Slot 2nd Start Time"
                        callback={onChange}
                        error={errors?.startTime2?.message}
                        value={value}
                        config={{
                          noCalendar: true,
                          enableTime: true,
                          dateFormat: "h:i K",
                        }}
                        disabled={!canEdit}
                      />
                    )}
                  />
                </div>
                <div className="col-2 text-center mt-4">to</div>
                {/* End Time */}
                <div className="col-3">
                  <Controller
                    control={control}
                    name="endTime2"
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label="Slot 2nd End Time"
                        callback={onChange}
                        value={value}
                        error={errors?.endTime2?.message}
                        config={{
                          noCalendar: true,
                          enableTime: true,
                          dateFormat: "h:i K",
                        }}
                        disabled={!startTime2 || !canEdit}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Active Period Ends */}
        </div>
      </AppCard>

      <ImgPreviewModal
        show={imgPreviewData.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData.data}
      />

      <BannerImgSelectModal
        show={imgSelectModal.show}
        callback={imgSelectModalCb}
        data={imgSelectModal.data}
        selectedId={imgSelectModal.selectedId}
      />
    </>
  );
};

export default memo(BannerDetails);
