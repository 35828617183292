import {
  AppCard,
  DateInput,
  DatePickerInput,
  EntitySearchInput,
  SelectInput,
} from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { filterView } from "../../constantService";

const canvasStyle = { width: "30%" };

const defaultFormData = filterView.filterFormData;

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const PromotionAdvanceFilterModal = ({ show, callback }) => {
  const [eventOption, setEventOptions] = useState([]);
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  const [type, executeType] = useWatch({
    control,
    name: ["type", "executeType"],
  });

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset({ ...defaultFormData });
    callback({ formData: defaultFormData, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  const onPromotionChange = useCallback((val) => {
    let opt = [...filterView.eventOptions].filter((e) => {
      return e.type.indexOf(val) != -1;
    });
    opt.unshift({ label: "Choose", value: "" });
    setEventOptions(opt);
    setValue("eventType", "");
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="border-top modal-bg">
          <>
            <AppCard>
              <div className="row">
                <div className="col-12">
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        label="Search for Franchise by Id/Name"
                        placeholder="Search by Name/ID"
                        value={value}
                        callback={onChange}
                        uid="franchise"
                        isMultiple={false}
                        emptyLabel="Franchise Not found"
                      />
                    )}
                  />
                </div>

                <div className="col-12 mb-4">
                  <label className="fw-semibold mb-1 fs-val-md">
                    Created On
                  </label>
                  <Controller
                    name="createdAt"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DateInput
                        placeholder="Choose"
                        value={value}
                        callback={onChange}
                        config={createdOnDateConfig}
                        template={2}
                      />
                    )}
                  />
                </div>

                {/* Type  options */}
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Promotion Type"
                    register={register}
                    name="type"
                    isMandatory={false}
                    options={filterView.typOptions}
                    callback={onPromotionChange}
                  />
                </div>

                {/* Criteria Type */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Criteria Type"
                  register={register}
                  name="criteriaType"
                  isMandatory={false}
                  options={filterView.criteriaTypeOptions}
                />
              </div> */}

                {/* Criteria Customer Status */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Customer Staus"
                  register={register}
                  name="customerstatus"
                  isMandatory={false}
                  options={filterView.criteriaCustomerStatusOptions}
                />
              </div> */}

                {/* Event Type */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Event Type"
                  register={register}
                  name="eventType"
                  isMandatory={false}
                  options={eventOption}
                  disabled={!type}
                />
              </div> */}

                {/* Event Action Type */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Event Action Type"
                  register={register}
                  name="eventActionType"
                  isMandatory={false}
                  options={filterView.criteriaEventTypeActionOptions}
                />
              </div> */}

                {/* Execute  Type */}
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Execute Option"
                    register={register}
                    name="executeOption"
                    isMandatory={false}
                    options={filterView.executeOptions}
                  />
                </div>

                {/* Execute  Unit */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Execute Type"
                  register={register}
                  name="executeType"
                  isMandatory={false}
                  options={filterView.criteriaExecuteTypeOptions}
                />
              </div> */}

                {/* Execute  Unit */}
                {/* {executeType == "Custom" ? (
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Execute Unit"
                    register={register}
                    name="executeUnit"
                    isMandatory={false}
                    options={filterView.criteriaExecuteUnitOptions}
                  />
                </div>
              ) : null} */}

                {/* Execute  Type */}
                {/* <div className="col-6 mb-3">
                <SelectInput
                  label="Execute Action"
                  register={register}
                  name="executeAction"
                  isMandatory={false}
                  options={filterView.criteriaExecuteActionOptions}
                />
              </div> */}

                {/* Status  options */}
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Status"
                    register={register}
                    name="status"
                    isMandatory={false}
                    options={filterView.statusOptions}
                  />
                </div>
              </div>
            </AppCard>
          </>
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="text-end w-100">
            <button
              className="btn me-1 btn-warning"
              type="button"
              onClick={resetFormData}
            >
              Reset
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(PromotionAdvanceFilterModal);
