import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AjaxService,
  BannerService,
  NavService,
  UtilityService,
} from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  InputErrorMsg,
  NoDataFound,
  PageInfo,
  PageLoader,
  PaginationSummary,
  Rbac,
  SelectInput,
  Spinner,
  Toaster,
} from "@sk/uis";
import { differenceInDays } from "date-fns";
import { sortBy, uniqBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { manageView } from "../constantService";
import BannerSliderTable from "./components/BannerSliderTable";
import ManageBannerSliderModal from "./modals/ManageBannerSliderModal";
import ViewAccessLevelModal from "./modals/ViewAccessLevelModal";

const rbac = {
  edit: ["EditBanner", "AddBanner"],
};

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

// let l = [...BannerService.getBannerLocationCode()];
// l.unshift({ label: "Select", value: "" });
// const bannerCodeOptions = [...l];

const validationSchema = yup.object({
  // title: yup.string().trim().label("Banner Name").required(),
  applicableFor: yup.string().when("platformType", {
    is: (platformType) => platformType == "RetailerApp",
    then: (schema) => schema.label("Banner For").required(),
  }),
  platformType: yup
    .string()
    .default("RetailerApp")
    .label("Platform Type")
    .required(),
  // bannerLocationCode: yup.string().when("platformType", {
  //   is: (platformType) => platformType == "ClubApp",
  //   then: (schema) => schema.label("Banner Location Code").required(),
  // }),
  page: yup.string().when("platformType", {
    is: (platformType) => platformType == "ClubApp",
    then: (schema) => schema.label("Banner Page").required(),
  }),
  location: yup.string().when("platformType", {
    is: (platformType) => platformType == "ClubApp",
    then: (schema) => schema.label("Banner Location").required(),
  }),
  // sliderTime: yup
  //   .number()
  //   .nullable()
  //   .transform((v) => (isNaN(v) ? null : v))
  //   .label("Slider Time")
  //   .required(),
  // resolutionHeight: yup
  //   .number()
  //   .nullable()
  //   .transform((v) => (isNaN(v) ? null : v))
  //   .label("Resolution Height")
  //   .required(),
  // resolutionWidth: yup
  //   .number()
  //   .nullable()
  //   .transform((v) => (isNaN(v) ? null : v))
  //   .label("Resolution Width")
  //   .required(),
  // maxImageSize: yup
  //   .number()
  //   .nullable()
  //   .transform((v) => (isNaN(v) ? null : v))
  //   .label("Max Image Size")
  //   .required(),
  // shortDescription: yup
  //   .string()
  //   .trim()
  //   .max(300)
  //   .label("Description")
  //   .required(),
  banners: yup.array().min(1).label("Banner Slider").required(),
  // filterByFranchiseType: yup
  //   .string()
  //   .trim()
  //   .when("platformType", {
  //     is: (platformType) => platformType == "ClubApp",
  //     then: (schema) => schema.label("Filter By Franchise Code").required(),
  //   }),
  // filterByFranchiseGroupType: yup
  //   .string()
  //   .trim()
  //   .when("filterByFranchiseType", {
  //     is: (filterByFranchiseType) => filterByFranchiseType == "ByGroup",
  //     then: (schema) => schema.label("Filter By Franchise Code").required(),
  //   }),
  // franchise: yup.array().when("filterByFranchiseType", {
  //   is: (filterByFranchiseType) => filterByFranchiseType == "ByFranchise",
  //   then: (schema) => schema.label("Filter By Franchise Code").required(),
  // }),

  // filterByConditionType: yup.string().label("Criteria Type").required(),

  // filterBrand: yup.array().when("filterByConditionType", {
  //   is: (filterByConditionType) =>
  //     ["Brand", "Brand-Category"].indexOf(filterByConditionType) != -1,
  //   then: (schema) => schema.min(1, "Criteria Brand").required(),
  // }),

  // filterCategory: yup.array().when("filterByConditionType", {
  //   is: (filterByConditionType) =>
  //     ["Category", "Brand-Category", "Menu"].indexOf(filterByConditionType) !=
  //     -1,
  //   then: (schema) => schema.min(1, "Criteria Category").required(),
  // }),

  // filterDeal: yup.array().when("filterByConditionType", {
  //   is: (filterByConditionType) =>
  //     ["Deal"].indexOf(filterByConditionType) != -1,
  //   then: (schema) => schema.min(1, "Criteria Deal").required(),
  // }),
});

const defaultFormValue = {
  title: "",
  applicableFor: "",
  sliderTime: null,
  resolutionHeight: null,
  resolutionWidth: null,
  maxImageSize: null,
  banners: [],
  shortDescription: "",
  platformType: "RetailerApp",
  bannerLocationCode: "",
  franchise: [],
  filterByFranchiseType: "",
  filterByFranchiseGroupType: "",
  bannerFilterType: "",
  page: "",
  location: "",
};

const ManageBanner = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([...manageView.breadcrumbs]);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(false);

  // const [criteriaType, setCriteriaType] = useState([
  //   ...manageView.filterByConditionTypeOptions,
  // ]);

  // const [submitting, setSubmitting] = useState({ msg: "", loading: false });

  const [platformTypeOptions, setPlatformTypeOptions] = useState([
    ...manageView.platformTypeOptions,
  ]);

  const [accessLevelModal, setAccesLevelModal] = useState({
    show: false,
    data: {},
  });

  const [bannerPageList, setBannerPageList] = useState({
    loading: false,
    data: [],
    pageOptions: [],
  });

  const [locationOptions, setLocationOptions] = useState([]);

  const [showManageBannersliderModal, setShowManageBannersliderModal] =
    useState({
      status: false,
      mode: "Add",
      data: {},
      index: -1,
    });

  const [display, setDisplay] = useState("loading");

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [selectedLocation, setSelectedLocation] = useState({});

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const navigate = useNavigate();

  const formRef = useRef({ page: "", location: "" });

  const paginationRef = useRef({ ...defaultPagination });

  // Accecc level input component data for location details
  const stateListRef = useRef([]);
  const rmfSubtypeOptionsRef = useRef([]);
  const rfSubtypeOptionsRef = useRef([]);
  const sfSubtypeOptionsRef = useRef([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValue,
  });

  const [
    banners,
    platformType,
    filterByFranchiseType,
    location,
    page,
    resolutionWidth,
    resolutionHeight,
  ] = useWatch({
    name: [
      "banners",
      "platformType",
      "filterByFranchiseType",
      // "bannerFilterType",
      // "bannerLocationCode",
      // "filterByConditionType",
      "location",
      "page",
      "resolutionWidth",
      "resolutionHeight",
    ],
    control: methods.control,
  });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(
    (d = {}) => {
      paginationRef.current = { ...defaultPagination };
      if ((query?.loc && query?.page) || (d.page && d.location)) {
        setDisplay("loading");
        let page = d?.page || query?.page;
        let loc = d?.location || query?.loc;
        applyFilter(loc, page);
        // setBreadcrumbs((prevData) => {
        //   prevData.at(-1).name = "Edit Banner";
        //   return prevData;
        // });

        // let p = breadcrumbs.map((e) => {
        //   // if (e.name == "Banner Details") {
        //   //   e.params = { id: query.id, type: "ClubApp" || "" };
        //   // }
        //   if (e.name == "Add Banner") {
        //     e.name = "Edit Banner";
        //   }
        //   return e;
        // });
        // setBreadcrumbs([...p]);
      } else {
        setDisplay("form");
        resetFormData();
        loadBannerLocations();
      }

      // if (query?.type == "ClubApp") {
      let p = breadcrumbs.map((e) => {
        if (e.name == "Banner List") {
          e.link = "/configs/marketing/dashboard";
          e.params = { tab: "banner" };
        }
        return e;
      });
      setBreadcrumbs([...p]);
      // }

      // if (query?.type && query.type == "ClubApp") {
      let k = [
        { label: "Select", value: "" },
        { label: "Club App", value: "ClubApp" },
      ];
      setPlatformTypeOptions([...k]);
      methods.setValue("platformType", "ClubApp");
      // }
      loadStateList();
      loadRmfSubtypeOptions();
      loadRfSubtypeOptions();
      loadSfSubtypeOptions();
    },
    [
      query?.loc,
      query?.page,
      breadcrumbs,
      methods,
      loadStateList,
      loadRmfSubtypeOptions,
      loadRfSubtypeOptions,
      loadSfSubtypeOptions,
      applyFilter,
      resetFormData,
      loadBannerLocations,
    ]
  );

  const applyFilter = useCallback(
    (loc, page) => {
      paginationRef.current = { ...defaultPagination };
      loadDetails(loc, page);
      loadCount(loc, page);
    },
    [loadCount, loadDetails]
  );

  const loadCount = useCallback(async (loc, page) => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getLoadDetailsParams(loc, page);
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, []);

  const getCount = async (params) => {
    try {
      const r = await BannerService.getBannerCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const getLoadDetailsParams = (loc, page) => {
    let params = {
      filter: {
        location: loc,
        page: page,
      },
      count: paginationRef.current.rowsPerPage,
      page: paginationRef.current.activePage,
      sort: { lastUpdated: -1 },
    };
    return params;
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      let f = methods.getValues();
      loadDetails(f.location, f.page);
    },
    [loadDetails, methods]
  );

  const loadDetails = useCallback(
    async (loc, page) => {
      let params = getLoadDetailsParams(loc, page);

      let m = await BannerService.getBannerList(params);

      if (m.statusCode == 200 && Array.isArray(m.resp)) {
        let banners = [];

        m.resp.forEach((e) => {
          banners.push({
            ...e.banners[0],
            resolution: e.resolution,
            page: m.resp[0].page,
            locationType: m.resp[0]?.location,
            platformType: m.resp[0].platformType,
            applicableFor: m.resp[0].applicableFor,
            bannerLocationCode: m.resp[0].bannerLocationCode,
            shortDescription: m.resp[0].shortDescription,
            id: e._id,
          });
        });

        // const r = m?.resp || {};
        const ignoreFormKeys = [
          "resolutionHeight",
          "resolutionWidth",
          "banners",
          "filterBrand",
          "filterDeal",
          "filterCategory",
          // "location",
        ];
        Object.keys(defaultFormValue).forEach((key) => {
          if (ignoreFormKeys.indexOf(key) == -1) {
            // methods.setValue(key, r[key]);
          }
          // methods.setValue("resolutionHeight", r.resolution.height);
          // methods.setValue("resolutionWidth", r.resolution.width);
          banners.forEach((e) => {
            e._bannerType = getBannerType(e.bannerType);
            e._canEdit = "yes";

            e._activeStatus = BannerService.getBannerStatusByDate(
              e?.period?.date?.startDate,
              e?.period?.date?.endDate
            );

            if (
              differenceInDays(
                new Date(e?.period?.date?.endDate),
                new Date()
              ) <= 1 &&
              e._activeStatus?.key != "expired"
            ) {
              e._isExpiringSoon = true;
            } else {
              e._isExpiringSoon = false;
            }
          });

          // methods.setValue("banners", r.banners);

          methods.setValue("banners", banners);
          // if (r.length) {
          //   formRef.current = { page: r.page, location: r.location };
          // }
          // methods.setValue(
          //   "filterByConditionType",
          //   r.banners[0]?.filterByConditionType
          // );

          // // criteria Details
          // if (r.banners[0]?.filterByConditionType == "Brand") {
          //   loadBrandDetails(r.banners[0]?.filterByCondition, "filterBrand");
          // } else if (r.banners[0]?.filterByConditionType == "Category") {
          //   loadCategoryDetails(
          //     r.banners[0]?.filterByCondition,
          //     "filterCategory"
          //   );
          // } else if (r.banners[0]?.filterByConditionType == "Deal") {
          //   loadDealDetails(r.banners[0]?.filterByCondition, "filterDeal");
          // } else if (r.banners[0]?.filterByConditionType == "Brand-Category") {
          //   let ids = r.banners[0]?.filterByCondition[0].split("-");
          //   loadBrandDetails([ids[0]], "filterBrand");
          //   loadCategoryDetails([ids[1]], "filterCategory");
          // }
          setDisplay("form");
        });

        loadBannerLocations(
          m.resp[0]?.page || "",
          m.resp[0]?.location,
          true,
          false
        );
        // if (r.filterByFranchiseId) {
        //   loadFranchiseDetails(m.resp[0].filterByFranchiseId);
        // }
      } else {
        setDisplay("notFound");
      }
    },
    [methods, loadBannerLocations]
  );

  const loadBannerLocations = useCallback(
    async (p = "", location = "", initialCall = false, isActive = true) => {
      setBannerPageList({ loading: true, data: [], pageOptions: [] });
      let params = {
        page: 1,
        count: 200,
        filter: {},
      };

      if (isActive) {
        params.filter.status = "Active";
      }

      const r = await BannerService.getBannerLocationMasterList(params);

      let d = Array.isArray(r.resp) ? r.resp : [];
      let page = [{ label: "Choose", value: "" }];
      if (d.length) {
        sortBy(uniqBy(d, "page"), "page").forEach((e) => {
          page.push({ label: e.page, value: e.page });
        });
      }

      setBannerPageList({
        loading: false,
        data: [...d],
        pageOptions: [...page],
      });

      if (p) {
        methods.setValue("page", p);
        onPageInputChange(p, [...d], location, initialCall);
      }
    },
    [methods, setBannerPageList, onPageInputChange]
  );

  // const loadDealDetails = useCallback(
  //   async (ids) => {
  //     let m = await DealService.getDeals({ filter: { _id: { $in: ids } } });
  //     if (Array.isArray(m?.resp)) {
  //       let x = [
  //         {
  //           label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")" || "",
  //           value: m.resp[0],
  //         },
  //       ];
  //       methods.setValue("deal", x);
  //     }
  //   },
  //   [methods]
  // );

  // const loadBrandDetails = useCallback(
  //   async (ids, key) => {
  //     let m = await BrandService.getList({
  //       filter: { _id: { $in: ids } },
  //     });
  //     if (Array.isArray(m?.resp) && m.resp.length > 0) {
  //       let x = [
  //         {
  //           label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")" || "",
  //           value: m.resp[0],
  //         },
  //       ];
  //       methods.setValue(key, x);
  //     }
  //   },
  //   [methods]
  // );

  // const loadCategoryDetails = useCallback(
  //   async (ids, key) => {
  //     let m = await CategoryService.getList({
  //       filter: { _id: { $in: ids } },
  //     });
  //     if (Array.isArray(m?.resp) && m.resp.length > 0) {
  //       let x = [
  //         {
  //           label: m.resp[0]?.name + " (" + m.resp[0]?._id + ")" || "",
  //           value: m.resp[0],
  //         },
  //       ];
  //       methods.setValue(key, x);
  //     }
  //   },
  //   [methods]
  // );

  // const loadFranchiseDetails = useCallback(
  //   async (id) => {
  //     let m = await FranchiseService.getFranchise(id, {
  //       select: "name,state,district,town,city",
  //     });
  //     let f = [{ label: m.resp.name + " (" + m.resp._id + ")", value: m.resp }];
  //     methods.setValue("franchise", f);
  //   },
  //   [methods]
  // );

  //Fetching  State List for Location details
  const loadStateList = useCallback(async () => {
    let r = await UtilityService.getStateList();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All", value: "all" });
    list.unshift({ label: "Select", value: "" });
    stateListRef.current = list;
  }, []);

  //RMF Subtype  Options  for Location details
  const loadRmfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getRmfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All RMF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    rmfSubtypeOptionsRef.current = list;
  }, []);

  //RF Subtype  Options  for Location details
  const loadRfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getRfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All RF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    rfSubtypeOptionsRef.current = list;
  }, []);

  //SF Subtype  Options  for Location details
  const loadSfSubtypeOptions = useCallback(async () => {
    let r = await UtilityService.getSfSubTypes();
    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All SF", value: "all" });
    list.unshift({ label: "Select", value: "" });
    sfSubtypeOptionsRef.current = list;
  }, []);

  const onSubmit = async (data) => {
    let clonedData = data.banners.filter((e) => {
      return e.status == "Clone";
    });

    if (clonedData.length) {
      let title = "'" + clonedData[0]?.title + "'";
      Toaster.error("Please Update Cloned Details of " + title);
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setBusyLoader({
      msg: "please,wait submitting your response",
      loading: true,
    });
    const p = preparePayload(data);
    // setSubmitting(true);

    let r = {};

    if (!query.id) {
      r = await BannerService.createBanner(p);
    } else {
      r = await BannerService.updateBanner(query.id, p);
    }

    // setSubmitting(false);

    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0] || "Failed to update");
      return;
    }

    resetFormData();
    Toaster.success(`Banner ${query.id ? "Added" : "Updated"} Successfully`);

    // if (query?.type && query.type == "ClubApp") {
    NavService.to(navigate, "/configs/marketing/dashboard", {
      tab: "banner",
    });
    // } else {
    //   NavService.to(navigate, "/configs/banner/list");
    // }
  };

  const getBannerType = (key) => {
    let d = manageView.bannerTypeOptions.filter((e) => {
      return e.value == key;
    });
    return d.length ? d[0]?.label || "" : "";
  };

  const preparePayload = (d) => {
    d.banners.forEach((k, i) => {
      k.ordering = i + 1;
      if (d.filterByFranchiseType == "ByFranchise") {
        k.location = [
          {
            accessLevel: "sf",
            sf: d?.filterByFranchiseId || d?.franchise[0]?.value?._id || "",
            rmf: "",
            rf: "",
            state: "",
            district: "",
            town: "",
            rmfType: "",
            rfType: "",
            sfType: "",
          },
        ];
      } else if (d.filterByFranchiseType == "ByGroup") {
        k.location = [{ accessLevel: "all" }];
      } else {
        k.location = k.location.map((e) => {
          if (e.accessLevel == "rmf") {
            e.rmf = e.rmfv;
          } else {
            e.rmf = "";
          }
          if (e.accessLevel == "rf") {
            e.rf = e.rfv;
          } else {
            e.rf = "";
          }
          if (e.accessLevel == "sf") {
            e.sf = e.sfv;
          } else {
            e.sf = "";
          }

          delete e.rmfv;
          delete e.rmfDetails;
          delete e.rfv;
          delete e.rfDetails;
          delete e.sfv;
          delete e.sfDetails;
          e.customer = "";
          return e;
        });
      }

      k._bannerType = getBannerType(k.bannerType);
      if (k?.image?.length) {
        k.image = k.image || "";
      } else {
        delete k.image;
      }
      if (k?.mobileImage?.length) {
        k.mobileImage = k.mobileImage || "";
      } else {
        delete k.mobileImage;
      }
      (k.period.timeRanges || []).forEach((e) => {
        e.startTime = e.startTime || "";
        e.endTime = e.endTime || "";
      });
    });

    let p = {
      platformType: d.platformType,
      bannerLocationCode:
        d.platformType == "ClubApp" ? d.bannerLocationCode : "",
      applicableFor:
        d.platformType == "ClubApp" ? "Franchise" : d.applicableFor,
      banners: d.banners,
      maxImageSize: selectedLocation?.assetAttributes?.maxSizeBytes,
      // resolution: { height: d.resolutionHeight, width: d.resolutionWidth },
      shortDescription: d.shortDescription,
      sliderTime: selectedLocation?.assetAttributes?.slideTimeSecs || 0,
      resolution: {
        height:
          selectedLocation?.assetAttributes?.heightPxl || d.resolutionHeight,
        width: selectedLocation?.assetAttributes?.widthPxl || d.resolutionWidth,
      },
      title: d.title,
      page: d.page,
      location: d.location,
    };

    if (query.id) {
      p = { ...d, ...p };
    }

    return p;
  };

  const resetFormData = useCallback(() => {
    methods.reset(defaultFormValue);
  }, [methods]);

  const addSlider = async () => {
    let list = banners;
    let maxImg = selectedLocation?.assetAttributes?.totalImages || 1;

    if (list?.length >= maxImg) {
      await Alert.alert({
        title: "Max Banner Slide Limit Alert",
        text: "You Can Upload Max " + maxImg + "Slides for this location",
        icon: "info",
        okText: "OK",
      });
      return;
    } else {
      let f = methods.getValues();
      setShowManageBannersliderModal({
        status: true,
        mode: "Add",
        data: {
          locationType: f.location,
          page: f.page,
          platformType: f.platformType || "ClubApp",
          applicableFor: "Franchise",
          bannerLocationCode: f.page + "::" + f.location,
        },
        index: -1,
      });
    }
  };

  const editSlider = (index) => {
    setShowManageBannersliderModal({
      status: true,
      mode: "Edit",
      data: { ...banners[index] },
      index: index,
    });
  };

  const deleteCb = (i) => {
    let list = [...banners];
    list.splice(i, 1);
    methods.setValue("banners", [...list]);
    methods.trigger("banners");
  };

  const cloneCb = (i) => {
    const d = [...banners];
    let dup = d[i];
    let list = [
      ...banners,
      { ...dup, title: dup.title + " Duplicate", status: "Clone" },
    ];
    methods.setValue("banners", [...list]);
    methods.trigger("banners");
  };

  const viewCb = (i) => {
    const d = [...banners];
    let data = d[i];
    setAccesLevelModal({ show: true, data: data });
  };

  const closeAccessLevelCb = () => {
    setAccesLevelModal({ show: false, data: {} });
  };

  const manageBannersliderModalCallback = (d) => {
    if (d.status == "applied" && d.data.page && d.data.location) {
      // let data = methods.getValues("banners");
      // let x = prepareSliderData(d);
      // // Edit mode updating data
      // if (showManageBannersliderModal.index != -1) {
      //   data[showManageBannersliderModal.index] = x;
      // } else {
      //   data.push(x);
      // }
      // methods.setValue("banners", [...data]);
      // methods.trigger("banners");
      init({ page: d.data.page, location: d.data.location });
    }
    setShowManageBannersliderModal({
      status: false,
      mode: "Add",
      data: {},
      index: -1,
    });
  };

  const prepareSliderData = ({ formData, mode }) => {
    const d = formData;
    let p = {
      _bannerType: getBannerType(d?.bannerType),
      bannerTrackingCode: d.bannerTrackingCode || "",
      bannerMasterId: d.bannerMasterId || "",
      image: d?.bannerMasterData?.assetId || "",
      mobileImage: d?.bannerMasterData?.assetId || "",
      filterByConditionType: d?.filterByConditionType || "",
      filterByFranchiseType: d.filterByFranchiseType,
      filterByFranchiseGroupType:
        d.filterByFranchiseType == "ByGroup"
          ? d.filterByFranchiseGroupType
          : "",
      filterByFranchiseId:
        d.filterByFranchiseType == "ByFranchise"
          ? d.franchise[0].value._id
          : "",
      accessLevel: "all",
      allDayRange: true, //d?.activePeriod == "all",
      appType: ["SK"],
      // || []).map((e) => {
      //   return e.value;
      // }),
      bannerText: d?.bannerText || "",
      bannerType: d?.bannerType,
      bannerTypeNames: {},
      displayType: d?.displayType,
      // image: d?.image || "",
      // mobileImage: d?.mobileImage || "",
      isPartnerApp: false,
      period: {
        date: {
          startDate: new Date(d?.startDate),
          // set(new Date(d?.startDate).toISOString(), {
          //   hours: 0,
          //   minutes: 0,
          //   seconds: 0,
          // }),

          endDate: new Date(d?.endDate),
          //  new Date(d?.endDate).toISOString(),
        },
        timeRanges: [
          {
            startTime:
              d?.startTime1?.length > 0
                ? new Date(d?.startTime1[0]).toISOString()
                : "",
            endTime:
              d?.endTime1?.length > 0
                ? new Date(d?.endTime1[0]).toISOString()
                : "",
          },
        ],
      },
      shortDescription: d?.shortDescription,
      subType: d?.subType,
      title: d?.title,
      url: d?.url || "",
      status: d?.status == "true" ? "Active" : "Inactive",
      ordering: d?.ordering || 1,
      location: d?.location || [],
      isEdit: true,
    };

    if (d?.startTime2?.length && d?.endTime2?.length) {
      p.period.timeRanges.push({
        startTime: new Date(
          d?.startTime2?.length ? d?.startTime2[0] : ""
        ).toISOString(),
        endTime: new Date(
          d?.endTime2?.length ? d?.endTime2[0] : ""
        ).toISOString(),
      });
    } else {
      p.period.timeRanges.splice(1, 1);
    }

    if (d?.bannerType == "deal") {
      p.bannerTypeNames["deal"] = d?.deal[0]?.value?._id;
      p.deal = d.deal;
    }

    if (["brand", "brandAndCategory"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["brand"] = d?.brand[0]?.value?._id;
      p.brand = d.brand;
    }

    if (["category", "brandAndCategory", "menu"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["category"] = d?.category[0]?.value?._id;
    }

    if (["marketPlaceSeller"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["marketPlaceSeller"] =
        d?.marketPlaceSeller[0]?.value?._id;
    }

    if (["kingsale"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["kingsale"] = d?.kingsale[0]?.value?._id;
    }

    if (["keyword"].indexOf(d?.bannerType) != -1) {
      p.bannerTypeNames["keyword"] = d?.keyword;
    }

    if (d?.bannerType == "multiDeals") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiDeals"] = idsList;
    }

    if (d?.bannerType == "multiCategories") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiCategories"] = idsList;
    }

    if (d?.bannerType == "multiBrands") {
      let idsList = d.ids.split(",");
      p.bannerTypeNames["multiBrands"] = idsList;
    }

    // Criteria Type

    if (d?.filterByConditionType == "Brand-Category") {
      let id =
        d?.filterBrand[0]?.value?._id + "-" + d?.filterCategory[0]?.value?._id;
      p.filterByCondition = [id];
    } else if (d?.filterByConditionType == "Brand") {
      p.filterByCondition = [d?.filterBrand[0]?.value?._id];
    } else if (["Menu", "Category"].indexOf(d?.filterByConditionType) != -1) {
      p.filterByCondition = [d?.filterCategory[0]?.value?._id];
    } else if (d?.filterByConditionType == "Deal") {
      p.filterByCondition = [d?.filterDeal[0]?.value?._id];
    }

    if (
      ["recharge", "dmt", "wallet", "servicePartner"].indexOf(d?.bannerType) !=
      -1
    ) {
      p.bannerTypeNames[d.bannerType] = d[d.bannerType];
    }

    if (query?.id?.length > 0) {
      p._id = d?._id;
    }
    return p;
  };

  const moveCardCb = (dragIndex, hoverIndex) => {
    let list = [...banners];
    // swaping data within list
    const draggedItem = list.splice(dragIndex, 1);
    list.splice(hoverIndex, 0, draggedItem[0]);
    list.forEach((e, i) => {
      e.ordering = i + 1;
    });
    methods.setValue("banners", [...list]);
    methods.trigger("banners");
  };

  const onFilterByFranchiseTypeChanges = () => {
    methods.setValue("filterByFranchiseGroupType", "");
    methods.setValue("franchise", []);
  };

  const onPlatForTypeChange = () => {
    methods.setValue("applicableFor", "");
    methods.setValue("filterByFranchiseType", "");
    methods.setValue("filterByFranchiseGroupType", "");
    methods.setValue("bannerFilterType", "");
  };

  const onFilterByConditionTypeChange = (d) => {
    methods.setValue("filterBrand", []);
    methods.setValue("filterDeal", []);
    methods.setValue("filterCategory", []);
  };

  // const onBannerLocationCodeChange = useCallback(
  //   (val) => {
  //     let k = bannerCodeOptions.find((e) => {
  //       return e.value == val;
  //     });
  //     let m = "";

  //     if (k?.key) {
  //       m = manageView.filterByConditionTypeOptions.filter((e) => {
  //         return e?.key.indexOf(k.key) != -1;
  //       });
  //       if (m?.length > 0) {
  //         let x = m?.length >= 1 ? m[0]?.value || "" : "";
  //         setCriteriaType([...m]);
  //         methods.setValue("filterByConditionType", x);
  //       }
  //     } else {
  //       setCriteriaType([]);
  //       methods.setValue("filterByConditionType", "");
  //     }
  //   },
  //   [methods]
  // );

  const onPageInputChange = useCallback(
    async (val, datalist = [], l = "", initialCall = false) => {
      if (selectedLocation?._id && banners.length && !initialCall) {
        let res = await Alert.confirm({
          title: "Please confirm",
          text: "Based On Page we are showing Banner List and it will reflect on below showing list, If you change Do you want to proceed?",
          icon: "info",
          okText: "Yes",
          cancelText: "No",
        });

        if (!res.isConfirmed) {
          methods.setValue("page", formRef.current.page);
          return;
        }
      }

      if (selectedLocation?._id && banners.length && !initialCall) {
        methods.setValue("banners", []);
        paginationRef.current = { ...defaultPagination };
      }

      let p = [];
      if (datalist.length) {
        p = datalist.filter((e) => {
          return e.page == val;
        });
      } else {
        p = [...bannerPageList.data].filter((e) => {
          return e.page == val;
        });
      }
      let loc = [{ label: "Choose", value: "" }];
      let list = sortBy(p, "location");
      list.forEach((e) => {
        loc.push({ label: e.location, value: e.location });
      });
      methods.setValue("location", "");

      setLocationOptions([...loc]);
      formRef.current.page = val;
      if (l?.length) {
        methods.setValue("location", l);
        methods.trigger();
        let z = datalist.filter((e) => {
          return e.location == l && val == e.page;
        });
        setSelectedLocation(z.length ? z[0] : {});
      }
    },
    [selectedLocation?._id, banners.length, methods, bannerPageList.data]
  );

  const onLocationInChange = useCallback(
    async (val) => {
      if (selectedLocation?._id && banners.length) {
        let res = await Alert.confirm({
          title: "Please confirm",
          text: "Based On Location we are showing Banner List and it will reflect on below showing list, If you change Do you want to proceed?",
          icon: "info",
          okText: "Yes",
          cancelText: "No",
        });

        if (!res.isConfirmed) {
          methods.setValue("location", formRef.current.location);
          return;
        }
      }

      if (selectedLocation?._id && banners.length) {
        methods.setValue("banners", []);
      }

      formRef.current.location = val;
      if (val) {
        let p = [...bannerPageList.data].filter((e) => {
          return e.location == val && page == e.page;
        });
        let s = p.length ? p[0] : p;
        setSelectedLocation(s);
        methods.setValue("resolutionHeight", s?.assetAttributes?.heightPxl);
        methods.setValue("resolutionWidth", s?.assetAttributes?.widthPxl);
        applyFilter(val, methods.getValues("page"));
      }
    },
    [
      selectedLocation?._id,
      banners.length,
      methods,
      bannerPageList.data,
      applyFilter,
      page,
    ]
  );

  return (
    <>
      {display === "loading" && <PageLoader animation="border" />}

      {display === "notFound" && <NoDataFound> No Data Found </NoDataFound>}

      {display === "form" && (
        <>
          <PageInfo
            title="Add/Update Banner"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
              <div className="mb-3">
                <AppCard title="Basic config Details">
                  <div className="row">
                    {/* Title */}
                    {/* <div className="col-8">
                      <TextInput
                        type="text"
                        label="Banner Title"
                        placeholder="Enter Banner Title Here"
                        register={methods.register}
                        name="title"
                        error={methods?.formState?.errors?.title?.message}
                        isMandatory={true}
                      />
                    </div> */}

                    {/* Platform Type */}
                    <div className="col-2 mb-2">
                      <SelectInput
                        type="text"
                        label="Platform Type"
                        register={methods.register}
                        name="platformType"
                        error={
                          methods?.formState?.errors?.platformType?.message
                        }
                        options={[...platformTypeOptions]}
                        isMandatory={true}
                        callback={onPlatForTypeChange}
                        disabled={query?.id?.length > 0}
                      />
                    </div>

                    {/* Banner For */}
                    {/* {platformType == "RetailerApp" ? (
                      <div className="col-2 mb-2">
                        <SelectInput
                          type="text"
                          label="Banner For"
                          register={methods.register}
                          name="applicableFor"
                          error={
                            methods?.formState?.errors?.applicableFor?.message
                          }
                          options={[...manageView.applicableForOptions]}
                          isMandatory={true}
                        />
                      </div>
                    ) : null} */}

                    {/* Banner Location Code Options */}

                    {bannerPageList.loading ? (
                      <div className="col-3">
                        <div>Banner Page </div>
                        <Spinner />
                      </div>
                    ) : (
                      <div className="col-3 mb-2">
                        <SelectInput
                          type="text"
                          label="Banner Page"
                          register={methods.register}
                          name="page"
                          error={methods?.formState?.errors?.page?.message}
                          options={bannerPageList.pageOptions}
                          isMandatory={true}
                          disabled={platformType != "ClubApp"}
                          callback={onPageInputChange}
                        />
                      </div>
                    )}

                    <div className="col-3 mb-2">
                      {bannerPageList.loading ? (
                        <div className="col-3">
                          <div>Banner Location </div>
                          <Spinner />
                        </div>
                      ) : (
                        <SelectInput
                          type="text"
                          label="Banner Location"
                          register={methods.register}
                          name="location"
                          error={methods?.formState?.errors?.location?.message}
                          options={locationOptions}
                          isMandatory={true}
                          disabled={query?.id?.length > 0 || !page.length}
                          callback={onLocationInChange}
                        />
                      )}
                    </div>
                  </div>
                </AppCard>
              </div>

              <div className="mt-3">
                <AppCard>
                  <div className="row">
                    <div className="col-8">
                      <label className="pt-3 fs-val-md fw-semibold">
                        Config Banner Sliders
                      </label>
                      {methods?.formState?.errors?.banners?.message && (
                        <InputErrorMsg
                          msg={methods?.formState?.errors?.banners?.message}
                        />
                      )}
                    </div>
                    <div className="col-4  text-end">
                      {!selectedLocation || !location || !page ? (
                        <div className="text-danger fs-val-sm mb-2 pe-2">
                          Note : Please Add Title , Page, Location details To
                          Add Slide Details
                        </div>
                      ) : null}

                      {selectedLocation?._id ? (
                        <div className="text-primary fs-val-sm mb-2 pe-1">
                          Note: You Can Upload Max{" "}
                          {selectedLocation?.assetAttributes?.totalImages || 1}{" "}
                          Slides for this Location
                        </div>
                      ) : null}
                      <div>
                        <Rbac roles={rbac.edit}>
                          <button
                            className="btn btn-success mb-3 text-end"
                            onClick={addSlider}
                            type="button"
                            disabled={!selectedLocation || !location || !page}
                          >
                            Add Slider
                          </button>
                        </Rbac>
                      </div>
                    </div>
                  </div>

                  <PaginationSummary
                    paginationConfig={paginationRef.current}
                    loadingTotalRecords={loadingTotalRecords}
                  />

                  <BannerSliderTable
                    banners={[...banners]}
                    editCb={editSlider}
                    deleteCb={deleteCb}
                    cloneCb={cloneCb}
                    viewCb={viewCb}
                    moveCardCb={moveCardCb}
                    resolutionWidth={resolutionWidth}
                    resolutionHeight={resolutionHeight}
                    paginationConfig={paginationRef.current}
                    paginationCb={paginationCb}
                    loadingTotalRecords={loadingTotalRecords}
                  />
                </AppCard>
              </div>

              {/* Submit Button */}
              {/* <div className="text-end py-3">
                <button
                  className="btn btn-lg btn-primary"
                  type="submit"
                  disabled={submitting.loading}
                >
                  Submit
                  {submitting.loading ? <Spinner isSmall={true} /> : null}
                </button>
              </div> */}
            </form>
          </FormProvider>
        </>
      )}

      <ManageBannerSliderModal
        show={showManageBannersliderModal.status}
        mode={showManageBannersliderModal.mode}
        bannerId={query?.id}
        data={showManageBannersliderModal.data}
        callback={manageBannersliderModalCallback}
        stateListOptions={stateListRef.current}
        rmfSubtypeOptions={rmfSubtypeOptionsRef.current}
        rfSubtypeOptions={rfSubtypeOptionsRef.current}
        sfSubtypeOptions={sfSubtypeOptionsRef.current}
        filterByFranchiseType={filterByFranchiseType}
        type={"ClubApp"}
        selectedLocation={selectedLocation}
      />

      <ViewAccessLevelModal
        show={accessLevelModal.show}
        data={accessLevelModal.data}
        callback={closeAccessLevelCb}
      />
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default ManageBanner;
