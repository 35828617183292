import { MarketingDashboardNavComponent } from "@sk/features";
import { PageInfo } from "@sk/uis";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchUrlQueryString } from "@sk/hooks";
import FilterForm from "./components/FilterForm";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Marketing Dashboard",
  },
];

const MarketingDashboard = () => {
  const [type, setType] = useState("marketing");
  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const filterRef = useRef({});

  useEffect(() => {
    if (query?.tab) {
      setType(query.tab);
    }
  }, [query]);

  const filterCb = (data) => {
    filterRef.current = data;
  };

  return (
    <div>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Banners"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* {type == "marketing" ? (
        <div className="pt-2">
          <FilterForm callback={filterCb} />
        </div>
      ) : null} */}
      {/* Marketing Nav Chips */}
      <div className="mt-2">
        <MarketingDashboardNavComponent
          activeTab={type}
          router={router}
          filterData={filterRef.current}
          subTypeKey="dashboard"
        />
      </div>
    </div>
  );
};

export default MarketingDashboard;
