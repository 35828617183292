import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TextareaInput, Toaster } from "@sk/uis";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { uniq } from "lodash";

const validationSchema = yup.object({
  cIds: yup
    .string()
    .required("Customer Ids are Required")
    .test("id-pattern", (val, ctx) => {
      let valid = true;
      let errorId = "";
      let cIds = val.split(",");
      for (const c of cIds) {
        valid = /^CR\d+/.test(c);
        if (!valid) {
          errorId = c;
          return ctx.createError({
            message: `Invalid Id ${errorId}`,
          });
        }
      }
      return true;
    })
    .test("ids-length", (val, ctx) => {
      let cIds = val.split(",");
      if (cIds.length > 20) {
        return ctx.createError({
          message: "IDs exceeding limit",
        });
      }
      return true;
    })
    .test("id-duplicate", (val, ctx) => {
      let cIds = val.split(",");
      let uni = uniq(cIds);
      if (uni.length != cIds.length) {
        return ctx.createError({
          message: "Please remove Duplicate Ids",
        });
      }
      return true;
    }),
});

const BulkCustomerUpload = ({ show, callback, customers }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cIds: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const triggerCloseModal = () => {
    reset({ cIds: "" });
    callback({ action: "close" });
  };
  const onCustomerIdChange = (e) => {
    let val = e?.target?.value?.trim()?.toUpperCase();
    e.target.value = val;
  };

  const validateCustomerIds = (data) => {
    let errMsg = "";
    let existIds = [];
    const cIds = data.cIds;
    customers.forEach((c) => {
      if (cIds.includes(c.id)) {
        existIds.push(c.id);
      }
    });

    if (existIds.length) {
      errMsg = ` ${existIds.join(",")} already exists `;
    }
    return {
      isValid: !errMsg,
      errMsg,
    };
  };

  const onSubmit = (data) => {
    const validation = validateCustomerIds(data);
    if (!validation.isValid) {
      Toaster.error(validation.errMsg);
      return;
    }
    callback({ action: "submit", data });

    reset({ cIds: "" });
  };
  return (
    <Modal show={show} onHide={triggerCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Bulk Upload Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextareaInput
          register={register}
          name="cIds"
          rows={2}
          placeholder="Enter Customer ID in Comma separated values   "
          error={errors?.cIds?.message}
          note="Please Enter 20 Ids at one time"
          callback={onCustomerIdChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary fs-val-sm"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkCustomerUpload;
