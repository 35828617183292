import { FranchiseService } from "@sk/services";
import { AppCard, Spinner } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "50%" };

const ViewAccessLevelModal = ({ show, data, callback }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setLoading(true);
      if (data?.location?.length > 0) {
        let franList = data.location.filter((e) => {
          return e.rmf || e.rf || e.sf;
        });

        let ids = franList.map((e) => {
          return e.rmf || e.rf || e.sf;
        });
        if (ids.length) {
          loadFranchiseDetails(ids, data);
        } else {
          setList([...data.location]);
          setLoading(false);
        }
      } else {
        setList([...data.location]);
        setLoading(false);
      }
    }
  }, [data, show]);

  const loadFranchiseDetails = useCallback(async (ids, data) => {
    let m = await FranchiseService.getFranchises({
      filter: { _id: { $in: ids } },
      select: "name,state,district,town,city",
    });

    if (Array.isArray(m?.resp)) {
      let l = [...data.location];
      l.forEach((e) => {
        m.resp.forEach((k) => {
          if ([e.rmf, e.rf, e.sf].indexOf(k._id) != -1) {
            if (e.rmf == k._id) {
              e.rmfDetails = k;
              e.rmfv = k._id;
            } else if (e.rf == k._id) {
              e.rfDetails = k;
              e.rfv = k._id;
            } else if (e.sf == k._id) {
              e.sfDetails = k;
              e.sfv = k._id;
            }
          }
        });
      });
      setList(l);
      setLoading(false);
    }
  });

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">{data.title} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3 border-top">
          <div className="p-3">
            <AppCard title="Access Level">
              {/* List */}
              {loading ? (
                <div className="text-center">
                  <Spinner></Spinner>
                </div>
              ) : (
                <>
                  {list?.length > 0 && !loading
                    ? list.map((e, index) => (
                        <div className="mb-3" key={index}>
                          {index + 1}.{" "}
                          {e?.state == "all" ? "All State" : e?.state}
                          {e?.district ? " > " : ""}
                          {e?.district == "all" ? "All District" : e?.district}
                          {e?.town ? " > " : ""}
                          {e?.town == "all" ? "All Town" : e?.district}
                          {e?.rmfType ? " > " : ""}
                          {e?.rmfType == "all" ? "ALL RMF" : e?.rmfType}
                          {e?.rfType ? " > " : ""}
                          {e?.rfType == "all" ? "ALL RF" : e?.rfType}
                          {e?.sfType ? " > " : ""}
                          {e?.sfType == "all" ? "ALL SF" : e?.sfType}
                          {/* RMF  Details*/}
                          {e?.rmfv ? (
                            <>
                              {e?.rmfDetails?.name} {e?.rmfDetails?._id}
                              {e?.rmfDetails?.state
                                ? ", (" + e?.rmfDetails?.state
                                : ""}{" "}
                              {e?.rmfDetails?.district
                                ? ", " + e?.rmfDetails?.district
                                : ""}{" "}
                              {e?.rmfDetails?.town
                                ? ", " + e?.rmfDetails?.town + ")"
                                : ""}
                            </>
                          ) : null}
                          {/* RF  Details*/}
                          {e?.rfv ? (
                            <>
                              {e?.rfDetails?.name} {e?.rfDetails?._id}
                              {e?.rfDetails?.state
                                ? ", (" + e?.rfDetails?.state
                                : ""}{" "}
                              {e?.rfDetails?.district
                                ? ", " + e?.rfDetails?.district
                                : ""}{" "}
                              {e?.rfDetails?.town
                                ? ", " + e?.rfDetails?.town + ")"
                                : ""}
                            </>
                          ) : null}
                          {/* SF  Details*/}
                          {e.sfv ? (
                            <>
                              {e?.sfDetails?.name} {e?.sfDetails?._id}
                              {e?.sfDetails?.state
                                ? ", (" + e?.sfDetails?.state
                                : ""}
                              {e?.sfDetails?.district
                                ? ", " + e?.sfDetails?.district
                                : ""}
                              {e?.sfDetails?.town
                                ? ", " + e?.sfDetails?.town + ")"
                                : ""}
                            </>
                          ) : null}
                        </div>
                      ))
                    : "All"}
                </>
              )}
            </AppCard>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewAccessLevelModal);
