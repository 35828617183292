import { yupResolver } from "@hookform/resolvers/yup";
import {
  AjaxService,
  AuthService,
  CommonService,
  DealService,
  FranchiseService,
  OmsService,
  UtilityService,
} from "@sk/services";
import {
  Alert,
  Amount,
  AppTitle,
  AutoCompleteInput,
  DatePickerInput,
  EntitySearchInput,
  FileUpload,
  ImgSlider,
  InfoPopover,
  InputErrorMsg,
  KeyVal,
  PageLoader,
  Rbac,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { add, differenceInMinutes } from "date-fns";

import produce from "immer";

import debounce from "lodash/debounce";

import uniqBy from "lodash/uniqBy";

import classNames from "classnames";

import { memo, useCallback, useEffect, useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { Controller, useForm, useWatch } from "react-hook-form";

import * as yup from "yup";

import listView from "../../constantService";

import tooltips from "../../tooltipservice";

import ExcludeForTable from "../components/ExcludeForTable";

import ManageRewardProducts from "./components/ManageRewardProducts";

import OfferDealsTable from "./components/OfferDealsTable";

import RewardProductTable from "./components/RewardProductTable";

import MovableDealModal from "./MovableDealModal";

import { sortBy } from "lodash";
import PreviewStoreOfferModal from "./PreviewStoreOfferModal";

const rbac = {
  viewSlc: ["ViewSkLandingCost"],
};

const searchTypeOptions = [
  { label: "Deal", value: "deal" },
  {
    label: "Category",
    value: "category",
  },
  { label: "Brand", value: "brand" },
];

let franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

let entityFranchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: ["SMARTSF", "SMARTSFCOCO"],
    },
  },
};

const offerProductTypeOptions = [
  { label: "Deal", value: "deal" },
  {
    label: "Category",
    value: "category",
  },
  { label: "Brand", value: "brand" },
  { label: "Category-Brand", value: "category-brand" },
];

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
  defaultDate: new Date(),
};

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

// Validation Schema For Brand
const managePosSmartCoinsValidationSchema = yup.object({
  state: yup.string(),
  district: yup.string(),
  town: yup.string(),
  title: yup.string().trim().required("Title is Required"),
  franchise: yup
    .array()
    .min(1, "Franchise is Required")
    .required("Franchise is Required"),
  desc: yup.string().trim().required("Description is Required"),
  status: yup.string().required("Status is required"),
  // offerCode: yup.string().trim().required("Offer code is Required"),
  offerType: yup.string().trim().required("OfferType is Required"),
  minValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("offerType", {
      is: (offerType) => offerType == "CARTOFFER",
      then: (schema) =>
        schema
          .label("Min Value")
          .min(1, "Cannot Be less than 1")
          .required("Min Value is Required"),
    }),

  maxValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("offerType", {
      is: (offerType) => offerType == "CARTOFFER",
      then: (schema) =>
        schema
          .label("Min Value")
          .moreThan(yup.ref("minValue"), "Cannot be less Than Min Value")
          .required("Max is Required"),
    }),

  mainDeal: yup.array().when("offerType", {
    is: (offerType) => offerType != "CARTOFFER" && offerType != "Buy-N Get-N",
    then: (schema) =>
      schema.label("Deal").min(1, "Please Select at least one Deal").required(),
  }),

  qty: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("offerType", {
      is: (offerType) => offerType != "CARTOFFER" && offerType != "Buy-N Get-N",
      then: (schema) =>
        schema
          .label("Quantity")
          .moreThan(0, "cannot be less than 1")
          .required("Quantity is Required"),
    }),

  rewardType: yup.string().trim().required("Reward Type is Required"),
  rewardTypeValue: yup.string().when("rewardType", {
    is: (rewardType) => rewardType == "DISCOUNT",
    then: (schema) =>
      schema
        .label("Reward Value Type")
        .required("Reward Value Type is Required"),
  }),
  rewardTypeOffer: yup
    .string()
    .trim()
    .when("rewardType", {
      is: (rewardType) => rewardType == "PRODUCT",
      then: (schema) =>
        schema
          .label("Reward Type Offer")
          .required("Reward Type Offer is Required"),
    }),
  validFrom: yup
    .date()
    .when("isEndDateExpired", {
      is: (isEndDateExpired) => isEndDateExpired,
      then: (schema) =>
        schema
          .label("From Date")
          .min(
            new Date(startDateConfig.minDate),
            "Start date must be greater than current date and time"
          ),
    })
    .typeError("Valid From Date is Required")
    .required("Valid From Date is Required "),

  validTo: yup
    .date()
    .when("isEndDateExpired", {
      is: (isEndDateExpired) => isEndDateExpired,
      then: (schema) =>
        schema
          .label("From Date")
          .min(
            new Date(),
            "End date must be greater than current date and time"
          ),
    })
    .min(yup.ref("validFrom"), "Must be greater than start date")
    .required("Valid to Date is Required"),

  rewardValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["rewardType", "rewardTypeValue"], {
      is: (rewardType, rewardTypeValue) => {
        return rewardType == "DISCOUNT" && rewardTypeValue == "Rupees";
      },
      then: (schema) =>
        schema
          .label("Reward Value")
          .min(0.1, "Cannot Be less than 0.1")
          .required(),
    })
    .when(["rewardType", "rewardTypeValue"], {
      is: (rewardType, rewardTypeValue) => {
        return rewardType == "DISCOUNT" && rewardTypeValue == "Percentage";
      },
      then: (schema) =>
        schema
          .label("Reward Value")
          .min(0.1, "Cannot Be less than 0.1")
          .max(100, "Cannot Be greater than 100")
          .required(),
    }),
  limitBy: yup.string().when(["rewardType", "rewardTypeOffer"], {
    is: (rewardType, rewardTypeValue) => {
      return rewardType == "PRODUCT" && rewardTypeValue == "MANYPRODUCT";
    },
    then: (schema) => schema.label("Limit By ").required(),
  }),
  limitValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["rewardType", "rewardTypeOffer"], {
      is: (rewardType, rewardTypeValue) => {
        return rewardType == "PRODUCT" && rewardTypeValue == "MANYPRODUCT";
      },
      then: (schema) =>
        schema
          .label("Limit Value")
          .moreThan(0, "cannot be less than 1")
          .required("Limit Value is Required"),
    }),
  offerLimit: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .nullable()
    .integer()
    .positive()
    .label("Offer Limit"),
});

// default From Values
const defaultFromData = listView.defaultFromData;

const info = tooltips.info; // tool tip

const rewardTypeOptions = [
  { label: "PRODUCT", value: "PRODUCT" },
  // { label: "DISCOUNT", value: "DISCOUNT" },
];

let rewardTypeProductOptions = [
  { label: "Select", value: "" },
  { label: "ONE PRODUCT", value: "ONEPRODUCT" },
];

const limitByOptions = [
  { label: "Select", value: "" },
  { label: "Value", value: "Value" },
  { label: "Quantity", value: "Quantity" },
];

const rewardTypeDiscountOptions = [
  { label: "Select", value: "" },
  { label: "Fixed", value: "Fixed" },
  { label: "Percentage", value: "Percentage" },
];

const rewardTypeValueOptions = [
  { label: "Select", value: "" },
  { label: "Rupees", value: "Rupees" },
  { label: "Percentage", value: "Percentage" },
];

const offerTypeOptions = [
  { label: "CARTOFFER", value: "CARTOFFER" },
  { label: "Buy A Get A", value: "Buy-A Get-A" },
  { label: "Buy A Get B", value: "Buy-A Get-B" },
  { label: "Buy N Get N", value: "Buy-N Get-N" },
];

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "80%",
  },
  alertStyle: {
    zIndex: 2000,
  },
};

const getEntitySearchResult = async (type, id, franchiseId, mode) => {
  if (type == "deal") {
    let params = {
      franchiseId: franchiseId,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: {
        _id: franchiseId,
      },
      inventoryType: "Available",
      slc: canViewSkLandingCost,
      dealFilter: {
        _id: id,
      },
    };

    if (mode == "edit") {
      delete params.inventoryType;
    }

    const r = await FranchiseService.getFranchiseInventorySummary(params);

    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + ` (${x._id})`,
        value: x,
      })),
    };
  }
  if (type == "franchise") {
    const r = await FranchiseService.getList({
      filter: {
        _id: id,
        ...franchiseSearchFilter.filter,
      },
    });
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }
};

const resetDefaultOptions = () => {
  startDateConfig = {
    minDate: new Date(),
    enableTime: true,
    dateFormat: "d M Y G:i K",
    defaultDate: new Date(),
  };
  rewardTypeProductOptions = [
    { label: "Select", value: "" },
    { label: "ONE PRODUCT", value: "ONEPRODUCT" },
  ];
};

// MasterBrand Entity Search Input Filter
const ManageStoreDiscountModal = ({ show, closeModal, mode, id }) => {
  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  // To store Exclude For Data in
  const [excludeForData, setExcludeForData] = useState([]);

  const [offerProductType, setOfferProductType] = useState("deal");

  const [canEditAllInfo, setCanEditAllInfo] = useState(true);

  const [mainDealStockDetails, setMainDealStockDetails] = useState({
    loading: false,
    data: {},
  });

  const [franchiseStockDetails, setFranchiseStockDetails] = useState({
    loading: false,
    data: {},
  });

  const [stateList, setStateList] = useState({
    loading: true,
    options: [{ label: "All", value: "" }],
  });

  const [districtList, setDistrictList] = useState({
    loading: false,
    options: [{ label: "All", value: "" }],
  });

  const [townList, setTownList] = useState({
    loading: false,
    options: [{ label: "All", value: "" }],
  });

  const [showMovableDealModal, setShowMovableDealModal] = useState({
    show: false,
    selectedData: {},
  });

  const [franchiseOptions, setFranchiseOptions] = useState({
    loading: false,
    list: [],
    options: [],
  });

  const [viewPreviewStoreOfferModal, setViewPreviewStoreOfferModal] = useState({
    show: false,
    data: {},
  });

  // React Hook Form
  const {
    formState: { errors },
    setValue,
    getValues,
    control,
    register,
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(managePosSmartCoinsValidationSchema),
    defaultValues: { ...defaultFromData },
  });

  useEffect(() => {
    if (show) {
      setCanEditAllInfo(true);
      // when the modal get opened clear the form data
      reset(defaultFromData);
      loadStateList();
      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
      // when mode is add we need to re-configure Date
      if (mode == "add") {
        resetDefaultOptions();
      }
    }
    setOfferProductType("deal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id, show, loadStateList]);

  // Default State Value
  const loadStateList = useCallback(async () => {
    setStateList({ loading: true, options: [] });
    let r = await UtilityService.getStateList();

    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All", value: "" });
    setStateList({ loading: false, options: [...list] });
  }, [setStateList]);

  // To Prepare Form Values From LoadEditData
  const prepareFromValue = async (r) => {
    // Default FormValues

    [
      "title",
      "desc",
      "offerCode",
      "offerType",
      "rewardType",
      "status",
      "rewardTypeOffer",
      "rewardTypeValue",
      "limitValue",
      "limitBy",
    ].forEach((x) => {
      setValue(x, r[x] || "");
    });

    if (r.productType) {
      setOfferProductType(r.productType);
    }

    // Offer Validity

    if (r.offerValidity) {
      ["validFrom", "validTo"].forEach((x) => {
        setValue(x, new Date(r.offerValidity[x]));
      });
      const startDate = new Date(r.offerValidity.validFrom);
      const endDate = new Date(r.offerValidity.validTo);

      const isEditable = differenceInMinutes(startDate, new Date()) <= 0;
      const isEndDateExpired = differenceInMinutes(endDate, new Date()) <= 0;

      setValue("disableStartDate", isEditable);

      setValue("isEndDateExpired", isEndDateExpired);

      if (isEditable) {
        startDateConfig = { ...startDateConfig, minDate: startDate };
      }

      // Setting Min date of End Config must be Greater than Valid from Date
      endDateConfig = {
        ...endDateConfig,
        minDate: add(startDate, { minutes: 10 }),
      };
    }

    // IF Offer Type is Cart OFFER
    if (r.offerType == "CARTOFFER") {
      ["minValue", "maxValue"].forEach((x) => {
        setValue(x, r[x]);
      });
    }

    // if reward Type is Discount then only reward value will come
    if (r.rewardType == "DISCOUNT") {
      setValue("rewardValue", r.rewardValue);
    }
    // If Reward Type is Product then only we will get reward List
    if (r.rewardType == "PRODUCT") {
      setValue("productList", r.productsList);
    }

    // Exclude For if Offer Type is Cart Offer
    if (r.excludeFor) {
      Object.keys(r.excludeFor).forEach((key) => {
        const list = r.excludeFor[key].map((x) => ({ ...x, type: key }));
        setValue(`excludeFor${key}`, list);
      });

      Object.keys(r.excludeFor).forEach((key) => {
        const list = getValues(`excludeFor${key}`);
        setExcludeForData(
          produce((draft) => {
            draft.push(...list);
          })
        );
      });
    }

    if (r.offerType != "CARTOFFER" && r.offerType != "Buy-N Get-N") {
      const deal = r.offerDeals[0];
      setValue("qty", deal.qty);
      setValue(
        "offerLimit",
        deal.sellInLooseQty ? deal.offerLimit / 1000 : deal.offerLimit
      );
      const resp = await getEntitySearchResult(
        "deal",
        deal.dealId,
        r.franchiseId,
        mode
      );
      setValue("mainDeal", resp.data);
    }

    if (r.offerType == "Buy-N Get-N") {
      setValue("offerDeals", r.offerDeals);
    }

    if (r.franchiseId) {
      const resp = await getEntitySearchResult(
        "franchise",
        r.franchiseId,
        "",
        mode
      );
      setValue("franchise", resp.data);
      loadFranchiseSalesDetails({ value: { _id: r.franchiseId } });
    }

    if (r?.offerImages?.length) {
      const imgs = r.offerImages.map((i) => ({ asset: i }));
      setValue("offerImages", imgs);
    }
    onOfferTypeChange();
  };

  // To get Edit Data When Id Is There
  const loadEditData = async (id) => {
    setPageLoading(true);
    const r = await DealService.getStoreDiscountById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }

    startDateConfig = {
      minDate: new Date(d.offerValidity.validFrom),
      enableTime: true,
      dateFormat: "d M Y G:i K",
      defaultDate: new Date(new Date(d.offerValidity.validFrom)),
    };

    const x = await DealService.getStoreOfferOrderCount({
      filter: { ids: [id] },
    });

    if (x.statusCode == 200) {
      setCanEditAllInfo(x?.resp[0]?.count ? false : true);
      await prepareFromValue(d);
    } else {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal("cancel");
    }
    setPageLoading(false);
  };

  // Preparing Payload While Hitting API
  const preparePayload = useCallback(
    (data) => {
      let p = {
        title: data.title,
        desc: data.desc,
        status:
          mode == "add" || data.status == "Expired" ? "Pending" : data.status,
        // offerCode: data.offerCode || new Date().getTime().toString(),
        offerValidity: {
          validFrom: data.validFrom,
          validTo: data.validTo,
        },
        offerType: data.offerType,
        rewardType: data.rewardType,
        rewardTypeOffer: data.rewardTypeOffer,
        rewardTypeValue: data.rewardTypeValue,
        productType: offerProductType,
      };

      if (data.limitBy) {
        p.limitBy = data.limitBy;
      }

      if (data.limitValue) {
        p.limitValue = data.limitValue;
      }

      if (data?.offerImages?.length) {
        p.offerImages = data.offerImages.map((x) => x.asset);
      }

      if (data.offerType == "CARTOFFER") {
        p.minValue = data.minValue;
        p.maxValue = data.maxValue;
      }

      if (data.offerType != "CARTOFFER" && data.offerType != "Buy-N Get-N") {
        const deal = data.mainDeal[0].value;

        const tempVal = {
          dealId: deal._id,
          name: deal.name,
          qty: data.qty,
          offerLimit: deal.sellInLooseQty
            ? data.offerLimit * 1000
            : data.offerLimit,
          image: deal?.images?.[0] ?? "",
          sellInLooseQty: deal.sellInLooseQty,
          packSize: deal.packSize,
          allowedUnitTypes: deal.allowedUnitTypes,
          mrp: deal.mrp,
          price: deal.retailerSellingPrice,
          menu: {
            id: deal.menuId,
            name: deal.menuName,
          },
          category: {
            id: deal.categoryId,
            name: deal.categoryName,
          },
          brand: {
            id: deal.brandId,
            name: deal.brandName,
          },
        };
        // Sending in main Deal
        if (deal.sellInLooseQty) {
          tempVal.unitType = deal.defaultUnitType;
        }

        p.offerDeals = [tempVal];
      }

      if (data.offerType == "Buy-N Get-N") {
        p.offerDeals = data.offerDeals;
      }

      if (rewardType == "PRODUCT") {
        p.productsList = data.productList;
      }

      if (rewardType == "DISCOUNT") {
        p.rewardValue = data.rewardValue;
      }

      if (data.franchise.length) {
        const franchise = data.franchise[0].value;
        p.franchiseId = franchise._id;
        p.franchiseName = franchise.name;
      }
      if (data.excludeFor) {
        Object.keys(data.excludeFor).forEach((key) => {
          data.excludeFor[key] = data.excludeFor[key].map((x) => ({
            name: x.name,
            id: x.id,
          }));
        });

        p.excludeFor = data.excludeFor;
      }
      if (!data?.offerCode && data.offerType != "CARTOFFER") {
        let cd = ["offerType", "rewardType", "rewardTypeOffer", "franchiseId"]
          .map((e) => p[e])
          .join("-");
        p.offerCode +=
          "-" + p.offerDeals.map((e) => e.dealId + "-" + e.qty).join(":");
        p.offerCode = CommonService.createMD5Hash(cd);
      } else if (!data?.offerCode && data.offerType == "CARTOFFER") {
        p.offerCode = new Date().getTime().toString();
      } else {
        p.offerCode = data.offerCode;
      }
      return p;
    },
    [mode, offerProductType, rewardType]
  );

  // for resetting the form data and its dependent state
  const resetFormData = useCallback(() => {
    reset(defaultFromData);
    //  to enable Config
    endDateConfig = {
      enableTime: true,
      dateFormat: "d M Y G:i K",
    };
  }, [reset]);

  const onNumberInputChange = (e) => {
    const v = CommonService.roundedByDecimalPlace(1 * e.target.value, 0);
    e.target.value = v;
  };

  const onRewardValueChange = debounce((e) => {
    const v = CommonService.roundedByDecimalPlace(
      1 * e.target.value,
      getValues("rewardTypeOffer") == "Percentage" ? 3 : 0
    );
    e.target.value = v;
  }, 1000);

  const validateForm = (data) => {
    let msg = "";
    if (data.offerType == "CARTOFFER" && data.minValue >= data.maxValue) {
      msg = "Min value Cannot be less or Smaller than Max Value";

      // offer deal is list of deal for which we are giving reward
    } else if (data.offerType == "Buy-N Get-N" && !offerDeals.length) {
      msg = "Main Deal list cannot be empty";
    }
    // product list is what we are giving as reward
    else if (rewardType == "PRODUCT" && !productList.length) {
      msg = "Product list cannot be empty";
    }

    return {
      msg,
      valid: !msg,
    };
  };

  const previewStoreOfferCb = useCallback(
    (d) => {
      setViewPreviewStoreOfferModal({ show: false, data: {} });
      if (d.status == "success") {
        submitData(d.data);
      }
    },
    [submitData]
  );

  const onSubmit = async (data) => {
    const validation = validateForm(data);
    if (!validation.valid) {
      Toaster.error(validation.msg);
      return;
    }

    if (mode == "edit") {
      submitData(data);
    } else {
      setViewPreviewStoreOfferModal({ show: true, data: data });
    }
  };

  const submitData = useCallback(
    async (data) => {
      // asking confirm to submit
      let res = await Alert.confirm({
        title: "Please confirm",
        text: `Do you want to ${
          mode == "edit" ? "update " + id : "proceed"
        } ? `,
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      const p = preparePayload(data);

      setSubmitting(true);

      let r = {};

      if (mode == "add") {
        r = await DealService.createStoreDiscount(p);
      } else {
        r = await DealService.updateStoreDiscount(id, p);
      }

      setSubmitting(false);

      if (r.statusCode != 200) {
        let error = AjaxService.parseError(r.resp);
        Toaster.error(error.msg || "Failed to update");
        return;
      }

      resetFormData();

      Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

      triggerCloseModal("submit");
    },
    [id, mode, preparePayload, resetFormData, triggerCloseModal]
  );

  // To Close Modal
  const triggerCloseModal = useCallback(
    (status = "cancel") => {
      closeModal({ status });
      setExcludeForData([]);
      resetFormData();
    },
    [closeModal, resetFormData]
  );

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      if (!val.length) {
        return;
      }

      chngFn(val);

      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = {
          ...endDateConfig,
          minDate: add(val[0], { minutes: 10 }),
        };
      }
      trigger("validTo");
    };
  };

  const [
    offerImages,
    disableStartDate,
    isEndDateExpired,
    rewardTypeOffer,
    offerType,
    productList,
    offerDeals,
    mainDeal,
    franchise,
    searchType,
    excludeFor,
    rewardType,
    state,
    district,
    selectedFranchise,
    qty,
  ] = useWatch({
    name: [
      "offerImages",
      "disableStartDate",
      "isEndDateExpired",
      "rewardTypeOffer",
      "offerType",
      "productList",
      "offerDeals",
      "mainDeal",
      "franchise",
      "searchType",
      "excludeFor",
      "rewardType",
      "state",
      "district",
      "selectedFranchise",
      "qty",
    ],
    control,
  });

  const addExcludeFor = () => {
    const data = getValues(searchType);

    // Preparing Values
    const tempValues = {
      name: data?.[0]?.value?.name,
      id: data?.[0]?.value?._id,
      type: searchType,
    };

    const excludeForValidation = validateExcludeFor(data, tempValues);

    if (!excludeForValidation.valid) {
      Toaster.error(excludeForValidation.msg);
      return;
    }

    setExcludeForData(
      produce((draft) => {
        draft.unshift(tempValues);
      })
    );

    const oldValues = getValues(`excludeFor.${searchType}`);

    const list = uniqBy([...oldValues, tempValues], "id");

    //setting unique Values
    setValue(`excludeFor.${searchType}`, list);

    // reset current
    ["brand", "category", "deal"].forEach((x) => setValue(x, []));
    // const res = await  trigger("excludeFor");

    Toaster.success(
      `Successfully added  : ${tempValues.name} - ${tempValues.id} `
    );
  };

  const validateExcludeFor = (data, tempValues) => {
    let msg = "";
    const isItemAlreadyExists = excludeForData.some(
      (x) => tempValues.id == x.id
    );

    if (!data.length) {
      msg = "Please select at least One " + searchType;
    } else if (isItemAlreadyExists) {
      msg = "This " + searchType + " already exists";
    } else {
      msg = "";
    }
    return {
      msg,
      valid: !msg,
    };
  };

  const removeFromExclude = async (item) => {
    let list = getValues(`excludeFor.${item.type}`);

    // asking confirm to submit
    let res = await Alert.confirm({
      title: `<p className="fs-val-lg p-0 mb-0" > Please confirm </p>`,
      text: `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.name} - ${item.id} ? </p>`,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    // Setting Values in Exclude For
    list = list.filter((x) => x.id != item.id);
    setValue(`excludeFor.${item.type}`, list);

    // Setting Values for Display Purpose
    const excludeForList = excludeForData.filter((x) => x.id != item.id);
    setExcludeForData(excludeForList);
  };

  const manageRewardProductCb = (data) => {
    // Data coming the Manage Reward Product component
    const f = data.formData;
    // Extracting Deal  only
    const dealData = f?.deal?.[0]?.value;
    const list = getValues("productList");
    let tempValue = {};
    // preparing object to push in product list
    if (dealData?._id) {
      let offDisc = 0;
      if (data?.formData?.discountType == "Percentage") {
        offDisc =
          dealData?.retailerSellingPrice *
          (1 - data?.formData?.discountValue / 100);
      } else if (data?.formData?.discountType == "Fixed") {
        offDisc =
          dealData?.retailerSellingPrice - data?.formData?.discountValue;
      } else if (data?.formData?.isFree) {
        offDisc = 0;
      } else {
        offDisc = dealData?.retailerSellingPrice;
      }

      tempValue = {
        dealId: dealData._id,
        name: dealData.name,
        image: dealData?.images?.[0] ?? "",
        mrp: dealData.mrp,
        price: dealData.retailerSellingPrice,
        offerPrice: CommonService.roundedByDecimalPlace(offDisc, 3),
        qty: f.qty,
        isFree: f.isFree,
        sellInLooseQty: dealData.sellInLooseQty,
        packSize: dealData.packSize,
        allowedUnitTypes: dealData.allowedUnitTypes,
        menu: {
          id: dealData.menuId,
          name: dealData.menuName,
        },
        category: {
          id: dealData.categoryId,
          name: dealData.categoryName,
        },
        brand: {
          id: dealData.brandId,
          name: dealData.brandName,
        },
        reserveConfig: dealData.reserveConfig || {},
        _displayUnitKey: dealData._displayUnitKey,
        // for pnl calculation
        _raw: {
          b2bprice: dealData?.b2bprice,
          mrp: dealData?.mrp,
          retailerSellingPrice: dealData?.retailerSellingPrice || 0,
          slc: dealData?.slc,
        },
      };

      if (dealData.sellInLooseQty) {
        tempValue.unitType = dealData.defaultUnitType;
      }

      if (f.discountType) {
        tempValue.discountType = f.discountType;
      }
      if (f.discountValue) {
        tempValue.discountValue = f.discountValue;
      }
    }

    if (f.category?.length) {
      let c = f?.category?.[0]?.value;
      tempValue = {
        categoryId: c.categoryId,
        categoryName: c.categoryName,
      };
    }

    if (f.brand?.length) {
      let c = f?.brand?.[0]?.value;
      tempValue.brandId = c.brandId;
      tempValue.brandName = c.brandName;
    }

    if (f.category?.length || f.brand?.length) {
      tempValue.mrpValue = f.mrpValue;
      tempValue.isFixedMrp = f.isFixedMrp;
    }

    list.unshift(tempValue);

    setValue("productList", list);
  };

  const removeFromProductListCb = useCallback(
    async (item, index) => {
      let text = ``;
      if (offerProductType == "deal") {
        text = `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.name} - ${item.dealId} ? </p>`;
      } else if (offerProductType == "category") {
        text = `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.categoryName} - ${item.categoryId} ? </p>`;
      } else if (offerProductType == "brand") {
        text = `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.brandName} - ${item.brandId} ? </p>`;
      } else if (offerProductType == "category-brand") {
        text = `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.categoryName} - ${item.categoryId} and ${item.brandName} - ${item.brandId} ? </p>`;
      }
      let res = await Alert.confirm({
        title: `<p className="fs-val-lg p-0 mb-0" > Please confirm </p>`,
        text: text,
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      const list = getValues("productList");
      list.splice(index, 1);
      setValue("productList", list);

      Toaster.success(`${item.name} - ${item.dealId} removed successfully `);
    },
    [getValues, offerProductType, setValue]
  );

  const removeOfferDealItemCb = useCallback(
    async (item, index) => {
      let res = await Alert.confirm({
        title: `<p className="fs-val-lg p-0 mb-0" > Please confirm </p>`,
        text: `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.name} - ${item.dealId} ? </p>`,
        icon: "info",
        okText: "Yes",
        cancelText: "No",
      });

      if (!res.isConfirmed) {
        return;
      }

      const list = getValues("offerDeals");
      list.splice(index, 1);
      setValue("offerDeals", list);

      Toaster.success(`${item.name} - ${item.dealId} removed successfully `);
    },
    [getValues, setValue]
  );

  const validateOfferDealsItem = (deal, qty) => {
    const dealId = deal?._id;
    let msg = "";
    const isOfferDealExists = getValues("offerDeals").find(
      (x) => dealId == x.dealId
    );

    if (!deal?._id) {
      msg = "Please select at least 1 deal";
    } else if (!qty) {
      msg = "Please enter required quantity for deal";
    } else if (qty <= 0) {
      msg = "Quantity cannot be 0 or lesser";
    } else if (isOfferDealExists) {
      msg = "Already in the list";
    }

    return {
      msg,
      isValid: !msg,
    };
  };

  const addOfferDealItem = () => {
    const [mainDeal, qty, offerLimit] = getValues([
      "mainDeal",
      "qty",
      "offerLimit",
    ]);
    const list = getValues("offerDeals");
    const deal = mainDeal?.[0]?.value;
    const v = validateOfferDealsItem(deal, qty);
    if (!v.isValid) {
      Toaster.error(v.msg);
      return;
    }
    const tempVal = {
      dealId: deal?._id,
      name: deal.name,
      qty: qty,
      image: deal?.images?.[0] ?? "",
      sellInLooseQty: deal.sellInLooseQty,
      packSize: deal.packSize,
      allowedUnitTypes: deal.allowedUnitTypes,
      // for pnl calculation
      _raw: {
        b2bprice: deal?.b2bprice,
        mrp: deal?.mrp,
        retailerSellingPrice: deal?.retailerSellingPrice || 0,
        slc: deal?.slc,
      },
      offerLimit: offerLimit,
    };

    // Only If Product is in sell in loose
    if (deal.sellInLooseQty) {
      tempVal.unitType = deal.defaultUnitType;
    }

    list.unshift(tempVal);
    setValue("offerDeals", list);
    setValue("mainDeal", []);
    setValue("qty", "");
    setValue("offerLimit", null);
    Toaster.success("Item added successfully");
  };

  const onOfferTypeChange = useCallback(() => {
    const type = getValues("offerType");

    const isManyOptionsExists = rewardTypeProductOptions.find(
      (x) => "MANYPRODUCT" == x?.value
    );
    // if (type != "Buy-A Get-A" && !isManyOptionsExists) {
    //   rewardTypeProductOptions.push({
    //     label: "MANY PRODUCT",
    //     value: "MANYPRODUCT",
    //   });
    // }

    const isAllOptionsExists = rewardTypeProductOptions.find(
      (x) => "ALL" == x?.value
    );

    // If Offer Type = Buy-N Get-N
    if (type == "Buy-N Get-N" || type == "CARTOFFER") {
      // If Mode is add then only we will do
      if (mode == "add") {
        setValue("rewardTypeOffer", "");
      }

      if (!isAllOptionsExists && type == "Buy-N Get-N") {
        rewardTypeProductOptions.push({ label: "ALL", value: "ALL" });
      }
      if (isAllOptionsExists && type == "CARTOFFER") {
        rewardTypeProductOptions.pop();
      }
    }

    if (type == "Buy-A Get-A" && isManyOptionsExists) {
      if (rewardTypeProductOptions.find((e) => e?.value == "MANYPRODUCT")) {
        rewardTypeProductOptions.splice(2, 1);
      }
    }

    if (["Buy-N Get-N", "CARTOFFER", "Buy-N FixedPrice"].indexOf(type) == -1) {
      setValue("rewardTypeOffer", "ONEPRODUCT");

      // we need All Option if it Buy-A Get-A ||  Buy-A Get-B

      // if All option is there then only we will remove
      if (isAllOptionsExists) {
        rewardTypeProductOptions.pop();
      }

      if (mode == "add") {
        // When you are changing Offer Type product list should get Empty
        ["mainDeal", "productList", "offerDeals"].forEach((x) => {
          setValue(x, []);
        });
        setValue("qty", "");
      }
    }
    if (type == "Buy-N FixedPrice") {
      setValue("rewardType", "DISCOUNT");
      setValue("rewardTypeOffer", "");
      if (rewardTypeOptions.length >= 1) {
        rewardTypeOptions.shift();
      }
    } else {
      setValue("rewardType", "PRODUCT");
      if (rewardTypeOptions.length <= 0) {
        rewardTypeOptions.unshift({ label: "PRODUCT", value: "PRODUCT" });
      }
    }
  }, [getValues, mode, setValue]);

  const onMainDealChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      if (offerType == "Buy-A Get-A") {
        setValue("productList", []);
        setValue("qty", "");
      }
      if (val?.length) {
        loadStockDetails(val[0]);
      } else {
        setMainDealStockDetails({ loading: false, data: {} });
      }
    },
    [offerType, setValue, loadStockDetails]
  );

  const loadStockDetails = useCallback(async (data) => {
    setMainDealStockDetails({ loading: true, data: {} });
    let r = await DealService.getBulkDealStock(AuthService.getDefaultWhId(), {
      dealIds: data?.value?._id,
    });
    let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};
    setMainDealStockDetails({ loading: false, data: d });
  }, []);

  const onFranchiseChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      setValue("offerDeals", []);
      if (val.length) {
        loadFranchiseSalesDetails(val[0]);
      } else {
        setFranchiseStockDetails({ loading: false, data: {} });
      }
    },
    [setValue, loadFranchiseSalesDetails, setFranchiseStockDetails]
  );

  const loadFranchiseSalesDetails = useCallback(async (data) => {
    setFranchiseStockDetails({ loading: true, data: {} });
    let r = await OmsService.getFranchiseOfferAnalyticsDetails({
      franchiseId: data?.value?._id,
    });
    let d = r.resp || {};
    setFranchiseStockDetails({ loading: false, data: d });
  }, []);

  const onMainDealSearch = async (val, callback) => {
    const franchiseId = franchise?.[0]?.value?._id;
    let params = {
      franchiseId: franchiseId,
      excludeOutOfStock: true,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: {
        _id: franchiseId,
      },
      slc: canViewSkLandingCost,
      dealFilter: {},
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.dealFilter = {
        $or: [{ _id: searchRegex }, { name: searchRegex }],
      };
    }
    const r = await FranchiseService.getFranchiseInventorySummary(params);

    callback(
      (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const onMainDealQtyChange = useCallback(
    debounce((e) => {
      e.target.value = CommonService.roundedByDecimalPlace(
        Number(e.target.value),
        mainDeal?.[0]?.value?.sellInLooseQty ? 3 : 0
      );
    }, 1000),
    [mainDeal]
  );

  const onOfferCodeChange = useCallback(() => {
    let value = getValues("offerCode");

    value = value.toUpperCase().replace(/\s/g, "");

    setValue("offerCode", value);
  }, [setValue, getValues]);

  const offerProductTypeCallback = useCallback(
    (d) => {
      setOfferProductType(d.type);
      setValue("productList", []);
    },
    [setValue]
  );

  const onRewardTypeChange = useCallback(() => {
    setValue("rewardValue", "");
    setValue("rewardTypeValue", "");
    setValue("rewardTypeOffer", "");
    setValue("limitValue", "");
    setValue("limitBy", "");
  }, [setValue]);

  const onRewardValueTypeChange = useCallback(() => {
    setValue("rewardValue", "");
  }, [setValue]);

  const imagesCb = useCallback(
    async (e) => {
      const v = getValues("offerImages") || [];
      setValue("offerImages", [...v, e]);
    },
    [setValue, getValues]
  );

  const removeImage = useCallback(
    async (index) => {
      let v = getValues("offerImages");
      v.splice(index, 1);
      setValue("offerImages", v);
      await trigger("offerImages");
    },
    [getValues, setValue, trigger]
  );

  const imagesRemove = useCallback(
    (asset, index) => {
      removeImage(index);
    },
    [removeImage]
  );

  const onStateChange = useCallback(
    async (val, type = "") => {
      if (type != "init") {
        setValue("district", "");
        setValue("town", "");
        if (mode != "edit") {
          setValue("franchise", []);
        }
        delete franchiseSearchFilter.filter["district"];
        delete franchiseSearchFilter.filter["town"];
        if (val == "") {
          delete franchiseSearchFilter.filter["state"];
        } else {
          franchiseSearchFilter.filter["state"] = val;
        }
      }
      loadFranchiseOptions();
      setDistrictList({ loading: true, options: [] });

      setTownList({ loading: false, options: [] });

      let r = val.length
        ? await UtilityService.getDistrictList(val)
        : { resp: [] };
      let opt = (r?.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      franchiseSearchFilter.filter.state = val;
      opt.unshift({ label: "All", value: "" });
      setDistrictList({ loading: false, options: opt });
      type != "init" ? setTownList({ loading: false, options: [] }) : null;
    },
    [loadFranchiseOptions, setValue, mode]
  );

  const onDistrictChange = useCallback(
    async (val) => {
      setValue("town", "");
      setValue("franchise", []);
      if (val == "") {
        delete franchiseSearchFilter.filter["district"];
      } else {
        franchiseSearchFilter.filter["district"] = val;
      }
      loadFranchiseOptions();
      setTownList({ loading: true, options: [] });
      delete franchiseSearchFilter.filter["town"];
      let r = val.length
        ? await UtilityService.getTownList(getValues("state"), val)
        : { resp: [] };
      let opt = (r.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "All", value: "" });
      setTownList({ loading: false, options: opt });
    },
    [getValues, setValue, loadFranchiseOptions]
  );

  const onTownChange = useCallback(
    async (val) => {
      setValue("franchise", []);
      if (val == "") {
        delete franchiseSearchFilter.filter["town"];
      } else {
        franchiseSearchFilter.filter["town"] = val;
      }
      loadFranchiseOptions();
    },
    [setValue, loadFranchiseOptions]
  );

  const loadFranchiseOptions = useCallback(
    async (val) => {
      setFranchiseOptions({ loading: true, list: [], options: [] });
      setValue("franchise", []);
      setValue("selectedFranchise", "");
      let p = {
        page: 1,
        count: 100,
        filter: {
          state: getValues("state"),
        },
      };
      if (getValues("district").length > 0) {
        p.filter.district = getValues("district");
      }
      if (getValues("town").length > 0) {
        p.filter.town = getValues("town");
      }
      let r = await FranchiseService.getFranchises(p);
      let m = Array.isArray(r.resp) ? r.resp : [];
      let s = sortBy(m, ["name"]);
      let opt = (s || []).map((e) => {
        return { label: e.name + " (" + e._id + ") ", value: e._id };
      });

      opt.unshift({ label: "Choose Store", value: "" });
      setFranchiseOptions({ loading: false, list: s, options: opt });
    },
    [setValue]
  );

  const onSelectedFranchiseChange = useCallback(
    (val) => {
      setValue("offerDeals", []);

      let f = franchiseOptions.list.filter((e) => {
        return e._id == val;
      });
      if (f.length > 0) {
        setValue("franchise", [{ label: f[0].name, value: f[0] }]);
      } else {
        setValue("franchise", []);
      }
      trigger("franchise");
      if (val.length) {
        loadFranchiseSalesDetails({ value: { _id: val } });
      } else {
        setFranchiseStockDetails({ loading: false, data: {} });
      }
    },
    [setValue, franchiseOptions.list, trigger, loadFranchiseSalesDetails]
  );

  const viewNonMovableStock = () => {
    setShowMovableDealModal({ show: true, selectedData: {} });
  };

  const movableStockModalCb = (d) => {
    if (d?.action == "selected") {
      setValue("mainDeal", [{ label: d.data.deal.name, value: d.data.deal }]);
    }
    setShowMovableDealModal({
      show: false,
      // selectedData: d.status == "submit" ? d.data : {},
    });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={
              mode === "edit"
                ? "Edit Store Offer" + `  #${id}`
                : "Add  Store Offer"
            }
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              {/* Store Details */}
              {isEndDateExpired ? (
                <div
                  style={style.alertStyle}
                  className="alert alert-danger position-sticky top-0 rounded-0"
                  role="alert"
                >
                  Offer is Expired ,Please extend offer dates
                </div>
              ) : null}
              <div className="p-4 bg-light border-bottom">
                <div className="row">
                  <div className="col-12">
                    <div className="fw-semibold fs-val-lg mb-3">
                      Storeking Offers Config
                    </div>
                  </div>

                  {/* State */}
                  {mode != "edit" ? (
                    <>
                      {" "}
                      <div className="col-4 mb-3">
                        {stateList.loading ? (
                          <Spinner />
                        ) : (
                          <div>
                            <SelectInput
                              type="text"
                              label="State"
                              register={register}
                              name="state"
                              options={stateList.options}
                              callback={onStateChange}
                            />
                          </div>
                        )}
                      </div>
                      {/* District */}
                      <div className="col-4 mb-3">
                        {districtList.loading ? (
                          <Spinner />
                        ) : (
                          <div>
                            {" "}
                            <SelectInput
                              type="text"
                              label="District"
                              register={register}
                              name="district"
                              options={districtList.options}
                              callback={onDistrictChange}
                              disabled={!state?.length}
                            />
                          </div>
                        )}
                      </div>
                      {/* Town */}
                      <div className="col-4 mb-3">
                        {townList.loading ? (
                          <Spinner />
                        ) : (
                          <div>
                            <SelectInput
                              type="text"
                              label="Choose Town"
                              register={register}
                              name="town"
                              options={townList.options}
                              callback={onTownChange}
                              disabled={!district?.length}
                            />
                          </div>
                        )}
                      </div>
                      {state?.length > 0 ? (
                        <div className="col-8">
                          {franchiseOptions?.loading ? (
                            <Spinner />
                          ) : (
                            <SelectInput
                              label="Choose Stores"
                              register={register}
                              name="selectedFranchise"
                              disabled={!state?.length}
                              error={errors?.selectedFranchise?.message}
                              callback={onSelectedFranchiseChange}
                              options={franchiseOptions?.options}
                              isMandatory={true}
                            />
                          )}

                          {franchise.length ? (
                            <div className="row">
                              <div className="col-3">
                                <KeyVal
                                  label="Active Offer Count"
                                  template="col"
                                >
                                  <span className="fs-val-md">
                                    {franchiseStockDetails.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        {franchiseStockDetails.data
                                          ?.activeOfferCount || 0}
                                      </span>
                                    )}
                                  </span>
                                </KeyVal>
                              </div>
                              <div className="col-3">
                                <KeyVal
                                  label="Total Sales Value"
                                  template="col"
                                >
                                  <span className="fs-val-md">
                                    {franchiseStockDetails.loading ? (
                                      <Spinner />
                                    ) : (
                                      <Amount
                                        value={
                                          franchiseStockDetails.data
                                            ?.totalSalesValue || 0
                                        }
                                      />
                                    )}
                                  </span>
                                </KeyVal>
                              </div>
                              <div className="col-3">
                                <KeyVal label="Total Orders" template="col">
                                  <span className="fs-val-md">
                                    {franchiseStockDetails.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        {franchiseStockDetails?.data
                                          ?.totalOrders || 0}
                                      </span>
                                    )}
                                  </span>
                                </KeyVal>
                              </div>

                              {/* <div className="col-3">
                                <KeyVal
                                  label="Sales Contribution"
                                  template="col"
                                >
                                  <span className="fs-val-md">
                                    {franchiseStockDetails.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        {
                                          franchiseStockDetails?.data
                                            ?.salesPercentage
                                        }{" "}
                                        %
                                      </span>
                                    )}
                                  </span>
                                </KeyVal>
                              </div> */}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {/* Franchise */}

                  {!state?.length ? (
                    <div className="col-7">
                      <Controller
                        control={control}
                        name="franchise"
                        render={({ field: { onChange, value } }) => (
                          <EntitySearchInput
                            type="franchise"
                            placeholder="Search by Name/ID"
                            value={value}
                            label="Search Franchise"
                            callback={onFranchiseChange(onChange)}
                            uid="franchise"
                            emptyLabel={"No Franchise found"}
                            gap={0}
                            filterParams={entityFranchiseSearchFilter}
                            isMandatory={true}
                            error={errors?.franchise?.message}
                            info={{ content: info.franchise }}
                            disabled={mode == "edit"}
                          />
                        )}
                      />
                      {franchise.length ? (
                        <div className="row">
                          <div className="col-3">
                            <KeyVal label="Active Offer Count" template="col">
                              <span className="fs-val-md">
                                {franchiseStockDetails.loading ? (
                                  <Spinner />
                                ) : (
                                  <span>
                                    {franchiseStockDetails.data
                                      ?.activeOfferCount || 0}
                                  </span>
                                )}
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal label="Total Sales Value" template="col">
                              <span className="fs-val-md">
                                {franchiseStockDetails.loading ? (
                                  <Spinner />
                                ) : (
                                  <Amount
                                    value={
                                      franchiseStockDetails.data
                                        ?.totalSalesValue || 0
                                    }
                                  />
                                )}
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal label="Total Orders" template="col">
                              <span className="fs-val-md">
                                {franchiseStockDetails.loading ? (
                                  <Spinner />
                                ) : (
                                  <span>
                                    {franchiseStockDetails?.data?.totalOrders ||
                                      0}
                                  </span>
                                )}
                              </span>
                            </KeyVal>
                          </div>

                          {/* <div className="col-3">
                            <KeyVal label="Sales Contribution" template="col">
                              <span className="fs-val-md">
                                {franchiseStockDetails.loading ? (
                                  <Spinner />
                                ) : (
                                  <span>
                                    {
                                      franchiseStockDetails?.data
                                        ?.salesPercentage
                                    }{" "}
                                    %
                                  </span>
                                )}
                              </span>
                            </KeyVal>
                          </div> */}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Offer Details */}
              <div className="p-4 bg-white border-bottom">
                <div className="row">
                  <div className="col-12">
                    <div className="fw-semibold fs-val-lg mb-3">
                      Offer Details
                    </div>
                  </div>
                  {/* Title  */}
                  <div className="col-12 mb-2">
                    <TextInput
                      register={register}
                      name="title"
                      placeholder="Enter title here"
                      label="Title"
                      isMandatory={true}
                      error={errors?.title?.message}
                      info={{ content: info.title }}
                      // disabled={!canEditAllInfo}
                    />
                  </div>
                  {/* Description */}
                  <div className="col-6 mb-2">
                    <TextareaInput
                      register={register}
                      name="desc"
                      label="Description"
                      rows={10}
                      placeholder="Enter description here"
                      isMandatory={true}
                      error={errors?.desc?.message}
                      info={info.desc}
                      note="Maximum allowed Character 250"
                      disabled={!canEditAllInfo}
                      maxLength="250"
                    />
                  </div>

                  {/* Image */}
                  <div className="col-6 mb-2">
                    <label className="fw-normal mb-2" htmlFor="image">
                      Offer Image
                      {/* <span className="text-danger"> * </span> */}
                      <InfoPopover content={"Image For Offer"} />
                    </label>

                    <FileUpload callback={imagesCb} maxFile={3} />

                    {offerImages.length > 0 ? (
                      <div className="my-3">
                        <ImgSlider
                          assets={offerImages}
                          onRemove={imagesRemove}
                          removeType="icon"
                        />
                      </div>
                    ) : null}
                    <InputErrorMsg msg={errors?.offerImages?.message} />
                  </div>

                  {/* Offer Type */}
                  <div className="col-3 mb-2">
                    <SelectInput
                      label="Offer Type"
                      register={register}
                      name="offerType"
                      disabled={mode == "edit" && !canEditAllInfo}
                      error={errors?.offerType?.message}
                      callback={onOfferTypeChange}
                      options={offerTypeOptions}
                      info={{ content: info.offerType }}
                      isMandatory={true}
                    />
                  </div>

                  {/* <div className="col-3 mb-2">
                    <TextInput
                      register={register}
                      name="offerCode"
                      placeholder="Enter Offer Code here"
                      label="Offer Code"
                      isMandatory={true}
                      disabled={disableStartDate}
                      callback={onOfferCodeChange}
                      error={errors?.offerCode?.message}
                      info={{ content: info.offerCode }}
                      disabled={mode == "edit" && !canEditAllInfo}
                    />
                  </div> */}

                  {/*  Offer Start Date   */}
                  <div className="col-3 mb-2">
                    <label className=" mb-1 fs-val-md">
                      Offer Start Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="validFrom"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePickerInput
                            placeholder="Choose"
                            value={[value]}
                            disabled={disableStartDate || !canEditAllInfo}
                            inpChange={dateChangeCb(onChange, "validFrom")}
                            config={startDateConfig}
                          />
                        );
                      }}
                    />
                    <InputErrorMsg msg={errors?.validFrom?.message} />
                  </div>

                  {/* Offer End Date */}
                  <div className="col-3 mb-2">
                    <label className="mb-1 fs-val-md">
                      Offer End Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="validTo"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange, "validTo")}
                          config={endDateConfig}
                        />
                      )}
                    />
                    <InputErrorMsg msg={errors?.validTo?.message} />
                  </div>

                  {/* Reward Type  */}
                  <div className="col-3 mb-2">
                    <SelectInput
                      label="Reward Type"
                      register={register}
                      name="rewardType"
                      disabled={!canEditAllInfo}
                      error={errors?.rewardType?.message}
                      options={rewardTypeOptions}
                      info={{ content: info.rewardType }}
                      isMandatory={true}
                      callback={onRewardTypeChange}
                    />
                  </div>

                  {/* Reward Type Offer */}
                  <div className="col-3 mb-2">
                    <SelectInput
                      label="Reward Type Offer"
                      register={register}
                      name="rewardTypeOffer"
                      disabled={!canEditAllInfo}
                      info={{ content: info.rewardTypeOffer }}
                      error={errors?.rewardTypeOffer?.message}
                      options={
                        rewardType == "PRODUCT"
                          ? rewardTypeProductOptions
                          : rewardTypeDiscountOptions
                      }
                      isMandatory={true}
                    />
                  </div>

                  {rewardType == "PRODUCT" &&
                  rewardTypeOffer == "MANYPRODUCT" ? (
                    <>
                      <div className="col-3 mb-2">
                        <SelectInput
                          label="Limit By"
                          register={register}
                          name="limitBy"
                          disabled={disableStartDate}
                          error={errors?.limitBy?.message}
                          options={limitByOptions}
                          isMandatory={true}
                          info={{ content: info.limitBy }}
                        />
                      </div>
                      <div className="col-3 mb-2">
                        <TextInput
                          name="limitValue"
                          label="Limit Value"
                          type="number"
                          error={errors?.limitValue?.message}
                          register={register}
                          placeholder="Enter Limit Value"
                          isMandatory={true}
                          info={{ content: info.limitValue }}
                          disabled={disableStartDate}
                        />
                      </div>
                    </>
                  ) : null}

                  {rewardType == "DISCOUNT" ? (
                    <>
                      {/* Reward Value Type */}
                      <div className="col-3 mb-2">
                        <SelectInput
                          label="Reward Value Type"
                          register={register}
                          name="rewardTypeValue"
                          info={{ content: info.rewardTypeOffer }}
                          error={errors?.rewardTypeValue?.message}
                          options={rewardTypeValueOptions}
                          isMandatory={true}
                          disabled={disableStartDate}
                          callback={onRewardValueTypeChange}
                        />
                      </div>

                      {/* Reward Type Value */}
                      <div className="col-3 mb-2">
                        <TextInput
                          name="rewardValue"
                          label="Reward Value"
                          type="number"
                          callback={onRewardValueChange}
                          error={errors?.rewardValue?.message}
                          register={register}
                          info={{ content: info.rewardValue }}
                          placeholder="Enter Reward Value"
                          isMandatory={true}
                          disabled={disableStartDate}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* Main Deals */}

              {offerType != "CARTOFFER" && (
                <>
                  <div
                    className={classNames("p-4 bg-white", {
                      "border-bottom": rewardType == "PRODUCT",
                    })}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Main Deals
                        </div>
                      </div>
                      {/* {mainDeal?.[0]?.value?.sellInLooseQty && (
                        <div
                          className="alert alert-warning fs-val-md col-12"
                          role="alert"
                        >
                          Quantity you enter is in KG
                        </div>
                      )} */}
                      <div className="col-6">
                        <Controller
                          control={control}
                          name="mainDeal"
                          render={({ field: { onChange, value } }) => (
                            <AutoCompleteInput
                              label="Search For Main Deal"
                              placeholder="Search By Name /ID "
                              value={value}
                              onSearch={onMainDealSearch}
                              callback={onMainDealChange(onChange)}
                              uid="deal-search"
                              isMultiple={false}
                              info={{ content: info.mainDeal }}
                              emptyLabel="No Deal Found"
                              error={errors?.mainDeal?.message}
                              gap={0}
                              isMandatory={true}
                              disabled={!franchise.length || !canEditAllInfo}
                            />
                          )}
                        />

                        {mainDeal?.[0]?.value._id && (
                          <>
                            <div className="row justify-content-between mt-1">
                              <div className="col-auto p-1">
                                <div className="row border rounded p-2 m-1">
                                  <div className="col-auto">
                                    <KeyVal label="MRP" template="col">
                                      <span className="fs-val-md">
                                        <Amount
                                          value={mainDeal?.[0]?.value?.mrp}
                                          decimalPlace={2}
                                        />
                                      </span>
                                    </KeyVal>
                                  </div>
                                  <div className="col-auto ps-2">
                                    <KeyVal label="RSP" template="col">
                                      <span className="fs-val-md">
                                        <Amount
                                          value={
                                            mainDeal?.[0]?.value
                                              ?.retailerSellingPrice
                                          }
                                          decimalPlace={2}
                                        />
                                      </span>
                                    </KeyVal>
                                  </div>
                                </div>
                              </div>

                              <Rbac roles={rbac.viewSlc}>
                                <div className="col-auto p-1">
                                  <div className="row border rounded p-2 m-1">
                                    <div className="col-auto">
                                      <KeyVal
                                        label="Store Landing"
                                        template="col"
                                      >
                                        <span className="fs-val-md text-primary">
                                          <Amount
                                            value={
                                              mainDeal?.[0]?.value?.b2bprice
                                            }
                                            decimalPlace={2}
                                          />
                                        </span>
                                      </KeyVal>
                                    </div>

                                    <div className="col-auto">
                                      <KeyVal label="Store PNL" template="col">
                                        <span
                                          className={classNames({
                                            "fs-val-md": true,
                                            "text-success":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value
                                                  ?.b2bprice || 0) >
                                              0,
                                            "text-danger":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value
                                                  ?.b2bprice || 0) <
                                              0,
                                            "text-primary":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value
                                                  ?.b2bprice || 0) ==
                                              0,
                                          })}
                                        >
                                          {[
                                            "Buy-A Get-A",
                                            "Buy-A Get-B",
                                          ].indexOf(offerType) != -1 ? (
                                            <Amount
                                              value={
                                                ((mainDeal?.[0]?.value
                                                  ?.retailerSellingPrice || 0) -
                                                  (mainDeal?.[0]?.value
                                                    ?.b2bprice || 0)) *
                                                (qty || 1)
                                              }
                                              decimalPlace={2}
                                            />
                                          ) : (
                                            <Amount
                                              value={
                                                (mainDeal?.[0]?.value
                                                  ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value
                                                  ?.b2bprice || 0)
                                              }
                                              decimalPlace={2}
                                            />
                                          )}
                                        </span>
                                      </KeyVal>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-auto p-1">
                                  <div className="row border rounded p-2 m-1">
                                    <div className="col-auto">
                                      <KeyVal label="SK Landing" template="col">
                                        <span className="fs-val-md text-primary">
                                          <Amount
                                            value={
                                              mainDeal?.[0]?.value?.slc || 0
                                            }
                                            decimalPlace={2}
                                          />
                                        </span>
                                      </KeyVal>
                                    </div>
                                    <div className="col-auto">
                                      <KeyVal label="SK PNL" template="col">
                                        <span
                                          className={classNames({
                                            "fs-val-md": true,
                                            "text-success":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value?.slc ||
                                                  0) >
                                              0,
                                            "text-danger":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value?.slc ||
                                                  0) <
                                              0,
                                            "text-primary":
                                              (mainDeal?.[0]?.value
                                                ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value?.slc ||
                                                  0) ==
                                              0,
                                          })}
                                        >
                                          {[
                                            "Buy-A Get-A",
                                            "Buy-A Get-B",
                                          ].indexOf(offerType) != -1 ? (
                                            <Amount
                                              value={
                                                ((mainDeal?.[0]?.value
                                                  ?.retailerSellingPrice || 0) -
                                                  (mainDeal?.[0]?.value?.slc ||
                                                    0)) *
                                                (qty || 1)
                                              }
                                              decimalPlace={2}
                                            />
                                          ) : (
                                            <Amount
                                              value={
                                                (mainDeal?.[0]?.value
                                                  ?.retailerSellingPrice || 0) -
                                                (mainDeal?.[0]?.value?.slc || 0)
                                              }
                                              decimalPlace={2}
                                            />
                                          )}
                                        </span>
                                      </KeyVal>
                                    </div>
                                  </div>
                                </div>
                              </Rbac>

                              <div className="col-auto p-1">
                                <div className="row border rounded p-2 m-1">
                                  <div className="col-auto ">
                                    <KeyVal label="Stock Qty" template="col">
                                      <span className="fs-val-md">
                                        {mainDeal?.[0]?.value?._qty || 0}{" "}
                                        {mainDeal?.[0]?.value?._displayUnitKey}
                                      </span>
                                    </KeyVal>
                                  </div>

                                  <div className="col-auto">
                                    <KeyVal label="Reserve Qty" template="col">
                                      <span className="fs-val-md">
                                        {mainDeal?.[0]?.value?.reserveConfig
                                          ?._available || 0}{" "}
                                        {mainDeal?.[0]?.value?._displayUnitKey}
                                      </span>
                                    </KeyVal>
                                  </div>

                                  <div className="col-auto">
                                    <KeyVal label="Pack Size" template="col">
                                      <span className="fs-val-md">
                                        {mainDeal?.[0]?.value?.packSize}
                                      </span>
                                    </KeyVal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-auto pt-3">
                        <button
                          className="btn btn-sm btn-primary mt-2"
                          onClick={viewNonMovableStock}
                          disabled={!franchise?.length}
                        >
                          Choose Slow/Non Moving Deal
                        </button>
                      </div>

                      <div className="col-auto">
                        <TextInput
                          type="number"
                          register={register}
                          name="qty"
                          callback={onMainDealQtyChange}
                          label={`Quantity ${
                            mainDeal?.[0]?.value?.sellInLooseQty
                              ? `in ${mainDeal?.[0]?.value?.defaultUnitType} `
                              : ""
                          } `}
                          disabled={!franchise.length || !canEditAllInfo}
                          isMandatory={true}
                          error={errors?.qty?.message}
                          info={{ content: info.qty }}
                          placeholder="Enter Qty Here"
                        />
                      </div>

                      <div className="col-auto">
                        <TextInput
                          type="number"
                          register={register}
                          name="offerLimit"
                          label={`Offer Stock Limit ${
                            mainDeal?.[0]?.value?.sellInLooseQty ? `in Kg` : ""
                          }`}
                          error={errors?.offerLimit?.message}
                          info={{
                            content:
                              "Offer available until the configured offer stock limit is met.",
                          }}
                          disabled={!franchise.length}
                        />
                      </div>

                      {offerType == "Buy-N Get-N" && !disableStartDate && (
                        <div className="col-auto mt-4 ms-auto">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary fs-val-md"
                            onClick={addOfferDealItem}
                          >
                            Add
                          </button>
                        </div>
                      )}

                      {/* Offer Deals Table Only Visible when Offer Type is Buy-N Get-N   */}
                      {offerType == "Buy-N Get-N" && (
                        <div className="col-12 mt-2">
                          <OfferDealsTable
                            mode={mode}
                            disableStartDate={disableStartDate}
                            offerDeals={offerDeals}
                            deleteCb={removeOfferDealItemCb}
                            editMode={mode}
                            fid={franchise?.[0]?.value?._id}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Cart Offer is Enables */}
              {offerType == "CARTOFFER" && (
                <div className={classNames("p-4 bg-light", "border-bottom")}>
                  <div className="row">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Cart Details
                      </div>
                    </div>
                    <div className="col-4">
                      <TextInput
                        name="minValue"
                        label="Min Value Rs"
                        callback={onNumberInputChange}
                        type="number"
                        register={register}
                        error={errors?.minValue?.message}
                        placeholder="Enter Min Value"
                        isMandatory={true}
                        disabled={!canEditAllInfo}
                        info={{
                          content: info.minValue,
                          placement: "right",
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <TextInput
                        name="maxValue"
                        label="Max Values Rs"
                        callback={onNumberInputChange}
                        type="number"
                        disabled={!canEditAllInfo}
                        error={errors?.maxValue?.message}
                        register={register}
                        placeholder="Enter Max Value"
                        isMandatory={true}
                        info={{
                          content: info.maxValue,
                          placement: "right",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Offer Deal */}
              {/* Add Product For When Reward Type is Product */}
              {rewardType == "PRODUCT" && (
                <>
                  <div
                    className={classNames("p-4 bg-white", {
                      "border-bottom": offerType == "CARTOFFER",
                    })}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Offer Deal
                        </div>
                      </div>

                      {!disableStartDate && (
                        <>
                          <div className="col-12">
                            <ManageRewardProducts
                              offerType={offerType}
                              rewardTypeOffer={rewardTypeOffer}
                              updatedOfferProductsType={offerProductType}
                              callback={manageRewardProductCb}
                              data={productList}
                              mainDeal={mainDeal?.[0]?.value}
                              franchise={
                                franchise?.length ? franchise?.[0]?.value : ""
                              }
                              offerProductTypeCallback={
                                offerProductTypeCallback
                              }
                              mode={mode}
                            />
                          </div>
                        </>
                      )}

                      <div className="col-12 mt-2">
                        <RewardProductTable
                          dealList={productList}
                          mode={mode}
                          deleteCb={removeFromProductListCb}
                          disableStartDate={disableStartDate}
                          type={offerProductType}
                          fid={franchise?.[0]?.value?._id}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Exclude For  Only fif Offer Type is not Deal Offer */}
              {offerType == "CARTOFFER" && (
                <>
                  <div className={classNames("p-4 bg-white", "border-bottom")}>
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          Add Exclusions
                        </div>
                      </div>
                      <div className="col-3">
                        <SelectInput
                          name="searchType"
                          label="Type"
                          register={register}
                          options={searchTypeOptions}
                          gap={0}
                        />
                      </div>

                      <div className="col-6">
                        <Controller
                          key={searchType}
                          control={control}
                          name={searchType}
                          render={({ field: { onChange, value } }) => (
                            <EntitySearchInput
                              type={searchType}
                              placeholder="Search by Name/ID"
                              value={value}
                              label={"Search For " + searchType}
                              callback={onChange}
                              uid={searchType}
                              emptyLabel={"No " + searchType.label + " found"}
                              gap={0}
                            />
                          )}
                        />
                      </div>
                      <div className="col-auto mt-4 ms-auto">
                        <button
                          type="button"
                          className="btn btn-sm  btn-outline-primary fs-val-md"
                          onClick={addExcludeFor}
                        >
                          Add
                        </button>
                        {/* <button
                              type="button"
                              className="btn btn-sm  btn-outline-primary fs-val-md mt-1"
                              onClick={addExcludeFor}
                            >
                              Add Bulk
                            </button> */}
                      </div>
                    </div>
                  </div>

                  <div className="p-4">
                    <AppTitle title="Excluded Items List" />

                    <ExcludeForTable
                      excludeForData={excludeForData}
                      removeFromExclude={removeFromExclude}
                      mode="edit"
                    />
                  </div>
                </>
              )}

              <MovableDealModal
                show={showMovableDealModal.show}
                callback={movableStockModalCb}
                fid={franchise?.length ? franchise[0].value._id : ""}
              />
            </>
          )}
        </Offcanvas.Body>
        {!pageLoading ? (
          <Offcanvas.Header>
            <div className="my-3 w-100 row justify-content-between">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-outline-dark me-1"
                  onClick={triggerCloseModal}
                >
                  Cancel
                </button>
              </div>

              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  // type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                >
                  {mode == "edit" ? "Submit" : "Preview"}{" "}
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              </div>
            </div>
          </Offcanvas.Header>
        ) : null}
      </Offcanvas>

      <PreviewStoreOfferModal
        show={viewPreviewStoreOfferModal.show}
        data={viewPreviewStoreOfferModal.data}
        closeModal={previewStoreOfferCb}
      />
    </>
  );
};

export default memo(ManageStoreDiscountModal);
