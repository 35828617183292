import {
  BrandService,
  CategoryService,
  DealService,
  DigitalPartnerService,
  SourcingService,
  UtilityService,
} from "@sk/services";
import { KeyVal } from "@sk/uis";
import { useCallback, useEffect, useState } from "react";

const SlideTypeDetails = ({ data }) => {
  const [deal, setDeal] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    if (data?._id) {
      if (["brand", "brandAndCategory"].indexOf(data?.bannerType) != -1) {
        loadBrandDetails(data?.bannerTypeNames?.brand);
      } else if (
        ["category", "brandAndCategory", "menu"].indexOf(data?.bannerType) != -1
      ) {
        loadCategoryDetails(data?.bannerTypeNames?.category);
      } else if (data?.bannerType == "deal") {
        loadDealDetails(data?.bannerTypeNames?.deal);
      } else if (data?.bannerType == "marketPlaceSeller") {
        loadMarketplaceDetails(data?.bannerTypeNames?.marketPlaceSeller);
      } else if (data?.bannerType == "kingsale") {
        loadKingSaleDetails(data?.bannerTypeNames?.kingsale);
      } else if (data?.bannerType == "keyword") {
        setDetails(data?.bannerTypeNames?.keyword || "");
      } else if (
        ["recharge", "dmt", "wallet", "servicePartner"].indexOf(
          data?.bannerType
        ) != -1
      ) {
        loadDigitalPartnerDetails(
          data?.bannerTypeNames[data?.bannerType] || ""
        );
      }
    }
  }, []);

  const loadBrandDetails = useCallback(async (ids) => {
    let m = await BrandService.getList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setBrand(x);
    }
  }, []);

  const loadCategoryDetails = useCallback(async (ids, key) => {
    let m = await CategoryService.getList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setCategory(x);
    }
  }, []);

  const loadDealDetails = useCallback(async (ids, key) => {
    let m = await DealService.getDeals({ filter: { _id: { $in: ids } } });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setDeal(x);
    }
  }, []);

  const loadMarketplaceDetails = useCallback(async (ids, key) => {
    let m = await SourcingService.getList({ filter: { _id: { $in: ids } } });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setDetails(x);
    }
  }, []);

  const loadKingSaleDetails = useCallback(async (ids, key) => {
    let m = await UtilityService.getKingSaleList({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setDetails(x);
    }
  }, []);

  const loadDigitalPartnerDetails = useCallback(async (ids, key) => {
    let m = await await DigitalPartnerService.getlist({
      filter: { _id: { $in: ids } },
    });
    if (Array.isArray(m?.resp) && m.resp.length > 0) {
      let x = m.resp[0]?.name + " (" + m.resp[0]?._id + ") ";
      setDetails(x);
    }
  }, []);

  return (
    <>
      {brand?.length > 0 ? (
        <div className="mb-2">
          <KeyVal
            label="Brand:"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            <span>{brand || ""} </span>
          </KeyVal>
        </div>
      ) : null}

      {category?.length > 0 ? (
        <div className="mb-2">
          <KeyVal
            label={data?.bannerType == "Menu" ? "Menu:" : "Category:"}
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            <span>{category || ""} </span>
          </KeyVal>
        </div>
      ) : null}

      {deal?.length > 0 ? (
        <div className="mb-2">
          <KeyVal
            label="Deal:"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            <span>{deal || ""} </span>
          </KeyVal>
        </div>
      ) : null}

      {details?.length > 0 ? (
        <div className="mb-2">
          <KeyVal
            label={data?.bannerType}
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            <span>{details || ""} </span>
          </KeyVal>
        </div>
      ) : null}
      {/* </AppCard> */}
    </>
  );
};

export default SlideTypeDetails;
