import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { format } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";

import { DealService } from "@sk/services";

import listView from "../../constantService";

import SmartCoinRewardAuditLog from "../components/SmartCoinRewardAuditLog";
import { CustomerService } from "@sk/services";
import { Amount } from "@sk/uis";
import SmartCoinRewardSlab from "../components/SmartCoinRewardSlab";

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

const headers = [{ label: "Sl.no" }, { label: "Customer ID" }];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const SmartCoinRewardViewModal = ({ show, closeModal, modalData }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const triggerCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const isValidDate = (dateString) => {
    // testing Both date String and ISO Stirng
    return /\(IST\)$/gi.test(dateString) || /000Z/.test(dateString);
  };

  useEffect(() => {
    if (show) {
      if (modalData._id) {
        const fetchDetails = async () => {
          setLoading(true);
          const r = await CustomerService.getSmartCoinRewardById(modalData._id);

          let d = r.resp || {};

          // set value for status

          // for formatting the audit log date values
          if (d.auditLog) {
            (d.auditLog || []).forEach((x) => {
              try {
                if (isValidDate(x.oldData)) {
                  x.oldData = format(
                    new Date(x.oldData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
                if (isValidDate(x.newData)) {
                  x.newData = format(
                    new Date(x.newData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
              } catch (error) {}
            });
          }

          if (d.type == "ParentCoinReward") {
            d.slab = d.slab.map((e) => ({
              min: e.min,
              max: e.max,
              coins: e.senderValue,
            }));
          }

          setDetails(d);

          setLoading(false);

          if (d._id) {
            let tmp = [];
            // Attach User Info
            setAdditionalData([d], attachAdditionalDataConfig, (x) => {
              tmp.push(x);
              if (tmp.length == attachAdditionalDataConfig.length) {
                const t = [...attachAllData([d], tmp)][0];
                setDetails((v) => ({ ...v, ...t }));
              }
            });
          } else {
            Toaster.error("Failed to fetch data, please try again");
            triggerCloseModal();
          }
        };

        fetchDetails();
      }
    }
  }, [
    modalData._id,
    show,
    triggerCloseModal,
    setAdditionalData,
    attachAllData,
  ]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          <div className="fs-val-lg text-dark px-2">
            {"StoreKing Coins Reward Config" + " - " + modalData?._id}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {/* Offer Details */}
                  <div className="p-4 bg-white border-bottom">
                    <div className="row">
                      <div className="col-12">
                        <div className="fw-semibold fs-val-lg mb-3">
                          SmartCoin Reward Details
                        </div>
                      </div>

                      {/* Title */}
                      <div className="col-3 mb-3">
                        <KeyVal label="Rule Type" template="col">
                          <span>{listView.getRuleType(details.type)}</span>
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-3">
                        <KeyVal label="Status" template="col">
                          <HighlightText status={details.status} isBadge />
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-3">
                        <KeyVal label="Valid From" template="col">
                          <DateFormatter date={details?.validityDate?.from} />
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-3">
                        <KeyVal label="Valid To" template="col">
                          <DateFormatter date={details?.validityDate?.to} />
                        </KeyVal>
                      </div>

                      {details.type != "ParentCoinReward" ? (
                        <div className="col-3 mb-3">
                          <KeyVal label="Min Order Value" template="col">
                            <span className="text-primary">
                              <Amount value={details?.slab?.[0]?.min} />
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}

                      {details.type != "NoInviteFirstorder" &&
                        details.type != "ParentCoinReward" && (
                          <div className="col-3 mb-3">
                            <KeyVal label="Parent Coins Reward" template="col">
                              <span className="text-primary">
                                {details?.slab[0].senderValue || "N/A"}
                              </span>
                            </KeyVal>
                          </div>
                        )}

                      {details.type != "ParentCoinReward" && (
                        <div className="col-3 mb-3">
                          <KeyVal
                            label={
                              details.type == "NoInviteFirstorder"
                                ? "Storeking Coins"
                                : "Child Coins Reward"
                            }
                            template="col"
                          >
                            <span className="text-primary">
                              {details?.slab?.[0]?.reciverValue || "N/A"}
                            </span>
                          </KeyVal>
                        </div>
                      )}

                      {/* {details.type == "ParentCoinReward" && (
                        <div className="col-3 mb-3">
                          <KeyVal label="Order Limit" template="col">
                            <span className="text-primary">
                              {details?.orderLimitValidity.orderLimit}
                            </span>
                          </KeyVal>
                        </div>
                      )} */}

                      {details.type == "ParentCoinReward" ? (
                        <div className="col-3 mb-3">
                          <KeyVal label="Monthly Limit" template="col">
                            <span className="text-primary">
                              {details?.monthlyOrderLimitValidity?.orderLimit ||
                                0}
                            </span>
                          </KeyVal>
                        </div>
                      ) : null}

                      {details.type == "ReferralCoupon" ? (
                        <div className="col-3 mb-3">
                          <KeyVal label="Network Size" template="col">
                            {details?.parentEligibleLimit}
                          </KeyVal>
                        </div>
                      ) : null}

                      {/* {details.type == "ReferralCoupon" && (
                        <div className="col-3 mb-3">
                          <KeyVal label="Coupon Code " template="col">
                            <span className="text-primary">
                              {details?.couponCode}
                            </span>
                          </KeyVal>
                        </div>
                      )} */}
                    </div>

                    <div className="row">
                      {/* Created At */}
                      <div className="col-3 mb-3">
                        <KeyVal label="Created At" template="col">
                          <DateFormatter date={details.createdAt} />
                        </KeyVal>
                      </div>
                      {/* Created By */}
                      <div className="col-3 mb-3">
                        <KeyVal label="Created By" template="col">
                          <span>
                            {details.userLoading ? (
                              <Spinner />
                            ) : (
                              details._createdBy?.name
                            )}
                          </span>
                        </KeyVal>
                      </div>
                      {/* Last Updated */}
                      <div className="col-3 mb-3">
                        <KeyVal label="Last Updated At" template="col">
                          <DateFormatter date={details.lastUpdated} />
                        </KeyVal>
                      </div>
                      {/* Last Updated By */}
                      {details._modifiedBy?.name && (
                        <div className="col-3 mb-3">
                          <KeyVal label="Last Updated By" template="col">
                            {details._modifiedBy?.name ? (
                              <span>{details._modifiedBy.name}</span>
                            ) : null}
                          </KeyVal>
                        </div>
                      )}
                    </div>
                  </div>

                  {details.type == "ReferralCouponsssss" && (
                    <div className="bg-light">
                      <div className="row p-4">
                        <div className="col-12">
                          <div className="fw-semibold fs-val-lg mb-3">
                            Customer Table
                          </div>
                        </div>
                      </div>

                      <table className="table table-bordered">
                        <TableHeader data={headers} />
                        <tbody>
                          {!details.customersList.length ? (
                            <tr className="fs-val-md text-center">
                              <td colSpan={headers.length}> No Data Found </td>
                            </tr>
                          ) : null}
                          {details.customersList.length
                            ? details.customersList.map((cus, i) => (
                                <tr key={cus} className="fs-val-md">
                                  <td className="text-center">{i + 1}</td>
                                  <td className="">{cus}</td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {details.type == "ParentCoinReward" ? (
                    <div className="border-b">
                      <div className="fw-semibold p-4 fs-val-lg">
                        Slab Details
                      </div>
                      <SmartCoinRewardSlab
                        slabData={details.slab}
                        mode="view"
                      />
                    </div>
                  ) : null}

                  {/* Audit Log */}
                  <SmartCoinRewardAuditLog auditLogs={details.auditLog || []} />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(SmartCoinRewardViewModal);
