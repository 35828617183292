import { BooleanCheckboxInput, EntitySearchInput } from "@sk/uis";
import { useState } from "react";
import { Controller } from "react-hook-form";
import BulkFilterModal from "../modals/BulkFilterModal";

const FilterData = ({ control, feature, mode, setValue, showDirectChild }) => {
  const [bulkModal, setBulkModal] = useState({
    show: false,
    type: "",
    title: "",
  });

  const addBulk = (type) => {
    let title = "";
    if (type == "menu") {
      title = "Add Bulk Menu";
    }
    if (type == "brand") {
      title = "Add Bulk Brand";
    }
    if (type == "category") {
      title = "Add Bulk Category";
    }
    if (type == "deal") {
      title = "Add Bulk Deal";
    }
    setBulkModal({ show: true, type, title });
  };

  const modalCb = (e) => {
    setBulkModal({ show: false, type: "" });

    if (e?.action == "added") {
      if (e.type == "menu") {
        setValue("menuFilter", e.data);
      }
      if (e.type == "brand") {
        setValue("brandFilter", e.data);
      }
      if (e.type == "category") {
        setValue("categoryFilter", e.data);
      }
      if (e.type == "deal") {
        setValue("dealFilter", e.data);
      }
    }
  };

  return (
    <div className="p-4">
      <div className="text-muted mb-2">
        To filter the data, for example if any menu is selected then data will
        be related to the selected menu
      </div>

      <div>
        <div className="row mb-1">
          <div className="col fs-val-md">Menu Filter</div>
          <div className="col col-auto">
            <button
              className="btn btn-link p-0"
              onClick={() => addBulk("menu")}
            >
              Add Bulk
            </button>
          </div>
        </div>
        <Controller
          name="menuFilter"
          control={control}
          render={({ field: { value, onChange } }) => (
            <EntitySearchInput
              type="menu"
              isMultiple={true}
              callback={onChange}
              value={value}
              disabled={mode == "view"}
              uid="fmenu"
            />
          )}
        />
      </div>

      {showDirectChild ? (
        <div>
          <Controller
            control={control}
            name="showDirectChild"
            render={({ field: { value, onChange } }) => (
              <BooleanCheckboxInput
                label="Show direct child of categories"
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>
      ) : null}

      {1 || feature == "brand" || feature == "deal" ? (
        <div>
          <div className="row mb-1">
            <div className="col fs-val-md">Category Filter</div>
            <div className="col col-auto">
              <button
                className="btn btn-link p-0"
                onClick={() => addBulk("category")}
              >
                Add Bulk
              </button>
            </div>
          </div>
          <Controller
            name="categoryFilter"
            control={control}
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                type="category"
                isMultiple={true}
                callback={onChange}
                value={value}
                disabled={mode == "view"}
                uid="fcategory"
              />
            )}
          />
        </div>
      ) : null}

      {1 || feature == "category" || feature == "deal" ? (
        <div>
          <div className="row mb-1">
            <div className="col fs-val-md">Brand Filter</div>
            <div className="col col-auto">
              <button
                className="btn btn-link p-0"
                onClick={() => addBulk("brand")}
              >
                Add Bulk
              </button>
            </div>
          </div>
          <Controller
            name="brandFilter"
            control={control}
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                type="brand"
                isMultiple={true}
                callback={onChange}
                value={value}
                disabled={mode == "view"}
                uid="fbrand"
              />
            )}
          />
        </div>
      ) : null}

      <div>
        <div className="row mb-1">
          <div className="col fs-val-md">Deal Filter</div>
          <div className="col col-auto">
            <button
              className="btn btn-link p-0"
              onClick={() => addBulk("deal")}
            >
              Add Bulk
            </button>
          </div>
        </div>
        <Controller
          name="dealFilter"
          control={control}
          render={({ field: { value, onChange } }) => (
            <EntitySearchInput
              type="deal"
              isMultiple={true}
              callback={onChange}
              value={value}
              disabled={mode == "view"}
              uid="fdeal"
            />
          )}
        />
      </div>

      <BulkFilterModal
        type={bulkModal.type}
        callback={modalCb}
        title={bulkModal.title}
        show={bulkModal.show}
      />
    </div>
  );
};

export default FilterData;
