import { FranchiseService, UtilityService } from "@sk/services";
import {
  EntitySearchInput,
  InputErrorMsg,
  RadioInput,
  SelectInput,
  Spinner,
  Toaster,
} from "@sk/uis";
import { each } from "lodash";
import { memo, useCallback, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import MultipleFranhiseFormModal from "../MultipleFranchiseFormModal";

const validationSchema = yup.object({
  locationData: yup.object({
    accessLevelType: yup.string().label("Access Level Type").required(),

    franchiseSelectType: yup.string().when("accessLevelType", {
      is: (accessLevelType) =>
        ["rmf", "rf", "sf"].indexOf(accessLevelType) != -1,
      then: (schema) => schema.label("Franchise Input Type").required(),
    }),

    franchiseIds: yup.array().when(["accessLevelType", "franchiseSelectType"], {
      is: (accessLevelType, franchiseSelectType) =>
        ["rmf", "rf", "sf"].indexOf(accessLevelType) != -1 &&
        franchiseSelectType != "single",
      then: (schema) =>
        schema
          .min(1, "Please Enter Mulitple Franchise ID")
          .label("Mulitple Franchise")
          .required(),
    }),

    franchiseList: yup
      .array()
      .when(["accessLevelType", "franchiseSelectType"], {
        is: (accessLevelType, franchiseSelectType) =>
          ["rmf", "rf", "sf"].indexOf(accessLevelType) != -1 &&
          franchiseSelectType != "single",
        then: (schema) =>
          schema
            .min(1, "Please add Multiple Franchise Ids ")
            .label("Mulitple Franchise")
            .required(),
      }),

    rmfv: yup.array().when(["accessLevelType", "franchiseSelectType"], {
      is: (accessLevelType, franchiseSelectType) =>
        ["rmf"].indexOf(accessLevelType) != -1 &&
        franchiseSelectType == "single",
      then: (schema) =>
        schema.min(1, "Please Select RMF ").label("RMF").required(),
    }),

    rfv: yup.array().when(["accessLevelType", "franchiseSelectType"], {
      is: (accessLevelType, franchiseSelectType) =>
        ["sf"].indexOf(accessLevelType) != -1 &&
        franchiseSelectType == "single",
      then: (schema) =>
        schema.min(1, "Please Select RF").label("RF").required(),
    }),

    sfv: yup.array().when(["accessLevelType", "franchiseSelectType"], {
      is: (accessLevelType, franchiseSelectType) =>
        ["sf"].indexOf(accessLevelType) != -1 &&
        franchiseSelectType == "single",
      then: (schema) =>
        schema.min(1, "Please Select SF").label("SF").required(),
    }),

    state: yup.string().when("accessLevelType", {
      is: (accessLevelType) => ["state"].indexOf(accessLevelType) != -1,
      then: (schema) => schema.label("State").required(),
    }),

    district: yup.string().when(["accessLevelType", "state"], {
      is: (accessLevelType, state) =>
        ["state"].indexOf(accessLevelType) != -1 && state != "all",
      then: (schema) => schema.label("district").required(),
    }),

    town: yup.string().when(["accessLevelType", "state", "district"], {
      is: (accessLevelType, state, district) =>
        ["state"].indexOf(accessLevelType) != -1 &&
        state != "all" &&
        district != "all",
      then: (schema) => schema.label("Town").required(),
    }),

    franchiseType: yup.string().when("accessLevelType", {
      is: (accessLevelType) => ["state"].indexOf(accessLevelType) != -1,
      then: (schema) => schema.label("Franchise Type").required(),
    }),

    rmfType: yup.string().when(["accessLevelType", "franchiseType"], {
      is: (accessLevelType, franchiseType) =>
        ["state"].indexOf(accessLevelType) != -1 && franchiseType == "RMF",
      then: (schema) => schema.label("RMF Type").required(),
    }),

    rfType: yup.string().when(["accessLevelType", "franchiseType"], {
      is: (accessLevelType, franchiseType) =>
        ["state"].indexOf(accessLevelType) != -1 && franchiseType == "RF",
      then: (schema) => schema.label("RF Type").required(),
    }),

    sfType: yup.string().when(["accessLevelType", "franchiseType"], {
      is: (accessLevelType, franchiseType) =>
        ["state"].indexOf(accessLevelType) != -1 && franchiseType == "SF",
      then: (schema) => schema.label("SF Type").required(),
    }),
  }),
});

const accessLevelOptions = [
  { label: "Select", value: "" },
  { label: "State", value: "state" },
  { label: "RMF", value: "rmf" },
  { label: "RF", value: "rf" },
  { label: "SF", value: "sf" },
];

const franchiseTypeOptions = [
  { label: "Choose", value: "" },
  { label: "RMF", value: "RMF" },
  { label: "RF", value: "RF" },
  { label: "SF", value: "SF" },
];

const franchiseInputTypeOptions = [
  { label: "Single Franchise", value: "single" },
  { label: "Multiple Franchise", value: "multiple" },
];

const defaultFormData = {
  accessLevelType: "",
  rmfv: [],
  rfv: [],
  sfv: [],
  state: "",
  district: "",
  town: "",
  rmfType: "",
  rfType: "",
  sfType: "",
  districtOptions: [],
  townOptions: [],
  franchiseType: "",
  franchiseSelectType: "",
  franchiseIds: "",
};

const AccessLevelConfig = ({
  stateListOptions = [],
  rmfSubtypeOptions = [],
  rfSubtypeOptions = [],
  sfSubtypeOptions = [],
}) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
    reset,
    register,
  } = useFormContext({});

  const [editData, setEditData] = useState({
    index: -1,
    mode: "Add",
    data: {},
  });

  const [districtTownLoading, setDistrictTownLoading] = useState(false);

  const [multipleFranchiseModal, setMultipleFranchiseModal] = useState({
    show: false,
    data: "",
  });

  const [franchiseLoader, setFranchiseLoader] = useState(false);

  const [franchiseList, setFranchiseList] = useState([]);

  const [
    accessLevel,
    location,
    state,
    district,
    districtOptions,
    townOptions,
    franchiseType,
    franchiseSelectType,
  ] = useWatch({
    control: control,
    name: [
      "locationData.accessLevelType",
      "location",
      "locationData.state",
      "locationData.district",
      "districtOptions",
      "townOptions",
      "locationData.franchiseType",
      "locationData.franchiseSelectType",
    ],
  });

  const onDistrictChange = useCallback(
    async (val, type = "", data = {}) => {
      let opt = [
        { label: "All Towns", value: "all" },
        { label: "Select Towns", value: "" },
      ];

      setValue("locationData.town", "");

      if (val != "all") {
        let r = await UtilityService.getTownList(
          getValues("locationData.state"),
          val
        );

        let o = (r.resp || []).map((e) => {
          return { label: e.name, value: e.name };
        });

        opt = opt.concat(o);
        setValue("townOptions", opt);

        if (type == "edit") {
          setValue("locationData.town", data.town);
        }
      }
    },
    [getValues, setValue]
  );

  const onStateChange = useCallback(
    async (val, type = "", data = {}) => {
      let opt = [
        { label: "All District", value: "all" },
        { label: "Select District", value: "" },
      ];

      ["locationData.district", "locationData.town"].forEach((x) => {
        setValue(x, "");
      });

      if (val != "all" && val) {
        let r = await UtilityService.getDistrictList(val);
        let o = (r?.resp || []).map((e) => {
          return { label: e.name, value: e.name };
        });

        opt = opt.concat(o);

        setValue("districtOptions", opt);
        setValue("locationData.district", data.district);

        // Auto fill data for edit option
        if (type == "edit") {
          onDistrictChange(data.district, "edit", { town: data.town });
        }
      }
    },
    [setValue]
  );

  const addLevels = async () => {
    let errMsg = "";
    try {
      await validationSchema.validate({
        locationData: { ...getValues("locationData") },
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let f = { ...getValues("locationData") };

    const list = location?.length > 0 ? [...location] : [];

    if (list.length > 0 && editData.index < 0) {
      Toaster.error("Only One Level Can Add for One Config");
      return;
    }

    let p = {
      accessLevel: f.accessLevelType,
      franchisees: [],
    };

    if (f.accessLevelType == "rmf") {
      if (f.franchiseSelectType == "single") {
        p.rmfv = f.rmfv[0].value?._id || "";
        p.rmfDetails = {
          name: f.rmfv[0].value?.name,
          state: f.rmfv[0].value?.state,
          district: f.rmfv[0].value?.district,
          town: f.rmfv[0].value?.town,
          _id: f.rmfv[0].value?._id,
        };
      } else {
        p.franchisees = [...f.franchiseList];
      }
    } else {
      p.rmfv = [];
      p.rmfDetails = {};
    }

    if (f.accessLevelType == "rf") {
      if (f.franchiseSelectType == "single") {
        p.rfv = f.rfv[0].value?._id;
        p.rfDetails = {
          name: f.rfv[0].value?.name,
          state: f.rfv[0].value?.state,
          district: f.rfv[0].value?.district,
          town: f.rfv[0].value?.town,
          _id: f.rfv[0].value?._id,
        };
      } else {
        p.franchisees = [...f.franchiseList];
      }
    } else {
      p.rfv = [];
      p.rfDetails = {};
    }

    if (f.accessLevelType == "sf") {
      if (f.franchiseSelectType == "single") {
        p.sfv = f.sfv[0].value?._id || "";
        p.sfDetails = {
          name: f.sfv[0].value?.name,
          state: f.sfv[0].value?.state,
          district: f.sfv[0].value?.district,
          town: f.sfv[0].value?.town,
          _id: f.sfv[0].value?._id,
        };
      } else {
        p.franchisees = [...f.franchiseList];
      }
    } else {
      p.sfv = [];
      p.sfDetails = {};
    }

    if (f.accessLevelType == "state") {
      p.state = f.state;
      p.district = f.district;
      p.town = f.town;
      p.franchiseType = f.franchiseType;
      p.rmfType = f.rmfType;
      p.rfType = f.rfType;
      p.sfType = f.sfType;
    } else {
      p.franchiseType = "";
      p.state = "";
      p.district = "";
      p.town = "";
      p.rmfType = "";
      p.rfType = "";
      p.sfType = "";
    }

    const formData = Object.assign({}, p);
    if (editData.index >= 0) {
      list.splice(editData.index, 1, formData);
    } else {
      list.push(formData);
    }

    setValue("location", [...list]);
    trigger("location");

    each(defaultFormData, (x, k) => {
      setValue("locationData." + k, x);
    });

    setEditData({ index: -1, mode: "Add", data: {} });
  };

  const deleteLevels = (i) => {
    let list = [...getValues("location")];
    list.splice(i, 1);
    setValue("location", [...list]);
  };

  const editLevel = (i) => {
    let list = getValues("location");
    let f = list[i] || {};
    setEditData({ index: i, mode: "Edit", data: f });
    const ignoreFormKeys = ["rmfDetails", "accessLevelType"];
    setValue(
      "locationData.accessLevelType",
      f.accessLevel || f.accessLevelType
    );

    if (f.accessLevel == "state" || f.accessLevelType == "state") {
      updateDistrictTownValue(f);
    }

    Object.keys(defaultFormData).forEach((key) => {
      if (ignoreFormKeys.indexOf(key) == -1) {
        if (["rmfv", "rfv", "sfv"].indexOf(key) != -1) {
          setValue("locationData.rmfv", [
            { label: f.rmfDetails?.name, value: f.rmfDetails },
          ]);
          setValue("locationData.rfv", [
            { label: f.rfDetails?.name, value: f.rfDetails },
          ]);
          setValue("locationData.sfv", [
            { label: f.sfDetails?.name, value: f.sfDetails },
          ]);
          setValue("locationData.rmfDetails", f.rmfDetails);
          setValue("locationData.rfDetails", f.rfDetails);
          setValue("locationData.sfDetails", f.sfDetails);
        }
        if (["rmfv", "rfv", "sfv"].indexOf(key) == -1) {
          setValue("locationData." + key, f[key]);
          trigger();
        }
      }
    });
  };

  const updateDistrictTownValue = useCallback(async (f) => {
    setDistrictTownLoading(true);
    // District list
    let disOpt = [
      { label: "Select District", value: "" },
      { label: "All District", value: "all" },
    ];
    let r = await UtilityService.getDistrictList(f.state);
    let o = (r?.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });

    disOpt = disOpt.concat(o);
    setValue("districtOptions", disOpt);
    setValue("locationData.district", f.district);

    // Town
    let opt = [
      { label: "Select Towns", value: "" },
      { label: "All Towns", value: "all" },
    ];

    let m = await UtilityService.getTownList(f.state, f.district);

    let op = (m.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });

    opt = opt.concat(op);
    setValue("townOptions", opt);
    setValue("locationData.town", f.town);

    setDistrictTownLoading(false);
  }, []);

  const onAccesLevelChange = () => {
    let defaultForm = { ...defaultFormData };
    // reseting data eccept accessLevelType key
    delete defaultForm.accessLevelType;
    each(defaultForm, (x, k) => {
      setValue("locationData." + k, x);
    });
  };

  const resetForm = () => {
    let defaultForm = { ...defaultFormData };
    each(defaultForm, (x, k) => {
      setValue("locationData." + k, x);
    });
    setEditData({ index: -1, mode: "Add", data: {} });
  };

  const onClickAddMultipleFranchise = () => {
    setMultipleFranchiseModal({
      show: true,
      data: getValues("locationData.franchiseList"),
    });
  };

  const multipleFranchiseModalCb = useCallback(
    (d) => {
      setMultipleFranchiseModal({ show: false, data: "" });
      if (d.status == "submit") {
        setValue("locationData.franchiseIds", d.data.split(","));
        LoadFranchiseDetails(d.data.split(","));
      }
    },
    [LoadFranchiseDetails]
  );

  const LoadFranchiseDetails = async (ids) => {
    let params = {
      page: 1,
      count: 25,
      select: "name,_id,status",
      filter: { _id: { $in: ids } },
    };
    setFranchiseLoader(true);
    const r = await FranchiseService.getFranchises(params);
    if (r.resp.length) {
      setFranchiseList(r.resp);
      setValue("locationData.franchiseList", r.resp);
      addLevels();
    } else {
      Toaster.error("Unable to Fetch Franchise Details");
    }
    setFranchiseLoader(false);
  };

  const onFranchiseInputTypeChange = (chngfn) => (e) => {
    setValue("locationData.rmfv", []);
    setValue("locationData.rfv", []);
    setValue("locationData.sfv", []);
    setValue("locationData.franchiseIds", []);
    setFranchiseList([]);
    chngfn(e);
  };

  const deleteFranchise = (i) => {
    let list = [...franchiseList];
    list.splice(i, 1);
    setFranchiseList([...list]);
  };

  console.log("e", location);

  return (
    <div>
      <div className="row">
        <div className="col-4">
          <SelectInput
            label="Access Level Type"
            register={register}
            name="locationData.accessLevelType"
            isMandatory={false}
            options={accessLevelOptions}
            error={errors?.locationData?.accessLevelType?.message}
            callback={onAccesLevelChange}
          />
        </div>

        {["rmf", "sf", "rf"].indexOf(accessLevel) != -1 ? (
          <div className="col-8 pt-1">
            <Controller
              name="locationData.franchiseSelectType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioInput
                  label="Franchise Input Type"
                  register={register}
                  value={value}
                  onChange={onFranchiseInputTypeChange(onChange)}
                  cols={6}
                  name="status"
                  isMandatory={true}
                  options={franchiseInputTypeOptions}
                  error={errors?.locationData?.franchiseSelectType?.message}
                />
              )}
            />
          </div>
        ) : null}

        {franchiseSelectType == "multiple" &&
        ["rmf", "sf", "rf"].indexOf(accessLevel) != -1 ? (
          <div className="col-auto pt-3">
            <button
              className="btn btn-primary btn-sm fs-val-sm"
              type="button"
              onClick={onClickAddMultipleFranchise}
              disabled={franchiseLoader}
            >
              Add Multiple Franchise
              {franchiseLoader ? <Spinner></Spinner> : null}
            </button>
            <InputErrorMsg msg={errors?.locationData?.franchiseIds?.message} />
          </div>
        ) : null}

        {accessLevel == "rmf" && franchiseSelectType == "single" ? (
          <div className="col-6">
            <label className="fw-semibold mb-1 fs-val-md">
              RMF <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="locationData.rmfv"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="franchise"
                  placeholder="Search by RMF Id/Name"
                  value={value}
                  name="locationData.rmfv"
                  callback={onChange}
                  uid="rmfv"
                  isMultiple={false}
                  isMandatory={true}
                  emptyLabel="RMF Not found"
                  error={errors?.locationData?.rmf?.message}
                  filterParams={{
                    filter: {
                      "sk_franchise_details.franchise_type": "RMF",
                    },
                  }}
                />
              )}
            />
          </div>
        ) : null}

        {accessLevel == "rf" && franchiseSelectType == "single" ? (
          <div className="col-6">
            <label className="fw-semibold mb-1 fs-val-md">
              RF <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="locationData.rfv"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="franchise"
                  placeholder="Search by RF Id/Name"
                  value={value}
                  name="locationData.rfv"
                  callback={onChange}
                  uid="rfv"
                  isMultiple={true}
                  isMandatory={true}
                  emptyLabel="RMF Not found"
                  error={errors?.locationData?.rfv?.message}
                  filterParams={{
                    filter: { "sk_franchise_details.franchise_type": "RF" },
                  }}
                />
              )}
            />
          </div>
        ) : null}

        {accessLevel == "sf" && franchiseSelectType == "single" ? (
          <div className="col-6">
            <label className="fw-semibold mb-1 fs-val-md">
              SF <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="locationData.sfv"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="franchise"
                  placeholder="Search by SF Id/Name"
                  value={value}
                  name="locationData.sfv"
                  callback={onChange}
                  uid="sfv"
                  isMultiple={false}
                  isMandatory={true}
                  emptyLabel="RMF Not found"
                  error={errors?.locationData?.sfv?.message}
                  filterParams={{
                    filter: { "sk_franchise_details.franchise_type": "SF" },
                  }}
                />
              )}
            />
          </div>
        ) : null}

        {accessLevel == "state" ? (
          <>
            {/* State */}
            <div className="col-3 mb-3">
              <SelectInput
                type="text"
                label="State"
                register={register}
                name="locationData.state"
                options={stateListOptions}
                callback={onStateChange}
                isMandatory={true}
              />
            </div>

            {/* District */}
            {districtTownLoading ? (
              <div className="col-3 pt-4 text-center">
                <Spinner title="Loading District List" type="dots"></Spinner>
              </div>
            ) : null}
            {state != "all" && !districtTownLoading && (
              <div className="col-3 mb-3">
                <SelectInput
                  type="text"
                  label="District"
                  register={register}
                  name="locationData.district"
                  options={districtOptions}
                  callback={onDistrictChange}
                  disabled={!state}
                  isMandatory={true}
                />
              </div>
            )}

            {/* Town */}
            {districtTownLoading ? (
              <div className="col-3 pt-4 text-center">
                <Spinner title="Loading Town List" type="dots"></Spinner>
              </div>
            ) : null}
            {state != "all" && district != "all" && !districtTownLoading && (
              <div className="col-3 mb-3">
                <SelectInput
                  type="text"
                  label="Town"
                  register={register}
                  name="locationData.town"
                  options={townOptions}
                  disabled={!district}
                  isMandatory={true}
                />
              </div>
            )}

            {/* Franchise Type */}
            <div className="col-3 mb-3">
              <SelectInput
                type="text"
                label="Franchise Type"
                register={register}
                name="locationData.franchiseType"
                options={franchiseTypeOptions}
                isMandatory={true}
              />
            </div>

            {/* RMF Type */}
            {franchiseType == "RMF" ? (
              <div className="col-3 mb-3">
                <SelectInput
                  type="text"
                  label="RMF Type"
                  register={register}
                  name="locationData.rmfType"
                  options={rmfSubtypeOptions}
                  isMandatory={true}
                />
              </div>
            ) : null}

            {/* RF Type */}
            {franchiseType == "RF" ? (
              <div className="col-3 mb-3">
                <SelectInput
                  type="text"
                  label="RF Type"
                  register={register}
                  name="locationData.rfType"
                  options={[...rfSubtypeOptions]}
                  isMandatory={true}
                />
              </div>
            ) : null}

            {/* SF Type */}
            {franchiseType == "SF" ? (
              <div className="col-3 mb-3">
                <SelectInput
                  type="text"
                  label="SF Type"
                  register={register}
                  name="locationData.sfType"
                  options={[...sfSubtypeOptions]}
                  isMandatory={true}
                />
              </div>
            ) : null}
          </>
        ) : null}

        {franchiseSelectType != "multiple" ? (
          <div className="col-auto pt-4">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={addLevels}
            >
              {editData.mode == "Add" ? "Add" : "Update"}
            </button>
            <button
              className="btn btn-outline-danger btn-sm ms-2"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
          </div>
        ) : null}
      </div>

      {location?.length > 0 && !location[0].franchisees.length && (
        <div className="col-12 mt-2">
          {location.map((e, i) => (
            <div className="row mb-3 fs-val-md" key={i}>
              <div className="col-auto">{i + 1}</div>

              <div className="col">
                {/* State Details */}
                {e?.state == "all" ? "All State" : e?.state}
                {e?.district ? " > " : ""}
                {e?.district == "all" ? "All District" : e?.district}
                {e?.town ? " > " : ""}
                {e?.town == "all" ? "All Town" : e?.town}
                {e?.franchiseType ? " > " : ""}
                {e?.franchiseType}
                {e?.rmfType ? " > " : ""}
                {e?.rmfType == "all" ? "ALL RMF" : e?.rmfType}
                {e?.rfType ? " > " : ""}
                {e?.rfType == "all" ? "ALL RF" : e?.rfType}
                {e?.sfType ? " > " : ""}
                {e?.sfType == "all" ? "ALL SF" : e?.sfType}

                {/* RMF  Details*/}
                {e?.rmfv ? (
                  <>
                    {e?.rmfDetails?.name} {e?.rmfDetails?._id}
                    {e?.rmfDetails?.state
                      ? ", (" + e?.rmfDetails?.state
                      : ""}{" "}
                    {e?.rmfDetails?.district
                      ? ", " + e?.rmfDetails?.district
                      : ""}{" "}
                    {e?.rmfDetails?.town ? "," + e?.rmfDetails?.town + ")" : ""}
                  </>
                ) : null}
                {/* RF  Details*/}
                {e?.rfv ? (
                  <>
                    {e?.rfDetails?.name} {e?.rfDetails?._id}
                    {e?.rfDetails?.state
                      ? ", (" + e?.rfDetails?.state
                      : ""}{" "}
                    {e?.rfDetails?.district
                      ? ", " + e?.rfDetails?.district
                      : ""}{" "}
                    {e?.rfDetails?.town ? "," + e?.rfDetails?.town + ")" : ""}
                  </>
                ) : null}
                {/* SF  Details*/}
                {e.sfv ? (
                  <>
                    {e?.sfDetails?.name} {e?.sfDetails?._id}
                    {e?.sfDetails?.state ? ", (" + e?.sfDetails?.state : ""}
                    {e?.sfDetails?.district
                      ? ", " + e?.sfDetails?.district
                      : ""}
                    {e?.sfDetails?.town ? "," + e?.sfDetails?.town + ")" : ""}
                  </>
                ) : null}
              </div>

              {/* Edit Or Delete */}
              <div className="col-2">
                <span
                  role="button"
                  tabIndex={0}
                  onClick={() => editLevel(i)}
                  className="shadow-sm p-0 px-1 bg-white rounded "
                >
                  <i className="bi bi-pencil-square  text-primary cursor-pointer"></i>
                </span>
                <span
                  className="shadow-sm p-0 px-1 bg-white rounded  ms-3"
                  onClick={() => deleteLevels(i)}
                  role="button"
                  tabIndex={0}
                >
                  <i className="bi bi-trash  text-danger cursor-pointer"></i>
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {franchiseList?.length > 0 && (
        <div className="col-12 mt-4">
          <div className="fw-semibold mb-3 fs-val-md">
            Added Franchises List
          </div>
          {franchiseList.map((e, i) => (
            <div className="row mb-3 fs-val-md" key={i}>
              <div className="col-auto">{i + 1}</div>

              <div className="col">
                {e?.name} - {e?._id}
              </div>

              {/* Edit Or Delete */}
              <div className="col-2">
                <span
                  className="shadow-sm p-0 px-1 bg-white rounded  ms-3"
                  onClick={() => deleteFranchise(i)}
                  role="button"
                  tabIndex={0}
                >
                  <i className="bi bi-trash  text-danger cursor-pointer"></i>
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      <MultipleFranhiseFormModal
        show={multipleFranchiseModal.show}
        data={multipleFranchiseModal.data}
        callback={multipleFranchiseModalCb}
      />
    </div>
  );
};

export default memo(AccessLevelConfig);
