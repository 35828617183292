import layout1Img from "../../assets/layout1.png";
import layout2Img from "../../assets/layout2.png";
import layout3Img from "../../assets/layout3.png";
import layout4Img from "../../assets/layout4.png";
import layout5Img from "../../assets/layout5.png";
import layout6Img from "../../assets/layout6.png";
import layout7Img from "../../assets/layout7.png";
import layout8Img from "../../assets/layout8.png";
import layout9Img from "../../assets/layout9.png";
import layout10Img from "../../assets/layout10.png";
import layout11Img from "../../assets/layout11.png";
import layout12Img from "../../assets/layout12.png";
import layout13Img from "../../assets/layout13.png";
import layout14Img from "../../assets/layout14.png";
import layout15Img from "../../assets/layout15.png";
import layout16Img from "../../assets/layout16.png";
import layout17Img from "../../assets/layout17.png";

const layoutData = [
  {
    img: layout1Img,
    value: "layout1",
    allowed: ["menu", "brand", "category"],
    canChangeBg: true,
    canChangeTitleColor: true,
    showBorderImg: true,
    displayOn: "Both",
  },
  {
    img: layout2Img,
    value: "layout2",
    allowed: ["deal", "comboDeals"],
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout3Img,
    value: "layout3",
    allowed: ["menu", "brand", "category"],
    displayOn: "Both",
  },
  {
    img: layout4Img,
    value: "layout4",
    displayOn: "Both",
    allowed: ["deal", "comboDeals"],
  },
  {
    img: layout5Img,
    value: "layout5",
    allowed: ["deal", "comboDeals"],
    showIcon: true,
    displayOn: "Both",
  },
  {
    img: layout6Img,
    value: "layout6",
    allowed: ["deal", "comboDeals"],
    showIcon: true,
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout7Img,
    value: "layout7",
    allowed: ["deal", "comboDeals"],
    showIcon: true,
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout8Img,
    value: "layout8",
    allowed: ["deal", "comboDeals"],
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout9Img,
    value: "layout9",
    allowed: ["menu", "brand", "category"],
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout10Img,
    value: "layout10",
    allowed: ["menu", "brand", "category", "deal"],
    showBannerImg: true,
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Mobile",
  },
  {
    img: layout11Img,
    value: "layout11",
    displayOn: "Both",
    allowed: ["deal", "comboDeals"],
  },
  {
    img: layout12Img,
    value: "layout12",
    allowed: ["menu", "brand", "category"],
    showDataName: true,
    showGridCount: true,
    displayOn: "Both",
  },
  {
    img: layout13Img,
    value: "layout13",
    allowed: ["menu", "brand", "category"],
    canChangeBg: true,
    canChangeTitleColor: true,
    displayOn: "Both",
  },
  {
    img: layout14Img,
    value: "layout14",
    allowed: ["menu", "brand", "category"],
    canChangeBg: true,
    canChangeTitleColor: true,
    showBgImage: true,
    showBannerImg: true,
    displayOn: "Mobile",
  },
  {
    img: layout15Img,
    value: "layout15",
    allowed: ["menu", "brand", "category"],
    canChangeTitleColor: true,
    showBgImage: true,
    showBannerImg: true,
    displayOn: "Mobile",
  },
  {
    img: layout16Img,
    value: "layout16",
    allowed: ["menu", "brand", "category"],
    canChangeTitleColor: true,
    canChangeBg: true,
    showBannerImg: true,
    displayOn: "Mobile",
  },
  {
    img: layout17Img,
    value: "layout17",
    allowed: ["menu", "brand", "category"],
    canChangeTitleColor: true,
    canChangeBg: true,
    showIcon: true,
    displayOn: "Both",
    showCellBg: true,
  },
];

export default layoutData;
