import { NotificationService } from "@sk/services";
import {
  AutoCompleteInput,
  HighlightText,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { memo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import TemplatesTable from "./TemplatesTable";

const channelOptions = [
  { label: "Choose Channel", value: "" },
  { label: "SMS", value: "SMS" },
  { label: "WhatsApp", value: "WhatsApp" },
  { label: "Email", value: "EMail" },
];

const NotificationTypeDetails = ({ templates, callback }) => {
  const { setValue } = useFormContext();

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState("");

  const smsTemplates = templates.filter(
    (template) => template.channelType === "SMS"
  );

  const whatsappTemplates = templates.filter(
    (template) => template.channelType === "WhatsApp"
  );

  const emailTemplates = templates.filter(
    (template) => template.channelType === "EMail"
  );

  const onTemplateSearch = async (val, callback) => {
    if (!selectedChannel) {
      callback([]);
      return;
    }

    let filter = {
      mode: selectedChannel,
      isActive: true,
    };

    if (val) {
      let v = "/" + val + "/";
      filter.$or = [{ name: v }, { _id: v }];
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id,default",
      filter: filter,
    };

    const r = await NotificationService.getList(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x?._id + ")",
        value: x,
      }))
    );
  };

  const handleChannelChange = (e) => {
    const value = e.target.value;
    setSelectedChannel(value);
    setValue("channel", value);
    setSelectedTemplate(null);
  };

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
  };

  const handleAddTemplate = () => {
    if (!selectedChannel) {
      Toaster.error("Please select a channel");
      return;
    }

    if (!selectedTemplate?.length) {
      Toaster.error("Please select a template");
      return;
    }

    const isDuplicate = templates.some(
      (template) =>
        template.value._id === selectedTemplate?.[0]?.value?._id &&
        template.channelType === selectedChannel
    );

    if (isDuplicate) {
      Toaster.error("This template is already added");
      return;
    }

    const templateData = {
      action: "add",
      data: {
        name: selectedTemplate?.[0]?.value?.name,
        channel: selectedChannel,
        templateId: selectedTemplate?.[0]?.value?._id,
        content: selectedTemplate?.[0]?.value?.default?.body || "",
      },
    };

    callback(templateData);
    setSelectedTemplate(null);
  };

  const handleRemoveTemplate = (type, index, id) => {
    const templateData = {
      action: "remove",
      data: {
        channel: type,
        index: index,
        id: id,
      },
    };

    callback(templateData);
  };

  const tableCallback = (action, data) => {
    if (action === "remove") {
      handleRemoveTemplate(data.channel, data.index, data.id);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-4">
          <div className="col-4">
            <div className="form-group">
              <select
                className="form-select mb-0"
                value={selectedChannel}
                onChange={handleChannelChange}
              >
                {channelOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-7">
            <AutoCompleteInput
              label=""
              placeholder="Search by Template Name/ID"
              value={selectedTemplate}
              onSearch={onTemplateSearch}
              callback={handleTemplateChange}
              uid="template-search"
              disabled={!selectedChannel}
              gap={0}
            />
          </div>
          <div className="col-1 d-flex align-items-end">
            <button
              className="btn btn-primary"
              disabled={!selectedTemplate}
              onClick={handleAddTemplate}
              type="button"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        {smsTemplates.length > 0 && (
          <>
            <div>
              <div className="fw-semibold fs-val-normal mb-1 mt-4">
                Added SMS Templates
              </div>
            </div>
            <TemplatesTable templates={smsTemplates} callback={tableCallback} />
          </>
        )}

        {whatsappTemplates.length > 0 && (
          <>
            <div>
              <div className="fw-semibold fs-val-normal mb-1 mt-4">
                Added WhatsApp Templates
              </div>
            </div>
            <TemplatesTable
              templates={whatsappTemplates}
              callback={tableCallback}
            />
          </>
        )}

        {emailTemplates.length > 0 && (
          <>
            <div>
              <div className="fw-semibold fs-val-normal mb-1 mt-4">
                Added Email Templates
              </div>
            </div>
            <TemplatesTable
              templates={emailTemplates}
              callback={tableCallback}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(NotificationTypeDetails);
