import {
  HighlightText,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "#", width: "5%", isCentered: true },
  { label: "Basic Details", width: "25%" },
  { label: "Rule Configuration", width: "20%" },
  { label: "Notification Details", width: "30%" },
  { label: "Status", width: "10%" },
  { label: "Actions", width: "10%", isCentered: true },
];

const rbac = {
  viewMarketPromotion: ["ViewMarketingPromotion", "ViewBrand"],
  editMarketPromotion: ["EditMarketingPromotion", "ViewBrand"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  viewCb,
}) => {
  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-2"
      />

      <table className="table table-sm">
        <TableHeader data={headers} noBg />
        <tbody>
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data?.length && (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          )}

          {!loading &&
            data.map((item, index) => (
              <tr key={item._id} className="fs-val-md">
                <td className="text-center" style={{ width: "50px" }}>
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Basic Details */}
                <td>
                  <div className="d-flex flex-column">
                    <div className="fw-semibold mb-1 mt-1">
                      {item.title || "N/A"}
                    </div>
                    <div className="mb-3">
                      <span className="badge badge-soft-primary">
                        {item.type || "N/A"}
                      </span>
                    </div>

                    {item._franchise?._id && (
                      <div className="d-flex align-items-start gap-2 fs-val-sm">
                        <div className="d-flex flex-column">
                          <div className="fw-medium">
                            {item._franchise?.name}
                          </div>
                          <div className="text-muted small">
                            FID: {item._franchise?._id}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </td>

                {/* Rule Configuration */}
                <td>
                  <KeyVal label="Event" fwSize="sm">
                    : {item.criteriaDetails?.eventType || "N/A"}
                  </KeyVal>

                  <KeyVal label="Execute" fwSize="sm">
                    : {item.executeOption || "N/A"}
                  </KeyVal>

                  <div className="d-flex flex-wrap gap-1">
                    {item._notificationTypes.map((type, idx) => (
                      <span key={idx} className="badge badge-soft-primary">
                        {type}
                      </span>
                    ))}
                  </div>
                </td>

                {/* Notification Stats */}
                <td>
                  <div className="d-flex flex-column gap-2">
                    {["SMS", "WhatsApp", "Email"].map((channel) => (
                      <div
                        key={channel}
                        className="d-flex align-items-center gap-2"
                      >
                        <span
                          className="text-muted small"
                          style={{ width: "80px" }}
                        >
                          {channel}:
                        </span>
                        <div className="d-flex gap-2">
                          <span className="badge badge-soft-primary">
                            Sent: 0
                          </span>
                          <span className="badge badge-soft-danger">
                            Failed: 0
                          </span>
                          <span className="badge badge-soft-success">
                            Delivered: 0
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </td>

                {/* Status */}
                <td>
                  {item.executeOption == "Scheduled" && item.isActive ? (
                    <HighlightText status={item?.status} isBadge={true} />
                  ) : (
                    <HighlightText
                      status={item?.isActive ? "Active" : "Inactive"}
                      isBadge={true}
                    />
                  )}
                </td>

                {/* Actions */}
                <td className="text-center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Action buttons"
                  >
                    <Rbac roles={rbac.viewMarketPromotion}>
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => viewCb(item?._id)}
                      >
                        View
                      </button>
                    </Rbac>
                    <Rbac roles={rbac.editMarketPromotion}>
                      <button
                        className="btn btn-sm btn-outline-warning"
                        onClick={() => editCb(item?._id)}
                      >
                        Edit
                      </button>
                    </Rbac>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="px-4 py-3">
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          showSummary={false}
          size="sm"
        />
      </div>
    </>
  );
};

export default memo(Table);
